import { API, graphqlOperation } from 'aws-amplify'
import Query from '../graphql'
import { GET_EXCEL_DATA, GET_EXPORT_HISTORY, GET_EXPORT_STATUS } from '../types/action.types'
import { normalizeTickets } from '../helpers/normalizers';

export const exportAllObservations = () => dispatch => {
  return new Promise(async function(resolve, reject) {
    try {
      const data = await API.get('export', '/export/observations', {
        responseType: 'text',
        response: true,
        headers: { 'Content-Type': 'application/octet-stream' },
      })

      dispatch({
        payload: data,
        type: GET_EXCEL_DATA,
      })

      resolve(data)
    } catch (err) {
      reject(err.response)
    }
  })
}

export const exportObservations = input => dispatch => {
  return new Promise(async function(resolve, reject) {
    try {
      const data = await API.post('export', '/export/observations', {
        responseType: 'text',
        response: true,
        headers: { 'Content-Type': 'application/octet-stream' },
        body: {
          project: input.project !== -1 ? input.project : '',
          mortality: input.mortality !== -1 ? input.mortality : '',
          species: input.species !== -1 ? input.species : '',
          speciesType: input.speciesType !== -1 ? input.speciesType : '',
          status: input.status !== -1 ? input.status : '',
          startDate: input.startDate ? input.startDate : '',
          endDate: input.endDate ? input.endDate : '',
        },
      })

      dispatch({
        payload: data,
        type: GET_EXCEL_DATA,
      })

      resolve(data)
    } catch (err) {
      reject(err.response)
    }
  })
}

export const exportWOS = input => dispatch => {
  return new Promise(async function(resolve, reject) {
    try {
      const data = await API.post('export', '/export/wos', {
        responseType: 'text',
        response: true,
        headers: { 'Content-Type': 'application/octet-stream' },
        body: {
          startDate: input.startDate ? input.startDate : '',
          endDate: input.endDate ? input.endDate : '',
        },
      })

      dispatch({
        payload: data,
        type: GET_EXCEL_DATA,
      })

      resolve(data)
    } catch (err) {
      reject(err.response)
    }
  })
}

export const getExportHistory = () => dispatch => {
  return new Promise(async function(resolve, reject) {
    try {
      const {
        data: { getExportHistory },
      } = await API.graphql(
        graphqlOperation(Query.getExportHistory),
      )

      dispatch({
        type: GET_EXPORT_HISTORY,
        payload: normalizeTickets(getExportHistory)
      })

      resolve(getExportHistory)
    } catch (err) {
      console.error(err)
      reject(err)
    }
  })
}

export const getExportStatus = ticketId => dispatch => {
  return new Promise(async function(resolve, reject) {
    try {
      const {
        data: { getExportStatus },
      } = await API.graphql(
        graphqlOperation(Query.getExportStatus, {
          input: {
            ticketId
          }
        }),
      )

      dispatch({
        type: GET_EXPORT_STATUS,
        payload: getExportStatus
      })

      resolve(getExportStatus)
    } catch (err) {
      console.error(err)
      reject(err)
    }
  })
}

export const exportUsers = input => dispatch => {
  return new Promise(async function(resolve, reject) {
    try {
      const data = await API.post('export', '/export/users', {
        responseType: 'text',
        response: true,
        headers: { 'Content-Type': 'application/octet-stream' },
        body: {
          active: input.active !== -1 ? input.active : '',
        },
      })

      dispatch({
        payload: data,
        type: GET_EXCEL_DATA,
      })

      resolve(data)
    } catch (err) {
      reject(err.response)
    }
  })
}
