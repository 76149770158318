import {
  APP_START,
  CLEAR_REVIEW_DATA,
  DISABLE_FORM_SUBMIT,
  ENABLE_FORM_SUBMIT,
  SET_REVIEW_DATA,
  UPDATE_FORM_TYPES,
  SET_FORM_METADATA,
  CLEAR_FORM_METADATA,
} from '../types/action.types'

const defaultState = {
  data: null,
  disableFormSubmit: false,
  metadata: null,
}

export default function (state = defaultState, action) {
  switch (action.type) {
    case UPDATE_FORM_TYPES:
      return {
        ...state,
        types: {
          ...action.payload,
          speciesByType: JSON.parse(action.payload.speciesByType),
        },
      }
    case APP_START:
      return {
        ...state,
        disableFormSubmit: false, // make sure this is never persisted
      }
    case DISABLE_FORM_SUBMIT:
      return {
        ...state,
        disableFormSubmit: true,
      }
    case ENABLE_FORM_SUBMIT:
      return {
        ...state,
        disableFormSubmit: false,
      }
    case SET_REVIEW_DATA:
      return {
        ...state,
        data: action.payload,
      }
    case CLEAR_REVIEW_DATA:
      return {
        ...state,
        data: null,
        metadata: null,
      }
    case CLEAR_FORM_METADATA:
      return {
        ...state,
        metadata: null,
      }
    case SET_FORM_METADATA:
      return {
        ...state,
        metadata: {
          ...state.metadata,
          ...action.payload,
        },
      }
    default:
      return state
  }
}
