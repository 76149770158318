import { OPEN_FLASH_MESSAGE, CLOSE_FLASH_MESSAGE } from '../types/action.types'

export function showFlashMessage(message, variant, duration) {
  return function(dispatch) {
    dispatch({
      type: OPEN_FLASH_MESSAGE,
      payload: {
        message,
        variant,
        duration
      },
    })
  }
}

export function hideFlashMessage() {
  return function(dispatch) {
    dispatch({
      type: CLOSE_FLASH_MESSAGE,
    })
  }
}
