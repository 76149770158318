import {
  AUTH_ERROR,
  AUTH_USER,
  AUTHENTICATING,
  SET_NEW_PASSWORD_USER,
  UNAUTH_USER,
  SET_RESET_PASSWORD_EMAIL
} from '../types/action.types'

const initialState = {
  isAuthenticated: false,
  isAuthenticating: true,
}

export default function(state = initialState, action) {
  switch (action.type) {
    case AUTH_USER:
      return {
        ...state,
        error: null,
        isAuthenticated: true,
        isAuthenticating: false,
      }
    case UNAUTH_USER:
      return {
        ...state,
        error: null,
        isAuthenticated: false,
        isAuthenticating: false,
      }
    case AUTHENTICATING:
      return { ...state, isAuthenticating: action.payload }
    case AUTH_ERROR:
      return { ...state, error: action.payload, isAuthenticating: false }
    case SET_NEW_PASSWORD_USER:
      return { ...state, newPasswordUser: action.payload }
    case SET_RESET_PASSWORD_EMAIL:
      return { ...state, resetPasswordEmail: action.payload }
    default:
      return state
  }
}
