import React from 'react'
import classnames from 'classnames'
import { withStyles, Typography } from '@material-ui/core'
import { IonInput, IonLabel } from '@ionic/react'

const styles = (theme) => ({
  root: {},
  label: {
    marginBottom: '0.4rem',
    color: theme.palette.primary.main,
    fontSize: '1.1rem',
  },
  value: {
    fontSize: '1.4rem',
  },
  highlightLabel: {
    fontWeight: '600',
  },
  highlightValue: {
    fontWeight: '600',
  },
})

const LabelValue = ({
  classes,
  className,
  disabled,
  editable,
  highlight,
  label,
  onBlur,
  onChange,
  step,
  type,
  value,
  inputmode
}) => {
  let labelClassNames = classes.label
  let valueClassNames = classes.value

  if (highlight) {
    labelClassNames = classnames(classes.label, classes.highlightLabel)
    valueClassNames = classnames(classes.value, classes.highlightValue)
  }

  return (
    <div className={classnames(classes.root, className)}>
      <IonLabel className={labelClassNames}>{label}</IonLabel>
      {editable ? (
        <IonInput
          className="form-control"
          disabled={disabled}
          inputmode={inputmode}
          onIonBlur={onBlur}
          onIonChange={onChange}
          step={step}
          type={type}
          value={value}
        />
      ) : (
        <Typography className={valueClassNames}>{value}</Typography>
      )}
    </div>
  )
}

export default withStyles(styles)(LabelValue)
