import React, { Fragment } from 'react'
import { withStyles } from '@material-ui/core'
import LabelValue from '../text/LabelValue'

const styles = theme => ({
  root: {},
})

const StandardObservation = props => {
  const { observation } = props

  return (
    <Fragment>
      {observation.trailSegment && (
        <LabelValue
          label="Trail Segment"
          value={observation.trailSegment.description}
        />
      )}
      {observation.obsPeriod && (
        <LabelValue
          label="Observation Period"
          value={observation.obsPeriod.description}
        />
      )}
      <LabelValue
        label="Sex"
        value={observation.sex ? observation.sex.description : ''}
      />
      <LabelValue
        label="Age"
        value={observation.age ? observation.age.description : ''}
      />
      <LabelValue label="Quantity" value={observation.quantity} />
      <LabelValue
        label="Activity"
        value={observation.activity ? observation.activity.description : ''}
      />
      <LabelValue
        label="Mortality"
        value={
          observation.mortality ? observation.mortality.description : 'N/A'
        }
      />
    </Fragment>
  )
}

export default withStyles(styles)(StandardObservation)
