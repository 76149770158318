import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withStyles, Typography } from '@material-ui/core'
import { Formik, Field } from 'formik'
import LoaderButton from '../../components/buttons/LoaderButton'
import actions from '../../actions'
import { DateTime } from 'luxon'
import { DatePicker } from 'react-rainbow-components'

const propTypes = {
  user: PropTypes.object.isRequired,
}

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    marginBottom: '4rem',
  },
  button: {
    margin: theme.spacing(0.5),
  },
  fieldWrapper: {
    marginBottom: '1rem',
  },
  dateLabel: {
    fontSize: '1.2rem',
    marginLeft: '1rem',
  },
})

class TrainingDateForm extends Component {
  handleSubmit = (values, actions) => {
    const { setTrainingDate } = this.props

    setTrainingDate(values)
      .then(actions.setSubmitting(false))
      .catch(actions.setSubmitting(false))
  }

  render() {
    const { classes, user } = this.props

    let trainingDate = null

    if (user.trainingDate) {
      if (user.trainingDate.includes('/')) {
        trainingDate = new Date(user.trainingDate).toISOString()
      } else {
        // eslint-disable-next-line prefer-destructuring
        trainingDate = user.trainingDate
      }
    }

    return (
      <section className={classes.root}>
        <Typography gutterBottom variant="h2">
          Nature Mapping Training Date
        </Typography>
        <Formik
          enableReinitialize
          initialValues={{ id: user._id, trainingDate }}
          onSubmit={this.handleSubmit}
        >
          {(props) => (
            <form onSubmit={props.handleSubmit}>
              <div>
                <p className={classes.dateLabel}>
                  {props.values.trainingDate
                    ? DateTime.fromISO(props.values.trainingDate)
                        .toLocal()
                        .toFormat('yyyy-LL-dd')
                    : 'No training date selected yet'}
                </p>
                <div className={classes.fieldWrapper}>
                  <Field component="input" name="id" type="hidden" />
                  <Field
                    borderRadius="semi-square"
                    className={classes.datePicker}
                    component={DatePicker}
                    id="trainingDate"
                    label={`${user.firstName} attended training on`}
                    maxDate={new Date()}
                    minDate={new Date(2010, 0, 1)}
                    name="trainingDate"
                    onChange={(e) => {
                      const dateTime = DateTime.fromJSDate(e)
                      props.setFieldValue('trainingDate', dateTime.toISO())
                    }}
                    value={
                      props.values.trainingDate
                        ? DateTime.fromISO(props.values.trainingDate)
                            .toLocal()
                            .toJSDate()
                        : null
                    }
                  />
                </div>
              </div>
              <div>
                <LoaderButton
                  disabled={
                    props.isSubmitting || user.level === props.values.level
                  }
                  text="Save Date"
                  type="submit"
                  variant="contained"
                />
              </div>
            </form>
          )}
        </Formik>
      </section>
    )
  }
}

TrainingDateForm.propTypes = propTypes

function mapStateToProps({ admin }) {
  return {
    user: admin.currentUser,
  }
}

const mapDispatchToProps = {
  setTrainingDate: actions.setTrainingDate,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(TrainingDateForm))
