import React from 'react'
import * as HighCharts from 'highcharts'
import { useIonViewDidEnter } from '@ionic/react'


export const BarChart = ({ data, id, title, seriesName }) => {
  useIonViewDidEnter(() => {
    populate()
  })

  const populate = () => {
    HighCharts.chart(id, {
      chart: {
        type: 'bar',
      },
      title: {
        text: title,
      },
      xAxis: {
        type: 'category',
      },
      yAxis: {
        opposite: true,
        title: {
          text: null,
        },
      },
      plotOptions: {
        series: {
          pointPadding: 0.4,
          pointWidth: 20,
          borderRadius: 4,
          borderWidth: 0,
          colorByPoint: true,
          dataSorting: {
            enabled: true,
            matchByName: true,
          },
          dataLabels: {
            enabled: true,
          },
        },
      },
      series: [
        {
          type: 'bar',
          name: seriesName,
          data,
        },
      ],
      legend: {
        enabled: false,
      },
      credits: {
        enabled: false,
      },
    })
  }

  return (
    <div className="ion-padding">
      <div id={id} style={{ height: `${data.length * 35 + 90}px` }}></div>
    </div>
  )
}
