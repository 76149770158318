import { UPDATE_USER, UPDATE_USER_STATS } from '../types/action.types'

const initialState = {
  observations: [],
  observationEntries: [],
  projects: [],
  settings: {},
  stats: {},
}

export default function (state = initialState, action) {
  switch (action.type) {
    case UPDATE_USER:
      return {
        ...state,
        ...action.payload,
      }
    case UPDATE_USER_STATS:
      return {
        ...state,
        stats: action.payload,
      }
    default:
      return state
  }
}
