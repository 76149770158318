import agencyRoadkill from './agencyRoadkill'
import backyard from './backyard'
import beaver from './beaver'
import casual from './casual'
import mooseDay from './mooseDay'
import snakeRiverFloat from './snakeRiverFloat'
import gv001 from './gv001'
import gv002 from './gv002'
import fieldEducation from './fieldEducation'
import nestboxProject from './nestboxProject'
import redTailedHawk from './redTailedHawk'
import ospreyProject from './ospreyProject'
import neighborsToNature from './neighborsToNature'
import gvMorning from './gvMorning'
import gameCameras from './gameCameras'
import wildlifeTour from './wildlifeTour'

export default {
  agencyRoadkill,
  backyard,
  beaver,
  casual,
  fieldEducation,
  gv001,
  gv002,
  gvMorning,
  nestboxProject,
  ospreyProject,
  mooseDay,
  neighborsToNature,
  redTailedHawk,
  snakeRiverFloat,
  gameCameras,
  wildlifeTour
}
