import { createSelector } from 'reselect'
import { toArray } from 'lodash'
import { defaultReactTableState } from '../reducers/tableData.reducer'

export const admin = state => state.admin
export const user = state => state.user
export const projects = state => state.projects
export const form = state => state.form
export const layout = state => state.layout
export const dataExports = state => state.dataExports
export const tableData = state => state.tableData

export const userSettings = createSelector(
  user,
  user => user.settings,
)

export const userStats = createSelector(
  user,
  user => user.stats,
)

export const dataVetterAccess = createSelector(
  userSettings,
  userSettings =>
    userSettings &&
    userSettings.dataVetterAccess &&
    (userSettings.dataVetterAccess.projects.length > 0 ||
      userSettings.dataVetterAccess.speciesTypes.length > 0) &&
    userSettings.dataVetterAccess
      ? userSettings.dataVetterAccess
      : {},
)

export const dataVetterStats = createSelector(
  admin,
  admin => admin.dataVetterStats || [],
)

export const notVerifiedObservations = createSelector(
  admin,
  admin => admin.notVerified || [],
)

export const formTypes = createSelector(
  form,
  form => form.types,
)

export const speciesTypes = createSelector(
  formTypes,
  formTypes => formTypes.speciesTypes,
)

export const online = createSelector(
  layout,
  layout => layout.online,
)

export const currentDataVerificationSelectedTab = createSelector(
  layout,
  layout => layout.currentDataVerificationSelectedTab,
)

export const myDataExports = createSelector(
  [ user, dataExports],
  (user, dataExports) => {
    return toArray(dataExports).filter(item => user._id === item.user._id)
  }
)

export const openDataExports = createSelector(
  myDataExports,
  myDataExports => {
    return myDataExports.filter(item => !item.url && !item.error)
  }
)

const tableKey = (state, props) => props && props.tableKey

export const getTableData = createSelector(
  tableKey,
  tableData,
  (tableKey, tableData) => {
    return tableData[tableKey] || defaultReactTableState
  }
)

export const getTableView = createSelector(
  tableData,
  (tableData) => {
    return tableData.tableView
  }
)
