import { DateTime } from 'luxon'
import { getSpeciesEnum, getSpeciesEnumNames } from '../../../../helpers/utils'

const speciesSortOrder = ['930', '931', '932', '925', '935', '901', '941', '940', '181', '206', '352', '194', '364']

const hiddenTourActivities = (item => {
  return !['8', '5.1'].includes(item.code)
})

const createSchema = (formTypes, options = {}) => ({
  steps: [
    {
      type: 'object',
      required: ['dateTime', 'species', 'onTour'],
      properties: {
        species: {
          type: 'string',
          title: 'What species was it?',
          enum: getSpeciesEnum('wildlifeTour', formTypes, null, options.isTaxonomicSortOrderEnabled),
          enumNames: getSpeciesEnumNames('wildlifeTour', formTypes, null, options.isTaxonomicSortOrderEnabled),
        },
        onTour: {
          title: 'Are you on a wildlife tour?',
          type: 'boolean',
          default: false,
        },
        dateTime: {
          title: 'When did you make this observation?',
          type: 'string',
          format: 'date-time',
          default: DateTime.local().toISO(),
        },
        comment: {
          type: 'string',
          title: 'Notes',
        },
      },
    },
    {
      definitions: {
        observation: {
          type: 'object',
          required: ['quantity', 'activity'],
          properties: {
            quantity: {
              type: 'number',
              title: 'Quantity',
              default: 1,
            },
            activity: {
              type: 'string',
              title: 'Activity',
              enum: formTypes ? formTypes.activities.filter(hiddenTourActivities).map(item => item._id) : [],
              enumNames: formTypes
                ? formTypes.activities.filter(hiddenTourActivities).map(item => item.description)
                : [],
              default:
                formTypes &&
                formTypes.activities.filter(hiddenTourActivities).find(item => item.code === '0')._id,
            },
          },
        },
      },
      type: 'object',
      properties: {
        observations: {
          type: 'object',
          title: 'What did you see?',
          $ref: '#/definitions/observation',
        },
        comment: {
          type: 'string',
          title: 'Notes',
        },
      },
    },
    {
      type: 'object',
      properties: {
        location: {
          title: 'Where did you observe this species?',
          type: 'string',
        },
      },
    },
    {
      type: 'object',
      properties: {
        photos: {
          type: 'string',
          title: 'Photos',
        },
      },
    },
  ],
})

const uiSchema = {
  dateTime: {
    'ui:widget': 'DateTimeWidget',
    'ui:options': {
      placeholder: 'Select date and time'
    }
  },
  comment: {
    'ui:options': {
      rows: 4,
      resize: false,
      placeholder: 'Any notes about the observation?',
    },
    'ui:widget': 'textarea',
  },
  observations: {
    items: {
      activity: {
        'ui:widget': 'SelectWidget',
      },
      quantity: {
        'ui:widget': 'NumberWidget',
      },
    },
  },
  species: {
    'ui:widget': 'SpeciesWidget',
    'ui:options': {
      placeholder: 'Select or type species'
    }
  },
  location: {
    'ui:widget': 'UtmWidget',
  },
  onTour: {
    'ui:widget': 'RadioWidget',
  },
  photos: {
    'ui:widget': 'PhotoWidget',
  },
}

export default {
  createSchema,
  speciesSortOrder,
  uiSchema,
}
