import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { IonApp, setupIonicReact } from '@ionic/react'
import { IonReactRouter } from '@ionic/react-router'
import { Hub, Logger } from 'aws-amplify'
import { Network } from '@capacitor/network'
import * as Sentry from '@sentry/capacitor'
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import * as Highcharts from 'highcharts'

import NavRoutes from './pages/NavRoutes'
import ErrorBoundary from './components/errors/ErrorBoundary'
import actions from './actions'
import './theme/react-jsonschema-form-overrides.css'

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css'

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css'
import '@ionic/react/css/structure.css'
import '@ionic/react/css/typography.css'

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css'
import '@ionic/react/css/float-elements.css'
import '@ionic/react/css/text-alignment.css'
import '@ionic/react/css/text-transformation.css'
import '@ionic/react/css/flex-utils.css'
import '@ionic/react/css/display.css'

/* Theme variables */
import './theme/variables.css'

/* Global CSS */
import './global.css'

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  release: 'nature-mapping@0.0.1',
  dist: '1',
  tracesSampleRate: 1.0,
})

const logger = new Logger('My-Logger')

const listener = (data: any) => {
  switch (data.payload.event) {
    case 'signIn':
      logger.info('user signed in')
      console.log('user signed in')
      break
    case 'signUp':
      logger.info('user signed up')
      console.log('user signed up')
      break
    case 'signOut':
      logger.info('user signed out')
      console.log('user signed out')
      break
    case 'signIn_failure':
      logger.error('user sign in failed')
      console.error('user sign in failed')
      break
    case 'tokenRefresh':
      logger.info('token refresh succeeded')
      console.log('token refresh succeeded')
      break
    case 'tokenRefresh_failure':
      logger.error('token refresh failed')
      console.error('token refresh failed')
      break
    case 'configured':
      logger.info('the Auth module is configured')
      console.log('the Auth module is configured')
  }
}

Hub.listen('auth', listener)

setupIonicReact({
  rippleEffect: false
})

interface IAppProps {
  isAuthenticated: boolean
  signout: Function
  updateOnlineStatus: Function
}

const App: React.FC<IAppProps> = (props) => {
  const { isAuthenticated, updateOnlineStatus } = props
  let pollingInterval: any = null
  let networkHandler: any = null

  useEffect(() => {
    if (isAuthenticated) {
      setupNetworkListener()
      startPolling()
    }

    return () => {
      clearInterval(pollingInterval)
      Network.removeAllListeners()
    }
  }, [])

  useEffect(() => {
    if (isAuthenticated) {
      setupNetworkListener()
      startPolling()
    }
  }, [isAuthenticated])

  const setupNetworkListener = async () => {
    if (!networkHandler) {
      networkHandler = Network.addListener(
        'networkStatusChange',
        async (status) => {
          console.log('Network status changed', status)
          if (status.connected) {
            // TODO add logic to show warning with offline
          }
        }
      )
    }
  }

  const ping = async () => {
    return new Promise(async (resolve) => {
      const isOnline = async () => {
        resolve(true)
      }
      const isOffline = () => resolve(false)

      try {
        let status = await Network.getStatus()
        if (status.connected) {
          isOnline()
        } else {
          isOffline()
        }
      } catch (e) {
        console.error(e)
        isOffline()
      }
    })
  }

  const startPolling = () => {
    ping().then((online) => {
      updateOnlineStatus(online)
    })

    pollingInterval = setInterval(() => {
      ping().then((online) => {
        updateOnlineStatus(online)
      })
    }, 20000)
  }

  const darkMode = false

  return (
    <IonApp className={`${darkMode ? 'dark-theme' : ''}`}>
      <ErrorBoundary>
        <IonReactRouter>
          <NavRoutes />
        </IonReactRouter>
      </ErrorBoundary>
    </IonApp>
  )
}

interface IProps {
  auth: any
}

function mapStateToProps({ auth }: IProps) {
  return {
    isAuthenticated: auth.isAuthenticated,
  }
}

// @ts-ignore
export default connect(mapStateToProps, actions)(App)
