import gql from 'graphql-tag'
import Fragments from '../fragments'

export const getAllFormTypesQuery = gql`
  query getAllFormTypesQuery {
    getAllFormTypes {
      ...FormTypes
    }
  }
  ${Fragments.FormTypesFragment}
`

export const getAppStats = gql`
  query getAppStats {
    getAppStats {
      userCount
      observationCount
      speciesCount
    }
  }
`
