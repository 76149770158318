import React from 'react'
import { DateTime } from 'luxon'
import { Checkbox } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/DeleteOutlined'
import { IonButton, useIonAlert } from '@ionic/react'

const activity = {
  accessor: 'activity',
  Header: 'Activity',
  width: 150,
}

const age = {
  accessor: 'age',
  Header: 'Age',
  width: 100,
}

const comment = {
  accessor: 'comment',
  Header: 'Comment',
  disableFilters: true,
  width: 400,
  Cell: ({ cell }) => {
    const { value } = cell

    if (!value) {
      return ''
    }

    return value
  },
}

const county = {
  accessor: 'county',
  Header: 'County',
  width: 170,
}

const friendlyId = {
  accessor: 'friendlyId',
  Header: 'NM Id',
  width: 150,
}

const mortality = {
  accessor: 'mortality',
  Header: 'Mortality',
  width: 150,
}

const ofTheWeek = {
  accessor: originalRow => originalRow.obsDateTime,
  Cell: ({ cell }) => {
    const { value } = cell

    if (!value) {
      return 'Not specified'
    }

    return DateTime.fromISO(value)
      .startOf('week')
      .toLocaleString(DateTime.DATE_SHORT)
  },
  disableFilters: true,
  Header: 'Week of',
  width: 100,
}

const obsDateTime = {
  accessor: 'obsDateTime',
  Cell: ({ cell }) => {
    const { value } = cell

    if (!value) {
      return 'Not specified'
    }

    return DateTime.fromISO(value).toLocaleString(DateTime.DATE_SHORT)
  },
  disableFilters: true,
  Header: 'Date',
  width: 100,
}

const createdDateTime = {
  accessor: 'created',
  Cell: ({ cell }) => {
    const { value } = cell

    if (!value) {
      return 'Not specified'
    }

    return DateTime.fromISO(value).toLocaleString(DateTime.DATE_SHORT)
  },
  disableFilters: true,
  Header: 'Created',
  width: 100,
}

const firstName = {
  accessor: 'firstName',
  disableFilters: true,
  Header: 'First',
  width: 70,
}

const lastName = {
  accessor: 'lastName',
  Header: 'Last Name',
  width: 100,
}

const project = {
  accessor: 'project',
  Header: 'Project',
  width: 140,
}

const sex = {
  accessor: 'sex',
  Header: 'Sex',
  width: 140,
}

const speciesType = {
  accessor: 'speciesType',
  Header: 'Wildlife Type',
  width: 120
}

const species = {
  accessor: 'species',
  Header: 'Species',
  width: 150,
}

const status = {
  accessor: 'status',
  Header: 'Status',
  width: 130,
}

const quantity = {
  accessor: 'quantity',
  Header: 'Quantity',
  disableFilters: true,
  width: 70,
  Cell: ({ cell }) => {
    const { value } = cell

    if (!value) {
      return ''
    }

    return value
  },
}

const draftActions = {
  id: 'actions',
  Header: 'Actions',
  disableFilters: true,
  width: 90,
  Cell: ({ cell, deleteDraftObservation }) => {
    const [presentAlert] = useIonAlert()

    return (
      <>
        <IonButton
          onClick={() =>
            presentAlert({
              header: 'Are you sure?',
              message: 'Do you want to delete this draft observation?',
              buttons: [
                {
                  text: 'Cancel',
                  role: 'cancel',
                  handler: () => {},
                },
                {
                  text: 'OK',
                  role: 'confirm',
                  handler: () => {
                    deleteDraftObservation(cell.row.original.offlineKey)
                  },
                },
              ],
            })
          }
        >
          <DeleteIcon />
        </IonButton>
      </>
    )
  },
}

const selectRows = {
  id: 'selection',
  width: 60,
  Header: ({ getToggleAllRowsSelectedProps }) => (
    <Checkbox {...getToggleAllRowsSelectedProps()} />
  ),
  Cell: ({ row }) => (
    <Checkbox {...row.getToggleRowSelectedProps()} />
  ),
}

const dataVerificationObservation = [
  selectRows,
  firstName,
  lastName,
  obsDateTime,
  ofTheWeek,
  speciesType,
  species,
  quantity,
  age,
  sex,
  activity,
  mortality,
  { accessor: 'nestbox.number', Header: 'Nestbox', width: 110 },
  comment,
  county,
  project,
  createdDateTime
]

const myObservations = [
  project,
  obsDateTime,
  species,
  quantity,
  activity,
  age,
  sex,
  mortality,
  comment,
  county,
  {
    accessor: row => (row.nestbox ? row.nestbox.utmEasting : row.utmEasting),
    Header: 'UTM Easting',
    id: 'utmEasting',
    disableFilters: true,
  },
  {
    accessor: row => (row.nestbox ? row.nestbox.utmNorthing : row.utmNorthing),
    Header: 'UTM Northing',
    id: 'utmNorthing',
    disableFilters: true,
  },
  {
    accessor: row => (row.nestbox ? row.nestbox.utmZone : row.utmZone),
    Header: 'UTM Zone',
    id: 'utmZone',
    disableFilters: true,
  },
  {
    accessor: row => (row.nestbox ? row.nestbox.latitude : row.latitude),
    Header: 'Latitude',
    id: 'latitude',
    disableFilters: true,
    Cell: ({ cell }) => {
      const { value } = cell

      if (!value) {
        return ''
      }

      return parseFloat(value).toFixed(6)
    },
  },
  {
    accessor: row => (row.nestbox ? row.nestbox.longitude : row.longitude),
    Header: 'Longitude',
    id: 'longitude',
    disableFilters: true,
    Cell: ({ cell }) => {
      const { value } = cell

      if (!value) {
        return ''
      }

      return parseFloat(value).toFixed(6)
    },
  },
  { accessor: 'nestbox.number', Header: 'Nestbox', width: 110 },
  status,
  friendlyId
]

const draftObservations = [
  draftActions,
  obsDateTime,
  species,
  project,
  comment,
  {
    accessor: row => (row.nestbox ? row.nestbox.utmEasting : row.utmEasting),
    Header: 'UTM Easting',
    id: 'utmEasting',
    disableFilters: true,
  },
  {
    accessor: row => (row.nestbox ? row.nestbox.utmNorthing : row.utmNorthing),
    Header: 'UTM Northing',
    id: 'utmNorthing',
    disableFilters: true,
  },
  {
    accessor: row => (row.nestbox ? row.nestbox.utmZone : row.utmZone),
    Header: 'UTM Zone',
    id: 'utmZone',
    disableFilters: true,
  },
  {
    accessor: row => (row.nestbox ? row.nestbox.latitude : row.latitude),
    Header: 'Latitude',
    id: 'latitude',
    disableFilters: true,
  },
  {
    accessor: row => (row.nestbox ? row.nestbox.longitude : row.longitude),
    Header: 'Longitude',
    id: 'longitude',
    disableFilters: true,
  },
]

const nestboxProject = [
  { accessor: 'nestbox.number', Header: 'Nestbox', width: 90 },
  obsDateTime,
  species,
  status,
  { accessor: 'creator', Header: 'Creator', width: 180 },
  {
    accessor: 'nestMaterial',
    Cell: ({ cell }) => {
      const { value } = cell

      return value ? 'Yes' : 'No'
    },
    Header: 'Nest Material?',
    width: 100
  },
  { accessor: 'eggs', Header: 'Eggs', width: 100 },
  { accessor: 'fledglings', Header: 'Fledglings', width: 100 },
  { accessor: 'deadNestlings', Header: 'Dead Nestlings', width: 100 },
  { accessor: 'nestlings', Header: 'Nestlings', width: 100 },
  { accessor: 'unhatchedEggs', Header: 'Unhatched Eggs', width: 100 },
  comment,
]

const observation = [
  obsDateTime,
  species,
  quantity,
  age,
  sex,
  activity,
  mortality,
  comment,
  project,
  speciesType,
]

const photoObservation = [
  obsDateTime,
  species,
  project,
  comment,
  speciesType,
]

const gameCameras = [
  { accessor: 'gameCamera.name', Header: 'Game Camera', width: 90 },
  ...observation
]

const user = [
  {
    accessor: 'email',
    Header: 'Email',
    width: 220,
  },
  firstName,
  lastName,
  { accessor: 'cell', Header: 'Cell', disableFilters: true },
  { accessor: 'address1', Header: 'Address', disableFilters: true },
  {
    accessor: 'city',
    Header: 'City',
    disableFilters: true
  },
  {
    accessor: 'state',
    Header: 'State',
    disableFilters: true
  },
  { accessor: 'zip', Header: 'Zip', disableFilters: true },
  { accessor: 'country', Header: 'Country', disableFilters: true },
  { accessor: 'utmEasting', Header: 'UTM Easting', disableFilters: true },
  { accessor: 'utmNorthing', Header: 'UTM Northing', disableFilters: true },
  { accessor: 'utmZone', Header: 'UTM Zone', disableFilters: true },
  {
    accessor: 'signupDate',
    Header: 'Signup date',
    disableFilters: true
  },
  {
    accessor: 'trainingDate',
    Header: 'Training date',
    disableFilters: true
  },
  { accessor: 'userVisits', Header: 'User visits', disableFilters: true },
  { accessor: 'lastUserVisit', Header: 'Last user visit', disableFilters: true },
]

export default {
  dataVerificationObservation,
  draftObservations,
  gameCameras,
  myObservations,
  nestboxProject,
  observation,
  photoObservation,
  user,
}
