import React from 'react'
import { connect } from 'react-redux'
import { Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import UserTable from '../../components/tables/UserTable'
import actions from '../../actions'
import OfflineWarning from '../../components/micros/OfflineWarning';
import tableColumns from '../../components/tables/tableColumns'
import { IonButton } from '@ionic/react'

const styles = {
  root: {
    position: 'relative',
  },
  sectionTop: {
    marginBottom: '1rem',
  },
}

const Users = props => {
  const { classes, getUsersList, online } = props

  return (
    <div className={classes.root}>
      <div className={classes.sectionTop}>
        <Typography variant="h4">
          User Management
        </Typography>
        <Typography gutterBottom>
          Select a user from the table below to view and edit their account.
        </Typography>
        <IonButton routerDirection="forward" routerLink="/admin/user/create">
          Create New User
        </IonButton>
      </div>
      {online ? (
        <UserTable
          columns={tableColumns.user}
          fetchData={getUsersList}
          tableKey="users"
        />
      ) : (
        <OfflineWarning />
      )}
    </div>
  )
}

function mapStateToProps({ layout, projects, user }) {
  return {
    online: layout.online,
    projects,
    user,
  }
}

const mapDispatchToProps = {
  getUsersList: actions.getUsersList,
  setCurrentUser: actions.setCurrentUser,
}

export default withStyles(styles)(connect(
  mapStateToProps,
  mapDispatchToProps,
)(Users))
