import * as ObservationQueries from './observations'
import * as UserQueries from './users'
import * as FormQueries from './forms'
import * as ProjectQueries from './projects'

export default {
  ...FormQueries,
  ...ObservationQueries,
  ...ProjectQueries,
  ...UserQueries,
}
