import {
  SET_INFORMATION_DRAWER,
  CLOSE_INFORMATION_DRAWER,
  NETWORK_CHECK,
  SET_DATA_VERIFICATION_SELECTED_TAB,
  CLEAR_LOCATION_LOGS,
  WRITE_LOCATION_LOGS,
  SET_LOCATION_STATE,
  SET_MENU_ENABLED,
} from '../types/action.types'

export const setInformationDrawer = (title, infoText) => ({
  type: SET_INFORMATION_DRAWER,
  payload: {
    title,
    infoText,
  },
})

export const closeInformationDrawer = () => ({
  type: CLOSE_INFORMATION_DRAWER,
})

export const updateOnlineStatus = online => {
  return {
    type: NETWORK_CHECK,
    payload: {
      online,
    },
  }
}

export const setMenuEnabled = menuEnabled => {
  return {
    type: SET_MENU_ENABLED,
    payload: menuEnabled
  }
}

export const updateDataVerificationSelectedTab = selected => {
  return {
    type: SET_DATA_VERIFICATION_SELECTED_TAB,
    payload: selected
  }
}

export const writeLocationLog = log => {
  return {
    type: WRITE_LOCATION_LOGS,
    payload: log
  }
}

export const setLocationState = state => {
  return {
    type: SET_LOCATION_STATE,
    payload: state
  }
}

export const clearLogs = () => {
  return {
    type: CLEAR_LOCATION_LOGS
  }
}
