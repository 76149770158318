import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { DateTime } from 'luxon'
import { withStyles, Typography } from '@material-ui/core'
import LabelValue from '../../../components/text/LabelValue'
import SelectWidget from './SelectWidget'
import { getSelectedSeason, safeWrap } from '../../../helpers/utils'
import { useLocation } from 'react-router-dom'

const styles = (theme) => ({
  commonalityGrid: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridGap: 15,

    [theme.breakpoints.up('sm')]: {
      gridTemplateColumns: '1fr 1fr 1fr 1fr',
    },

    '& > div': {
      padding: '0.5rem',
    }
  },
  alert: {
    textAlign: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(3, 118, 189, 0.15)',
    padding: '0.8rem 1rem',
    marginTop: '1rem',
    marginBottom: '1rem',
  },
  selectedSeason: {
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
    fontWeight: 'bold',

    '& label': {
      color: '#fff',
    }
  }
})

const CommonalityDisplay = (props) => {
  const { classes, data, obsDateTime } = props

  let selectedSeason = null

  if (obsDateTime) {
    selectedSeason = getSelectedSeason(obsDateTime)
  }

  if (data.occurrence) {
    return (
      <div className={classes.alert}>
        <Typography variant="h6">Occurrence</Typography>
        <div>
          <LabelValue label="General" value={data.occurrence} />
        </div>
      </div>
    )
  }

  return (
    <div className={classes.alert}>
      <Typography variant="h6">Occurrence</Typography>
      <div className={classes.commonalityGrid}>
        <LabelValue
          className={selectedSeason === 'spring' ? classes.selectedSeason : ''}
          highlight={selectedSeason === 'spring'}
          label="Spring"
          value={data.spring ? data.spring : 'N/A'}
        />
        <LabelValue
          className={selectedSeason === 'summer' ? classes.selectedSeason : ''}
          highlight={selectedSeason === 'summer'}
          label="Summer"
          value={data.summer ? data.summer : 'N/A'}
        />
        <LabelValue
          className={selectedSeason === 'fall' ? classes.selectedSeason : ''}
          highlight={selectedSeason === 'fall'}
          label="Fall"
          value={data.fall ? data.fall : 'N/A'}
        />
        <LabelValue
          className={selectedSeason === 'winter' ? classes.selectedSeason : ''}
          highlight={selectedSeason === 'winter'}
          label="Winter"
          value={data.winter ? data.winter : 'N/A'}
        />
      </div>
    </div>
  )
}

const StyledCommonalityDisplay = withStyles(styles)(CommonalityDisplay)

const SpeciesWidget = (props) => {
  const { data, onChange, speciesList, value } = props
  const species = speciesList.find((species) => species.code === value)
  const obsDateTime = data && data.dateTime && DateTime.fromISO(data.dateTime)
  const location = useLocation()

  // eslint-disable-next-line prefer-destructuring
  const projectKey = location.pathname.split('/')[3]

  const handleChange = (value) => {
    onChange(value)
  }

  return (
    <div>
      <SelectWidget {...props} onChange={handleChange} />
      {species && species.commonality && !['nestboxProject', 'mooseDay'].includes(projectKey) && (
        <StyledCommonalityDisplay
          data={species.commonality}
          obsDateTime={obsDateTime}
        />
      )}
    </div>
  )
}

SpeciesWidget.propTypes = {
  autoFocus: PropTypes.bool,
}

const mapStateToProps = ({ form }) => {
  return {
    speciesList: form && form.types && form.types.species,
    data: form.data,
  }
}

export default safeWrap(connect(mapStateToProps)(SpeciesWidget))
