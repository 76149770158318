/* eslint-disable no-unused-vars */
import { createTheme } from '@material-ui/core/styles'

const orange = '#F37342'
const red = '#f44336'
const successGreen = '#43a047'
const green = '#537552'
const white = '#FFFFFF'
const textColor = '#2f2f2f'
const blue = '#0277bd'
const jhBlue = '#799CA5'
const jhGreen = '#537552'
const rainyBlue = '#9AADBD'

const customTheme = createTheme({
  typography: {
    fontFamily: [
      'Roboto',
      '"Helvetica Neue"',
      'Karla',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    fontVariantLigatures: 'none',
  },
  palette: {
    primary: {
      light: '#c8e6c9',
      main: green,
      dark: '#4c6e76',
      contrastText: '#000000',
      borderColor: '#e4e4e4',
      error: red,
      textColor,
    },
    secondary: {
      light: '#39539a',
      main: '#23408f',
      dark: '#1f387e',
      contrastText: '#ffffff',
    },
  },
  props: {
    MuiButtonBase: {
      disableRipple: true,
    },
  },
  overrides: {
    MuiAppBar: {
      root: {
        borderRadius: 0,
      },
      colorDefault: {
        backgroundColor: blue,
      },
      colorPrimary: {
        backgroundColor: white,
      },
    },
    MuiAccordionDetails: {
      root: {
        display: 'block',
      },
    },
    MuiBox: {
      root: {
        padding: 0,
      },
    },
    MuiAccordionSummary: {
      content: {
        alignItems: 'center',
      },
    },
    MuiCheckbox: {
      colorSecondary: {
        color: green,

        '&$checked': {
          color: green,
        },

        '& svg': {
          width: '2rem',
          height: '2rem',
        },
      },
    },
    MuiTabs: {
      indicator: {
        backgroundColor: green,
      },
      flexContainer: {
        flexWrap: 'wrap',
      },
    },
    MuiTab: {
      root: {
        fontWeight: 600,
        fontSize: '1rem',
        padding: '1rem',

        '@media (min-width:600px)': {
          // eslint-disable-line no-useless-computed-key
          fontSize: '1rem',
        },
        '&$selected': {
          backgroundColor: green,
          color: white
        },
      },
    },
    MuiToolbar: {
      regular: {
        minHeight: '50px',
        '@media (min-width:600px)': {
          // eslint-disable-line no-useless-computed-key
          minHeight: '50px',
        },
      },
    },
    MuiSvgIcon: {
      root: {
        height: '1.5rem',
        width: '1.5rem',
      },
    },
    MuiPaper: {
      root: {
        borderRadius: 4,
        color: 'inherit',
        backgroundColor: 'initial',
        background: 'none',
        overflow: 'hidden',
      },
    },
    MuiDrawer: {
      paper: {
        boxShadow: '2px 0 20px 0 rgba(0,0,0,0.1)',
      },
      docked: {
        position: 'fixed',
        zIndex: 1200,
      },
    },
    MuiFormGroup: {
      root: {
        paddingTop: '1rem',
        width: '100%',
      },
    },
    MuiFormControl: {
      root: {
        width: '100%',
      },
    },
    MuiFormHelperText: {
      root: {
        fontSize: '0.8rem',
      },
      contained: {
        margin: '8px 6px 0',
      },
    },
    MuiFormLabel: {
      root: {
        color: 'inherit',
        fontSize: '1rem',
        fontWeight: '600',
        marginBottom: 0,
      },
    },
    MuiFormControlLabel: {
      root: {
        marginBottom: 0,
      },
    },
    MuiInput: {
      formControl: {
        'label + &': {
          marginTop: '1.2rem',
        },
      },
    },
    MuiInputLabel: {
      shrink: {
        transform: 'translate(0, 1.5px)',
      },
    },
    MuiButton: {
      root: {
        color: textColor,
        fontSize: '1.1rem',
        lineHeight: '1.2',
        padding: '0.7rem 1.6rem',
        transition: `background-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms`,

        '& p': {
          transition: `color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms`,
        },
      },
      label: {
        fontWeight: 600,
      },
      text: {
        color: textColor,
        fontSize: '1.1rem',
        lineHeight: '1.2',
        padding: '0.6rem 1.2rem',
        transition: `background-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms`,

        '&:hover, &:focus': {
          backgroundColor: 'rgba(102, 187, 106, 0.3)',
        },
      },
      contained: {
        backgroundColor: green,
        borderRadius: '25px',

        '&:hover': {
          backgroundColor: blue,
          color: white,

          '& p': {
            color: white,
          },
        },
      },
    },
    MuiDivider: {
      root: {
        backgroundColor: '#e4e4e4',
      },
    },
    MuiSnackbar: {
      root: {
        top: 0,
      },
      anchorOriginTopCenter: {
        top: 0,

        '@media (min-width:600px)': {
          top: 0,
        },
      },
    },
    MuiSnackbarContent: {
      root: {
        padding: '0.2rem 1.5rem',
        backgroundColor: green,
        borderRadius: '0 0 4px 4px',
        top: 0,

        '@media (min-width:960px)': {
          borderRadius: '0 0 4px 4px',
        },
      },
      message: {
        fontSize: '1.1rem',
        margin: '0 auto',
      },
    },
    MuiTypography: {
      root: {
        fontVariantLigatures: 'none',
      },
      gutterBottom: {
        marginBottom: '0.3rem',
      },
      h1: {
        fontSize: '2.2rem',
      },
      h2: {
        fontSize: '2rem',
      },
      h3: {
        '&$gutterBottom': {
          marginBottom: '1.8rem',
        },
      },
      h4: {
        fontSize: '1.3rem',
      },
      h5: {
        fontSize: '1.1rem',
        fontWeight: '600',
      },
      h6: {
        fontSize: '1rem',
      },
      body1: {
        fontSize: '1rem',

        '&$gutterBottom': {
          marginBottom: '0.8rem',
        },
      },
      body2: {
        fontSize: '1.2rem',

        '&$gutterBottom': {
          marginBottom: '0.8rem',
        },
      },
      caption: {
        fontSize: '0.9rem',
        fontWeight: 500,
        lineHeight: '1.4',
      },
      subtitle1: {
        fontSize: '1.2rem',
        lineHeight: 1.25,
        fontWeight: 500,
        color: green,
      },
      subtitle2: {
        fontSize: '1.2rem',
      },
    },
  },
})

// black: '#21262E'
export default customTheme
