import React from 'react'
import { connect } from 'react-redux'
import { IonButton, useIonViewDidEnter } from '@ionic/react'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import { me } from '../actions/user.actions'
import {
  setCurrentObservation,
  deleteDraftObservation,
} from '../actions/observations.actions'
import { clearReviewData } from '../actions/form.actions'
import MyObservations from './observations/MyObservations.page'
import OfflineObservationTable from '../components/tables/OfflineObservationTable'
import IonicPageLayout from '../components/siteLayout/IonicPageLayout'
import tableColumns from '../components/tables/tableColumns'
import GrizzlyBears from '../assets/images/grizzlyBears.jpg'
import { scrollToTop } from '../helpers/utils'

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: 'center',
  },
  welcomeHeadline: {
    color: theme.palette.primary.main,
    margin: '1rem 0.8rem 1rem',
    textTransform: 'uppercase',
    fontSize: '1.5rem',
  },
  section: {
    margin: '1rem 0',
  },
  innerSection: {
    marginTop: '1rem',
    marginBottom: '2rem',
  },
  welcomeImage: {
    width: '100%',
    maxWidth: 600
  }
}))
const Home = (props) => {
  const {
    projects,
    formTypes,
    deleteDraftObservation,
    drafts,
    user,
    me,
    clearReviewData,
    isAuthenticated,
  } = props
  const styles = useStyles()

  useIonViewDidEnter(() => {
    clearReviewData()

    if (isAuthenticated) {
      me()
    }

    scrollToTop()
  })

  const offlineData = drafts
    ? drafts.map(({ projectKey, data }) => {
        const project =
          projects && projects.find((project) => project.key === projectKey)
        const species =
          formTypes &&
          formTypes.species.find((species) => species.code === data.species)
        const location = data.location ? JSON.parse(data.location) : null

        return {
          comment: data.comment,
          obsDateTime: data.dateTime,
          offlineKey: data.offlineKey,
          project: project ? project.name : '',
          projectKey: project ? project.key : '',
          species: species ? species.commonName : '',
          utmEasting:
            location && location.utmEasting ? location.utmEasting : '',
          utmNorthing:
            location && location.utmNorthing ? location.utmNorthing : '',
          utmZone: location && location.utmZone ? location.utmZone : '',
          latitude: location && location.lat ? location.lat : '',
          longitude: location && location.lng ? location.lng : '',
          formData: data,
        }
      })
    : []

  return (
    <IonicPageLayout>
      {!isAuthenticated && (
        <div className={styles.root}>
          <Typography align="center" component="h1" variant="srOnly">
            Nature Mapping Jackson Hole
          </Typography>
          {drafts && drafts.length > 0 && (
            <div>
              <Typography component="h2" variant="h4">
                Draft Observations
              </Typography>
              <Typography gutterBottom>Click to edit and submit.</Typography>
              <OfflineObservationTable
                {...props}
                columns={tableColumns.draftObservations}
                data={offlineData}
                defaultPageSize={drafts.length}
                deleteDraftObservation={deleteDraftObservation}
                getTrProps={(state, rowInfo, column, instance) => ({
                  onClick: () => console.log(rowInfo, column, instance),
                })}
              />
            </div>
          )}
          <Typography className={styles.welcomeHeadline} variant="h2">
            Make Your Wildlife Sightings Count
          </Typography>
          <img alt="Grizzly Bears" className={styles.welcomeImage} src={GrizzlyBears} />
          <div className={styles.section}>
            <div className={styles.innerSection}>
              <IonButton routerLink={'/signin'}>
                Sign in
              </IonButton>
            </div>
            <div className={styles.innerSection}>
              <Typography gutterBottom variant="subtitle1">
                Want to join Nature Mapping?
              </Typography>
              <IonButton routerLink={'/signup'}>
                Sign up and enter observations
              </IonButton>
            </div>
          </div>
        </div>
      )}
      {isAuthenticated && (
        <>
          {drafts && drafts.length > 0 && (
            <div>
              <Typography component="h2" variant="h4">
                Draft Observations
              </Typography>
              <Typography gutterBottom>Click to edit and submit.</Typography>
              <OfflineObservationTable
                {...props}
                columns={tableColumns.draftObservations}
                data={offlineData}
                defaultPageSize={drafts.length}
                deleteDraftObservation={deleteDraftObservation}
                getTrProps={(state, rowInfo, column, instance) => ({
                  onClick: () => console.log(rowInfo, column, instance),
                })}
              />
            </div>
          )}
          <div>
            <Typography component="h2" variant="h4">
              Submitted Observations
            </Typography>
            {user.observations && user.observations.length > 0 ? (
              <>
                <Typography gutterBottom variant="srOnly">
                  Select an observation from the table below to view details
                </Typography>
                <MyObservations />
              </>
            ) : (
              <div>
                <Typography>
                  When you have entered observations, they will be shown here.
                </Typography>
              </div>
            )}
          </div>
        </>
      )}
    </IonicPageLayout>
  )
}

function mapStateToProps(state) {
  const { auth, form, drafts, projects, user } = state
  return {
    formTypes: form.types,
    isAuthenticated: auth.isAuthenticated,
    drafts: drafts.observations,
    projects,
    user,
  }
}

const mapDispatchToProps = {
  clearReviewData,
  setCurrentObservation,
  deleteDraftObservation,
  me,
}

export default connect(mapStateToProps, mapDispatchToProps)(Home)
