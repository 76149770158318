/* eslint-disable prefer-destructuring */
import React, { memo, useEffect } from 'react'
import { connect } from 'react-redux'
import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api'
import proj4 from 'proj4'
import { MarkerInfoWindow } from './MarkerInfoWindow'
import { setCurrentVerificationIndex } from '../../actions/observations.actions'
import { IonLoading, useIonModal } from '@ionic/react'
import { googleMapLibraries } from '../../helpers/utils'
import { useHistory, useLocation } from 'react-router-dom'

const containerStyle = {
  height: '100%',
  minHeight: '40vh',
  width: '100%',
}

// Jackson, WY
const defaultCenter = { lat: 43.4799, lng: -110.7624 }

const Map = (props) => {
  const {
    data = [],
    tableKey,
    setCurrentVerificationIndex,
  } = props
  const location = useLocation()
  const history = useHistory()

  const { isLoaded, loadError } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    libraries: googleMapLibraries
  })

  // eslint-disable-next-line no-unused-vars
  const [map, setMap] = React.useState(null)

  const onLoad = React.useCallback(function callback(map) {
    const bounds = new window.google.maps.LatLngBounds()
    const coordinates = data.map((observation) => {
      if (
        (!observation.latitude || !observation.longitude) &&
        !observation.nestbox
      ) {
        const utm = `+proj=utm +zone=${observation.utmZone}`
        const wgs84 = '+proj=longlat +ellps=GRS80 +datum=NAD83 +no_defs'

        const conversion = proj4(utm, wgs84, [
          observation.utmEasting,
          observation.utmNorthing,
        ])

        observation.latitude = conversion[1]
        observation.longitude = conversion[0]
      }

      if (observation.nestbox) {
        observation.latitude = observation.nestbox.latitude
        observation.longitude = observation.nestbox.longitude
      }

      const { latitude, longitude } = observation
      const latLng = new window.google.maps.LatLng(latitude, longitude)
      bounds.extend(latLng)
      return latLng
    })

    map && coordinates.length && map.fitBounds(bounds)
    setMap(map)
  }, [])

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null)
  }, [])

  const [selectedMarker, setSelectedMarker] = React.useState(null)

  useEffect(() => {
    if (selectedMarker) {
      present(modalOptions)
    }
  }, [selectedMarker])

  const goToObservation = () => {
    if (location.pathname.indexOf('verification') !== -1) {
      const index = data.findIndex((item) => item._id === selectedMarker._id)
      setCurrentVerificationIndex(tableKey, index)
      dismiss()
      return history.push(
        `/dashboard/me/verify/${tableKey}/${selectedMarker._id}`
      )
    } else {
      dismiss()
      return history.push(`/dashboard/me/view/${selectedMarker._id}`)
    }
  }

  const [present, dismiss] = useIonModal(MarkerInfoWindow, {
    marker: selectedMarker,
    goToObservation,
  })

  const modalOptions = {
    initialBreakpoint: 0.4,
    breakpoints: [0, 0.4, 0.6, 0.8, 1],
    backdropBreakpoint: 0,
    onDidDismiss: () => {
      setSelectedMarker(null)
      dismiss()
    },
  }

  const markers = data.map((observation) => {
    if (
      (!observation.latitude || !observation.longitude) &&
      !observation.nestbox
    ) {
      const utm = `+proj=utm +zone=${observation.utmZone}`
      const wgs84 = '+proj=longlat +ellps=GRS80 +datum=NAD83 +no_defs'

      const conversion = proj4(utm, wgs84, [
        observation.utmEasting,
        observation.utmNorthing,
      ])

      observation.latitude = conversion[1]
      observation.longitude = conversion[0]
    }

    if (observation.nestbox) {
      observation.latitude = observation.nestbox.latitude
      observation.longitude = observation.nestbox.longitude
    }

    return (
      <Marker
        key={observation._id}
        onClick={() => setSelectedMarker(observation)}
        position={{
          lat: parseFloat(observation.latitude),
          lng: parseFloat(observation.longitude),
        }}
      ></Marker>
    )
  })

  if (loadError) {
    return <div>Map cannot be loaded right now, sorry.</div>
  }

  return isLoaded ? (
    <GoogleMap
      defaultCenter={defaultCenter}
      mapContainerStyle={containerStyle}
      onLoad={onLoad}
      onUnmount={onUnmount}
      options={{
        fullscreenControl: false,
        mapTypeId: 'hybrid',
        streetViewControl: false,
      }}
      zoom={13}
    >
      {markers}
    </GoogleMap>
  ) : (
    <IonLoading />
  )
}

const mapDispatchToProps = {
  setCurrentVerificationIndex,
}

export default memo(connect(
  null,
  mapDispatchToProps
)(Map))
