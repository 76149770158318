import { API, Auth, graphqlOperation } from 'aws-amplify'
import { DateTime } from 'luxon'
import {
  CLEAR_CURRENT_USER,
  CREATE_USER,
  SET_CURRENT_USER,
  UPDATE_USER,
  UPDATE_TABLE_DATA,
  UPDATE_USER_STATS,
} from '../types/action.types'
import Query from '../graphql'
import { showFlashMessage } from './flash.actions'
import { signout } from './auth.actions'

export const setCurrentUser = (user) => ({
  type: SET_CURRENT_USER,
  payload: user,
})

export const clearCurrentUser = () => ({
  type: CLEAR_CURRENT_USER,
})

export const me = () => {
  return async function (dispatch) {
    try {
      const {
        data: { me },
      } = await API.graphql(graphqlOperation(Query.me))

      dispatch({
        payload: me,
        type: UPDATE_USER,
      })
    } catch (err) {
      console.error(err)
    }
  }
}

export const getUser = (id) => (dispatch) => {
  return new Promise(async function (resolve, reject) {
    try {
      const {
        data: { getUser },
      } = await API.graphql(
        graphqlOperation(Query.getUser, {
          input: {
            id,
          },
        })
      )

      dispatch(setCurrentUser(getUser))

      resolve(getUser)
    } catch (err) {
      console.error(err)
      reject(err)
    }
  })
}

export const passwordMigrationCheck2023 = (email) => (dispatch) => {
  return new Promise(async function (resolve, reject) {
    try {
      const {
        data: { passwordMigrationCheck2023 },
      } = await API.graphql(
        graphqlOperation(Query.passwordMigrationCheck2023, {
          input: {
            email,
          },
        })
      )
      console.log('passwordMigrationCheck2023', passwordMigrationCheck2023)
      resolve(passwordMigrationCheck2023)
    } catch (err) {
      console.error(err)
      reject(err)
    }
  })
}

export const forgotPassword = (email) => (dispatch) => {
  return new Promise(async function (resolve, reject) {
    try {
      const {
        data: { forgotPassword },
      } = await API.graphql(
        graphqlOperation(Query.forgotPassword, {
          input: {
            email,
          },
        })
      )
      console.log('forgotPassword', forgotPassword)
      resolve(forgotPassword)
    } catch (err) {
      console.error(err)
      reject(err)
    }
  })
}

export const confirmForgotPassword = (email, code, password) => (dispatch) => {
  return new Promise(async function (resolve, reject) {
    try {
      const {
        data: { confirmForgotPassword },
      } = await API.graphql(
        graphqlOperation(Query.confirmForgotPassword, {
          input: {
            email,
            code,
            password
          },
        })
      )
      console.log('confirmForgotPassword', confirmForgotPassword)
      resolve(confirmForgotPassword)
    } catch (err) {
      console.error(err)
      reject(err)
    }
  })
}

export const getUserStats = () => (dispatch) => {
  return new Promise(async function (resolve, reject) {
    try {
      const {
        data: { getUserStats },
      } = await API.graphql(graphqlOperation(Query.getUserStats))

      dispatch({
        payload: getUserStats,
        type: UPDATE_USER_STATS,
      })

      resolve(getUserStats)
    } catch (err) {
      console.error(err)
      reject(err)
    }
  })
}

export const updateUserVisits = (id) => (dispatch) => {
  return new Promise(async function (resolve, reject) {
    try {
      const {
        data: { updateUserVisits },
      } = await API.graphql(
        graphqlOperation(Query.updateUserVisits, {
          input: {
            id,
          },
        })
      )

      dispatch({
        payload: updateUserVisits,
        type: UPDATE_USER,
      })

      resolve(updateUserVisits)
    } catch (err) {
      console.error(err)
      reject(err)
    }
  })
}

export const deleteSelf = () => (dispatch) => {
  return new Promise(async function (resolve, reject) {
    try {
      const {
        data: { deleteSelf },
      } = await API.graphql(graphqlOperation(Query.deleteSelf))

      await Auth.signOut({ global: true })
      dispatch(signout())

      dispatch(
        showFlashMessage(
          `Your account has been deleted successfully`,
          'success'
        )
      )
      resolve(deleteSelf)
    } catch (err) {
      console.error(err)
      dispatch(showFlashMessage(`Your account was not been deleted`, 'danger'))
      reject(err)
    }
  })
}

export const deleteUser = (id, email) => (dispatch) => {
  return new Promise(async function (resolve, reject) {
    try {
      if (!email) {
        dispatch(
          showFlashMessage(`A user must have an email to be deleted`, 'danger')
        )
        return reject()
      }
      const {
        data: { deleteUser },
      } = await API.graphql(
        graphqlOperation(Query.deleteUser, {
          input: {
            id,
            email,
          },
        })
      )

      dispatch(
        showFlashMessage(`User deleted successfully for ${email}`, 'success')
      )
      resolve(deleteUser)
    } catch (err) {
      console.error(err)
      dispatch(showFlashMessage(`User not deleted`, 'danger'))
      reject(err)
    }
  })
}

export const updateUserMessagesSeen = (messageName) => (dispatch) => {
  return new Promise(async function (resolve, reject) {
    try {
      const {
        data: { updateUserMessagesSeen },
      } = await API.graphql(
        graphqlOperation(Query.updateUserMessagesSeen, {
          input: {
            messageName,
          },
        })
      )

      dispatch({
        payload: updateUserMessagesSeen,
        type: UPDATE_USER,
      })

      resolve(updateUserVisits)
    } catch (err) {
      console.error(err)
      reject(err)
    }
  })
}

export const updateUserDirectPreAuth = (data) => (dispatch) => {
  return new Promise(async function (resolve, reject) {
    try {
      dispatch({
        payload: data,
        type: UPDATE_USER,
      })

      resolve()
    } catch (err) {
      console.error(err)
      reject(err)
    }
  })
}

export const getUsersList =
  ({
    tableKey,
    tableState: { pageIndex = 0, pageSize = 5, sortBy, filters },
  }) =>
  (dispatch) => {
    return new Promise(async function (resolve, reject) {
      try {
        const {
          data: { getUsersList },
        } = await API.graphql(
          graphqlOperation(Query.getUsersList, {
            input: {
              limit: pageSize,
              offset: pageIndex,
              sortBy,
              filters,
            },
          })
        )

        dispatch({
          payload: {
            tableKey,
            pageIndex,
            pageSize,
            sortBy,
            filters,
            rows: getUsersList.data,
            total: getUsersList.count,
          },
          type: UPDATE_TABLE_DATA,
        })

        resolve(getUsersList)
      } catch (err) {
        console.error(err)
        reject(err)
      }
    })
  }

export const isAdmin = () => (dispatch) => {
  return new Promise(async function (resolve, reject) {
    try {
      const {
        data: { isAdmin },
      } = await API.graphql(graphqlOperation(Query.isAdmin))

      resolve(isAdmin)
    } catch (err) {
      console.error(err)
      reject(err)
    }
  })
}

export const isDataVetter = () => (dispatch) => {
  return new Promise(async function (resolve, reject) {
    try {
      const {
        data: { isDataVetter },
      } = await API.graphql(graphqlOperation(Query.isDataVetter))

      resolve(isDataVetter)
    } catch (err) {
      console.error(err)
      reject(err)
    }
  })
}

export const createUser = (input) => (dispatch) => {
  return new Promise(async function (resolve, reject) {
    try {
      const {
        data: { createUser },
      } = await API.graphql(
        graphqlOperation(Query.createUser, {
          input,
        })
      )

      dispatch({
        payload: createUser,
        type: CREATE_USER,
      })

      dispatch(
        showFlashMessage(
          `User created successfully for ${input.email}`,
          'success'
        )
      )

      resolve(createUser)
    } catch (err) {
      dispatch(
        showFlashMessage(
          `Error: User could not be created for ${input.email}`,
          'danger'
        )
      )

      console.error(err)
      reject(err)
    }
  })
}

export const assignProjectsToUser = (input) => (dispatch) => {
  return new Promise(async function (resolve, reject) {
    try {
      const {
        data: { assignProjectsToUser },
      } = await API.graphql(
        graphqlOperation(Query.assignProjectsToUser, {
          input,
        })
      )

      dispatch(setCurrentUser(assignProjectsToUser))

      dispatch(
        showFlashMessage('Project access updated successfully', 'success')
      )

      resolve(assignProjectsToUser)
    } catch (err) {
      console.error(err)
      dispatch(
        showFlashMessage(
          'Project access could not be updated at this time',
          'danger'
        )
      )
      reject(err)
    }
  })
}

export const updateUserDataVettingAccess = (input) => (dispatch) => {
  return new Promise(async function (resolve, reject) {
    try {
      const {
        data: { updateUserDataVettingAccess },
      } = await API.graphql(
        graphqlOperation(Query.updateUserDataVettingAccess, {
          input,
        })
      )

      dispatch(setCurrentUser(updateUserDataVettingAccess))

      dispatch(
        showFlashMessage('Data vetter access updated successfully', 'success')
      )

      resolve(updateUserDataVettingAccess)
    } catch (err) {
      console.error(err)
      dispatch(
        showFlashMessage(
          'Data vetter access could not be updated at this time',
          'danger'
        )
      )
      reject(err)
    }
  })
}

export const updateUserEmail = (email) => (dispatch) => {
  return new Promise(async function (resolve, reject) {
    try {
      const {
        data: { updateUserEmail },
      } = await API.graphql(
        graphqlOperation(Query.updateUserEmail, {
          input: {
            email,
          },
        })
      )

      dispatch({
        payload: updateUserEmail,
        type: UPDATE_USER,
      })

      dispatch(showFlashMessage('User email updated successfully', 'success'))

      resolve(updateUserEmail)
    } catch (err) {
      console.error(err)
      dispatch(
        showFlashMessage(
          'User email could not be updated at this time',
          'danger'
        )
      )
      reject(err)
    }
  })
}

export const changeUserLevel = (input) => (dispatch) => {
  return new Promise(async function (resolve, reject) {
    try {
      const {
        data: { changeUserLevel },
      } = await API.graphql(
        graphqlOperation(Query.changeUserLevel, {
          input,
        })
      )

      dispatch(setCurrentUser(changeUserLevel))

      dispatch(
        showFlashMessage('User access level updated successfully', 'success')
      )

      resolve(changeUserLevel)
    } catch (err) {
      console.error(err)
      dispatch(
        showFlashMessage(
          'User access level could not be updated at this time',
          'danger'
        )
      )
      reject(err)
    }
  })
}

export const setTrainingDate = (input) => (dispatch) => {
  return new Promise(async function (resolve, reject) {
    try {
      const {
        data: { setTrainingDate },
      } = await API.graphql(
        graphqlOperation(Query.setTrainingDate, {
          input: {
            ...input,
            trainingDate:
              input.trainingDate &&
              input.trainingDate.toLocaleString(DateTime.DATE_SHORT),
          },
        })
      )

      dispatch(setCurrentUser(setTrainingDate))

      dispatch(
        showFlashMessage('Training date was updated successfully', 'success')
      )

      resolve()
    } catch (err) {
      console.error(err)
      dispatch(
        showFlashMessage(
          'Training date could not be updated at this time',
          'danger'
        )
      )
      reject(err)
    }
  })
}

export const activateUser = (id) => (dispatch) => {
  return new Promise(async function (resolve, reject) {
    try {
      const {
        data: { activateUser },
      } = await API.graphql(
        graphqlOperation(Query.activateUser, {
          input: {
            id,
          },
        })
      )

      dispatch(setCurrentUser(activateUser))

      dispatch(showFlashMessage('User activited', 'success'))

      resolve(activateUser)
    } catch (err) {
      console.error(err)
      dispatch(showFlashMessage('User could not be activited', 'danger'))
      reject(err)
    }
  })
}

export const deactivateUser = (id) => (dispatch) => {
  return new Promise(async function (resolve, reject) {
    try {
      const {
        data: { deactivateUser },
      } = await API.graphql(
        graphqlOperation(Query.deactivateUser, {
          input: {
            id,
          },
        })
      )

      dispatch(setCurrentUser(deactivateUser))

      dispatch(showFlashMessage('User deactivited', 'success'))

      resolve(deactivateUser)
    } catch (err) {
      console.error(err)
      dispatch(showFlashMessage('User could not be deactivated', 'danger'))
      reject(err)
    }
  })
}

export const updateUserSettings =
  ({ firstName, lastName, phone, cell, taxonomicSortOrder, passwordMigrated}) =>
  (dispatch) => {
    return new Promise(async function (resolve, reject) {
      try {
        const {
          data: { updateUserSettings },
        } = await API.graphql(
          graphqlOperation(Query.updateUserSettings, {
            input: {
              firstName,
              lastName,
              phone,
              cell,
              taxonomicSortOrder,
              passwordMigrated
            },
          })
        )

        dispatch({
          payload: updateUserSettings,
          type: UPDATE_USER,
        })

        dispatch(
          showFlashMessage(
            'Your account has been updated successfully',
            'success'
          )
        )

        resolve(updateUserSettings)
      } catch (err) {
        console.error(err)
        dispatch(
          showFlashMessage(
            'Your account could not be updated at this time',
            'danger'
          )
        )
        reject(err)
      }
    })
  }

export const updateUserAddress = (userId, values) => (dispatch) => {
  return new Promise(async function (resolve, reject) {
    try {
      const {
        data: { updateUserAddress },
      } = await API.graphql(
        graphqlOperation(Query.updateUserAddress, {
          input: {
            id: userId,
            address: {
              address1: values.address1,
              city: values.city,
              state: values.state,
              zip: values.zip,
              country: values.country,
              utmEasting: parseFloat(values.utmEasting),
              utmNorthing: parseFloat(values.utmNorthing),
              utmZone: parseInt(values.utmZone),
            },
          },
        })
      )

      dispatch({
        payload: updateUserAddress,
        type: UPDATE_USER,
      })
      dispatch(showFlashMessage('Your address has been updated', 'success'))
      resolve(updateUserAddress)
    } catch (err) {
      console.error(err)
      dispatch(
        showFlashMessage(
          'Your address could not be updated at this time',
          'danger'
        )
      )
      reject(err)
    }
  })
}
