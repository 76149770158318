import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import OfflineWarning from '../../components/micros/OfflineWarning'
import ObservationTable from '../../components/tables/ObservationTable'
import tableColumns from '../../components/tables/tableColumns'
import { getMyObservations } from '../../actions/observations.actions'
import { getUserStats } from '../../actions/user.actions'
import { useHistory } from 'react-router-dom'
import { BarChart } from '../../components/charts/BarChart'
import { useIonViewDidEnter } from '@ionic/react'
import { online, user, userStats } from '../../selectors'

const styles = {
  root: {},
}

const MyObservations = (props) => {
  const { classes, getMyObservations, getUserStats, online, user } = props
  const history = useHistory()

  useIonViewDidEnter(() => {
    getUserStats()
  })

  const columns = user.projects.some(
    (project) => project.key === 'nestboxProject'
  )
    ? tableColumns.myObservations
    : tableColumns.myObservations.filter(
        (column) => column.accessor !== 'nestbox.number'
      )

  const onCellClick = (event, cell) => {
    if (
      cell.row &&
      cell.row.original &&
      cell.column &&
      cell.column.id !== 'selection'
    ) {
      return history.push(`/dashboard/me/view/${cell.row.original._id}`)
    }
  }

  return (
    <div className={classes.root}>
      {online ? (
        <Fragment>
          <ObservationTable
            columns={columns}
            fetchData={getMyObservations}
            onCellClick={onCellClick}
            tableKey="myObservations"
          />
        </Fragment>
      ) : (
        <OfflineWarning />
      )}
      {user.stats &&
        user.stats.observationsBySpecies &&
        user.stats.observationsBySpecies.length > 0 && (
          <BarChart
            data={user.stats.observationsBySpecies}
            id="speciesLifeList"
            seriesName="Observations"
            title="Your Species List"
          />
        )}
      {user.stats &&
        user.stats.observationsByType &&
        user.stats.observationsByType.length > 0 && (
          <BarChart
            data={user.stats.observationsByType}
            id="obsByType"
            seriesName="Observations"
            title="Observations by Wildlife Type"
          />
        )}
      {user.stats &&
        user.stats.observationsByProject &&
        user.stats.observationsByProject.length > 0 && (
          <BarChart
            data={user.stats.observationsByProject}
            id="obsByProject"
            seriesName="Observations"
            title="Observations by Project"
          />
        )}
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    online: online(state),
    user: user(state),
    userStats: userStats(state)
  }
}

const mapDispatchToProps = {
  getMyObservations,
  getUserStats,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(MyObservations))
