import React from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import Users from './users'
import IonicPageLayout from '../../components/siteLayout/IonicPageLayout'
import { useIonViewDidEnter } from '@ionic/react'
import { scrollToTop } from '../../helpers/utils'

const styles = (theme) => ({
  root: {
    display: 'grid',
    gridTemplateColumns: '100%',

    [theme.breakpoints.up('md')]: {
      gridGap: '2rem 0',
    },
  },
  taskList: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: '1.5rem',
  },
})

// eslint-disable-next-line react/prefer-stateless-function
const Admin = (props) => {
  const { classes } = props
  useIonViewDidEnter(() => {
    scrollToTop()
  })

  return (
    <IonicPageLayout title="Admin Console" withBackButton>
      <div className={classes.root}>
        <Users />
      </div>
    </IonicPageLayout>
  )
}

function mapStateToProps({ admin, projects, user }) {
  return {
    admin,
    projects,
    user,
  }
}

export default withStyles(styles)(connect(mapStateToProps)(Admin))
