/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import classnames from 'classnames'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import StyledLoading from '../micros/Loading'
import { scrollToTop } from '../../helpers/utils'

const styles = (theme) => ({
  loading: {
    textAlign: 'center',
    position: 'absolute',
    backgroundColor: 'rgba(0,0,0,0.5)',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 1,

    '& p': {
      position: 'absolute',
      backgroundColor: 'rgba(255,255,255,0.9)',
      width: '100%',
      top: 0,
      padding: '0.5rem',
    },
  },
  infoPanel: {
    minHeight: '24px',
    textAlign: 'center',
  },
  pagination: {
    textAlign: 'center',
    margin: '0.5rem 0 2rem',
  },
  pageButton: {
    width: '45px',
    height: '45px',
    fontSize: '22px',
    backgroundColor: 'transparent',
    borderRadius: '50%',
    border: `1px solid ${theme.palette.primary.main}`,
    color: theme.palette.primary.main,
    margin: '10px 5px',
    padding: 0,

    '& span': {
      position: 'relative',
      top: '-3px',
      right: '-1px',
    },

    '&:hover': {
      backgroundColor: theme.palette.primary.light,
    },

    '&:disabled': {
      backgroundColor: 'transparent',
      border: `1px solid ${theme.palette.primary.borderColor}`,
      color: theme.palette.primary.borderColor,
    },
  },
  minimal: {
    paddingRight: '1rem',
    marginBottom: '1rem',
    textAlign: 'right',

    '& $infoPanel': {
      textAlign: 'right',
    },
  },
  minimalPageButton: {
    width: '20px',
    height: '20px',
    fontSize: '12px',
  },
  pageCount: {
    display: 'block',
    width: '100%',
    marginBottom: 5,
  },
})

const TablePagination = ({
  classes,
  loading,
  page,
  total,
  canPreviousPage,
  canNextPage,
  minimal,
  pageCount,
  pageOptions,
  gotoPage,
  nextPage,
  previousPage,
  setPageSize,
  state: { pageIndex, pageSize },
}) => {
  return (
    <div
      className={classnames(classes.pagination, minimal ? classes.minimal : '')}
    >
      <div className={classes.infoPanel}>
        {loading && !minimal ? (
          // Use our custom loading state to show a loading indicator
          <div className={classes.loading}>
            <StyledLoading />
            <Typography>Loading...</Typography>
          </div>
        ) : (
          <Typography>
            {minimal
              ? ''
              : `Showing ${page.length * pageIndex + 1} - 
            ${page.length * pageIndex + page.length} of ${total} results`}
          </Typography>
        )}
      </div>
      <div>
        <button
          className={classnames(
            classes.pageButton,
            minimal ? classes.minimalPageButton : ''
          )}
          disabled={!canPreviousPage}
          onClick={() => gotoPage(0)}
        >
          <span>{'<<'}</span>
        </button>
        <button
          className={classnames(
            classes.pageButton,
            minimal ? classes.minimalPageButton : ''
          )}
          disabled={!canPreviousPage}
          onClick={() => previousPage()}
        >
          <span>{'<'}</span>
        </button>
        <button
          className={classnames(
            classes.pageButton,
            minimal ? classes.minimalPageButton : ''
          )}
          disabled={!canNextPage}
          onClick={() => {
            nextPage()
            scrollToTop()
          }}
        >
          <span>{'>'}</span>
        </button>
        <button
          className={classnames(
            classes.pageButton,
            minimal ? classes.minimalPageButton : ''
          )}
          disabled={!canNextPage}
          onClick={() => gotoPage(pageCount - 1)}
        >
          <span>{'>>'}</span>
        </button>
      </div>
      <div>
        <span className={classes.pageCount}>
          {minimal ? '' : `Page `}
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>
        </span>
        {!minimal && total > 5 && (
          <select
            onChange={(e) => {
              setPageSize(Number(e.target.value))
            }}
            value={pageSize}
          >
            {[5, 10, 20, 40, 50, 100].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </select>
        )}
      </div>
    </div>
  )
}

export default withStyles(styles)(TablePagination)
