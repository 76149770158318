import React from 'react'
import { Link } from 'react-router-dom'
import { withStyles, Typography } from '@material-ui/core'
import IonicPageLayout from '../components/siteLayout/IonicPageLayout'

const styles = {
  root: {
    textAlign: 'center',
    padding: '60px',
  },
}

export default withStyles(styles)((props) => {
  const { classes } = props
  return (
    <IonicPageLayout>
      <div className={classes.root}>
        <Typography gutterBottom variant="h1">
          404
        </Typography>
        <Typography gutterBottom variant="h5">
          This part of Nature Mapping is out for a hike
        </Typography>
        <Typography component={Link} to="/">
          Go home
        </Typography>
      </div>
    </IonicPageLayout>
  )
})
