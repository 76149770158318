import React from 'react'
import Select from 'react-select'
import PropTypes from 'prop-types'
import { useMediaQuery } from '@material-ui/core'
import { getValue, processValue } from '../../../helpers/utils'

function SelectWidget(props) {
  const {
    schema,
    id,
    options,
    value,
    required,
    disabled,
    readonly,
    multiple,
    autofocus,
    onChange,
    onBlur,
    onFocus,
    placeholder,
    menuPlacement = 'bottom',
  } = props
  const { enumOptions } = options
  const emptyValue = multiple ? [] : ''
  const isDarkModeEnabled = useMediaQuery('(prefers-color-scheme: dark)')

  const getContent = () => {
    const ionContent = document.querySelector(
      'ion-content#observationFormContent'
    )
    return ionContent
  }

  const scrollToPoint = (x, y) => {
    getContent().scrollToPoint(x, y, 300)
  }

  return (
    <Select
      autoFocus={autofocus}
      id={id}
      isDisabled={disabled || readonly}
      isMulti={multiple}
      menuPlacement={menuPlacement}
      onBlur={
        onBlur &&
        ((event, index, value) => {
          const newValue = getValue(event, index, value, multiple)
          onBlur(id, processValue(schema, newValue))
        })
      }
      onChange={(value) => {
        onChange(processValue(schema, value.value))
      }}
      onFocus={
        onFocus &&
        ((event, index, value) => {
          const clientBoundingRect = event.target.getBoundingClientRect()
          try {
            scrollToPoint(
              0,
              getContent().scrollEl.scrollTop + clientBoundingRect.top - 100
            )
          } catch (e) {
            console.error(e)
          }
          const newValue = getValue(event, index, value, multiple)
          onFocus(id, processValue(schema, newValue))
        })
      }
      options={enumOptions.map(({ value, label }) => ({
        label,
        value,
      }))}
      placeholder={placeholder}
      required={required}
      styles={{
        menu: (provided) => ({
          ...provided,
          zIndex: 9999,
          backgroundColor: isDarkModeEnabled ? '#2f2f2f' : '#fff',
        }),
        option: (provided, state) => ({
          ...provided,
          zIndex: 9999,
          color:
            isDarkModeEnabled && state.isSelected
              ? '#fff'
              : state.isSelected
              ? '#fff'
              : isDarkModeEnabled
              ? '#fff'
              : '#2f2f2f',
          backgroundColor:
            isDarkModeEnabled && state.isSelected
              ? '#537552'
              : state.isSelected
              ? '#537552'
              : isDarkModeEnabled
              ? '537552'
              : '#fff',
          fontWeight: state.isSelected ? 'bold' : 'normal',
        }),
        control: (provided) => ({
          ...provided,
          backgroundColor: isDarkModeEnabled ? '#2f2f2f' : '#fff',
          borderColor: isDarkModeEnabled ? '#537552' : '#537552',
          borderWidth: 5,
        }),
        singleValue: (provided) => ({
          ...provided,
          color: isDarkModeEnabled ? '#fff' : '#2f2f2f',
        }),
        placeholder: (provided) => ({
          ...provided,
          color: isDarkModeEnabled ? '#fff' : '#2f2f2f',
        }),
      }}
      value={
        typeof value === 'undefined'
          ? emptyValue
          : enumOptions.find((option) => option.value === value)
      }
    />
  )
}

SelectWidget.defaultProps = {
  autofocus: false,
}

if (process.env.NODE_ENV !== 'production') {
  SelectWidget.propTypes = {
    autofocus: PropTypes.bool,
    disabled: PropTypes.bool,
    id: PropTypes.string.isRequired,
    multiple: PropTypes.bool,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    options: PropTypes.shape({
      enumOptions: PropTypes.array,
    }).isRequired,
    readonly: PropTypes.bool,
    required: PropTypes.bool,
    schema: PropTypes.object.isRequired,
    value: PropTypes.any,
  }
}

export default SelectWidget
