import React, { useState } from 'react'
import { Auth } from 'aws-amplify'
import { withStyles, Typography } from '@material-ui/core'
import { connect } from 'react-redux'
import LoaderButton from '../components/buttons/LoaderButton'
import PasswordField from '../components/forms/PasswordField'
import actions from '../actions'

const styles = (theme) => ({
  root: {
    flesGrow: 1,
    marginTop: '60px',
  },
  textField: {
    marginBottom: theme.spacing(),
  },
  button: {
    marginTop: theme.spacing(),
  },
})

const ChangePassword = (props) => {
  const { classes, showFlashMessage } = props
  const [oldPassword, setOldPassword] = useState('')
  const [password, setPassword] = useState('')
  const [isChanging, setIsChanging] = useState(false)
  const [confirmPassword, setConfirmPassword] = useState('')

  const validateForm = () => {
    return (
      oldPassword.length > 0 &&
      password.length > 0 &&
      password === confirmPassword
    )
  }

  const handleChangeClick = async (e) => {
    e.preventDefault()

    setIsChanging(true)

    try {
      const currentUser = await Auth.currentAuthenticatedUser()
      await Auth.changePassword(
        currentUser,
        oldPassword,
        password
      )

      showFlashMessage('Password changed successfully', 'success')
      resetForm()
      setIsChanging(false)
    } catch (e) {
      alert(e.message)
      setIsChanging(false)
    }
  }

  const resetForm = () => {
    setOldPassword('')
    setPassword('')
    setConfirmPassword('')
  }

    return (
      <div className={classes.root}>
        <form onSubmit={handleChangeClick}>
          <Typography gutterBottom variant="h4">
            Change password
          </Typography>
          <div>
            <PasswordField
              autoComplete="current-password"
              autoFocus
              className={classes.textField}
              id="oldPassword"
              label="Old Password"
              onIonChange={(e) => setOldPassword(e.target.value)}
              value={oldPassword}
              variant="outlined"
            />
            <hr />
            <PasswordField
              autoComplete="new-password"
              className={classes.textField}
              helperText={`Passwords must be at least 8 characters.`}
              id="password"
              label="New Password"
              onIonChange={(e => setPassword(e.target.value))}
              value={password}
              variant="outlined"
            />
            <PasswordField
              className={classes.textField}
              id="confirmPassword"
              label="Confirm New Password"
              onIonChange={(e)=> setConfirmPassword(e.target.value)}
              value={confirmPassword}
              variant="outlined"
            />
          </div>
          <div>
            <LoaderButton
              className={classes.button}
              color="primary"
              disabled={!validateForm()}
              isLoading={isChanging}
              loadingText="Changing..."
              text="Change Password"
              type="submit"
              variant="contained"
            />
          </div>
        </form>
      </div>
    )
  }

function mapStateToProps(state) {
  return {
  }
}

const mapDispatchToProps = {
  showFlashMessage: actions.showFlashMessage,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(ChangePassword))
