import React, { useState } from 'react'
import { connect } from 'react-redux'
import { TextField, Typography, withStyles } from '@material-ui/core'
import { Auth } from 'aws-amplify'
import { IonRouterLink } from '@ionic/react'
import { useHistory } from 'react-router-dom'
import LoaderButton from '../../components/buttons/LoaderButton'
import actions from '../../actions'
import IonicPageLayout from '../../components/siteLayout/IonicPageLayout'

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    marginTop: '60px',
  },
  textField: {
    marginBottom: '1rem',
    marginTop: '1rem',
  },
  button: {
    marginBottom: '0.5rem',
    marginTop: '0.5rem',
  },
  buttonSignup: {
    margin: '1rem',
  },
  dialog: {
    padding: '1rem',
    width: '300px',
  },
})

const VerificationForm = (props) => {
  const { classes, user, showFlashMessage, signin } = props
  const [loading, setLoading] = useState(false)
  const [code, setCode] = useState('')
  const [email, setEmail] = useState(user.email)
  const [sent, setSent] = useState(false)
  const history = useHistory()

  const validateConfirmationForm = () => {
    return code.length > 3
  }

  const handleResendConfirmation = async (e) => {
    try {
      setSent(true)
      await Auth.resendSignUp(email)
      showFlashMessage('New verification email sent', 'success')
    } catch (e) {
      alert(e.message)
    }
  }

  const handleConfirmationSubmit = async (e) => {
    e.preventDefault()
    setLoading(true)

    try {
      await Auth.confirmSignUp(email, code)
      await Auth.signIn(email, user.password)
      showFlashMessage('Account verified', 'success')
      signin()
      history.push('/')
    } catch (e) {
      alert(e.message)
      setLoading(false)
    }
  }

  return (
    <IonicPageLayout withBackButton>
      <Typography variant="h2">Verify your account</Typography>
      {sent ? (
        <Typography gutterBottom>
          Need to resend the code?{' '}
          <IonRouterLink onClick={handleResendConfirmation}>
            Send code again
          </IonRouterLink>
        </Typography>
      ) : (
        <Typography gutterBottom>Verification code sent!</Typography>
      )}
      <form onSubmit={handleConfirmationSubmit}>
        <TextField
          autoFocus={!email}
          className={classes.textField}
          id="email"
          label="Email"
          onChange={(e) => setEmail(e.target.value)}
          value={email}
          variant="outlined"
        />
        <TextField
          autoFocus={!!email}
          className={classes.textField}
          helperText={`Please check your email ${
            email ? `(${email}) ` : ''
          }for the confirmation code.`}
          id="confirmationCode"
          label="Confirmation Code"
          onChange={(e) => setCode(e.target.value)}
          type="tel"
          value={code}
          variant="outlined"
        />
        <div>
          <LoaderButton
            className={classes.button}
            color="primary"
            disabled={!validateConfirmationForm()}
            isLoading={loading}
            loadingText="Verifying..."
            text="Verify"
            type="submit"
            variant="contained"
          />
        </div>
      </form>
    </IonicPageLayout>
  )
}

const mapStateToProps = ({ user }) => ({
  user,
})

export default withStyles(styles)(
  connect(mapStateToProps, actions)(VerificationForm)
)
