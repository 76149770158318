import { API, graphqlOperation } from 'aws-amplify'
import { GENERIC_ERROR, UPDATE_PROJECTS } from '../types/action.types'
import Query from '../graphql'

export const getProjects = () => {
  return async function(dispatch) {
    try {
      const {
        data: { getProjects },
      } = await API.graphql(graphqlOperation(Query.getProjects))

      dispatch({
        payload: getProjects,
        type: UPDATE_PROJECTS,
      })
    } catch (err) {
      console.log(err)
      dispatch({
        payload: err,
        type: GENERIC_ERROR,
      })
    }
  }
}

export const getProject = () => {}
