/* eslint-disable prefer-destructuring */
import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import utmConverter from 'proj4'
import { withStyles, Typography } from '@material-ui/core'
import LabelValue from '../text/LabelValue'
import MapWithAMarker from './MapWithAMarker'

const propTypes = {
  location: PropTypes.object.isRequired,
}

const styles = theme => ({
  locationData: {
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
    padding: '1.5rem 0',
    flexWrap: 'wrap',
    gap: '1rem',

    [theme.breakpoints.up('sm')]: {
      gridTemplateColumns: '110px 110px 110px',
    },

    '& > div': {
      margin: '0 1rem',
    }
  },
})

const DisplayObservationLocation = props => {
  const { classes, hideLocation, location } = props

  if (!location) {
    return <Typography align="center">No location on this observation</Typography>
  }

  let { latitude, longitude } = location

  if (
    (!location.utmEasting ||
      !location.utmNorthing ||
      !location.utmZone) &&
    (!location.latitude || !location.longitude)
  ) {
    return (
      <Typography align="center">No location on this observation</Typography>
    )
  }
  if (!latitude || !longitude) {
    // Observation doesn't have latitude and longitude saved on it so convert UTM coords to latitude and longitude

    const utm = `+proj=utm +zone=${
      location.utmZone
    }`
    const wgs84 = '+proj=longlat +ellps=GRS80 +datum=NAD83 +no_defs'

    const conversion = utmConverter(utm, wgs84, [
      location.utmEasting,
      location.utmNorthing,
    ])

    latitude = conversion[1]
    longitude = conversion[0]
  }

  return (
    <div>
      {!hideLocation && 
        <Fragment>
          <div className={classes.locationData}>
            <LabelValue
              label="UTM Easting"
              value={location.utmEasting}
            />
            <LabelValue
              label="UTM Northing"
              value={location.utmNorthing}
            />
            <LabelValue
              label="UTM Zone"
              value={location.utmZone}
            />
          </div>
          <div className={classes.locationData}>
            <LabelValue
              label="Latitude"
              value={parseFloat(latitude).toFixed(8)}
            />
            <LabelValue
              label="Longitude"
              value={parseFloat(longitude).toFixed(8)}
            />
          </div>
        </Fragment>
      }
      <MapWithAMarker
        center={{
          lat: parseFloat(latitude),
          lng: parseFloat(longitude),
        }}
        lat={parseFloat(latitude)}
        lng={parseFloat(longitude)}
      />
    </div>
  )
}

DisplayObservationLocation.propTypes = propTypes

export default withStyles(styles)(DisplayObservationLocation)
