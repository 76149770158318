import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Formik, Field } from 'formik'
import { DateTime } from 'luxon'
import { DatePicker } from 'react-rainbow-components'
import { MenuItem, Typography, Paper, InputLabel } from '@material-ui/core'
import DoneIcon from '@material-ui/icons/Done'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'
import { withStyles } from '@material-ui/core/styles'
import LoaderButton from '../../components/buttons/LoaderButton'
import actions from '../../actions'
import Loading from '../../components/micros/Loading'
import NatureMappingSelect from '../../components/forms/renderSelect'
import {
  getAccountStatusOptions,
  getCodeItemOptions,
  getNameOptions,
} from '../../helpers/dropdownOptions'
import OfflineWarning from '../../components/micros/OfflineWarning'
import {
  formTypes,
  projects,
  online,
  myDataExports,
  openDataExports,
  user,
} from '../../selectors'
import IonicPageLayout from '../../components/siteLayout/IonicPageLayout'

const styles = (theme) => ({
  root: {
    position: 'relative',
  },
  exportObservationOptions: {
    display: 'grid',
    gridGap: '1rem',
    gridTemplateColumns: 'repeat(auto-fit, minmax(180px, 1fr))',
    justifyContent: 'space-between',
  },
  exportSection: {
    marginBottom: '8rem',

    '& form': {
      margin: '1rem 0',
    },
  },
  buttons: {
    marginTop: '1rem',
  },
  dateRangeSection: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    gridGap: '1rem',

    [theme.breakpoints.up('sm')]: {
      gridTemplateColumns: '1fr 1fr',
    },
  },
  datePicker: {
    margin: '1rem 0',
  },
  ticket: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    alignItems: 'start',
    justifyContent: 'center',
    marginBottom: '1rem',
    textAlign: 'center',
    padding: '0.6rem 1rem',

    [theme.breakpoints.up('sm')]: {
      gridTemplateColumns: '50px 1fr 1fr 1fr 1fr',
    },
  },
  downloadLink: {
    color: theme.palette.primary.main,
    fontWeight: 600,
  },
  errorMessage: {
    fontSize: '0.7rem',
  },
})

class ExportData extends Component {
  state = {
    loading: false,
    interval: 8000,
  }
  timer = null

  componentDidMount() {
    const { getExportHistory } = this.props

    getExportHistory()
    this.checkDataExportStatus()
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.openDataExports.length !== prevProps.openDataExports.length
    ) {
      this.checkDataExportStatus()
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timer)
  }

  exportObservations = async (values) => {
    const { getExportHistory, exportObservations } = this.props
    this.setState({ loading: true })

    try {
      await exportObservations(values)

      this.setState({ loading: false })

      getExportHistory()
      this.checkDataExportStatus()
    } catch (e) {
      this.setState({ loading: false })
      console.error(JSON.stringify(e))
    }
  }

  exportWOS = async (values) => {
    const { getExportHistory, exportWOS } = this.props
    this.setState({ loading: true })

    try {
      await exportWOS(values)

      this.setState({ loading: false })

      getExportHistory()
      this.checkDataExportStatus()
    } catch (e) {
      this.setState({ loading: false })
      console.error(JSON.stringify(e))
    }
  }

  exportUsers = async (values) => {
    const { getExportHistory, exportUsers } = this.props
    this.setState({ loading: true })

    try {
      await exportUsers(values)

      this.setState({ loading: false })

      getExportHistory()
      this.checkDataExportStatus()
    } catch (e) {
      this.setState({ loading: false })
      console.error(JSON.stringify(e))
    }
  }

  checkDataExportStatus = () => {
    const { getExportHistory, openDataExports } = this.props
    const { interval } = this.state

    clearTimeout(this.timer)

    if (openDataExports.length) {
      getExportHistory()

      this.timer = setTimeout(() => {
        this.checkDataExportStatus()
      }, interval)
    }
  }

  addDefaultOption = (label) => {
    return (
      <MenuItem key="default" value={-1}>
        {label}
      </MenuItem>
    )
  }

  render() {
    const { classes, myDataExports, user, projects, online, types } = this.props
    const { loading } = this.state

    if (!online) {
      return (
        <div className={classes.root}>
          <Typography gutterBottom variant="h4">
            Data Export
          </Typography>
          <OfflineWarning />
        </div>
      )
    }

    const accountStatusOptions = getAccountStatusOptions()
    const mortalityOptions = getCodeItemOptions(types.mortality, true)
    const projectOptions = getNameOptions(projects)
    const statusOptions = getCodeItemOptions(types.observationStatuses, true)
    const speciesOptions = getNameOptions(types.species)
    const speciesTypeOptions = getNameOptions(types.speciesTypes)

    return (
      <IonicPageLayout>
        <div className={classes.root}>
          <Typography gutterBottom variant="h4">
            Data Export
          </Typography>
          {loading ? (
            <div>
              <Loading position="static" />
              <Typography variant="body1">
                We are gathering the requested data. This could take a little
                while.
              </Typography>
            </div>
          ) : (
            <section>
              {myDataExports.length > 0 && (
                <section className={classes.exportHistory}>
                  <Typography gutterBottom variant="h5">
                    Your Recent Exports
                  </Typography>
                  {myDataExports.slice(0, 3).map((ticket, index) => {
                    let StatusIcon = Loading

                    if (ticket.url) {
                      StatusIcon = DoneIcon
                    } else if (ticket.error) {
                      StatusIcon = ErrorOutlineIcon
                    }

                    const dateTime = DateTime.fromISO(ticket.created)

                    return (
                      <Paper className={classes.ticket} key={ticket._id}>
                        <div>
                          <Typography>{index + 1}</Typography>
                        </div>
                        <div>
                          <Typography>Status</Typography>
                          <StatusIcon position="static" />
                          {ticket.error && (
                            <Typography className={classes.errorMessage}>
                              {ticket.error}
                            </Typography>
                          )}
                        </div>
                        <div>
                          <Typography>Type</Typography>
                          <Typography>{ticket.type}</Typography>
                        </div>
                        <div>
                          <Typography>Created</Typography>
                          <Typography>
                            {dateTime.toLocaleString(DateTime.DATE_SHORT)}
                          </Typography>
                          <Typography>
                            {dateTime.toLocaleString(
                              DateTime.TIME_24_WITH_SHORT_OFFSET
                            )}
                          </Typography>
                        </div>
                        <div>
                          <Typography>Result</Typography>
                          {ticket.url && (
                            <Typography
                              className={classes.downloadLink}
                              component="a"
                              href={ticket.url}
                            >
                              Download data
                            </Typography>
                          )}
                        </div>
                      </Paper>
                    )
                  })}
                </section>
              )}
              <section className={classes.exportSection}>
                <Typography gutterBottom variant="h4">
                  Observations Export
                </Typography>
                <div className={classes.exportObservationsForm}>
                  <Formik
                    initialValues={{
                      mortality: -1,
                      project: -1,
                      species: -1,
                      speciesType: -1,
                      status: -1,
                      startDate: null,
                      endDate: null,
                    }}
                    onSubmit={this.exportObservations}
                  >
                    {(props) => (
                      <form onSubmit={props.handleSubmit}>
                        <Typography gutterBottom variant="h5">
                          Date range
                        </Typography>
                        <div className={classes.dateRangeSection}>
                          <div>
                            <InputLabel>Start Date</InputLabel>
                            <Field
                              borderRadius="semi-square"
                              className={classes.datePicker}
                              component={DatePicker}
                              formatStyle="large"
                              id="startDate"
                              maxDate={new Date()}
                              minDate={new Date(2010, 0, 1)}
                              name="startDate"
                              onChange={(e) => {
                                const dateTime = DateTime.fromJSDate(e)
                                props.setFieldValue(
                                  'startDate',
                                  dateTime.toISO()
                                )
                              }}
                              value={props.values.startDate}
                            />
                          </div>
                          <div>
                            <InputLabel>End Date</InputLabel>
                            <Field
                              borderRadius="semi-square"
                              className={classes.datePicker}
                              component={DatePicker}
                              formatStyle="large"
                              id="endDate"
                              maxDate={new Date()}
                              minDate={new Date(2010, 0, 1)}
                              name="endDate"
                              onChange={(e) => {
                                const dateTime = DateTime.fromJSDate(e)
                                props.setFieldValue('endDate', dateTime.toISO())
                              }}
                              value={props.values.endDate}
                            />
                          </div>
                        </div>
                        <Typography gutterBottom variant="h6">
                          Filters
                        </Typography>
                        <div className={classes.exportObservationOptions}>
                          <Field
                            component={NatureMappingSelect}
                            id="project"
                            label="Project"
                            name="project"
                            onBlur={props.setFieldTouched}
                            onChange={props.setFieldValue}
                            options={projectOptions}
                            value={props.values.project}
                          />
                          <Field
                            component={NatureMappingSelect}
                            id="mortality"
                            label="Mortality"
                            name="mortality"
                            onBlur={props.setFieldTouched}
                            onChange={props.setFieldValue}
                            options={mortalityOptions}
                            value={props.values.mortality}
                          />
                          <Field
                            component={NatureMappingSelect}
                            id="status"
                            label="Status"
                            name="status"
                            onBlur={props.setFieldTouched}
                            onChange={props.setFieldValue}
                            options={statusOptions}
                            value={props.values.status}
                          />
                          <Field
                            component={NatureMappingSelect}
                            id="species"
                            label="Species"
                            name="species"
                            onBlur={props.setFieldTouched}
                            onChange={props.setFieldValue}
                            options={speciesOptions}
                            value={props.values.species}
                          />
                          <Field
                            component={NatureMappingSelect}
                            id="speciesType"
                            label="Wildlife Type"
                            name="speciesType"
                            onBlur={props.setFieldTouched}
                            onChange={props.setFieldValue}
                            options={speciesTypeOptions}
                            value={props.values.speciesType}
                          />
                        </div>
                        <div className={classes.buttons}>
                          <LoaderButton
                            text="Export observations"
                            type="submit"
                            variant="contained"
                          />
                        </div>
                      </form>
                    )}
                  </Formik>
                </div>
              </section>
              <section className={classes.exportSection}>
                <Typography gutterBottom variant="h4">
                  Wyoming Game and Fish Department (WOS) Export
                </Typography>
                <Formik
                  initialValues={{
                    startDate: null,
                    endDate: null,
                  }}
                  onSubmit={this.exportWOS}
                >
                  {(props) => (
                    <form onSubmit={props.handleSubmit}>
                      <Typography gutterBottom variant="h5">
                        Date range
                      </Typography>
                      <div className={classes.dateRangeSection}>
                        <div>
                          <InputLabel>Start Date</InputLabel>
                          <Field
                            borderRadius="semi-square"
                            className={classes.datePicker}
                            component={DatePicker}
                            formatStyle="large"
                            id="startDate"
                            maxDate={new Date()}
                            minDate={new Date(2010, 0, 1)}
                            name="startDate"
                            onChange={(e) => {
                              const dateTime = DateTime.fromJSDate(e)
                              props.setFieldValue('startDate', dateTime.toISO())
                            }}
                            value={props.values.startDate}
                          />
                        </div>
                        <div>
                          <InputLabel>End Date</InputLabel>
                          <Field
                            borderRadius="semi-square"
                            className={classes.datePicker}
                            component={DatePicker}
                            formatStyle="large"
                            id="endDate"
                            maxDate={new Date()}
                            minDate={new Date(2010, 0, 1)}
                            name="endDate"
                            onChange={(e) => {
                              const dateTime = DateTime.fromJSDate(e)
                              props.setFieldValue('endDate', dateTime.toISO())
                            }}
                            value={props.values.endDate}
                          />
                        </div>
                      </div>
                      <div className={classes.buttons}>
                        <LoaderButton
                          text="Export WOS Format"
                          type="submit"
                          variant="contained"
                        />
                      </div>
                    </form>
                  )}
                </Formik>
              </section>
              {user.level > 7 && (
                <section className={classes.exportSection}>
                  <Typography gutterBottom variant="h5">
                    User Export
                  </Typography>
                  <Formik
                    initialValues={{
                      active: accountStatusOptions[0].value,
                    }}
                    onSubmit={this.exportUsers}
                  >
                    {(props) => (
                      <form onSubmit={props.handleSubmit}>
                        <div className={classes.exportObservationOptions}>
                          <Field
                            component={NatureMappingSelect}
                            id="active"
                            label="Account Status"
                            name="active"
                            onBlur={props.setFieldTouched}
                            onChange={props.setFieldValue}
                            options={accountStatusOptions}
                            value={props.values.active}
                          />
                        </div>
                        <div className={classes.buttons}>
                          <LoaderButton
                            text="Export users"
                            type="submit"
                            variant="contained"
                          />
                        </div>
                      </form>
                    )}
                  </Formik>
                </section>
              )}
            </section>
          )}
        </div>
      </IonicPageLayout>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    myDataExports: myDataExports(state),
    online: online(state),
    openDataExports: openDataExports(state),
    projects: projects(state),
    user: user(state),
    types: formTypes(state),
  }
}

const mapDispatchToProps = {
  exportObservations: actions.exportObservations,
  exportWOS: actions.exportWOS,
  exportUsers: actions.exportUsers,
  getExportHistory: actions.getExportHistory,
}

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(ExportData)
)
