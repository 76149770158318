import React, { Fragment } from 'react'
import { withStyles } from '@material-ui/core'
import LabelValue from '../text/LabelValue'

const styles = theme => ({
  root: {},
})

const MountainBluebirdDetails = props => {
  const { observation } = props

  return (
    <Fragment>
      <LabelValue
        label="Nestbox #"
        value={observation.nestbox && observation.nestbox.number}
      />
      <LabelValue
        label="Nest Material"
        value={observation.nestMaterial ? 'Yes' : 'No'}
      />
      <LabelValue label="Eggs" value={observation.eggs} />
      <LabelValue label="Unhatched Eggs" value={observation.unhatchedEggs} />

      <LabelValue label="Nestlings" value={observation.nestlings} />

      <LabelValue label="Fledglings" value={observation.fledglings} />

      <LabelValue label="Dead Nestlings" value={observation.deadNestlings} />
    </Fragment>
  )
}

export default withStyles(styles)(MountainBluebirdDetails)
