import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import utmConverter from 'utm-latlng'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import { Autocomplete, useJsApiLoader } from '@react-google-maps/api'
import actions from '../actions'
import '../theme/react-places.css'
import { googleMapLibraries } from '../helpers/utils'
import { IonLoading } from '@ionic/react'

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: '1rem',
  },
}))

const utmConvert = new utmConverter()

const LocationSearchInput = (props) => {
  const { handleSelect, showFlashMessage } = props
  const [savingAddress, setSavingAddress] = React.useState(false)
  const [autocomplete, setAutocomplete] = React.useState(null)
  const styles = useStyles()
  const { isLoaded, loadError } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    libraries: googleMapLibraries,
  })

  const onLoad = React.useCallback(function callback(autocomplete) {
    setAutocomplete(autocomplete)
  }, [])

  const onUnmount = React.useCallback(function callback(map) {
    setAutocomplete(null)
  }, [])

  if (loadError) {
    return <div>Map cannot be loaded right now, sorry.</div>
  }

  const handleAddressSelect = async () => {
    setSavingAddress(true)
    if (autocomplete !== null) {
      try {
        const place = autocomplete.getPlace()
        if (!place.formatted_address) {
          showFlashMessage(
            'Please select an address from the provided autocomplete options in Address Search.',
            'secondary'
          )
          setSavingAddress(false)
          return
        }
        const { address_components } = place

        const lat = place.geometry.location.lat()
        const lng = place.geometry.location.lng()

        const streetNumber = address_components.find((item) =>
          item.types.includes('street_number')
        )

        const street = address_components.find((item) =>
          item.types.includes('route')
        )

        const city = address_components.find((item) =>
          item.types.includes('locality')
        )

        const state = address_components.find((item) =>
          item.types.includes('administrative_area_level_1')
        )

        const country = address_components.find((item) =>
          item.types.includes('country')
        )

        const postalCode = address_components.find((item) =>
          item.types.includes('postal_code')
        )

        const utm = await utmConvert.convertLatLngToUtm(lat, lng, 4)

        const update = {
          address1: `${(streetNumber && streetNumber.long_name) || ''} ${
            (street && street.long_name) || ''
          }`,
          city: (city && city.long_name) || '',
          state: (state && state.short_name) || '',
          zip: (postalCode && postalCode.long_name) || '',
          country: (country && country.short_name) || '',
          utmEasting: utm.Easting,
          utmNorthing: utm.Northing,
          utmZone: utm.ZoneNumber,
        }

        handleSelect(update)
        setSavingAddress(false)
      } catch (e) {
        console.error(e)
        showFlashMessage(
          'Unable to update your address using the search right now. Please make sure to select an address from the autocomplete dropdown.',
          'danger'
        )
        setSavingAddress(false)
      }
    } else {
      console.log('Autocomplete is not loaded yet!')
    }
  }

  return isLoaded ? (
    <section className={styles.root}>
      <Autocomplete
        onLoad={onLoad}
        onPlaceChanged={handleAddressSelect}
        onUnmount={onUnmount}
      >
        <input
          placeholder="Enter your address"
          style={{
            boxSizing: `border-box`,
            border: `1px solid transparent`,
            width: `100%`,
            height: `50px`,
            padding: `0 12px`,
            borderRadius: `3px`,
            boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
            fontSize: `14px`,
            outline: `none`,
            textOverflow: `ellipses`,
            margin: '10px auto 20px',
          }}
          type="text"
        />
      </Autocomplete>
      {savingAddress && (
        <div>
          <Typography align="center">Saving address...</Typography>
        </div>
      )}
    </section>
  ) : (
    <IonLoading />
  )
}

LocationSearchInput.propTypes = {
  handleSelect: PropTypes.func.isRequired,
}

const mapDispatchToProps = {
  showFlashMessage: actions.showFlashMessage,
}

export default connect(null, mapDispatchToProps)(LocationSearchInput)
