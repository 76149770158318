import React from 'react'
import PropTypes from 'prop-types'
import { IonInput } from '@ionic/react'
import { getValue, processValue } from '../../../helpers/utils'

function NumberWidget(props) {
  const {
    schema,
    id,
    value,
    required,
    disabled,
    readonly,
    autofocus,
    onChange,
    onBlur,
    onFocus,
    placeholder,
  } = props

  return (
    <IonInput
      autoFocus={autofocus}
      disabled={disabled || readonly}
      id={id}
      inputmode="numeric"
      min={0}
      onIonBlur={
        onBlur &&
        ((event, index, value) => {
          const newValue = getValue(event, index, value)
          onBlur(id, processValue(schema, newValue))
        })
      }
      onIonChange={(e) => {
        onChange(processValue(schema, e.detail.value))
      }}
      onIonFocus={
        onFocus &&
        ((event, index, value) => {
          const newValue = getValue(event, index, value)
          onFocus(id, processValue(schema, newValue))
        })
      }
      placeholder={placeholder}
      required={required}
      type="number"
      value={value}
    />
  )
}

NumberWidget.defaultProps = {
  autofocus: false,
}

if (process.env.NODE_ENV !== 'production') {
  NumberWidget.propTypes = {
    autofocus: PropTypes.bool,
    disabled: PropTypes.bool,
    id: PropTypes.string.isRequired,
    multiple: PropTypes.bool,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    readonly: PropTypes.bool,
    required: PropTypes.bool,
    schema: PropTypes.object.isRequired,
    value: PropTypes.any,
  }
}

export default NumberWidget
