import * as authActions from './auth.actions'
import * as exportActions from './export.actions'
import * as flashActions from './flash.actions'
import * as formActions from './form.actions'
import * as layoutActions from './layout.actions'
import * as lifecycleActions from './lifecycle.actions'
import * as modalActions from './modal.actions'
import * as observationsActions from './observations.actions'
import * as userActions from './user.actions'

const actions = {
  ...authActions,
  ...exportActions,
  ...flashActions,
  ...formActions,
  ...layoutActions,
  ...lifecycleActions,
  ...modalActions,
  ...observationsActions,
  ...userActions,
}

export default actions
