import { schema, normalize } from 'normalizr'

const ticketSchema = new schema.Entity('tickets', {}, { idAttribute: '_id' })

const ticketListSchema = [ticketSchema]

export const normalizeTickets = (tickets) => {
  const data = normalize(tickets, ticketListSchema)

  return data.entities.tickets
}