import React, { useState } from 'react'
import { Typography, withStyles } from '@material-ui/core'
import { eyeOutline, eyeOffOutline } from 'ionicons/icons'
import { IonButton, IonIcon, IonInput, IonLabel } from '@ionic/react'

const styles = (theme) => ({
  passwordWrapper: {
    marginBottom: '1rem',
  },
  innerWrapper: {
    display: 'grid',
    gridTemplateColumns: '1fr auto',
    width: '100%',
    alignItems: 'center',

    '& button': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
})

const PasswordField = (props) => {
  const {
    autoComplete,
    className,
    id,
    classes,
    onKeyPress,
    helperText,
    label,
    onIonChange,
    placeholder,
    value,
  } = props
  const [showPassword, setShowPassword] = useState(false)

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword)
  }

  return (
    <>
      <div className={classes.passwordWrapper}>
        <IonLabel position="stacked">{label}</IonLabel>
        <div className={classes.innerWrapper}>
          <IonInput
            autocomplete={autoComplete}
            className={className}
            id={id}
            onIonChange={onIonChange}
            onKeyPress={onKeyPress}
            placeholder={placeholder}
            type={showPassword ? 'text' : 'password'}
            value={value}
          />
          <IonButton
            aria-label="Toggle password visibility"
            onClick={handleClickShowPassword}
          >
            <IonIcon
              icon={showPassword ? eyeOutline : eyeOffOutline}
              slot="start"
            />
          </IonButton>
        </div>
        <Typography>{helperText}</Typography>
      </div>
    </>
  )
}

export default withStyles(styles)(PasswordField)
