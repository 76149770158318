import React from 'react'
import PropTypes from 'prop-types'
import { IonRadio, IonRadioGroup, IonLabel, IonItem } from '@ionic/react'
import { getValue, processValue } from '../../../helpers/utils'

function RadioWidget(props) {
  const {
    schema,
    id,
    options,
    value,
    required,
    disabled,
    readonly,
    multiple,
    autofocus,
    onChange,
    onBlur,
    onFocus,
    placeholder,
  } = props
  const { enumOptions } = options

  return (
    <IonRadioGroup
      autoFocus={autofocus}
      disabled={disabled || readonly}
      id={id}
      multiple={multiple}
      onIonBlur={
        onBlur &&
        ((event, index, value) => {
          const newValue = getValue(event, index, value, multiple)
          onBlur(id, processValue(schema, newValue))
        })
      }
      onIonChange={(e) => {
        onChange(processValue(schema, e.detail.value))
      }}
      onIonFocus={
        onFocus &&
        ((event, index, value) => {
          const newValue = getValue(event, index, value, multiple)
          onFocus(id, processValue(schema, newValue))
        })
      }
      placeholder={placeholder}
      required={required}
      value={value}
    >
      {enumOptions.map(({ value, label }) => (
        <IonItem key={value}>
          <IonLabel>{label}</IonLabel>
          <IonRadio slot="start" value={value} />
        </IonItem>
      ))}
    </IonRadioGroup>
  )
}

RadioWidget.defaultProps = {
  autofocus: false,
}

if (process.env.NODE_ENV !== 'production') {
  RadioWidget.propTypes = {
    autofocus: PropTypes.bool,
    disabled: PropTypes.bool,
    id: PropTypes.string.isRequired,
    multiple: PropTypes.bool,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    options: PropTypes.shape({
      enumOptions: PropTypes.array,
    }).isRequired,
    readonly: PropTypes.bool,
    required: PropTypes.bool,
    schema: PropTypes.object.isRequired,
    value: PropTypes.any,
  }
}

export default RadioWidget
