import React, { Fragment, useState } from 'react'
import { connect } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { withStyles, Typography } from '@material-ui/core'
import { DateTime } from 'luxon'
import LabelValue from '../../components/text/LabelValue'
import Loading from '../../components/micros/Loading'
import LoaderButton from '../../components/buttons/LoaderButton'
import {
  activateUser,
  deactivateUser,
  deleteUser,
  getUser,
} from '../../actions/user.actions'
import AssignProjectsForm from './AssignProjectsForm'
import UpdateUserLevelForm from './UpdateUserLevelForm'
import TrainingDateForm from './TrainingDateForm'
import AssignDataVetterSettingsForm from './AssignDataVetterSettingsForm'
import SectionDivider from '../../components/micros/SectionDivider'
import {
  useIonAlert,
  useIonViewWillEnter,
  useIonViewWillLeave
} from '@ionic/react'
import IonicPageLayout from '../../components/siteLayout/IonicPageLayout'

const styles = (theme) => ({
  topSection: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    gridGap: '1rem',

    [theme.breakpoints.up('sm')]: {
      gridTemplateColumns: '1fr 2fr',
    },
  },
  modalPageHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    boxSizing: 'border-box',
    backgroundColor: '#fff',
  },
})

const ViewUser = (props) => {
  const { activateUser, classes, deactivateUser, getUser, deleteUser } = props
  const [user, setUser] = useState(null)
  const params = useParams()
  const history = useHistory()
  const [presentAlert] = useIonAlert()

  useIonViewWillEnter(async () => {
    const user = await getUser(params.id)

    setUser(user)
  }, [params.id])

  useIonViewWillLeave(async () => {
    setUser(null)
  })

  let trainingDate = ''

  if (user && user.trainingDate) {
    if (user.trainingDate.includes('/')) {
      trainingDate = DateTime.fromISO(new Date(user.trainingDate).toISOString())
        .toLocal()
        .toISODate()
    } else {
      trainingDate = DateTime.fromISO(user.trainingDate).toLocal().toISODate()
    }
  }

  return (
    <IonicPageLayout title="User Details" withBackButton>
      {user ? (
        <section>
          <Typography
            component="h1"
            gutterBottom
            variant="h4"
          >{`${user.firstName} ${user.lastName}`}</Typography>
          <div className={classes.topSection}>
            <LabelValue label="Email" value={user.email} />
            <LabelValue label="Phone" value={user.phone} />
            <LabelValue label="Cell" value={user.cell} />
            <LabelValue label="Address" value={user.address1} />
            <LabelValue label="City" value={user.city} />
            <LabelValue label="State" value={user.state} />
            <LabelValue label="Zip" value={user.zip} />
            <LabelValue label="Country" value={user.country} />
            <LabelValue label="UTM Easting" value={user.utmEasting} />
            <LabelValue label="UTM Northing" value={user.utmNorthing} />
            <LabelValue label="UTM Zone" value={user.utmZone} />
            <LabelValue label="User visits" value={user.userVisits} />
            <LabelValue label="Last user visit" value={user.lastUserVisit} />
            <LabelValue label="Active" value={user.active ? 'Yes' : 'No'} />
            <LabelValue label="Training Date" value={trainingDate} />
          </div>
          <div>
            <SectionDivider />
            <AssignProjectsForm user={user} />
            <SectionDivider />
            <TrainingDateForm user={user} />
            <SectionDivider />
            <UpdateUserLevelForm user={user} />
            <SectionDivider />
            {user.level > 4 && (
              <Fragment>
                <AssignDataVetterSettingsForm user={user} />
                <SectionDivider />
              </Fragment>
            )}
            <div>
              <Typography gutterBottom variant="h2">
                Change User Status
              </Typography>
              {user.active ? (
                <LoaderButton
                  onClick={() => deactivateUser(user._id)}
                  text="Deactivate User"
                  variant="contained"
                />
              ) : (
                <LoaderButton
                  onClick={() => activateUser(user._id)}
                  text="Activate User"
                  variant="contained"
                />
              )}
            </div>
            <SectionDivider />
            <div>
              {/* TODO - add are you sure check */}
              <Typography gutterBottom variant="h2">
                Delete User
              </Typography>
              <LoaderButton
                onClick={() =>
                  presentAlert({
                    header: 'Are you sure?',
                    message: `This action cannot be undone. Nature Mapping will keep the user's submitted observations but will delete all of their account information. The user will lose access to all of their data.`,
                    buttons: [
                      {
                        text: 'Cancel',
                        role: 'cancel',
                        handler: () => {},
                      },
                      {
                        text: 'Yes',
                        role: 'confirm',
                        handler: () => {
                          deleteUser(user._id, user.email)
                          history.push('/admin')
                        },
                      },
                    ],
                  })
                }
                text="Delete user account"
                variant="contained"
              />
            </div>
          </div>
        </section>
      ) : (
        <section>
          <Loading />
        </section>
      )}
    </IonicPageLayout>
  )
}

const mapDispatchToProps = {
  activateUser,
  deactivateUser,
  getUser,
  deleteUser,
}

export default connect(null, mapDispatchToProps)(withStyles(styles)(ViewUser))
