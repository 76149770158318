import { Storage } from 'aws-amplify'
import uuid from 'uuid/v4'

export async function s3UploadPhoto(file, progressCallback) {
  const fileName = `photos/${uuid()}-${file.name}`

  const stored = await Storage.put(fileName, file, {
    contentType: file.type,
    progressCallback
  })

  return stored.key
}

export async function s3UploadPrivate(file) {
  const fileName = `${uuid()}-${file.name}`

  // Storage.vault.put adds the file to a private storage instance (belonging to the logged in user)
  const stored = await Storage.vault.put(fileName, file, {
    contentType: file.type,
  })

  return stored.key
}

export async function s3Delete(fileKey) {
  return Storage.remove(fileKey)
}

export async function s3DeletePrivate(fileKey) {
  return Storage.vault.remove(fileKey)
}
