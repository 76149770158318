import { OPEN_MODAL, CLOSE_MODAL } from '../types/action.types'

export const openModal = (
  title,
  description,
  buttonText,
  showCancel,
  submit,
) => ({
  type: OPEN_MODAL,
  payload: {
    title,
    description,
    buttonText,
    submit,
    showCancel,
  },
})

export const closeModal = () => ({
  type: CLOSE_MODAL,
})
