import React, { useState, useEffect, Fragment } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'
import { getObservationsByProject } from '../../actions/observations.actions'
import ObservationTable from '../../components/tables/ObservationTable'
import tableColumns from '../../components/tables/tableColumns'
import OfflineWarning from '../../components/micros/OfflineWarning';
import IonicPageLayout from '../../components/siteLayout/IonicPageLayout'

const styles = {
  root: {
    margin: '1rem 0',
  },
}

const ProjectObservations = props => {
  const { classes, getObservationsByProject, online, project, history } = props

  const [columns, setColumns] = useState([])

  useEffect(() => {
    const columns = tableColumns[project.key] ? tableColumns[project.key] : tableColumns.observation
    setColumns(columns)
  }, [project])

    const onCellClick = (event, cell) => {
      if (
        cell.row &&
        cell.row.original &&
        cell.column &&
        cell.column.id !== 'selection'
      ) {
        return history.push(
          `/project/${project.key}/observation/${cell.row.original._id}`
        )
      }
    }

  return (
    <IonicPageLayout>
      <Typography component="h2" gutterBottom variant="h4">{`${project.name}`}</Typography>
      {online ? (
        <Fragment>
          <Typography gutterBottom>
            This table shows all observations, in any status, for the project.
          </Typography>
          <div className={classes.root}>
            {columns.length && (
              <ObservationTable
                columns={columns}
                fetchData={getObservationsByProject}
                forceTable
                hideMap
                onCellClick={onCellClick}
                project={project._id}
                tableKey={`project${project._id}`}
              />
            )}
          </div>
        </Fragment>
      ) : (
        <OfflineWarning />
      )}
    </IonicPageLayout>
  )
}

const mapStateToProps = ({ layout, projects }, ownProps) => {
  const { match } = ownProps
  const { key } = match.params

  return {
    online: layout.online,
    project: projects && projects.find(project => project.key === key),
  }
}

const mapDispatchToProps = {
  getObservationsByProject,
}

export default withStyles(styles)(withRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProjectObservations)))
