import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withStyles, Typography } from '@material-ui/core'
import { Formik, Field } from 'formik'
import LoaderButton from '../../components/buttons/LoaderButton'
import actions from '../../actions'
import renderSelect from '../../components/forms/renderSelect'

const propTypes = {
  user: PropTypes.object.isRequired,
}

const styles = theme => ({
  root: {
    flexGrow: 1,
    marginBottom: '4rem',
  },
  button: {
    margin: theme.spacing(0.5),
  },
  fieldWrapper: {
    marginBottom: '1rem',
  },
})

class UpdateUserLevelForm extends Component {
  handleSubmit = (values, actions) => {
    const { changeUserLevel } = this.props

    changeUserLevel(values)
      .then(actions.setSubmitting(false))
      .catch(actions.setSubmitting(false))
  }

  render() {
    const { classes, user } = this.props

    // TODO - move this to use types
    const levels = [
      {
        value: 3,
        label: 'General',
      },
      {
        value: 5,
        label: 'Data Vetter',
      },
      {
        value: 8,
        label: 'Admin',
      },
    ]

    return (
      <section className={classes.root}>
        <Typography gutterBottom variant="h2">
          Update User Access Level
        </Typography>
        <Typography gutterBottom>{`${
          user.firstName
        }'s current access level is ${
          levels.find(level => level.value === user.level).label
        }`}</Typography>
        <Formik
          enableReinitialize
          initialValues={{ id: user._id, level: user.level }}
          onSubmit={this.handleSubmit}
          render={props => (
            <form onSubmit={props.handleSubmit}>
              <div className={classes.activeForm}>
                <div className={classes.fieldWrapper}>
                  <Field component="input" name="id" type="hidden" />
                  <Field
                    component={renderSelect}
                    id="level"
                    name="level"
                    onBlur={props.setFieldTouched}
                    onChange={props.setFieldValue}
                    options={levels}
                    value={props.values.level}
                  />
                </div>
              </div>
              <div>
                <LoaderButton
                  disabled={
                    props.isSubmitting || user.level === props.values.level
                  }
                  text="Update access"
                  type="submit"
                  variant="contained"
                />
              </div>
            </form>
          )}
        />
      </section>
    )
  }
}

UpdateUserLevelForm.propTypes = propTypes

function mapStateToProps({ admin, projects }) {
  return {
    projects,
    user: admin.currentUser,
  }
}

const mapDispatchToProps = {
  changeUserLevel: actions.changeUserLevel,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(UpdateUserLevelForm))
