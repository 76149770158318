import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Auth } from 'aws-amplify'
import { TextField, Typography, withStyles } from '@material-ui/core'
import LoaderButton from '../components/buttons/LoaderButton'
import actions from '../actions'
import { IonInput, IonLabel } from '@ionic/react'

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    marginTop: '60px',
  },
  textField: {
    marginTop: theme.spacing(),
    marginBottom: theme.spacing(),
  },
  button: {
    marginTop: theme.spacing(),
  },
})

class ChangeEmail extends Component {
  constructor(props) {
    super(props)

    this.state = {
      code: '',
      email: props.email ||'',
      codeSent: false,
      isConfirming: false,
      isSendingCode: false,
    }
  }

  validateEmailForm() {
    return this.state.email.length > 0
  }

  validateConfirmForm() {
    return this.state.code.length > 0
  }

  handleChange = (e) => {
    let { value } = e.target

    if (e.target.id === 'email') {
      value = value.toLowerCase()
    }

    this.setState({
      [e.target.id]: value,
    })
  }

  handleUpdateClick = async (e) => {
    const { email } = this.state
    e.preventDefault()

    this.setState({
      isSendingCode: true,
    })

    try {
      const user = await Auth.currentAuthenticatedUser()
      await Auth.updateUserAttributes(user, { email })

      this.setState({
        codeSent: true,
      })
    } catch (e) {
      this.setState({
        isSendingCode: false,
      })
    }
  }

  handleConfirmClick = async (e) => {
    const { history, updateUserEmail } = this.props
    const { code, email } = this.state

    e.preventDefault()

    this.setState({
      isConfirming: true,
    })

    try {
      await Auth.verifyCurrentUserAttributeSubmit('email', code)

      await updateUserEmail(email.toLowerCase())

      history.push('/settings')
    } catch (e) {
      this.setState({
        isConfirming: false,
      })
    }
  }

  renderUpdateForm() {
    const { classes } = this.props
    const { email, isSendingCode } = this.state

    return (
      <form onSubmit={this.handleUpdateClick}>
        <Typography gutterBottom variant="h4">
          Change Email
        </Typography>
        <IonLabel position="stacked">Your Email</IonLabel>
        <IonInput
          autoComplete={'email'}
          // onKeyPress={onKeyPress}
          className={classes.textField}
          id="email"
          label="New email"
          onIonChange={this.handleChange}
          value={email}
          variant="outlined"
        />
        <LoaderButton
          className={classes.button}
          color="primary"
          disabled={!this.validateEmailForm()}
          isLoading={isSendingCode}
          loadingText="Updating..."
          text="Update Email"
          type="submit"
          variant="contained"
        />
      </form>
    )
  }

  renderConfirmationForm() {
    const { classes } = this.props
    const { email, code, isConfirming } = this.state

    return (
      <form onSubmit={this.handleConfirmClick}>
        <div>
          <TextField
            autoFocus
            className={classes.textField}
            helperText={`Please check your email (${email}) for the confirmation code.`}
            id="code"
            label="Confirmation Code"
            onChange={this.handleChange}
            value={code}
            variant="outlined"
          />
        </div>
        <div>
          <LoaderButton
            className={classes.button}
            color="primary"
            disabled={!this.validateConfirmForm()}
            isLoading={isConfirming}
            loadingText="Confirm..."
            text="Confirm"
            type="submit"
            variant="contained"
          />
        </div>
      </form>
    )
  }

  render() {
    const { codeSent } = this.state

    return (
      <div>
        {!codeSent ? this.renderUpdateForm() : this.renderConfirmationForm()}
      </div>
    )
  }
}

const mapDispatchToProps = {
  updateUserEmail: actions.updateUserEmail,
}

export default connect(
  null,
  mapDispatchToProps
)(withStyles(styles)(ChangeEmail))
