export default {
  aws: {
    apiGateway: {
      REGION: process.env.REACT_APP_APIGATEWAY_REGION,
      URL: process.env.REACT_APP_APIGATEWAY_URL,
    },
    cognito: {
      APP_CLIENT_ID: process.env.REACT_APP_COGNITO_APP_CLIENT_ID,
      IDENTITY_POOL_ID: process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID,
      REGION: process.env.REACT_APP_COGNITO_REGION,
      USER_POOL_ID: process.env.REACT_APP_COGNITO_USER_POOL_ID,
    },
    graphql: {
      URL: `${process.env.REACT_APP_APIGATEWAY_URL}/graphql`,
    },
    s3: {
      BUCKET: process.env.REACT_APP_S3_BUCKET,
      REGION: process.env.REACT_APP_S3_REGION,
    },
  },
  gaTrackingId: process.env.REACT_APP_GA_TRACKING_ID,
  fixedLocationProjects: ['backyard'],
  nonUserLocationProjects: ['gameCameras', 'nestboxProject', 'beaver'],
  style: {
    DRAWER_WIDTH: 240,
  },
}
