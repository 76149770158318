import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withStyles, Typography, Checkbox } from '@material-ui/core'
import { Formik, FieldArray, Field } from 'formik'
import LoaderButton from '../../components/buttons/LoaderButton'
import actions from '../../actions'

const propTypes = {
  user: PropTypes.object.isRequired,
}

const styles = theme => ({
  root: {
    flexGrow: 1,
    marginBottom: '4rem',
  },
  button: {
    margin: theme.spacing(0.5),
  },
  label: {
    fontWeight: 500,
  },
})

class AssignProjectsForm extends Component {
  handleSubmit = values => {
    const { assignProjectsToUser } = this.props

    assignProjectsToUser(values)
  }

  render() {
    const { classes, projects, user } = this.props

    return (
      <section className={classes.root}>
        <Typography gutterBottom variant="h2">
          Project Access
        </Typography>
        <Typography gutterBottom>
          Select the projects this user is able to enter observations for.
          Projects that are public are not listed.
        </Typography>
        <div>
          <Formik
            enableReinitialize
            initialValues={{
              id: user._id,
              projects: user.projects
                ? user.projects.map(project => project._id)
                : [],
            }}
            onSubmit={this.handleSubmit}
            render={props => (
              <form onSubmit={props.handleSubmit}>
                <Field component="input" name="id" type="hidden" />
                <FieldArray
                  name="projects"
                  render={arrayHelpers => (
                    <div>
                      {projects.map(project => {
                        if (!project.type) return null

                        return (
                          <div key={project._id}>
                            <label
                              className={classes.label}
                              htmlFor={project._id}
                            >
                              <Checkbox
                                checked={props.values.projects.includes(
                                  project._id,
                                )}
                                id={project._id}
                                inputProps={{ name: 'projectIds' }}
                                onChange={e => {
                                  if (e.target.checked)
                                    arrayHelpers.push(project._id)
                                  else {
                                    const idx = props.values.projects.indexOf(
                                      project._id,
                                    )
                                    arrayHelpers.remove(idx)
                                  }
                                }}
                                type="checkbox"
                                value={project._id}
                              />
                              {project.name}
                            </label>
                          </div>
                        )
                      })}
                    </div>
                  )}
                />
                <div>
                  <LoaderButton
                    className={classes.button}
                    disabled={props.isSubmitting}
                    text="Update project access"
                    type="submit"
                    variant="contained"
                  />
                </div>
              </form>
            )}
          />
        </div>
      </section>
    )
  }
}

AssignProjectsForm.propTypes = propTypes

function mapStateToProps({ projects }) {
  return {
    projects,
  }
}

const mapDispatchToProps = {
  assignProjectsToUser: actions.assignProjectsToUser,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(AssignProjectsForm))
