import uuid from 'uuid/v4'
import {
  DELETE_DRAFT_OBSERVATION,
  SAVE_DRAFT_OBSERVATION,
} from '../types/action.types'

const initialState = {
  observations: [],
}

export default function (state = initialState, action) {
  const currentDrafts = state.observations ? state.observations : []
  switch (action.type) {
    case SAVE_DRAFT_OBSERVATION:
      let nextDraftObservations

      if (action.payload.data.offlineKey) {
        const filteredObservations = currentDrafts.filter((draft) => {
          return draft.data.offlineKey !== action.payload.data.offlineKey
        })
        nextDraftObservations = filteredObservations.concat([
          { projectKey: action.payload.projectKey, data: action.payload.data },
        ])
      } else {
        nextDraftObservations = [
          ...currentDrafts,
          {
            projectKey: action.payload.projectKey,
            data: { ...action.payload.data, offlineKey: uuid() },
          },
        ]
      }
      return {
        ...state,
        observations: nextDraftObservations
      }
    case DELETE_DRAFT_OBSERVATION:
      return {
        ...state,
        observations: currentDrafts.filter(
          (draft) => draft.data.offlineKey !== action.payload
        ),
      }
    default:
      return state
  }
}
