import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonLabel,
  IonNote,
  IonRow,
} from '@ionic/react'
import {
  navigateOutline,
} from 'ionicons/icons'
import { DateTime } from 'luxon'

export const MarkerInfoWindow = ({ marker, goToObservation, dismiss }) => {
  return (
    <IonContent>
      <IonGrid className="ion-padding">
        <IonRow className="ion-margin-bottom">
          <IonCol size="12">
            <IonLabel>
              <h1>{marker.species}</h1>
              <IonNote>{marker.speciesType}</IonNote>
            </IonLabel>
          </IonCol>
          <IonCol size="12">
            <IonLabel>
              <IonNote>{marker.project}</IonNote>
            </IonLabel>
          </IonCol>
          <IonCol size="12">
            <IonLabel>
              {DateTime.fromISO(marker.obsDateTime).toLocaleString(
                DateTime.DATETIME_FULL
              )}
            </IonLabel>
          </IonCol>
          <IonCol size="10">{marker.county}</IonCol>
          <IonCol size="10">Status: {marker.status}</IonCol>
        </IonRow>

        <IonRow>
          <IonButton
            onClick={goToObservation}
          >
            <IonIcon icon={navigateOutline} />
            &nbsp; View Details
          </IonButton>
        </IonRow>
      </IonGrid>
    </IonContent>
  )
}
