import gql from 'graphql-tag'
import {
  ObservationEntryFragment,
  ObservationFragment,
  ObservationTableRowFragment,
  TicketFragment,
} from '../fragments'

export const createObservationMutation = gql`
  mutation createObservation($input: ObservationEntryInput) {
    createObservation(input: $input) {
      ...ObservationEntry
    }
  }
  ${ObservationEntryFragment}
`

export const editObservationMutation = gql`
  mutation editObservation($input: ObservationEntryInput) {
    editObservation(input: $input) {
      ...ObservationEntry
    }
  }
  ${ObservationEntryFragment}
`

export const deleteObservationMutation = gql`
  mutation deleteObservation($input: DeleteObservationInput) {
    deleteObservation(input: $input)
  }
`

export const updateObservationStatusMutation = gql`
  mutation updateObservationStatus($input: UpdateObservationStatusInput) {
    updateObservationStatus(input: $input) {
      ...Observation
    }
  }
  ${ObservationFragment}
`

export const updateDataVerificationNoteMutation = gql`
  mutation updateDataVerificationNote($input: UpdateDataVerificationNoteInput) {
    updateDataVerificationNote(input: $input) {
      ...Observation
    }
  }
  ${ObservationFragment}
`

export const verifyObservationMutation = gql`
  mutation verifyObservation($input: VerifyObservationInput) {
    verifyObservation(input: $input)
  }
`

export const getObservationQuery = gql`
  query getObservation($input: GetObservationInput) {
    getObservation(input: $input) {
      ...Observation
    }
  }
  ${ObservationFragment}
`

export const getExportHistory = gql`
  query getExportHistory {
    getExportHistory {
      ...Ticket
    }
  }
  ${TicketFragment}
`

export const getExportStatus = gql`
  query getExportStatus($input: GetExportStatusInput) {
    getExportStatus(input: $input) {
      ...Ticket
    }
  }
  ${TicketFragment}
`

export const getObservationEntryQuery = gql`
  query getObservationEntry($input: GetObservationEntryInput) {
    getObservationEntry(input: $input) {
      ...ObservationEntry
    }
  }
  ${ObservationEntryFragment}
`

export const getUsersObservationsQuery = gql`
  query getUsersObservations($input: UsersObservationsInput) {
    getUsersObservations(input: $input) {
      ...Observation
    }
  }
  ${ObservationFragment}
`

export const getObservationsByProjectQuery = gql`
  query getObservationsByProject($input: ObservationByProjectInput) {
    getObservationsByProject(input: $input) {
      count
      data {
        ...ObservationTableRow
      }
    }
  }
  ${ObservationTableRowFragment}
`

export const searchObservations = gql`
  query searchObservations($input: SearchObservationsInput) {
    searchObservations(input: $input) {
      count
      data {
        ...ObservationTableRow
      }
    }
  }
  ${ObservationTableRowFragment}
`

export const getAllObservationsWithPhotosQuery = gql`
  query getAllObservationsWithPhotos($input: TablePagingInput) {
    getAllObservationsWithPhotos(input: $input) {
      count
      data {
        ...ObservationTableRow,
      }
    }
  }
  ${ObservationTableRowFragment}
`

export const getAllVerifiedObservationsQuery = gql`
  query getAllVerifiedObservations($input: TablePagingInput) {
    getAllVerifiedObservations(input: $input) {
      count
      data {
        ...ObservationTableRow
      }
    }
  }
  ${ObservationTableRowFragment}
`

export const getAllNotVerifiedObservationsQuery = gql`
  query getAllNotVerifiedObservations($input: TablePagingInput) {
    getAllNotVerifiedObservations(input: $input) {
      count
      data {
        ...ObservationTableRow
      }
    }
  }
  ${ObservationTableRowFragment}
`

export const getDataVetterObservationsByStatusQuery = gql`
  query getDataVetterObservationsByStatus($input: TablePagingInput) {
    getDataVetterObservationsByStatus(input: $input) {
      count
      data {
        ...ObservationTableRow
      }
    }
  }
  ${ObservationTableRowFragment}
`

export const batchVerifyMutation = gql`
  mutation batchVerify($input: BatchStatusUpdateInput) {
    batchVerify(input: $input)
  }
`

export const batchRejectMutation = gql`
  mutation batchReject($input: BatchStatusUpdateInput) {
    batchReject(input: $input)
  }
`
