import { GET_EXPORT_HISTORY, GET_EXPORT_STATUS } from '../types/action.types'

const initialState = {}

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_EXPORT_HISTORY:
      return action.payload || []
    case GET_EXPORT_STATUS:
      return {
        ...state,
        [action.payload._id]: action.payload
      }
    default:
      return state
  }
}
