import SelectWidget from './SelectWidget'
import UtmWidget from './UtmWidget'
import DateTimeWidget from './DateTimeWidget'
import PhotoWidget from './PhotoWidget'
import SpeciesWidget from './SpeciesWidget'
import RadioWidget from './RadioWidget'
import NumberWidget from './NumberWidget'
import BandResightWidget from './BandResightWidget'

export default {
  DateTimeWidget,
  SelectWidget,
  UtmWidget,
  PhotoWidget,
  SpeciesWidget,
  RadioWidget,
  BandResightWidget,
  NumberWidget,
}
