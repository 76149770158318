import React from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { withStyles, Typography, TextField } from '@material-ui/core'
import { Formik, Field } from 'formik'
import actions from '../../actions'
import LoaderButton from '../../components/buttons/LoaderButton'
import OfflineWarning from '../../components/micros/OfflineWarning';
import { IonBackButton, IonButtons, IonContent, IonHeader, IonPage, IonToolbar } from '@ionic/react'

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  button: {
    margin: theme.spacing(0.5),
  },
  fieldWrapper: {
    marginBottom: '1rem',
  },
})

const CreateUser = (props) => {
  const { classes, online, createUser } = props
  const history = useHistory()

  const handleSubmit = async (values, { setErrors, setSubmitting }) => {
    try {
      await createUser(values)
      history.push('/admin')
    } catch (e) {
      console.error(e)
      setSubmitting(false)
      setErrors({ email: e })
    }
  }

    return (
      <IonPage>
        <IonHeader className={classes.modalPageHeader}>
          <IonToolbar>
            <IonButtons slot="start">
              <IonBackButton />
            </IonButtons>
            <Typography component="h2" variant="h4">
              Create User
            </Typography>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <Typography gutterBottom>
            Enter the new user's information and we will create a user for them. They will create their password when they login for the first time.
          </Typography>
          {online ? (
            <Formik
              initialValues={{
                email: '',
                firstName: '',
                lastName: '',
                phone: '',
                cell: '',
              }}
              onSubmit={handleSubmit}
              render={props => (
                <form onSubmit={props.handleSubmit}>
                  <div className={classes.activeForm}>
                    <div className={classes.fieldWrapper}>
                      <Field
                        component={TextField}
                        id="email"
                        label="Email"
                        name="email"
                        onChange={props.handleChange}
                        placeholder="Email"
                        required
                        variant="outlined"
                      />
                    </div>
                    <div className={classes.fieldWrapper}>
                      <Field
                        component={TextField}
                        id="firstName"
                        label="First name"
                        name="firstName"
                        onChange={props.handleChange}
                        placeholder="First name"
                        variant="outlined"
                      />
                    </div>
                    <div className={classes.fieldWrapper}>
                      <Field
                        component={TextField}
                        id="lastName"
                        label="Last name"
                        name="lastName"
                        onChange={props.handleChange}
                        placeholder="Last name"
                        variant="outlined"
                      />
                    </div>
                    <div className={classes.fieldWrapper}>
                      <Field
                        component={TextField}
                        id="phone"
                        label="Home phone"
                        name="phone"
                        onChange={props.handleChange}
                        placeholder="Home phone"
                        variant="outlined"
                      />
                    </div>
                    <div className={classes.fieldWrapper}>
                      <Field
                        component={TextField}
                        id="cell"
                        label="Mobile phone"
                        name="cell"
                        onChange={props.handleChange}
                        placeholder="Mobile phone"
                        variant="outlined"
                      />
                    </div>
                  </div>
                  <div className="buttons">
                    <LoaderButton
                      disabled={props.isSubmitting}
                      text="Submit"
                      type="submit"
                      variant="contained"
                    />
                  </div>
                </form>
              )}
            />
          ) : (
            <OfflineWarning />
          )}
        </IonContent>
      </IonPage>
    )
}

function mapStateToProps({ layout, user }) {
  return {
    online: layout.online,
    user,
  }
}

const mapDispatchToProps = {
  createUser: actions.createUser,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(CreateUser))
