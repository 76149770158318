import {
  SET_INFORMATION_DRAWER,
  CLOSE_INFORMATION_DRAWER,
  OPEN_FLASH_MESSAGE,
  CLOSE_FLASH_MESSAGE,
  OPEN_MODAL,
  CLOSE_MODAL,
  NETWORK_CHECK,
  SET_DATA_VERIFICATION_SELECTED_TAB,
  WRITE_LOCATION_LOGS,
  CLEAR_LOCATION_LOGS,
  SET_LOCATION_STATE,
  SET_MENU_ENABLED,
  UPDATE_APP_STATS,
} from '../types/action.types'

const initialState = {
  currentDataVerificationSelectedTab: 0,
  showModal: false,
  online: true,
  menuEnabled: true,
  stats: {
    userCount: 0,
    observationCount: 0,
    speciesCount: 0,
  },
  location: {
    logs: [],
    gettingLocation: true,
    deviceLocationError: null,
    data: null
  }

}

export default function(state = initialState, action) {
  switch (action.type) {
    case SET_INFORMATION_DRAWER:
      return {
        ...state,
        isInfoDrawerOpen: true,
        infoDrawerTitle: action.payload.title,
        infoDrawerInfoText: action.payload.infoText,
      }
    case CLOSE_INFORMATION_DRAWER:
      return {
        ...state,
        isInfoDrawerOpen: false,
      }
    case OPEN_FLASH_MESSAGE:
      return {
        ...state,
        openFlash: true,
        flashMessage: action.payload.message,
        flashVariant: action.payload.variant,
        flashDuration: action.payload.duration
      }
    case CLOSE_FLASH_MESSAGE:
      return {
        ...state,
        openFlash: false,
        flashDuration: null
      }
    case UPDATE_APP_STATS:
      return {
        ...state,
        stats: action.payload,
      }
    case SET_MENU_ENABLED:
      return {
        ...state,
        menuEnabled: action.payload,
      }
    case OPEN_MODAL:
      return {
        ...state,
        showModal: true,
        modalTitle: action.payload.title,
        modalDescription: action.payload.description,
        modalSubmit: action.payload.submit,
        modalButtonText: action.payload.buttonText,
        showModalCancel: action.payload.showModalCancel,
      }
    case CLOSE_MODAL:
      return {
        ...state,
        showModal: false,
      }
    case SET_DATA_VERIFICATION_SELECTED_TAB:
      return {
        ...state,
        currentDataVerificationSelectedTab: action.payload,
      }
    case NETWORK_CHECK:
      return {
        ...state,
        online: action.payload.online,
      }
    case SET_LOCATION_STATE:
      return {
        ...state,
        location: {
          ...state.location,
          ...action.payload
        }
      }
    case WRITE_LOCATION_LOGS:
      return {
        ...state,
        location: {
          ...state.location,
          logs: (state.location && state.location.logs && Array.isArray(state.location.logs)) ? state.location.logs.concat([action.payload]) : [action.payload]
        }
      }
    case CLEAR_LOCATION_LOGS:
      return {
        ...state,
        location: {
          ...state.location,
          logs: []
        }
      }
    default:
      return state
  }
}
