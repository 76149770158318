import { IonRouterOutlet } from '@ionic/react'
import React from 'react'
import { Route } from 'react-router'
import IonicObservationFormPage from './IonicObservationFormPage'
import ReviewObservationForm from '../../pages/projects/Review'

const ObservationProjectRouterOutlet: React.FC = () => (
  <IonRouterOutlet>
    <Route exact path="/observation/create/review/:projectKey">
      <ReviewObservationForm />
    </Route>
    <Route exact path="/observation/edit/review/:projectKey/:id">
      <ReviewObservationForm />
    </Route>
    <Route exact path="/observation/create/casual/:step">
      <IonicObservationFormPage />
    </Route>
    <Route exact path="/observation/create/beaver/:step">
      <IonicObservationFormPage />
    </Route>
    <Route exact path="/observation/create/backyard/:step">
      <IonicObservationFormPage />
    </Route>
    <Route exact path="/observation/create/wildlifeTour/:step">
      <IonicObservationFormPage />
    </Route>
    <Route exact path="/observation/create/nestboxProject/:step">
      <IonicObservationFormPage />
    </Route>
    <Route exact path="/observation/create/gameCameras/:step">
      <IonicObservationFormPage />
    </Route>
    <Route exact path="/observation/create/mooseDay/:step">
      <IonicObservationFormPage />
    </Route>
    <Route exact path="/observation/create/neighborsToNature/:step">
      <IonicObservationFormPage />
    </Route>
    <Route exact path="/observation/create/snakeRiverFloat/:step">
      <IonicObservationFormPage />
    </Route>
    <Route exact path="/observation/create/redTailedHawk/:step">
      <IonicObservationFormPage />
    </Route>
    <Route exact path="/observation/create/ospreyProject/:step">
      <IonicObservationFormPage />
    </Route>
    <Route exact path="/observation/create/fieldEducation/:step">
      <IonicObservationFormPage />
    </Route>
    <Route exact path="/observation/create/agencyRoadkill/:step">
      <IonicObservationFormPage />
    </Route>
    <Route exact path="/observation/create/gv001/:step">
      <IonicObservationFormPage />
    </Route>
    <Route exact path="/observation/create/gv002/:step">
      <IonicObservationFormPage />
    </Route>
    <Route exact path="/observation/create/gvMorning/:step">
      <IonicObservationFormPage />
    </Route>
    <Route exact path="/observation/edit/casual/:id/:step">
      <IonicObservationFormPage />
    </Route>
    <Route exact path="/observation/edit/beaver/:id/:step">
      <IonicObservationFormPage />
    </Route>
    <Route exact path="/observation/edit/backyard/:id/:step">
      <IonicObservationFormPage />
    </Route>
    <Route exact path="/observation/edit/wildlifeTour/:id/:step">
      <IonicObservationFormPage />
    </Route>
    <Route exact path="/observation/edit/nestboxProject/:id/:step">
      <IonicObservationFormPage />
    </Route>
    <Route exact path="/observation/edit/gameCameras/:id/:step">
      <IonicObservationFormPage />
    </Route>
    <Route exact path="/observation/edit/mooseDay/:id/:step">
      <IonicObservationFormPage />
    </Route>
    <Route exact path="/observation/edit/neighborsToNature/:id/:step">
      <IonicObservationFormPage />
    </Route>
    <Route exact path="/observation/edit/snakeRiverFloat/:id/:step">
      <IonicObservationFormPage />
    </Route>
    <Route exact path="/observation/edit/redTailedHawk/:id/:step">
      <IonicObservationFormPage />
    </Route>
    <Route exact path="/observation/edit/ospreyProject/:id/:step">
      <IonicObservationFormPage />
    </Route>
    <Route exact path="/observation/edit/fieldEducation/:id/:step">
      <IonicObservationFormPage />
    </Route>
    <Route exact path="/observation/edit/agencyRoadkill/:id/:step">
      <IonicObservationFormPage />
    </Route>
    <Route exact path="/observation/edit/gv001/:id/:step">
      <IonicObservationFormPage />
    </Route>
    <Route exact path="/observation/edit/gv002/:id/:step">
      <IonicObservationFormPage />
    </Route>
    <Route exact path="/observation/edit/gvMorning/:id/:step">
      <IonicObservationFormPage />
    </Route>
  </IonRouterOutlet>
)

export default ObservationProjectRouterOutlet