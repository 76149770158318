import React, { useState } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Auth } from 'aws-amplify'
import { withStyles, Typography } from '@material-ui/core'
import { Formik } from 'formik'
import { IonRouterLink } from '@ionic/react'
import * as Yup from 'yup'
import actions from '../../actions'
import LoaderButton from '../../components/buttons/LoaderButton'
import OfflineWarning from '../../components/micros/OfflineWarning';
import IonicPageLayout from '../../components/siteLayout/IonicPageLayout'
import IonicInput from '../../components/forms/IonicInput'

const styles = theme => ({
  formFields: {
    marginBottom: '1rem',

    '& > div': {
      marginBottom: '1rem',
    },

    '& input': {
      marginTop: '14px',
      marginBottom: '14px'
    }, 
    '& .validationMessage': {
      color: '#e04055',
      textAlign: 'center',
    }, 

    '& ion-input': {
      border: 0,

      '& input': {
        marginBottom: 0,
      }
    }
  },
  field: {
    marginTop: '14px',
  }
})

const signupSchema = Yup.object().shape({
  firstName: Yup.string().required('Required'),
  lastName: Yup.string().required('Required'),
  email: Yup.string().email('Invalid email').required('Required'),
  password: Yup.string().required('Required'),
  confirmPassword: Yup.string().required('Required'),
})

const Signup = (props) => {
  const { classes, online, history, showFlashMessage, updateUserDirectPreAuth } = props
  const [error, setError] = useState(null)

  const handleSubmit = async (values, { setErrors, setSubmitting }) => {
    try {
      setSubmitting(true)
      const {
        firstName,
        lastName,
        email,
        password
      } = values

      await Auth.signUp({
        username: email,
        password: password,
        attributes: {
          name: firstName,
          family_name: lastName
        }
      })
      showFlashMessage('Verification code sent to your email', 'success')

      updateUserDirectPreAuth({
        email,
        password,
        firstName,
        lastName
      })

      history.push('/verify-account')
    } catch (e) {
      console.error(e)
      setSubmitting(false)
      setErrors({ email: e.message })
      setError(e.log)
    }
  }
  return (
    <IonicPageLayout withBackButton>
      <Typography gutterBottom variant="h2">
        Sign up
      </Typography>
      <Typography gutterBottom>
        Welcome to Nature Mapping, we're excited to team up with you. Together we can help gather more data on our
        local wildlife!
      </Typography>
      <Typography gutterBottom>
        Already have an account?{' '}
        <IonRouterLink routerLink="/signin">Sign in</IonRouterLink>
      </Typography>
      <Typography gutterBottom>
        Looking to verify your account?{' '}
        <IonRouterLink routerLink="/verify-account">
          Verify account
        </IonRouterLink>
      </Typography>
      {error && (
        <div className="error">
          <Typography>{error}</Typography>
        </div>
      )}
      {online ? (
        <Formik
          initialValues={{
            firstName: '',
            lastName: '',
            email: '',
            password: '',
            confirmPassword: '',
          }}
          onSubmit={handleSubmit}
          render={(formikProps) => (
            <form onSubmit={formikProps.handleSubmit}>
              <div className={classes.formFields}>
                <IonicInput
                  className={classes.field}
                  label="First name" name="firstName"
                  {...formikProps}
                />
                <IonicInput
                  className={classes.field}
                  label="Last name" name="lastName"
                  {...formikProps}
                />
                <IonicInput className={classes.field} label="Email" name="email" {...formikProps} />
                <IonicInput className={classes.field} label="Password" name="password" {...formikProps} />
                <IonicInput
                  className={classes.field}
                  label="Confirm password" name="confirmPassword"
                  {...formikProps}
                />
              </div>
              <div className="buttons">
                <Typography gutterBottom>
                  We'll send you an email with a confirmation code, this will be
                  used to verify your account after clicking 'Submit'.
                </Typography>
                <LoaderButton
                  disabled={formikProps.isSubmitting}
                  text="Submit"
                  type="submit"
                />
              </div>
            </form>
          )}
          validationSchema={signupSchema}
        />
      ) : (
        <OfflineWarning />
      )}
    </IonicPageLayout>
  )
}

function mapStateToProps({ layout, user }) {
  return {
    online: layout.online,
    user,
  }
}

const mapDispatchToProps = {
  createUser: actions.createUser,
  showFlashMessage: actions.showFlashMessage,
  updateUserDirectPreAuth: actions.updateUserDirectPreAuth
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(withStyles(styles)(Signup)))
