import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'
import OfflineWarning from '../../components/micros/OfflineWarning'
import ObservationTable from '../../components/tables/ObservationTable'
import tableColumns from '../../components/tables/tableColumns'
import { getAllVerifiedObservations } from '../../actions/observations.actions'
import IonicPageLayout from '../../components/siteLayout/IonicPageLayout'
import { useIonViewDidEnter } from '@ionic/react'
import { scrollToTop } from '../../helpers/utils'
import { user } from '../../selectors'

const styles = {
  root: {},
}

const AllObservations = (props) => {
  const { classes, getAllVerifiedObservations, online, history, user } = props

  useIonViewDidEnter(() => {
    scrollToTop()
  })

  const onCellClick = (event, cell) => {
    if (
      cell.row &&
      cell.row.original &&
      cell.column &&
      cell.column.id !== 'selection'
    ) {
      return history.push(`/allobservations/${cell.row.original._id}`)
    }
  }

  return (
    <IonicPageLayout>
      <div className={classes.root}>
        <Typography component="h2" variant="h4">
          All observations
        </Typography>
        {online ? (
          <Fragment>
            <Typography gutterBottom>
              This table only shows observations that have been verified
            </Typography>
            <ObservationTable
              columns={[
                ...tableColumns.observation,
                user.level > 4
                  ? { accessor: 'creator', Header: 'Creator', width: 180 }
                  : null,
              ].filter(Boolean)}
              fetchData={getAllVerifiedObservations}
              forceTable
              hideMap
              onCellClick={onCellClick}
              tableKey="allObservations"
            />
          </Fragment>
        ) : (
          <OfflineWarning />
        )}
      </div>
    </IonicPageLayout>
  )
}

const mapStateToProps = (state) => ({
  user: user(state),
})

const mapDispatchToProps = {
  getAllVerifiedObservations,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(withRouter(AllObservations)))
