import { capitalize } from "../../helpers/utils"

export const ErrorListTemplate = (props) => {
  const { errors } = props
  return (
    <div className='error-list'>
      <h2>Errors</h2>
      <ul>
        {errors.map((error) => (
          <li key={error.stack}>
            {error.stack &&
              error.stack.split(':').length > 1 &&
              capitalize(error.stack.replace(':', ''))}
          </li>
        ))}
      </ul>
    </div>
  )
}