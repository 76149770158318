import React from 'react'
import Form from '@rjsf/core'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import InfoIcon from '@material-ui/icons/InfoOutlined'
import widgets from './formWidgets'
import {
  IonCard,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonLoading,
  useIonViewDidEnter,
  useIonViewWillLeave,
} from '@ionic/react'
import { isEmpty } from 'lodash'
import { getSelectedSeason, scrollToTop } from '../../helpers/utils'

const log = (type) => console.log.bind(console, type)

const useStyles = makeStyles((theme) => ({
  root: {},
  alert: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: 'rgba(3, 118, 189, 0.15)',
    padding: '0.8rem 1rem',
    marginTop: '1rem',
    marginBottom: '1rem',
  },
  infoIcon: {
    marginRight: '1rem',
  },
  speciesCard: {
    marginTop: 10,
  },
}))

const getContent = () => {
  const ionContent = document.querySelector(
    'ion-content#observationFormContent'
  )
  return ionContent
}

const handleNumberFocus = (e) => {
  try {
    if (e) {
      if (e.includes('quantity')) {
        let input = document.querySelector(`#${e} input`)
        if (!input) {
          input = document.querySelector(`input#${e}`)
        }

        if (input) {
          input.select()
        }
      }
    }
  } catch (e) {
    console.error(e)
  }
}

const handleClick = (e) => {
  try {
    if (e) {
      if (e.target.className.includes('btn btn-info btn-add')) {
        setTimeout(() => {
          const last = document.querySelector(
            `#root_observations .array-item:last-of-type`
          )

          getContent().scrollToPoint(0, last.offsetTop - 20, 300)
        }, 50)
      }
    }
  } catch (e) {
    console.error(e)
  }
}

const Wizard = (props) => {
  const {
    formTypes,
    schema,
    formData,
    onSubmit,
    onFormChange,
    submitFormRef,
    uiSchema,
    step,
    projectKey,
    submitting,
    ErrorList,
  } = props
  const styles = useStyles()

  useIonViewDidEnter(() => {
    document.body.addEventListener('click', handleClick)
  })

  useIonViewWillLeave(() => {
    document.body.removeEventListener('click', handleClick)
  })

  if (!formTypes || !schema || isEmpty(formData) || submitting) {
    return <IonLoading />
  }

  const species = formData.species
    ? formTypes.species.find((species) => species.code === formData.species)
    : null

  const transformErrors = (errors) => {
    return errors.map((error) => {
      if (error.name === 'pattern') {
        error.message = 'Only digits are allowed'
      }
      return error
    })
  }

  const validate = (formData, errors) => {
    if (projectKey === 'beaver') {
      if (
        formData.noActivity &&
        (formData.currentActivities?.length ||
          formData.recentActivities?.length ||
          formData.pastActivities?.length)
      ) {
        scrollToTop('observationFormContent')
        errors.noActivity.addError(
          'No Activity can not be checked when other activities are selected'
        )
      }

      if (
        !formData.noActivity &&
        !formData.currentActivities?.length &&
        !formData.recentActivities?.length &&
        !formData.pastActivities?.length
      ) {
        scrollToTop('observationFormContent')
        errors.noActivity.addError(
          'Either No Activity or at least one activity must be selected'
        )
      }
    }

    let rareSighting = false

    if (species) {
      const rareCommonalties = ['Rare', 'Accidental']
      if (formData.dateTime) {
        const selectedSeason = getSelectedSeason(formData.dateTime)
        if (
          species.commonality &&
          (rareCommonalties.includes(species.commonality.occurrence) ||
            rareCommonalties.includes(species.commonality[selectedSeason]) ||
            (species.commonality[selectedSeason] === undefined &&
              !species.commonality.occurrence) ||
            (species.commonality[selectedSeason] === '' &&
              !species.commonality.occurrence))
        ) {
          rareSighting = true
        }
      }

      // required notes checks for unknown and rare species
      // all projects but mountain bluebird nestbox project
      if (
        species &&
        species.name.toLowerCase().includes('unknown') &&
        projectKey !== 'nestboxProject' &&
        !formData.comment
      ) {
        scrollToTop('observationFormContent')
        errors.comment.addError('Notes are required for unknown species')
      }

      if (species && rareSighting && !formData.comment) {
        scrollToTop('observationFormContent')
        errors.comment.addError(
          'Notes are required for species with a rare occurrence'
        )
      }
    }

    return errors
  }

  return (
    <>
      {species && step !== 0 && (
        <IonCard className={styles.speciesCard}>
          <IonCardHeader>
            <IonCardSubtitle>{species.scientificName}</IonCardSubtitle>
            <IonCardTitle>{species.commonName}</IonCardTitle>
          </IonCardHeader>
        </IonCard>
      )}
      {projectKey === 'backyard' && (
        <div className={styles.alert}>
          <InfoIcon className={styles.infoIcon} color="secondary" />
          <Typography>
            Remember that for Project Backyard, the data protocol requires
            entering the top # of individuals by species per week.
          </Typography>
        </div>
      )}
      {schema.steps[step] && (
        <Form
          ErrorList={ErrorList}
          formData={formData}
          onChange={onFormChange}
          onError={log('errors')}
          onFocus={handleNumberFocus}
          onSubmit={onSubmit}
          schema={{ ...schema.steps[step] }}
          transformErrors={transformErrors}
          uiSchema={uiSchema}
          validate={validate}
          widgets={widgets}
        >
          <button
            ref={submitFormRef}
            style={{ display: 'none' }}
            type="submit"
          />
        </Form>
      )}
    </>
  )
}

export default Wizard
