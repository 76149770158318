import React from 'react'
import { withStyles, Paper, Typography } from '@material-ui/core'
import {
  CloudOff as OfflineIcon,
} from '@material-ui/icons'

const styles = theme => ({
  root: {
    // backgroundColor: theme.palette.common.white,
    boxShadow:
      '0px 1px 30px 5px rgba(0, 0, 0, 0.03), 0px 2px 2px 0px rgba(0, 0, 0, 0.01), 0px 3px 1px -2px rgba(0, 0, 0, 0.02);',
    marginTop: '1rem',
    padding: '2rem 1rem',
    textAlign: 'center',

    '& svg': {
      margin: '0 1rem',
    },
  },
})

const OfflineWarning = props => {
  const { classes } = props

  return (
    <Paper className={classes.root}>
      <OfflineIcon />
      <Typography variant='subtitle1'>No network connection detected</Typography>
      <Typography>This data is only available when you're online.</Typography>
    </Paper>
  )
}

export default withStyles(styles)(OfflineWarning)
