import React, { memo } from 'react'
import { IonLoading } from '@ionic/react'
import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api'
import { googleMapLibraries } from '../../helpers/utils'

const containerStyle = {
  height: '40vh',
  width: '100%',
}

const MapWithAMarker = (props) => {
  const { lat = 0, lng = 0, center, onClick } = props
  const { isLoaded, loadError } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    libraries: googleMapLibraries,
  })

  // eslint-disable-next-line no-unused-vars
  const [map, setMap] = React.useState(null)

  const onLoad = React.useCallback(function callback(map) {
    // const bounds = new window.google.maps.LatLngBounds(center)
    // map.fitBounds(bounds)
    setMap(map)
  }, [])

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null)
  }, [])

  if (loadError) {
    return <div>Map cannot be loaded right now, sorry.</div>
  }

  return isLoaded ? (
    <GoogleMap
      center={center}
      mapContainerStyle={containerStyle}
      onClick={onClick}
      onLoad={onLoad}
      onUnmount={onUnmount}
      options={{
        fullscreenControl: false,
        mapTypeId: 'hybrid',
        streetViewControl: false,
      }}
      zoom={13}
    >
      <Marker position={{ lat, lng }} />
    </GoogleMap>
  ) : (
    <IonLoading />
  )
}

export default memo(MapWithAMarker)
