import { APP_START, CLEAR_APPLICATION_STATE, UPDATE_PERSIST_TABLEDATA_ON_START } from '../types/action.types'
import { getAppStats, getFormTypes } from './form.actions'
import { getProjects } from './project.actions'
import { me, updateUserVisits, getUserStats } from './user.actions'
import { updateUserLocation } from './auth.actions'
import { updateDataVerificationSelectedTab } from './layout.actions'

// eslint-disable-next-line import/prefer-default-export
export const applicationStart = () => {
  return async function (dispatch, getState) {
    dispatch({
      type: APP_START,
    })
    dispatch(getFormTypes())
    dispatch(getAppStats())
    dispatch(getProjects())
    dispatch(updateUserLocation())

    if (getState().auth.isAuthenticated) {
      dispatch(me())
      dispatch(getUserStats())
      dispatch(updateUserVisits())
    }

    if (!getState().tableData.persistOnStart) {
      dispatch(updateDataVerificationSelectedTab(0))
    }
  }
}

export const updatePersistTableDataOnStart = (payload) => {
  return function (dispatch, getState) {
    dispatch({
      type: UPDATE_PERSIST_TABLEDATA_ON_START,
      payload
    })
  }
}

export const clearAppState = () => {
  return function (dispatch, getState) {
    dispatch({
      type: CLEAR_APPLICATION_STATE,
    })
  }
}
