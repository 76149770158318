import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { arrowBack } from 'ionicons/icons'
import {
  IonButtons,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
  IonImg,
  IonToast,
  IonBackButton,
  useIonAlert,
  IonButton,
  IonIcon,
} from '@ionic/react'
import { OverlayEventDetail } from '@ionic/core'
import { RouteComponentProps } from 'react-router'
import { useHistory, useLocation } from 'react-router-dom'
import NatureMappingLogo from '../../assets/images/natureMappingLogo.png'
import NatureMappingLogoWhite from '../../assets/images/natureMappingLogo-white.png'
import actions from '../../actions'
import { DateTime } from 'luxon'

const useStyles = makeStyles((theme: any) => ({
  root: {
    padding: '20px 10px',
  },
  button: {
    margin: theme.spacing(0.5),
  },
  fieldWrapper: {
    marginBottom: '1rem',
  },
  logo: {
    width: 75,
    margin: '0 auto',
    transform: 'translateX(-22px)',
  },
  trainingDateAlert: {
    padding: '5px 20px',
    textAlign: 'center',
    backgroundColor: 'rgba(3, 118, 189, 0.15)',
  },
}))

interface StateProps {
  darkMode?: boolean
  isAuthenticated?: boolean
  classes?: any
  openFlash?: boolean
  flashMessage?: string
  flashVariant?: string
  flashDuration?: number
  signout: () => void
  setResetPasswordEmail: (email: string) => void
  passwordMigrationCheck2023: (email: string) => Promise<boolean>
  hideFlashMessage: (event: CustomEvent<OverlayEventDetail<any>>) => void
}

interface DispatchProps {}

interface LayoutProps extends RouteComponentProps, StateProps, DispatchProps {
  history: any
  title?: string
  withBackButton?: boolean
  customBackButton?: string
  isAuthenticated?: boolean
  user: any
}

const IonicPageLayout: React.FC<LayoutProps> = ({
  children,
  title,
  openFlash,
  flashMessage,
  flashVariant,
  flashDuration,
  hideFlashMessage,
  signout,
  withBackButton,
  customBackButton,
  user,
  setResetPasswordEmail,
  passwordMigrationCheck2023,
  isAuthenticated,
}) => {
  const classes = useStyles()
  const history = useHistory()
  const location = useLocation()
  const isDarkModeEnabled = useMediaQuery('(prefers-color-scheme: dark)')
  const [presentAlert] = useIonAlert()

  useEffect(() => {
    checkPasswordMigration()
  }, [])

  const passwordMigrationCheck = async () => {
    if (!user.email) {
      return false
    }

    const cleanEmail = user.email.toLowerCase().trim()
    const migrationNeeded = await passwordMigrationCheck2023(cleanEmail)

    return migrationNeeded
  }

  const getCreatedDateFromMongoId = (id: string) => {
    const ts = id.substring(0, 8)

    return new Date(parseInt(ts, 16) * 1000)
  }

  const checkPasswordMigration = async () => {
    if (
      !isAuthenticated ||
      user.settings?.passwordMigrated ||
      (user._id &&
        DateTime.fromJSDate(getCreatedDateFromMongoId(user._id)) >
          DateTime.fromISO('2023-03-12')) ||
      ['/signin/migrate/password'].indexOf(location.pathname) !== -1
    ) {
      return
    }

    if (await passwordMigrationCheck()) {
      // set redux reset password email
      const { email } = user
      signout()
      setResetPasswordEmail(email)
      presentAlert({
        backdropDismiss: false,
        header: 'Please reset your password for Nature Mapping',
        message: `Due to recent improvements to Nature Mapping, you need to reset your password. We sent you an email to ${email}  with a confirmation code. Please follow the link in the email to enter the code and a new password.`,
        buttons: [
          {
            text: 'Ok',
            role: 'confirm',
            handler: () => {
              history.push(`/signin/migrate/password?email=${email}`)
            },
          },
        ],
      })
    }
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            {withBackButton && <IonBackButton />}
            {customBackButton && (
              <IonButton routerLink={customBackButton}>
                <IonIcon icon={arrowBack}>
                  <Typography variant="srOnly">Back</Typography>
                </IonIcon>
              </IonButton>
            )}
            <IonMenuButton />
          </IonButtons>
          <IonTitle>
            {title ? (
              title
            ) : (
              <IonImg
                alt="Nature Mapping Jackson Hole Logo"
                className={classes.logo}
                onClick={() => history.push('/dashboard/projects')}
                src={
                  isDarkModeEnabled ? NatureMappingLogoWhite : NatureMappingLogo
                }
              />
            )}
          </IonTitle>
        </IonToolbar>
        {isAuthenticated && user.email && !user.trainingDate && (
          <div className={classes.trainingDateAlert}>
            <Typography>
              There is no training date associated with your account. To attend
              a training session or if you attended training already and need
              your account updated, email{' '}
              <a href="mailto:info@jhwildlife.org">info@jhwildlife.org</a>.
            </Typography>
          </div>
        )}
      </IonHeader>
      <IonContent className={classes.root} id="appContent">
        {children}
      </IonContent>
      {openFlash && (
        <IonToast
          color={flashVariant}
          duration={flashDuration || 5000}
          isOpen={openFlash}
          message={flashMessage}
          onDidDismiss={hideFlashMessage}
          position="top"
        />
      )}
    </IonPage>
  )
}

function mapStateToProps({ layout, user, auth }: any) {
  return {
    user,
    isAuthenticated: auth.isAuthenticated,
    openFlash: layout.openFlash,
    flashMessage: layout.flashMessage,
    flashVariant: layout.flashVariant,
    flashDuration: layout.flashDuration,
  }
}

export default connect(mapStateToProps, actions)(IonicPageLayout)
