import React from 'react'
import Loading from '../micros/Loading'
import { IonButton } from '@ionic/react'

const LoaderButton = ({
  isLoading,
  text,
  loadingText,
  disabled = false,
  ...rest
}) => (
  <IonButton className="LoaderButton" disabled={disabled || isLoading} {...rest}>
    {isLoading && <Loading />}
    {!isLoading ? text : loadingText}
  </IonButton>
)

export default LoaderButton