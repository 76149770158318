import React from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import {
  withStyles,
  FormGroup,
  Typography,
  TextField,
  InputLabel,
  Switch,
  FormControlLabel,
} from '@material-ui/core'
import { useIonAlert } from '@ionic/react'
import { Formik, Field } from 'formik'
import LoaderButton from '../components/buttons/LoaderButton'
import LocationSearchInput from '../components/LocationSearchInput'
import SectionDivider from '../components/micros/SectionDivider'
import actions from '../actions'
import IonicPageLayout from '../components/siteLayout/IonicPageLayout'
import {
  IonButton,
  IonButtons,
  IonIcon,
  useIonViewDidEnter,
} from '@ionic/react'
import { scrollToTop } from '../helpers/utils'
import { trashBinOutline } from 'ionicons/icons'
import ChangePassword from './ChangePassword'
import ChangeEmail from './ChangeEmail'

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    maxWidth: 600,
  },
  button: {
    margin: theme.spacing(0.5),
  },
  fieldWrapper: {
    marginBottom: '1rem',
  },
  logo: {
    width: '140px',
    margin: '0 auto',
    transform: 'translateX(-52px)',
  },
  btnIcon: {
    marginRight: '0.5rem',
  },
})

const Settings = (props) => {
  const { classes, user, updateUserSettings, updateUserAddress, deleteSelf } =
    props
  const [presentAlert] = useIonAlert()
  const history = useHistory()
  useIonViewDidEnter(() => {
    scrollToTop()
  })

  const handleSubmitAppSettings = (taxonomicSortOrder) => {
    return updateUserSettings({ taxonomicSortOrder })
  }

  const handleSubmit = (values) => {
    return updateUserSettings(values)
  }

  const handleSubmitAddress = (values) => {
    return updateUserAddress(user._id, values)
  }

  const clearAddress = (values) => {
    return updateUserAddress(user._id, {
      address1: '',
      city: '',
      state: '',
      zip: '',
      country: '',
      utmEasting: '',
      utmNorthing: '',
      utmZone: '',
    })
  }

  const handleDeleteAccount = async () => {
    await deleteSelf()
    history.push('/signin')
  }

  return (
    <IonicPageLayout>
      <section className={classes.root}>
        <Typography gutterBottom variant="h4">
          {user.firstName} {user.lastName}
        </Typography>
        <Typography gutterBottom variant="h4">
          {user.email}
        </Typography>
        <div></div>
        <SectionDivider />
        <Typography component="h2" variant="h4">
          App settings
        </Typography>
        <div>
          <FormControlLabel
            control={
              <Switch
                checked={user.settings?.taxonomicSortOrder}
                color="primary"
                inputProps={{
                  'aria-label': 'Toggle Taxonomic Species Sort Order',
                }}
                onChange={() =>
                  handleSubmitAppSettings(!user.settings?.taxonomicSortOrder)
                }
              />
            }
            label={'Use Taxonomic Species Sort Order'}
          />
        </div>
        <SectionDivider />
        <Typography component="h2" gutterBottom variant="h4">
          Your profile
        </Typography>
        <div>
          <Formik
            initialValues={user}
            onSubmit={handleSubmit}
            render={(props) => (
              <form onSubmit={props.handleSubmit}>
                <div className={classes.fieldWrapper}>
                  <Field
                    component={TextField}
                    id="firstName"
                    label="First name"
                    name="firstName"
                    onChange={props.handleChange}
                    placeholder="First name"
                    value={props.values.firstName || ''}
                    variant="outlined"
                  />
                </div>
                <div className={classes.fieldWrapper}>
                  <Field
                    component={TextField}
                    id="lastName"
                    label="Last name"
                    name="lastName"
                    onChange={props.handleChange}
                    placeholder="Last name"
                    value={props.values.lastName || ''}
                    variant="outlined"
                  />
                </div>
                <div className={classes.fieldWrapper}>
                  <Field
                    component={TextField}
                    id="phone"
                    label="Home phone"
                    name="phone"
                    onChange={props.handleChange}
                    placeholder="Home phone"
                    value={props.values.phone || ''}
                    variant="outlined"
                  />
                </div>
                <div className={classes.fieldWrapper}>
                  <Field
                    component={TextField}
                    id="cell"
                    label="Mobile phone"
                    name="cell"
                    onChange={props.handleChange}
                    placeholder="Mobile phone"
                    value={props.values.cell || ''}
                    variant="outlined"
                  />
                </div>
                <div className="buttons">
                  <LoaderButton
                    className={classes.button}
                    disabled={props.isSubmitting}
                    text="Submit"
                    type="submit"
                    variant="contained"
                  />
                </div>
              </form>
            )}
          />
          <SectionDivider />
          <FormGroup className={classes.formGroup}>
            <Typography gutterBottom variant="h4">
              Your Address
            </Typography>
            <Typography gutterBottom>
              To access Project Backyard, enter your address in the search box
              below and select an address from the provided search options. When
              you select an address, we will save it and update your account. We
              will use your home address to get the UTM coordinates of your
              house to track your observations.
            </Typography>
            <InputLabel>Address Search</InputLabel>
            <LocationSearchInput handleSelect={handleSubmitAddress} />
            <Formik
              enableReinitialize
              initialValues={user}
              onSubmit={handleSubmitAddress}
              render={(props) => (
                <form onSubmit={props.handleSubmit}>
                  <div className={classes.fieldWrapper}>
                    <Field
                      component={TextField}
                      disabled
                      id="address1"
                      label="Street"
                      name="address1"
                      onChange={props.handleChange}
                      placeholder="Street"
                      value={props.values.address1 || ''}
                      variant="outlined"
                    />
                  </div>
                  <div className={classes.fieldWrapper}>
                    <Field
                      component={TextField}
                      disabled
                      id="city"
                      label="City"
                      name="city"
                      onChange={props.handleChange}
                      placeholder="City"
                      value={props.values.city || ''}
                      variant="outlined"
                    />
                  </div>
                  <div className={classes.fieldWrapper}>
                    <Field
                      component={TextField}
                      disabled
                      id="state"
                      label="State"
                      name="state"
                      onChange={props.handleChange}
                      placeholder="State"
                      value={props.values.state || ''}
                      variant="outlined"
                    />
                  </div>
                  <div className={classes.fieldWrapper}>
                    <Field
                      component={TextField}
                      disabled
                      id="zip"
                      label="Postal/Zip code"
                      name="zip"
                      onChange={props.handleChange}
                      placeholder="Postal/Zip code"
                      value={props.values.zip || ''}
                      variant="outlined"
                    />
                  </div>
                  <div className={classes.fieldWrapper}>
                    <Field
                      component={TextField}
                      disabled
                      id="country"
                      label="Country"
                      name="country"
                      onChange={props.handleChange}
                      placeholder="Country"
                      value={props.values.country || ''}
                      variant="outlined"
                    />
                  </div>
                  <div className={classes.fieldWrapper}>
                    <Field
                      component={TextField}
                      disabled
                      id="utmEasting"
                      label="UTM Easting"
                      name="utmEasting"
                      onChange={props.handleChange}
                      placeholder="UTM Easting"
                      value={props.values.utmEasting || ''}
                      variant="outlined"
                    />
                  </div>
                  <div className={classes.fieldWrapper}>
                    <Field
                      component={TextField}
                      disabled
                      id="utmNorthing"
                      label="UTM Northing"
                      name="utmNorthing"
                      onChange={props.handleChange}
                      placeholder="UTM Northing"
                      value={props.values.utmNorthing || ''}
                      variant="outlined"
                    />
                  </div>
                  <div className={classes.fieldWrapper}>
                    <Field
                      component={TextField}
                      disabled
                      id="utmZone"
                      label="UTM Zone"
                      name="utmZone"
                      onChange={props.handleChange}
                      placeholder="UTM Zone"
                      value={props.values.utmZone || ''}
                      variant="outlined"
                    />
                  </div>
                </form>
              )}
            />
            <IonButton
              onClick={() =>
                presentAlert({
                  header: 'Are you sure?',
                  message:
                    'This will clear your address and you will not be able to access Project Backyard.',
                  buttons: [
                    {
                      text: 'Cancel',
                      role: 'cancel',
                      handler: () => {},
                    },
                    {
                      text: 'Yes',
                      role: 'confirm',
                      handler: () => {
                        clearAddress()
                      },
                    },
                  ],
                })
              }
            >
              <IonIcon
                class={classes.btnIcon}
                icon={trashBinOutline}
                size="medium"
              />
              Clear Address
            </IonButton>
          </FormGroup>
          <SectionDivider />
          <FormGroup className={classes.formGroup}>
            <ChangeEmail email={user.email} />
          </FormGroup>
          <SectionDivider />
          <FormGroup className={classes.formGroup}>
            <ChangePassword />
          </FormGroup>
          <SectionDivider />
          <FormGroup className={classes.formGroup}>
            <Typography gutterBottom variant="h4">
              Delete account
            </Typography>
            <Typography gutterBottom>
              If you would like to delete your account, click the button below.
              This will permanently delete your account and all of your data.
            </Typography>
            <div className={classes.fieldWrapper}>
              <IonButtons slot="end">
                <IonButton
                  onClick={() =>
                    presentAlert({
                      header: 'Are you sure?',
                      message:
                        'This action cannot be undone. Nature Mapping will keep your submitted observations but will delete all of your account information. You will lose all of your data.',
                      buttons: [
                        {
                          text: 'Cancel',
                          role: 'cancel',
                          handler: () => {},
                        },
                        {
                          text: 'Yes',
                          role: 'confirm',
                          handler: () => {
                            handleDeleteAccount()
                          },
                        },
                      ],
                    })
                  }
                >
                  <IonIcon
                    class={classes.btnIcon}
                    icon={trashBinOutline}
                    size="medium"
                  />
                  Delete Account ({user.email})
                </IonButton>
              </IonButtons>
            </div>
          </FormGroup>
        </div>
      </section>
    </IonicPageLayout>
  )
}

function mapStateToProps({ user }) {
  return {
    user,
  }
}

const mapDispatchToProps = {
  updateUserAddress: actions.updateUserAddress,
  updateUserSettings: actions.updateUserSettings,
  deleteSelf: actions.deleteSelf,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Settings))
