import React, { useState } from 'react'
import {
  IonContent,
  IonPage,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonButton,
  IonIcon,
  useIonViewWillEnter,
  IonImg,
} from '@ionic/react'
import classnames from 'classnames'
import { useDispatch } from 'react-redux'
import { RouteComponentProps } from 'react-router'
import { useHistory } from 'react-router-dom'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Swiper as SwiperCore, Keyboard, Pagination, Navigation } from 'swiper'
import { makeStyles, Typography } from '@material-ui/core'
import { arrowForward } from 'ionicons/icons'
import { updateUserMessagesSeen } from '../actions/user.actions'
import { setMenuEnabled } from '../actions/layout.actions'
import { saveLocalMessagesSeen } from '../helpers/localStorage'
import NatureMappingLogo from '../assets/images/natureMappingLogo.png'
import Grosbeak from '../assets/images/eveningGrosbeak.jpg'
import Squirrel from '../assets/images/redSquirrel.jpg'
import Plover from '../assets/images/semipalmatedPlover.jpg'
import Snake from '../assets/images/wanderingGarterSnake.jpg'
import Cubs from '../assets/images/grizCubs.jpg'
import 'swiper/css/bundle'
import '@ionic/react/css/ionic-swiper.css'
import '../theme/tutorial.css'

interface OwnProps extends RouteComponentProps {}

interface TutorialProps extends OwnProps {
  isAuthenticated: boolean
  user: any
}

const useStyles = makeStyles((theme) => ({
  logo: {
    width: '100%',
    maxWidth: 300,
    margin: '0 auto 30px',
  },
  content: {
    maxWidth: '500px',
    margin: '0 auto',
  },
  slideImg: {
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  slideOne: {
    backgroundImage: `url(${Cubs})`,
  },
  slideTwo: {
    backgroundImage: `url(${Grosbeak})`,
  },
  slideThree: {
    backgroundImage: `url(${Plover})`,
  },
  slideFour: {
    backgroundImage: `url(${Squirrel})`,
  },
  slideFive: {
    backgroundImage: `url(${Snake})`,
  },
  slideContentWrapper: {
    backgroundColor: 'rgba(255,255,255,0.7)',
    padding: '5px 25px',
    margin: '95px 20px 10px',
  },
}))

const Tutorial: React.FC<TutorialProps> = ({ isAuthenticated }) => {
  const [showSkip, setShowSkip] = useState(true)
  let [swiper, setSwiper] = useState<SwiperCore>()
  const dispatch: any = useDispatch()
  const history = useHistory()
  const classes = useStyles()

  useIonViewWillEnter(() => {
    dispatch(setMenuEnabled(false))
  })

  const startApp = async () => {
    if (isAuthenticated) {
      dispatch(updateUserMessagesSeen('welcomeTutorial'))
    } else {
      await saveLocalMessagesSeen('welcomeTutorial')
    }
    dispatch(setMenuEnabled(true))
    history.push('/dashboard/me')
  }

  const handleSlideChangeStart = () => {
    if (!swiper) return
    setShowSkip(!swiper.isEnd)
  }

  return (
    <IonPage id="tutorial-page">
      <IonHeader no-border>
        <IonToolbar>
          <IonButtons slot="end">
            {showSkip && (
              <IonButton color="primary" onClick={startApp}>
                Skip
              </IonButton>
            )}
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <Swiper
          keyboard={{
            enabled: true,
          }}
          modules={[Keyboard, Pagination, Navigation]}
          navigation={true}
          onSlideChangeTransitionStart={handleSlideChangeStart}
          onSwiper={setSwiper}
          pagination={{
            clickable: true,
          }}
        >
          <SwiperSlide
            className={classnames(classes.slideImg, classes.slideOne)}
          >
            <div className={classes.slideContentWrapper}>
              <div className={classes.content}>
                <h1 className="slide-title">
                  Welcome to{' '}
                  <Typography variant="srOnly">
                    Nature Mapping Jackson Hole
                  </Typography>
                </h1>
                <IonImg
                  alt="Nature Mapping Jackson Hole Logo"
                  className={classes.logo}
                  src={NatureMappingLogo}
                />
                <Typography variant="body2">
                  A program of the Jackson Hole Wildlife Foundation
                </Typography>
              </div>
            </div>
          </SwiperSlide>

          <SwiperSlide
            className={classnames(classes.slideImg, classes.slideTwo)}
          >
            <div className={classes.slideContentWrapper}>
              <div className={classes.content}>
                <Typography variant="body2">
                  Nature Mapping data are used by scientists and wildlife
                  managers to conserve wildlife.
                </Typography>
              </div>
            </div>
          </SwiperSlide>

          <SwiperSlide
            className={classnames(classes.slideImg, classes.slideThree)}
          >
            <div className={classes.slideContentWrapper}>
              <div className={classes.content}>
                <Typography variant="body2">
                  To participate, create a Nature Mapping account and enter your
                  wildlife observations using this app.
                </Typography>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide
            className={classnames(classes.slideImg, classes.slideFour)}
          >
            <div className={classes.slideContentWrapper}>
              <div className={classes.content}>
                <h2>To ensure database quality:</h2>
                <ol>
                  <li>
                    <Typography variant="body2">
                      Submit sightings only when species can be verified
                    </Typography>
                  </li>
                  <li>
                    <Typography variant="body2">
                      Map both common and uncommon species
                    </Typography>
                  </li>
                  <li>
                    <Typography variant="body2">
                      Include observations of roadkill
                    </Typography>
                  </li>
                </ol>
              </div>
            </div>
          </SwiperSlide>

          <SwiperSlide
            className={classnames(classes.slideImg, classes.slideFive)}
          >
            <div className={classes.slideContentWrapper}>
              <div className={classes.content}>
                <h2 className="slide-title">Get started Nature Mapping!</h2>
                <IonButton fill="clear" onClick={startApp}>
                  Continue
                  <IonIcon icon={arrowForward} slot="end" />
                </IonButton>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
      </IonContent>
    </IonPage>
  )
}

export default Tutorial
