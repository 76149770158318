import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import {
  IonButton,
  useIonViewDidEnter,
  useIonViewWillEnter,
} from '@ionic/react'
import { me } from '../actions/user.actions'
import { getProjects } from '../actions/project.actions'
import { clearReviewData, getFormTypes } from '../actions/form.actions'
import { scrollToTop, sortByName } from '../helpers/utils'
import IonicPageLayout from '../components/siteLayout/IonicPageLayout'
import StyledLoading from '../components/micros/Loading'

const styles = (theme) => ({
  root: {},
  logo: {
    width: '140px',
    margin: '0 auto',
    transform: 'translateX(-52px)',
  },
  projectBtn: {
    height: '4rem',
    width: '100%',
  },
  projectList: {
    alignItems: 'center',
    display: 'grid',
    gridTemplateColumns: '1fr',
    gridGap: '1.2rem 2rem',
    justifyContent: 'space-around',
    marginTop: '1.5rem',

    [theme.breakpoints.up('sm')]: {
      gridTemplateColumns: '1fr 1fr',
    },

    [theme.breakpoints.up('lg')]: {
      gridTemplateColumns: '1fr 1fr 1fr',
    },
  },
})

const SelectProject = (props) => {
  const {
    classes,
    clearReviewData,
    isAuthenticated,
    me,
    projects,
    formTypes,
    getFormTypes,
    getProjects,
    user,
  } = props
  useIonViewDidEnter(() => {
    clearReviewData()

    if (isAuthenticated) {
      me()
    }

    scrollToTop()
  })

  useIonViewWillEnter(() => {
    if (!formTypes) {
      getFormTypes()
    }

    if (!projects) {
      getProjects()
    }
  })

  const renderProjects = () => {
    if (!projects || !projects.length) {
      return <StyledLoading />
    }

    if (!isAuthenticated) {
      if (projects.length && user.projects.length) {
        const openProjects = user.projects.map((project) => project._id)
        const sortedProjects = projects.sort(sortByName)

        return sortedProjects.map((project) => {
          if (
            !project.type ||
            openProjects.includes(project._id) ||
            project.key === 'wildlifeTour'
          ) {
            if (
              project.key === 'backyard' &&
              (!user.utmEasting || !user.utmNorthing || !user.utmZone)
            ) {
              return (
                <div key={project.key}>
                  <Typography>
                    You need to login to access Project Backyard,{' '}
                    <Link to="/signin">Sign in now</Link>
                  </Typography>
                  <IonButton disabled>Project Backyard</IonButton>
                </div>
              )
            }

            return (
              <IonButton
                className={classes.projectBtn}
                key={project.key}
                routerLink={`/observation/create/${project.key}/0`}
              >
                {project.name}
              </IonButton>
            )
          }

          return null
        })
      }

      return (
        <>
          <div key={'wildlifeTour'}>
            <IonButton
              className={classes.projectBtn}
              key={'wildlifeTour'}
              routerLink={`/observation/create/wildlifeTour/0`}
            >
              Wildlife tour
            </IonButton>
          </div>
        </>
      )
    }

    if (!user.email) {
      return <StyledLoading />
    }

    if (user.email && !user.trainingDate) {
      return (
        <div key={'wildlifeTour'}>
          <IonButton
            className={classes.projectBtn}
            key={'wildlifeTour'}
            routerLink={`/observation/create/${'wildlifeTour'}/0`}
          >
            Wildlife tour
          </IonButton>
        </div>
      )
    }

    if (projects.length && user.projects) {
      const openProjects = user.projects.map((project) => project._id)
      const sortedProjects = projects.sort(sortByName)

      return sortedProjects.map((project) => {
        if (!project.type || openProjects.includes(project._id)) {
          if (
            project.key === 'backyard' &&
            (!user.utmEasting || !user.utmNorthing || !user.utmZone)
          ) {
            return (
              <div key={project.key}>
                <Typography>
                  To get access to Project Backyard,{' '}
                  <Link to="/dashboard/settings">go to your settings</Link> and
                  add the UTM coordinates for your home.
                </Typography>
                <IonButton disabled>Project Backyard</IonButton>
              </div>
            )
          }

          return (
            <IonButton
              className={classes.projectBtn}
              key={project.key}
              routerLink={`/observation/create/${project.key}/0`}
            >
              {project.name}
            </IonButton>
          )
        }

        return null
      })
    }
    return null
  }

  return (
    <IonicPageLayout>
      <Typography component="h2" variant="h4">
        Add Observations
      </Typography>
      <Typography component="h2" variant="body1">
        {isAuthenticated
          ? 'You can enter observations for the following projects:'
          : 'Observations will not be uploaded until you sign in with an account. You can enter observations for the following projects:'}
      </Typography>
      <div className={classes.projectList}>{renderProjects()}</div>
    </IonicPageLayout>
  )
}

function mapStateToProps({ projects, user, form }) {
  return {
    projects,
    user,
    formTypes: form.types,
  }
}

const mapDispatchToProps = {
  clearReviewData,
  me,
  getFormTypes,
  getProjects,
}

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(SelectProject)
)
