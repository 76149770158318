import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import NatureMappingTable from './NatureMappingTable'
import { getTableData } from '../../selectors'

const propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({ Header: PropTypes.string, accessor: PropTypes.string }),
  ).isRequired,
  fetchData: PropTypes.func.isRequired,
  tableKey: PropTypes.string.isRequired,
}

const UserTable = ({
  columns,
  fetchData,
  history,
  tableKey,
  tableData
}) => {
  const [loading, setLoading] = React.useState(false)
  const fetchIdRef = React.useRef(0)
  const { pageSize, pageIndex, filters, sortBy, total, rows } = tableData
  const initialState = {
    pageSize,
    pageIndex,
    filters,
    sortBy
  }

  const fetchDataCall = React.useCallback(state => {
    // Whenever the table model changes, or the user sorts or changes pages, this method gets called and passed the current table model.
    const fetchID = ++fetchIdRef.current
    setLoading(true)

    if (fetchID === fetchIdRef.current) {
      fetchData({
        tableKey,
        tableState: state,
      }).then(res => {
        setLoading(false)
      })
    }
  }, [fetchData, tableKey])

  function onCellClick(event, cell) {
    return history.push(`/admin/user/view/${cell.row.original._id}`)
  }

  return (
    <NatureMappingTable
      columns={columns}
      data={rows}
      fetchData={fetchDataCall}
      initialState={initialState}
      loading={loading}
      onCellClick={onCellClick}
      pageCount={Math.ceil(total / pageSize)}
      tableView
      total={total}
    />
  )
}

const mapStateToProps = (state, props) => {
  return {
    tableData: getTableData(state, props)
  }
}

UserTable.propTypes = propTypes

export default connect(mapStateToProps, null)(withRouter(UserTable))
