/* eslint-disable sort-vars */
/* eslint-disable prefer-destructuring */
import {
  Camera,
  CameraResultType,
  CameraSource,
  Photo,
} from '@capacitor/camera'

import { s3UploadPhoto } from '../libs/awsLib'

async function dataUrlToFile(dataUrl: string, fileName: string): Promise<File> {
  const res: Response = await fetch(dataUrl);
  const blob: Blob = await res.blob();
  return new File([blob], fileName, { type: 'image/png' });
}

export function usePhotoGallery() {
  const takePhoto = async (progressCallback: Function, disableFormSubmit: Function) => {
    const cameraPhoto = await Camera.getPhoto({
      resultType: CameraResultType.DataUrl,
      source: CameraSource.Prompt,
      quality: 100,
      saveToGallery: true,
    })
    disableFormSubmit()
    const fileName = new Date().getTime() + '.' + cameraPhoto.format
    const savedFileImage = await savePicture(cameraPhoto, fileName, progressCallback)
    return savedFileImage
  }

  const savePicture = async (
    photo: Photo,
    fileName: string,
    progressCallback?: Function
  ): Promise<any> => {
    try {
      const fileFromDataUrl = await dataUrlToFile(photo.dataUrl!, fileName)

      const s3photo = await s3UploadPhoto(fileFromDataUrl, progressCallback)

      return {
        key: s3photo,
      }
    } catch (e) {
      console.error('S3 upload error', e)
      throw e
    }
  }

  return {
    takePhoto,
  }
}
