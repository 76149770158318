import React, { useState } from 'react'
import { connect } from 'react-redux'
import {
  IonGrid,
  IonRow,
  IonCol,
  IonActionSheet,
  useIonViewWillLeave,
} from '@ionic/react'
import { makeStyles, Typography } from '@material-ui/core'
import { trash, close } from 'ionicons/icons'
import AddAPhoto from '@material-ui/icons/AddAPhoto'
import actions from '../../../actions'
import { safeWrap } from '../../../helpers/utils'
import { usePhotoGallery } from '../../../hooks/usePhotoGallery'
import { s3Delete } from '../../../libs/awsLib'

const useStyles = makeStyles((theme) => ({
  dropzone: {
    backgroundColor: '#FFF',
    color: '#000',
    cursor: 'pointer',
    padding: '2rem 3rem',
    textAlign: 'center',
    boxShadow:
      '0px 1px 30px 5px rgba(0, 0, 0, 0.03), 0px 2px 2px 0px rgba(0, 0, 0, 0.01), 0px 3px 1px -2px rgba(0, 0, 0, 0.02);',
  },
  img: {
    display: 'block',
    width: 'auto',
    height: '100%',
  },
  thumbsContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 16,
  },
  thumbWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  thumb: {
    display: 'inline-flex',
    borderRadius: 2,
    border: '1px solid #eaeaea',
    width: 100,
    height: 100,
    padding: 4,
    boxSizing: 'border-box',
    marginBottom: '0.5rem',
  },
  thumbLink: {
    cursor: 'pointer',
  },
  thumbInner: {
    alignItems: 'center',
    display: 'flex',
    minWidth: 0,
    overflow: 'hidden',
  },
}))

const PhotoWidget = (props) => {
  const {
    disableFormSubmit,
    enableFormSubmit,
    isUploading,
    onChange,
    online,
    reviewData,
    value,
    showFlashMessage,
  } = props
  const [photoToDelete, setPhotoToDelete] = useState()
  const classes = useStyles()

  useIonViewWillLeave(() => {
    enableFormSubmit()
  })

  let cleanValue = value

  if (value && !Array.isArray(value)) cleanValue = value.split('|')

  if (!cleanValue) cleanValue = []

  if (reviewData && reviewData.photos) cleanValue = reviewData.photos.split('|')

  const [files, setFiles] = useState(cleanValue)
  const { takePhoto } = usePhotoGallery()

  const progressCallback = (progress) => {
    const progressBar = document.querySelector('.progress')
    const h2 = document.querySelector('.progress-indicator h2')
    h2.innerHTML = Math.round((progress.loaded / progress.total) * 100) + '%'
    progressBar.style.width = (progress.loaded / progress.total) * 100 + '%'
  }

  const handleTakePhoto = async () => {
    try {
      const photo = await takePhoto(progressCallback, disableFormSubmit)

      const fileValues = files.concat(photo)
      setFiles(fileValues)

      onChange(fileValues.map((file) => (file.key ? file.key : file)).join('|'))
      setTimeout(() => {
        enableFormSubmit()
        setTimeout(() => {
          const progressBar = document.querySelector('.progress')
          const h2 = document.querySelector('.progress-indicator h2')
          h2.innerHTML = 0 + '%'
          progressBar.style.width = 0 + '%'
        }, 800)
      }, 1500)
    } catch (error) {
      console.error(error)
      showFlashMessage(
        `There was an error uploading your photos. Please try again later with a strong network connection.`,
        'danger'
      )
      enableFormSubmit()
      setTimeout(() => {
        const progressBar = document.querySelector('.progress')
        const h2 = document.querySelector('.progress-indicator h2')
        h2.innerHTML = 0 + '%'
        progressBar.style.width = 0 + '%'
      }, 800)
    }
  }

  const handleDelete = async (photo) => {
    try {
      const photoKey = photo.key || photo

      try {
        await s3Delete(photoKey)
      } catch (e) {
        console.error(e)
      }

      const fileValues = files
        .filter((file) =>
          file.key ? file.key !== photoKey : file !== photoKey
        )
        .map((file) => (file.key ? file.key : file))

      setFiles(fileValues)

      onChange(fileValues.join('|'))
    } catch (e) {
      console.error(e)
    }
  }

  if (!online) {
    return (
      <Typography align="center">
        Photo uploads are only available when online. You can come back to edit
        this observation and add photos once you reach service again.
      </Typography>
    )
  }

  return (
    <section>
      <div
        className="dropzone photo-dropzone"
        onClick={() => handleTakePhoto()}
      >
        <div className={classes.dropzone}>
          <AddAPhoto />
          {isUploading ? (
            <Typography>Uploading</Typography>
          ) : (
            <>
              <Typography gutterBottom variant="body1">
                Click here to take a photo or upload a photo from your device.
              </Typography>
              <Typography variant="body1">
                A photo can help scientists verify this sighting
              </Typography>
            </>
          )}
        </div>
      </div>
      <div className={`${isUploading ? 'uploading' : ''} progress-indicator`}>
        <div className="progressDiv">
          <div className="progress"></div>
        </div>
        <h2>0%</h2>
      </div>
      <IonGrid>
        <IonRow>
          {files.map((photo, index) => {
            const imageRequest = JSON.stringify({
              bucket: process.env.REACT_APP_S3_BUCKET,
              key: `public/${photo.key || photo}`,
              edits: {
                resize: {
                  width: 200,
                  height: 200,
                  fit: 'contain',
                },
              },
            })

            const encodedRequest = btoa(imageRequest)

            const photoUrl = `${process.env.REACT_APP_PHOTO_SERVER}/${encodedRequest}`

            return (
              <IonCol key={index} size="4">
                <img
                  alt="nature mapping observation"
                  className={classes.img}
                  onClick={() => setPhotoToDelete(photo)}
                  src={photoUrl}
                />
              </IonCol>
            )
          })}
        </IonRow>
      </IonGrid>
      <IonActionSheet
        buttons={[
          {
            text: 'Delete',
            role: 'destructive',
            icon: trash,
            handler: () => {
              if (photoToDelete) {
                handleDelete(photoToDelete)
              }
            },
          },
          {
            text: 'Cancel',
            icon: close,
            role: 'cancel',
          },
        ]}
        isOpen={!!photoToDelete}
        onDidDismiss={() => setPhotoToDelete(undefined)}
      />
    </section>
  )
}

function mapStateToProps({ form, layout }) {
  return {
    isUploading: form.disableFormSubmit,
    online: layout.online,
    reviewData: form.data,
  }
}

export default safeWrap(connect(mapStateToProps, actions)(PhotoWidget))
