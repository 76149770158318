import React from 'react'
import Select from 'react-select'
import { withStyles, InputLabel, useMediaQuery } from '@material-ui/core'

const styles = theme => ({
  selectLabel: {
    width: '100%',
  },
  select: {
    marginTop: '0.5rem',
  },
})

const NatureMappingSelect = props => {
  const {
    classes,
    error,
    field: { name },
    id,
    label,
    isMulti,
    onChange,
    onBlur,
    options,
    touched,
    value,
  } = props
  const isDarkModeEnabled = useMediaQuery('(prefers-color-scheme: dark)')

  const emptyValue = isMulti ? [] : ''

  const handleChange = ({ value }) => {
    onChange(name, value)
  }

  const handleBlur = () => {
    onBlur(name, true)
  }

  return (
    <div>
      <InputLabel className={classes.selectLabel} htmlFor={name}>
        {label}
        <Select
          className={classes.select}
          id={id}
          isMulti={isMulti}
          name={name}
          onBlur={handleBlur}
          onChange={handleChange}
          options={options}
          styles={{
            menu: (provided) => ({
              ...provided,
              zIndex: 9999,
              backgroundColor: isDarkModeEnabled ? '#2f2f2f' : '#fff',
            }),
            option: (provided, state) => ({
              ...provided,
              zIndex: 9999,
              color:
                isDarkModeEnabled && state.isSelected
                  ? '#fff'
                  : state.isSelected
                  ? '#fff'
                  : isDarkModeEnabled
                  ? '#fff'
                  : '#2f2f2f',
              backgroundColor:
                isDarkModeEnabled && state.isSelected
                  ? '#537552'
                  : state.isSelected
                  ? '#537552'
                  : isDarkModeEnabled
                  ? '537552'
                  : '#fff',
              fontWeight: state.isSelected ? 'bold' : 'normal',
            }),
            control: (provided) => ({
              ...provided,
              backgroundColor: isDarkModeEnabled ? '#2f2f2f' : '#fff',
              borderColor: isDarkModeEnabled ? '#537552' : '#537552',
              borderWidth: 5,
            }),
            singleValue: (provided) => ({
              ...provided,
              color: isDarkModeEnabled ? '#fff' : '#2f2f2f',
            }),
            placeholder: (provided) => ({
              ...provided,
              color: isDarkModeEnabled ? '#fff' : '#2f2f2f',
            }),
          }}
          value={
            typeof value === 'undefined'
              ? emptyValue
              : options.find(option => option.value === value)
          }
        />
      </InputLabel>
      {!!error && touched && (
        <div style={{ color: 'red', marginTop: '.5rem' }}>{error}</div>
      )}
    </div>
  )
}

export default withStyles(styles)(NatureMappingSelect)
