import gql from 'graphql-tag'
import {
  ObservationTableRowFragment,
  UserFragment,
  UserDisplayFragment,
} from '../fragments'

export const me = gql`
  query me {
    me {
      ...User
    }
  }
  ${UserFragment}
`

export const isAdmin = gql`
  query isAdmin {
    isAdmin
  }
`

export const isDataVetter = gql`
  query isDataVetter {
    isDataVetter
  }
`

export const getUser = gql`
  query getUser($input: GetUserInput) {
    getUser(input: $input) {
      ...UserDisplay
    }
  }
  ${UserDisplayFragment}
`

export const passwordMigrationCheck2023 = gql`
  query passwordMigrationCheck2023($input: ForgotPasswordInput) {
    passwordMigrationCheck2023(input: $input)
  }
`

export const forgotPassword = gql`
  query forgotPassword($input: ForgotPasswordInput) {
    forgotPassword(input: $input)
  }
`

export const confirmForgotPassword = gql`
  mutation confirmForgotPassword($input: ConfirmForgotPasswordInput) {
    confirmForgotPassword(input: $input)
  }
`

export const getUsersList = gql`
  query getUsersList($input: TablePagingInput) {
    getUsersList(input: $input) {
      count
      data {
        ...UserDisplay
      }
    }
  }
  ${UserDisplayFragment}
`

export const getMyObservations = gql`
  query getMyObservations($input: TablePagingInput) {
    getMyObservations(input: $input) {
      count
      data {
        ...ObservationTableRow
      }
    }
  }
  ${ObservationTableRowFragment}
`

export const assignProjectsToUser = gql`
  mutation assignProjectsToUser($input: AssignProjectsToUserInput) {
    assignProjectsToUser(input: $input) {
      ...User
    }
  }
  ${UserFragment}
`

export const updateUserDataVettingAccess = gql`
  mutation updateUserDataVettingAccess(
    $input: UpdateUserDataVettingAccessInput
  ) {
    updateUserDataVettingAccess(input: $input) {
      ...User
    }
  }
  ${UserFragment}
`

export const changeUserLevel = gql`
  mutation changeUserLevel($input: ChangeUserLevelInput) {
    changeUserLevel(input: $input) {
      ...User
    }
  }
  ${UserFragment}
`

export const setTrainingDate = gql`
  mutation setTrainingDate($input: SetTrainingDateInput) {
    setTrainingDate(input: $input) {
      ...User
    }
  }
  ${UserFragment}
`

export const createUser = gql`
  mutation createUser($input: CreateUserInput) {
    createUser(input: $input) {
      ...User
    }
  }
  ${UserFragment}
`

export const updateUserEmail = gql`
  mutation updateUserEmail($input: UpdateUserEmailInput) {
    updateUserEmail(input: $input) {
      ...User
    }
  }
  ${UserFragment}
`

export const activateUser = gql`
  mutation activateUser($input: UserIdInput) {
    activateUser(input: $input) {
      ...User
    }
  }
  ${UserFragment}
`

export const deactivateUser = gql`
  mutation deactivateUser($input: UserIdInput) {
    deactivateUser(input: $input) {
      ...User
    }
  }
  ${UserFragment}
`

export const getUserStats = gql`
  query getUserStats {
    getUserStats {
      count
      uniqueSpecies
      observationsBySpecies
      observationsByProject
      observationsByType
    }
  }
`

export const getDataVerificationStats = gql`
  query getDataVerificationStats {
    getDataVerificationStats {
      numberToVerify
      numberVerified
      numberRejected
      numberQuestionable
    }
  }
`

export const updateUserAddress = gql`
  mutation updateUserAddress($input: UpdateUserAddressInput) {
    updateUserAddress(input: $input) {
      ...User
    }
  }
  ${UserFragment}
`

export const updateUserSettings = gql`
  mutation updateUserSettings($input: UpdateUserSettingsInput) {
    updateUserSettings(input: $input) {
      ...User
    }
  }
  ${UserFragment}
`

export const updateUserVisits = gql`
  mutation updateUserVisits {
    updateUserVisits {
      ...User
    }
  }
  ${UserFragment}
`

export const updateUserMessagesSeen = gql`
  mutation updateUserMessagesSeen($input: UpdateUserMessagesSeenInput) {
    updateUserMessagesSeen(input: $input) {
      ...User
    }
  }
  ${UserFragment}
`

export const deleteSelf = gql`
  mutation deleteSelf {
    deleteSelf
  }
`

export const deleteUser = gql`
  mutation deleteUser($input: DeleteUserInput) {
    deleteUser(input: $input)
  }
`
