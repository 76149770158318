import gql from 'graphql-tag'
import { ProjectFragment } from '../fragments'

export const getProject = gql`
  query getProject($input: GetProjectInput) {
    getProject(input: $input) {
      ...Project
    }
  }
  ${ProjectFragment}
`

export const getProjects = gql`
  query getProjects {
    getProjects {
      ...Project
    }
  }
  ${ProjectFragment}
`
