import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Auth } from 'aws-amplify'
import { Grid, withStyles, InputLabel } from '@material-ui/core'
import LoaderButton from '../../components/buttons/LoaderButton'
import actions from '../../actions'
import PasswordField from '../../components/forms/PasswordField'
import IonicPageLayout from '../../components/siteLayout/IonicPageLayout'
import { useHistory } from 'react-router'

const styles = (theme) => ({
  root: {
    flesGrow: 1,
    marginTop: '60px',
  },
  textField: {
    marginTop: theme.spacing(),
    marginBottom: theme.spacing(),
  },
  button: {
    marginTop: theme.spacing(),
  },
})

const SetNewPassword = (props) => {
  const { classes, cognitoUser, signin } = props
  const [password, setPassword] = useState('')
  const [isChanging, setIsChanging] = useState(false)
  const [confirmPassword, setConfirmPassword] = useState('')

  const history = useHistory()

  const handleChange = (e) => {
    switch (e.target.id) {
      case 'password':
        setPassword(e.target.value)
        break
      case 'confirmPassword':
        setConfirmPassword(e.target.value)
        break
      default:
        break
    }
  }

  const handleChangeClick = async (e) => {
    e.preventDefault()

    setIsChanging(true)

    try {
      await Auth.completeNewPassword(cognitoUser, password)

      signin()
      history.push('/')
    } catch (e) {
      alert(e.message)
      setIsChanging(false)
    }
  }

  const validateForm = () => {
    return password.length > 0 && password === confirmPassword
  }

  return (
    <IonicPageLayout withBackButton>
      <Grid className={classes.root} container>
        <Grid item md={4} sm={3} xs={1} />
        <Grid item md={4} sm={6} xs={10}>
          <form onSubmit={handleChangeClick}>
            <InputLabel>Set new password</InputLabel>
            <div>
              <PasswordField
                className={classes.textField}
                helperText={`Passwords must be at least 8 characters.`}
                id="password"
                label="New Password"
                onIonChange={handleChange}
                value={password}
                variant="outlined"
              />
              <PasswordField
                className={classes.textField}
                id="confirmPassword"
                label="Confirm New Password"
                onIonChange={handleChange}
                value={confirmPassword}
                variant="outlined"
              />
            </div>
            <div>
              <LoaderButton
                className={classes.button}
                color="primary"
                disabled={!validateForm()}
                isLoading={isChanging}
                loadingText="Changing..."
                text="Change Password"
                type="submit"
                variant="contained"
              />
            </div>
          </form>
        </Grid>
        <Grid item md={4} sm={3} xs={1} />
      </Grid>
    </IonicPageLayout>
  )
}

const mapStateToProps = ({ auth }) => {
  return {
    cognitoUser: auth.newPasswordUser,
  }
}

const mapDispatchToProps = {
  signin: actions.signin,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(SetNewPassword))
