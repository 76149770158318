import React, { useState } from 'react'
import { withStyles } from '@material-ui/core'
import { IonButton, IonIcon, IonInput, IonItem, IonLabel } from '@ionic/react'
import { eyeOffOutline, eyeOutline } from 'ionicons/icons'

const styles = (theme) => ({
  innerWrapper: {
    display: 'grid',
    gridTemplateColumns: '1fr auto',
    width: '100%',
    alignItems: 'center',

    '& button': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
})

const defaultLabelProps = { position: 'stacked' }
const defaultInputProps = {}
const IonicInput = ({
  label,
  labelProps = {},
  inputProps = {},
  type,
  classes,
  name,
  values,
  touched,
  errors,
  enterkeyhint = 'Enter',
  setFieldValue,
  setFieldTouched,
}) => {
  const [showPassword, setShowPassword] = useState(false)

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword)
  }

  const blurHandler = (e) => {
    setFieldTouched(name)
  }

  const changeHandler = (e) => {
    const usedValue =
      type !== 'password' && e.target && e.target.value
        ? e.target.value
        : e.detail.value
    setFieldValue(name, usedValue)
    setFieldTouched(name)
  }
  const inputHandler = (e) => {
    setFieldValue(name, e.target.value)
  }

  return (
    <div>
      <IonItem
        className={errors && errors[name] && touched[name] ? 'ion-invalid' : ''}
      >
        <IonLabel {...{ ...defaultLabelProps, ...labelProps }}>
          {label}
        </IonLabel>
        <div className={classes.innerWrapper}>
          <IonInput
            enterkeyhint={enterkeyhint}
            name={name}
            onIonBlur={blurHandler}
            onIonChange={changeHandler}
            onIonInput={inputHandler}
            type={
              type === 'password' ? (showPassword ? 'text' : 'password') : type
            }
            value={values[name]}
            {...{ ...defaultInputProps, ...inputProps }}
          />
          {type === 'password' && (
            <IonButton
              aria-label="Toggle password visibility"
              onClick={handleClickShowPassword}
            >
              <IonIcon
                icon={showPassword ? eyeOutline : eyeOffOutline}
                slot="start"
              />
            </IonButton>
          )}
        </div>
      </IonItem>
      {errors && errors[name] && touched[name] && (
        <div
          className={`validationMessage ${
            errors && errors[name] && touched[name] ? 'error' : ''
          }`}
        >
          {errors[name]}
        </div>
      )}
    </div>
  )
}

export default withStyles(styles)(IonicInput)
