import React, { createRef, memo, useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import proj4 from 'proj4'
import UtmConverter from 'utm-latlng'
import queryString from 'qs'
import config from '../../config'
import actions from '../../actions'
import {
  getSpeciesEnum,
  getSpeciesEnumNames,
  getActivityEnum,
  getActivityEnumNames,
  getAgeEnum,
  getAgeEnumNames,
} from '../../helpers/utils'
import projectForms from '../../pages/projects/forms'
import Wizard from '../../pages/projects/Wizard'
import Loading from '../micros/Loading'
import {
  IonButton,
  IonContent,
  IonHeader,
  IonFooter,
  IonPage,
  IonToolbar,
  IonTitle,
  IonIcon,
  IonButtons,
  useIonViewDidEnter,
  useIonViewWillEnter,
  useIonAlert,
  useIonViewWillLeave,
  IonToast,
} from '@ionic/react'
import { closeOutline } from 'ionicons/icons'
import { Redirect } from 'react-router'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { ErrorListTemplate } from '../forms/ErrorListTemplate'

// we use these to limit the parts of schema that we update on load of form and form changes below
const limitedSpeciesProjects = ['mooseDay', 'nestboxProject']
const noAgeProjects = ['beaver', 'nestboxProject', 'wildlifeTour']
const noActivityProjects = ['nestboxProject']

const useStyles = makeStyles((theme) => ({
  root: {
    '& .checkbox': {
      fontSize: '1rem',
      marginTop: 0,
      marginBottom: 0,

      '& label': {
        alignItems: 'center',
        display: 'flex',

        '& > span': {
          position: 'relative',
          top: 2,
        },
      },

      '& input[type=checkbox]': {
        flexShrink: 0,
        height: '25px',
        marginRight: '8px',
        position: 'static',
        width: '25px',
      },
    },
    '& .checkboxes': {
      display: 'grid',
      gridGap: '15px',
      gridTemplateColumns: '1fr 1fr',
      alignItems: 'center',

      [theme.breakpoints.up('sm')]: {
        gridTemplateColumns: '1fr 1fr 1fr 1fr',
      },

      [theme.breakpoints.up('lg')]: {
        gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr',
      },

      '& .checkbox': {
        marginRight: 25,

        '& label > span': {
          alignItems: 'center',
          display: 'flex',

          '& > span': {
            position: 'relative',
            top: 2,
          },
        },
      },
    },
  },
  alert: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: 'rgba(3, 118, 189, 0.15)',
    padding: '0.8rem 1rem',
    marginTop: '1rem',
    marginBottom: '1rem',
  },
  infoIcon: {
    marginRight: '1rem',
  },
  wizardButtons: {
    backgroundColor: 'rgba(255,255,255, 0.8)',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    boxSizing: 'border-box',
    zIndex: 99999,

    '& ion-toolbar': {
      padding: '0rem 1rem',
    },

    [theme.breakpoints.up('sm')]: {
      '& ion-toolbar': {
        padding: '0 1.5rem',
      },
    },

    [theme.breakpoints.up('md')]: {
      marginTop: 0,
      width: `100%`,
    },
  },
}))

const IonicObservationFormPage = (props) => {
  const {
    clearReviewData,
    disableFormSubmit,
    enableFormSubmit,
    flashDuration,
    flashMessage,
    flashVariant,
    formTypes,
    getFormTypes,
    getObservationEntry,
    hideFlashMessage,
    initialLocation,
    isAuthenticated,
    openFlash,
    projects,
    reviewData,
    saveDraftObservation,
    setReviewData,
    formMetadata,
    setFormMetadata,
    user,
  } = props
  const { step: stepParam, id } = useParams()
  const location = useLocation()
  const history = useHistory()
  const classes = useStyles()

  const parsedQueryString = queryString.parse(location.search, {
    ignoreQueryPrefix: true,
  })

  // Jackson, WY
  const defaultCenter = { lat: 43.4799, lng: -110.7624 }

  const utmConvert = new UtmConverter()

  const defaultUTM = utmConvert.convertLatLngToUtm(
    defaultCenter.lat,
    defaultCenter.lng,
    4
  )
  let locationData = initialLocation

  // eslint-disable-next-line prefer-destructuring
  const action = location.pathname.split('/')[2]
  // eslint-disable-next-line prefer-destructuring
  const projectKey = location.pathname.split('/')[3]
  const step = stepParam !== 'review' ? parseInt(stepParam) : stepParam

  const submitFormRef = createRef()

  const [schema, setSchema] = useState(
    projectForms[projectKey] &&
      projectForms[projectKey].createSchema(formTypes, {
        isTaxonomicSortOrderEnabled: isAuthenticated
          ? user.settings?.taxonomicSortOrder
          : true,
      })
  )
  const [submitting, setSubmitting] = useState(false)
  const [isVisible, setIsVisible] = useState(true)
  const [isLoading, setIsLoading] = useState(true)
  const contentRef = useRef()
  const [presentAlert] = useIonAlert()

  useEffect(() => {
    setFormMetadata(parsedQueryString)
  }, [])

  useEffect(() => {
    setFormMetadata(parsedQueryString)
  }, [JSON.stringify(parsedQueryString)])

  useIonViewDidEnter(() => {
    resetFormSubmitting()
    setIsVisible(true)
  })

  useIonViewWillEnter(async () => {
    resetFormSubmitting()
    if (!formTypes) {
      getFormTypes()
    }

    const schemaUpdate = { ...schema }

    if (action === 'edit') {
      if (!reviewData || parsedQueryString.fromVerification) {
        const entry = await getObservationEntry(id)

        if (
          entry.speciesType &&
          Object.keys(schema.steps[step].properties).includes('species') &&
          !limitedSpeciesProjects.includes(projectKey)
        ) {
          schemaUpdate.steps[step] = Object.assign(schema.steps[step], {
            properties: {
              ...schema.steps[step].properties,
              species: {
                ...schema.steps[step].properties.species,
                enum: getSpeciesEnum(
                  projectKey,
                  formTypes,
                  entry.speciesType,
                  true
                ),
                enumNames: getSpeciesEnumNames(
                  projectKey,
                  formTypes,
                  entry.speciesType,
                  true
                ),
              },
            },
          })

          if (entry.speciesType && !noAgeProjects.includes(projectKey)) {
            const observationsStep = schema.steps.findIndex((s) => {
              return Object.keys(s.properties).includes('observations')
            })

            schemaUpdate.steps[observationsStep] = Object.assign(
              schema.steps[observationsStep],
              {
                definitions: {
                  ...schema.steps[observationsStep].definitions,
                  observation: {
                    ...schema.steps[observationsStep].definitions.observation,
                    properties: {
                      ...schema.steps[observationsStep].definitions.observation
                        .properties,
                      age: {
                        ...schema.steps[observationsStep].definitions
                          .observation.properties.age,
                        enum: getAgeEnum(
                          formTypes,
                          entry.speciesType,
                          projectKey
                        ),
                        enumNames: getAgeEnumNames(
                          formTypes,
                          entry.speciesType,
                          projectKey
                        ),
                      },
                    },
                  },
                },
              }
            )
          }

          if (
            entry.speciesType &&
            entry.species &&
            !noActivityProjects.includes(projectKey)
          ) {
            const observationsStep = schema.steps.findIndex((s) => {
              return Object.keys(s.properties).includes('observations')
            })

            schemaUpdate.steps[observationsStep] = Object.assign(
              schema.steps[observationsStep],
              {
                definitions: {
                  ...schema.steps[observationsStep].definitions,
                  observation: {
                    ...schema.steps[observationsStep].definitions.observation,
                    properties: {
                      ...schema.steps[observationsStep].definitions.observation
                        .properties,
                      activity: {
                        ...schema.steps[observationsStep].definitions
                          .observation.properties.activity,
                        enum: getActivityEnum(
                          formTypes,
                          entry.species,
                          entry.speciesType
                        ),
                        enumNames: getActivityEnumNames(
                          formTypes,
                          entry.species,
                          entry.speciesType
                        ),
                      },
                    },
                  },
                },
              }
            )
          }

          setSchema(schemaUpdate)
        }

        const formData = Object.assign(entry, {
          photos: entry.photos ? entry.photos.join('|') : '',
        })

        // This stops the form lib (react-jsonschema-form) from throwing errors on null fields from the db
        Object.keys(formData).forEach((key) => {
          if (formData[key] === null) {
            formData[key] = undefined
          }
        })

        const project = projects.find(
          (project) => project._id === entry.project
        )

        if (project.key === 'nestboxProject') {
          // eslint-disable-next-line prefer-destructuring
          formData.observations = formData.observations[0]
          if (formData.nestbox) {
            formData.nestbox = formTypes.nestboxes.find(
              (box) => box._id === formData.nestbox
            ).number
          }
        }

        if (project.key === 'wildlifeTour') {
          // eslint-disable-next-line prefer-destructuring
          formData.observations = formData.observations[0]
        }

        if (project.key === 'beaver') {
          formData.beaverSite = formData.beaverSite._id
          formData.currentActivities = formData.currentActivities.map(
            (activity) => activity._id
          )
          formData.recentActivities = formData.recentActivities.map(
            (activity) => activity._id
          )
          formData.pastActivities = formData.pastActivities.map(
            (activity) => activity._id
          )
        }

        setReviewData(formData)
      }
    } else {
      if (reviewData) {
        if (
          reviewData.speciesType &&
          Object.keys(schema.steps[step].properties).includes('species') &&
          !limitedSpeciesProjects.includes(projectKey)
        ) {
          schemaUpdate.steps[step] = Object.assign(schema.steps[step], {
            properties: {
              ...schema.steps[step].properties,
              species: {
                ...schema.steps[step].properties.species,
                enum: getSpeciesEnum(
                  projectKey,
                  formTypes,
                  reviewData.speciesType,
                  true
                ),
                enumNames: getSpeciesEnumNames(
                  projectKey,
                  formTypes,
                  reviewData.speciesType,
                  true
                ),
              },
            },
          })

          if (reviewData.speciesType && !noAgeProjects.includes(projectKey)) {
            const observationsStep = schema.steps.findIndex((s) => {
              return Object.keys(s.properties).includes('observations')
            })

            schemaUpdate.steps[observationsStep] = Object.assign(
              schema.steps[observationsStep],
              {
                definitions: {
                  ...schema.steps[observationsStep].definitions,
                  observation: {
                    ...schema.steps[observationsStep].definitions.observation,
                    properties: {
                      ...schema.steps[observationsStep].definitions.observation
                        .properties,
                      age: {
                        ...schema.steps[observationsStep].definitions
                          .observation.properties.age,
                        enum: getAgeEnum(
                          formTypes,
                          reviewData.speciesType,
                          projectKey
                        ),
                        enumNames: getAgeEnumNames(
                          formTypes,
                          reviewData.speciesType,
                          projectKey
                        ),
                      },
                    },
                  },
                },
              }
            )
          }

          if (
            reviewData.speciesType &&
            reviewData.species &&
            !noActivityProjects.includes(projectKey)
          ) {
            const observationsStep = schema.steps.findIndex((s) => {
              return Object.keys(s.properties).includes('observations')
            })

            schemaUpdate.steps[observationsStep] = Object.assign(
              schema.steps[observationsStep],
              {
                definitions: {
                  ...schema.steps[observationsStep].definitions,
                  observation: {
                    ...schema.steps[observationsStep].definitions.observation,
                    properties: {
                      ...schema.steps[observationsStep].definitions.observation
                        .properties,
                      activity: {
                        ...schema.steps[observationsStep].definitions
                          .observation.properties.activity,
                        enum: getActivityEnum(
                          formTypes,
                          reviewData.species,
                          reviewData.speciesType
                        ),
                        enumNames: getActivityEnumNames(
                          formTypes,
                          reviewData.species,
                          reviewData.speciesType
                        ),
                      },
                    },
                  },
                },
              }
            )
          }

          setSchema(schemaUpdate)
        }

        // This stops the form lib (react-jsonschema-form) from throwing errors on null fields from the db
        Object.keys(reviewData).forEach((key) => {
          if (reviewData[key] === null) {
            reviewData[key] = undefined
          }
        })
      } else {
        // no review data set
        setSchema(
          projectForms[projectKey] &&
            projectForms[projectKey].createSchema(formTypes, {
              isTaxonomicSortOrderEnabled: isAuthenticated
                ? user.settings?.taxonomicSortOrder
                : true,
            })
        )

        // authenticated and fixed location project, uses the user's home location
        if (
          isAuthenticated &&
          config.fixedLocationProjects.includes(projectKey)
        ) {
          // currently only backyard so we use user location
          const utm = `+proj=utm +zone=${user.utmZone}`
          const wgs84 = '+proj=longlat +ellps=GRS80 +datum=NAD83 +no_defs'

          const conversion = await proj4(utm, wgs84, [
            user.utmEasting,
            user.utmNorthing,
          ])

          locationData = {
            lat: conversion[1],
            lng: conversion[0],
            utmEasting: user.utmEasting,
            utmNorthing: user.utmNorthing,
            utmZone: user.utmZone,
          }
        } else if (!(reviewData && reviewData.location)) {
          // no review data location set
          // a project that doesn't use a location tied to project data, show device start up location if we have it
          if (!config.nonUserLocationProjects.includes(projectKey)) {
            // use initial location, location from device set in layout state
            if (initialLocation) {
              locationData = initialLocation
            } else {
              locationData = {
                lat: defaultCenter.lat,
                lng: defaultCenter.lng,
                utmEasting: defaultUTM.Easting,
                utmNorthing: defaultUTM.Northing,
                utmZone: defaultUTM.ZoneNumber,
              }
            }
          } else {
            locationData = {
              lat: defaultCenter.lat,
              lng: defaultCenter.lng,
              utmEasting: defaultUTM.Easting,
              utmNorthing: defaultUTM.Northing,
              utmZone: defaultUTM.ZoneNumber,
            }
          }
        } else {
          // review data location set
          const parsed = JSON.parse(reviewData.location)

          if (!parsed.lat || !parsed.lng) {
            // Observation doesn't have latitude and longitude saved on it so convert UTM coords to latitude and longitude

            const utm = `+proj=utm +zone=${parsed.utmZone}`
            const wgs84 = '+proj=longlat +ellps=GRS80 +datum=NAD83 +no_defs'

            const conversion = await proj4(utm, wgs84, [
              parsed.utmEasting,
              parsed.utmNorthing,
            ])

            // eslint-disable-next-line prefer-destructuring
            parsed.lat = conversion[1]
            // eslint-disable-next-line prefer-destructuring
            parsed.lng = conversion[0]
          }

          locationData = {
            lat:
              parseFloat(parsed.lat) &&
              parseFloat(parseFloat(parsed.lat).toFixed(8)),
            lng:
              parseFloat(parsed.lng) &&
              parseFloat(parseFloat(parsed.lng).toFixed(8)),
            utmEasting: parseFloat(parsed.utmEasting),
            utmNorthing: parseFloat(parsed.utmNorthing),
            utmZone: parseFloat(parsed.utmZone),
          }
        }

        setReviewData({
          location: locationData ? JSON.stringify(locationData) : null,
        })
      }
    }

    setIsLoading(false)
  }, [stepParam, projectKey])

  useIonViewWillLeave(() => {
    setIsVisible(false)
  })

  const scrollToTop = () => {
    contentRef?.current?.scrollToTop(10)
  }

  const onFormChange = (data) => {
    const { formData } = data
    const schemaUpdate = { ...schema }

    if (
      formData.speciesType &&
      Object.keys(schema.steps[step].properties).includes('species') &&
      !limitedSpeciesProjects.includes(projectKey)
    ) {
      schemaUpdate.steps[step] = Object.assign(schema.steps[step], {
        properties: {
          ...schema.steps[step].properties,
          species: {
            ...schema.steps[step].properties.species,
            enum: getSpeciesEnum(
              projectKey,
              formTypes,
              formData.speciesType,
              isAuthenticated ? user.settings?.taxonomicSortOrder : true
            ),
            enumNames: getSpeciesEnumNames(
              projectKey,
              formTypes,
              formData.speciesType,
              isAuthenticated ? user.settings?.taxonomicSortOrder : true
            ),
          },
        },
      })
    }

    if (formData.speciesType && !noAgeProjects.includes(projectKey)) {
      const observationsStep = schema.steps.findIndex((s) => {
        return Object.keys(s.properties).includes('observations')
      })

      schemaUpdate.steps[observationsStep] = Object.assign(
        schema.steps[observationsStep],
        {
          definitions: {
            ...schema.steps[observationsStep].definitions,
            observation: {
              ...schema.steps[observationsStep].definitions.observation,
              properties: {
                ...schema.steps[observationsStep].definitions.observation
                  .properties,
                age: {
                  ...schema.steps[observationsStep].definitions.observation
                    .properties.age,
                  enum: getAgeEnum(formTypes, formData.speciesType, projectKey),
                  enumNames: getAgeEnumNames(
                    formTypes,
                    formData.speciesType,
                    projectKey
                  ),
                },
              },
            },
          },
        }
      )
    }

    if (
      formData.speciesType &&
      formData.species &&
      !noActivityProjects.includes(projectKey)
    ) {
      const observationsStep = schema.steps.findIndex((s) => {
        return Object.keys(s.properties).includes('observations')
      })

      schemaUpdate.steps[observationsStep] = Object.assign(
        schema.steps[observationsStep],
        {
          definitions: {
            ...schema.steps[observationsStep].definitions,
            observation: {
              ...schema.steps[observationsStep].definitions.observation,
              properties: {
                ...schema.steps[observationsStep].definitions.observation
                  .properties,
                activity: {
                  ...schema.steps[observationsStep].definitions.observation
                    .properties.activity,
                  enum: getActivityEnum(
                    formTypes,
                    formData.species,
                    formData.speciesType
                  ),
                  enumNames: getActivityEnumNames(
                    formTypes,
                    formData.species,
                    formData.speciesType
                  ),
                },
              },
            },
          },
        }
      )
    }

    setSchema(schemaUpdate)
    setReviewData(formData)
  }

  const onSubmit = ({ formData: fd }) => {
    setSubmitting(true)

    if (parseInt(step) < schema.steps.length - 1) {
      setReviewData({
        ...reviewData,
        ...fd,
      })
      setSubmitting(false)
      scrollToTop()
      return history.push(
        `/observation/${action}/${projectKey}/${id ? `${id}/` : ''}${
          parseInt(step) + 1
        }`,
        { direction: 'forward' }
      )
    } else {
      setReviewData({
        ...reviewData,
        ...fd,
      })
      return history.push(
        `/observation/${action}/review/${projectKey}/${id ? `${id}` : ''}`,
        {
          direction: 'forward',
        }
      )
    }
  }

  const handleBack = () => {
    if (step === 0) {
      handleClose()
    }

    scrollToTop()
    return history.push(
      `/observation/${action}/${projectKey}/${id ? `${id}/` : ''}${step - 1}`,
      {
        direction: 'back',
      }
    )
  }

  const resetFormSubmitting = () => {
    setSubmitting(false)
    enableFormSubmit()
  }

  const handleClose = () => {
    if (formMetadata && formMetadata.fromVerification) {
      history.push(
        `/verification/verification-new/${formMetadata.fromVerification}`,
        { direction: 'forward' }
      )
    } else {
      history.push('/', { direction: 'back' })
    }
    clearReviewData()
  }

  const handleSaveDraft = async () => {
    await saveDraftObservation(projectKey, reviewData)
    clearReviewData()
    return history.push('/dashboard/me')
  }

  if (!isVisible || location.pathname.includes('review')) {
    return null
  }

  if (!formTypes && !schema) {
    return <Loading />
  }

  if (!schema) {
    return <Redirect to={'/404'} />
  }

  if (!projectKey) {
    return null
  }

  const { uiSchema } = projectForms[projectKey]

  let submitButtonText = 'Review'

  if (step < schema.steps.length - 1) {
    submitButtonText = 'Next'
  }

  const project = projects.find((project) => project.key === projectKey)

  return (
    <IonPage className={classes.root}>
      <IonHeader>
        <IonToolbar>
          <IonTitle>{project && project.name}</IonTitle>
          <IonButtons slot="end">
            <IonButton
              onClick={() =>
                presentAlert({
                  header: 'Are you sure?',
                  message:
                    action === 'edit'
                      ? 'Any edits will be lost, do you want to proceed?'
                      : 'Data entered will be lost, do you want to proceed?',
                  buttons: [
                    {
                      text: 'Cancel',
                      role: 'cancel',
                      handler: () => {},
                    },
                    {
                      text: 'Yes',
                      role: 'confirm',
                      handler: () => {
                        handleClose()
                      },
                    },
                  ],
                })
              }
            >
              <IonIcon icon={closeOutline} size="large" />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent id="observationFormContent" ref={contentRef}>
        {isLoading && <Loading />}
        <Wizard
          ErrorList={ErrorListTemplate}
          formData={reviewData}
          formTypes={formTypes}
          handleBack={handleBack}
          handleClose={handleClose}
          handleSaveDraft={handleSaveDraft}
          onFormChange={onFormChange}
          onSubmit={onSubmit}
          projectKey={projectKey}
          schema={schema}
          step={step}
          submitFormRef={submitFormRef}
          submitting={submitting}
          uiSchema={uiSchema}
        />
      </IonContent>
      {!isLoading && (
        <IonFooter className={classes.wizardButtons}>
          <IonToolbar>
            {step === 0 ? (
              <IonButton
                color="secondary"
                onClick={() =>
                  presentAlert({
                    header: 'Are you sure?',
                    message:
                      action === 'edit'
                        ? 'Any edits will be lost, do you want to proceed?'
                        : 'Data entered will be lost, do you want to proceed?',
                    buttons: [
                      {
                        text: 'Cancel',
                        role: 'cancel',
                        handler: () => {},
                      },
                      {
                        text: 'Yes',
                        role: 'confirm',
                        handler: () => {
                          handleClose()
                        },
                      },
                    ],
                  })
                }
                slot="start"
                type="button"
              >
                Cancel
              </IonButton>
            ) : (
              <IonButton onClick={handleBack} slot="start" type="button">
                Previous
              </IonButton>
            )}
            {action !== 'edit' && (
              <IonButton
                disabled={disableFormSubmit || submitting}
                onClick={handleSaveDraft}
                variant="contained"
              >
                Save draft
              </IonButton>
            )}
            <IonButton
              disabled={disableFormSubmit || submitting}
              onClick={() => submitFormRef.current.click()}
              slot="end"
              type="submit"
              variant="contained"
            >
              {submitButtonText}
            </IonButton>
          </IonToolbar>
        </IonFooter>
      )}
      {openFlash && (
        <IonToast
          color={flashVariant}
          duration={flashDuration || 5000}
          isOpen={openFlash}
          message={flashMessage}
          onDidDismiss={hideFlashMessage}
          position="top"
        />
      )}
    </IonPage>
  )
}

function mapStateToProps({ auth, form, layout, projects, user }) {
  return {
    isAuthenticated: auth.isAuthenticated,
    disableFormSubmit: form.disableFormSubmit,
    formTypes: form.types,
    formMetadata: form.metadata,
    initialLocation: layout.location.data,
    projects,
    reviewData: form.data,
    openFlash: layout.openFlash,
    flashMessage: layout.flashMessage,
    flashVariant: layout.flashVariant,
    flashDuration: layout.flashDuration,
    user,
  }
}

const mapDispatchToProps = {
  me: actions.me,
  getFormTypes: actions.getFormTypes,
  setReviewData: actions.setReviewData,
  setFormMetadata: actions.setFormMetadata,
  clearReviewData: actions.clearReviewData,
  saveDraftObservation: actions.saveDraftObservation,
  enableFormSubmit: actions.enableFormSubmit,
  getObservationEntry: actions.getObservationEntry,
  hideFlashMessage: actions.hideFlashMessage,
}

export default memo(
  connect(mapStateToProps, mapDispatchToProps)(IonicObservationFormPage)
)
