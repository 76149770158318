/* eslint-disable import/no-anonymous-default-export */
import {
  UPDATE_TABLE_DATA,
  UPDATE_TABLE_VIEW,
  UPDATE_CURRENT_VERIFICATION_INDEX,
  APP_START,
  UPDATE_PERSIST_TABLEDATA_ON_START
} from '../types/action.types'

export const defaultReactTableState = {
  pageSize: 10,
  pageIndex: 0,
  filters: [],
  sortBy: [],
  total: 0,
  rows: [],
}

const initialState = { tableView: true, persistOnStart: false }

export default function (state = initialState, action) {
  switch (action.type) {
    case APP_START: {
      if (state.persistOnStart) {
        return state
      }
      
      return initialState
    }
    case UPDATE_PERSIST_TABLEDATA_ON_START: {
      return {
        ...state,
        persistOnStart: action.payload
      }
    }
    case UPDATE_TABLE_DATA: {
      return {
        ...state,
        [action.payload.tableKey]: {
          ...state[action.payload.tableKey],
          pageSize: action.payload.pageSize,
          pageIndex: action.payload.pageIndex,
          total: action.payload.total,
          sortBy: action.payload.sortBy,
          filters: action.payload.filters,
          rows: action.payload.rows,
        },
      }
    }
    case UPDATE_TABLE_VIEW: {
      return {
        ...state,
        tableView: action.payload.value,
      }
    }
    case UPDATE_CURRENT_VERIFICATION_INDEX: {
      return {
        ...state,
        [action.payload.tableKey]: {
          ...state[action.payload.tableKey],
          currentIndex: action.payload.currentIndex,
        },
      }
    }
    default:
      return state
  }
}
