import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Auth } from 'aws-amplify'
import { withStyles, Typography } from '@material-ui/core'
import queryString from 'qs'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import actions from '../../actions'
import LoaderButton from '../../components/buttons/LoaderButton'
import { useIonViewDidLeave } from '@ionic/react'
import IonicPageLayout from '../../components/siteLayout/IonicPageLayout'
import { useHistory, useLocation } from 'react-router-dom'
import IonicInput from '../../components/forms/IonicInput'

const styles = (theme) => ({
  root: {
    marginTop: '30px',
  },
  logo: {
    backgroundColor: '#fff',
    display: 'block',
    maxWidth: 300,
    margin: '0 auto',
    width: '100%',
  },
  button: {
    marginTop: '1.5rem',
  },
  loginForm: {
    margin: '1rem auto',
    maxWidth: '350px',
    width: '100%',

    '& input': {
      marginTop: '14px',
      marginBottom: '14px',
    },

    '& .input-wrapper': {
      marginTop: '10px',
    },

    [theme.breakpoints.up('md')]: {},
  },
  textField: {
    marginTop: '8px',
    marginBottom: '20px',
  },
  forgotPasswordLink: {
    display: 'inline-block',
    margin: '1.5rem 0 2rem',
  },
  welcomeHeadline: {
    color: theme.palette.primary.main,
    margin: '1rem 0 1rem',
    fontSize: '1.5rem',
  },
  loginFormHeading: {
    margin: '1rem 1rem 2.5rem',
    textAlign: 'center',
  },
  welcomeText: {
    margin: '0 auto',
    maxWidth: 500,
    textAlign: 'center',

    '& p': {
      marginBottom: '1rem',
    },
  },
  label: {
    marginBottom: '0.5rem',
  },
  offline: {},
})

const migrationSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Required'),
  code: Yup.string().required('Required'),
  password: Yup.string().required('Required'),
  confirmPassword: Yup.string()
    .required('Required')
    .oneOf([Yup.ref('password'), null], 'Passwords must match'),
})

const Login = ({ classes, signin, resetPasswordEmail, updateUserSettings }) => {
  const [loading, setLoading] = useState(false)
  const [email, setEmail] = useState(
    resetPasswordEmail ? resetPasswordEmail : ''
  )
  const [error, setError] = useState(null)
  const history = useHistory()
  const location = useLocation()

  useEffect(() => {
    // Get the query strings
    const parsedQueryString = queryString.parse(location.search, {
      ignoreQueryPrefix: true,
    })

    if (parsedQueryString.email) {
      setEmail(parsedQueryString.email.toLowerCase().trim())
    }
  })

  useIonViewDidLeave(() => {
    setError(null)
    setEmail('')
  })

  const handleSubmit = async (
    values,
    { setSubmitting, resetForm, setErrors }
  ) => {
    setSubmitting(true)
    setLoading(true)

    const { email, code, password } = values

    try {
      const cleanEmail = email.toLowerCase().trim()
      const user = await Auth.signIn(cleanEmail, code.trim())

      if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
        await Auth.completeNewPassword(user, password.trim())
        setLoading(false)
        setSubmitting(false)
        updateUserSettings({ passwordMigrated: true })
        signin()
        resetForm()
        setErrors({})
        return history.push('/dashboard/projects')
      } else {
        setError('Email reset has not been requested.')
        setLoading(false)
        setSubmitting(false)
        resetForm()
        setErrors({})
      }
    } catch (e) {
      console.error(e.message)
      if (e.message === 'Incorrect username or password.') {
        setError('Incorrect confirmation code.')
      } else {
        setError(e.message)
      }
      setLoading(false)
      setSubmitting(false)
      resetForm()
      setErrors({})
    }
  }

  return (
    <IonicPageLayout withBackButton>
      <div className={classes.loginFormHeading}>
        <Typography align="center" component="h1" variant="srOnly">
          Nature Mapping Jackson Hole
        </Typography>
        <Typography className={classes.welcomeHeadline} variant="h2">
          <div>Welcome</div>
        </Typography>
        <Typography>
          Enter your confirmation code and your new password.
        </Typography>
        <Typography>Passwords must be at least 8 characters.</Typography>
      </div>
      <Formik
        enableReinitialize
        initialValues={{
          email,
          code: '',
          password: '',
          confirmPassword: '',
        }}
        onSubmit={handleSubmit}
        validationSchema={migrationSchema}
      >
        {(formikProps) => (
          <Form className={classes.loginForm}>
            <div>
              <div className={classes.textField}>
                <IonicInput
                  className={classes.field}
                  label="Email"
                  name="email"
                  {...formikProps}
                />
              </div>
              <div className={classes.textField}>
                <IonicInput
                  className={classes.field}
                  label="Confirmation Code"
                  name="code"
                  {...formikProps}
                />
              </div>
              <div className={classes.textField}>
                <IonicInput
                  className={classes.field}
                  label="Password"
                  name="password"
                  type="password"
                  {...formikProps}
                />
              </div>
              <div className={classes.textField}>
                <IonicInput
                  className={classes.field}
                  label="Confirm password"
                  name="confirmPassword"
                  type="password"
                  {...formikProps}
                />
              </div>
              {error && (
                <Typography align="center" color="error">
                  {error}
                </Typography>
              )}
              <LoaderButton
                className={classes.button}
                isLoading={loading}
                loadingText="Setting new password..."
                text="Reset Password"
                type="submit"
              />
            </div>
          </Form>
        )}
      </Formik>
    </IonicPageLayout>
  )
}

const mapStateToProps = ({ auth }) => ({
  resetPasswordEmail: auth.resetPasswordEmail,
})

export default connect(mapStateToProps, actions)(withStyles(styles)(Login))
