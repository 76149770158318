import React from 'react'
import { Link } from 'react-router-dom'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import IonicPageLayout from '../components/siteLayout/IonicPageLayout'
import { connect } from 'react-redux'
import Grosbeak from '../assets/images/eveningGrosbeak.jpg'
import Squirrel from '../assets/images/redSquirrel.jpg'
import Plover from '../assets/images/semipalmatedPlover.jpg'
import Snake from '../assets/images/wanderingGarterSnake.jpg'
import Cubs from '../assets/images/grizCubs.jpg'
import GrizzlyBears from '../assets/images/grizzlyBears.jpg'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '10px 15px',
  },
  imgCreditSection: {
    marginBottom: '30px',
  },
}))

const About = (props) => {
  const { userCount, observationCount, speciesCount } = props
  const styles = useStyles()
  return (
    <IonicPageLayout>
      <div className={styles.root}>
        <Typography gutterBottom variant="h1">
          Nature Mapping Jackson Hole
        </Typography>
        <Typography gutterBottom variant="h5">
          {`To date, over ${userCount} volunteers have contributed more than ${observationCount} wildlife observations of ${speciesCount} species in Jackson Hole.`}
        </Typography>
        <Typography gutterBottom>
          {`Nature Mapping Jackson Hole (NMJH) is a community science initiative
          founded in 2009 by Meg and Bert Raynes and now supported by the
          Jackson Hole Wildlife Foundation (JHWF). NMJH seeks to obtain
          long-term, accurate wildlife data in Teton County WY, Lincoln County
          WY, and Teton County ID via volunteer use of this application. Prior
          to using the app, volunteers are required to take a certification
          course where they are trained in NMJH data collection protocols and
          wildlife identification. Each wildlife observation submitted to NMJH
          is carefully vetted by a wildlife biologist to ensure data quality.
          After being verified, data are made available to JHWF partners such as
          the Wyoming Game and Fish Department (WGFD), National Parks Service
          (NPS) and US Forest Service (USFS), where they can be used to inform
          wildlife and land management decisions. To date, over ${observationCount} wildlife
          observations have been verified and shared with our partners. There
          are several NMJH projects in which volunteers can participate.`}
        </Typography>
        <Typography gutterBottom>Projects include:</Typography>
        <ul>
          <li>
            <Typography gutterBottom>
              Wildlife Tour: Visitors to Jackson are encouraged to report
              wildlife seen on ecotours. Does not require Nature Mapping
              certification training
            </Typography>
          </li>
          <li>
            <Typography gutterBottom>
              Casual Observations: Used to report incidental observations of
              wildlife in the study area
            </Typography>
          </li>
          <li>
            <Typography gutterBottom>
              Project Backyard: Residents can submit weekly wildlife sightings
              in their backyards
            </Typography>
          </li>
          <li>
            <Typography gutterBottom>
              Moose Day: Annual moose survey conducted on one day in late
              winter.
            </Typography>
          </li>
          <li>
            <Typography gutterBottom>
              Snake River Float: Biweekly summer bird count held by boat.
            </Typography>
          </li>
          <li>
            <Typography gutterBottom>
              Beaver Project: Citizen scientists survey stream stretches near
              Jackson and indicate if that stream has beaver activity or not.
            </Typography>
          </li>
          <li>
            <Typography gutterBottom>
              Mountain Bluebird Monitoring: Nestboxes are surveyed by Nature
              Mappers once per week throughout the summer
            </Typography>
          </li>
        </ul>
        <Typography gutterBottom>
          Please email{' '}
          <a href="mailto:info@jhwildlife.org">info@jhwildlife.org</a> for more
          information on Nature Mapping or to sign up for a certification
          training.
        </Typography>
        <Typography gutterBottom variant="h5">
          Photo Credits
        </Typography>
        <div className={styles.imgCreditSection}>
          <img alt="Grizzly Bears" className={styles.img} src={GrizzlyBears} />
          <Typography gutterBottom>Grizzly Bears by Kevin Perozeni</Typography>
        </div>
        <div className={styles.imgCreditSection}>
          <img alt="Grizzly Cubs" className={styles.img} src={Cubs} />
          <Typography gutterBottom>Grizzly Bears by Kevin Perozeni</Typography>
        </div>
        <div className={styles.imgCreditSection}>
          <img alt="Grosbeak" className={styles.img} src={Grosbeak} />
          <Typography gutterBottom>Evening Grosbeak by Kevin Perozeni</Typography>
        </div>
        <div className={styles.imgCreditSection}>
          <img alt="Squirrel" className={styles.img} src={Squirrel} />
          <Typography gutterBottom>Red Squirrel by Kevin Perozeni</Typography>
        </div>
        <div className={styles.imgCreditSection}>
          <img alt="Plover" className={styles.img} src={Plover} />
          <Typography gutterBottom>Semipalmated Plover by Hilary Turner</Typography>
        </div>
        <div className={styles.imgCreditSection}>
          <img alt="Snake" className={styles.img} src={Snake} />
          <Typography gutterBottom>Western Terrestrial Garter Snake by Hilary Turner</Typography>
        </div>
        <Typography component={Link} to="/">
          Go home
        </Typography>
      </div>
    </IonicPageLayout>
  )
}

const mapStateToProps = ({ layout }) => {
  return {
    userCount: layout.stats && layout.stats.userCount,
    observationCount: layout.stats && layout.stats.observationCount,
    speciesCount: layout.stats && layout.stats.speciesCount,
  }
}

export default connect(mapStateToProps, null)(About)
