import React, { Component } from 'react'
import { connect } from 'react-redux'
import { API } from 'aws-amplify'
import { withStyles, Typography, InputLabel, Button } from '@material-ui/core'
import { clearAppState } from '../../actions/lifecycle.actions'
import { signout } from '../../actions/auth.actions'
import config from '../../config'

const styles = theme => ({
  root: {
    marginTop: '2rem',
    padding: '4rem',
    [theme.breakpoints.up('md')]: {
      marginLeft: config.style.DRAWER_WIDTH,
      marginTop: 0,
      width: `calc(100% - ${config.style.DRAWER_WIDTH}px)`,
    },
  },
  sectionSpacing: {
    marginBottom: '2rem'
  }
})

const logErrorToCloudwatch = (error, info) => {
    API.post('logFrontendError', '/logFrontendError', {
      responseType: 'text',
      response: true,
      headers: { 'Content-Type': 'application/octet-stream' },
      body: {
        error,
        info
      },
    })
}

class ErrorBoundary extends Component {
  state = {
    error: null,
    hasError: false,
  }

  componentDidCatch(error, info) {
    this.setState({ hasError: true, error })
    // You can also log the error to an error reporting service
    logErrorToCloudwatch(error.message, info)
  }

  reload = () => {
    const { clearAppState, signout } = this.props

    signout()
    clearAppState()
    window.location.href = '/signin'
  }

  render() {
    const { classes, formTypes } = this.props
    const { error, hasError } = this.state

    if (hasError) {
      if (!formTypes.species.length) {
        return (
          <div className={classes.root}>
            <Typography gutterBottom variant="h5">
              Form Data Error
            </Typography>
            <Typography gutterBottom variant="body1">
              Your application has not been able to download the form data yet. Please connect to the internet and try again.
            </Typography>
            <Button color="primary" onClick={this.reload} variant="contained">
              Go back to home page
            </Button>
          </div>
        )
      }
      return (
        <div className={classes.root}>
          <Typography variant='h1'>Whoops, something went wrong.</Typography>
          <Typography gutterBottom>If this issue persists, please reach out to JHWF for support.</Typography>
          <div className={classes.sectionSpacing}>
            <InputLabel>Error Message</InputLabel>
            <Typography variant='body2'>{error.message}</Typography>
          </div>
          <div className={classes.sectionSpacing}>
            <Typography>Click here to reload the Nature Mapping application</Typography>
            <Button onClick={this.reload} variant="contained">
              Reload
            </Button>
          </div>
        </div>
      )
    }

    return this.props.children
  }
}

const mapStateToProps = state => ({
  formTypes: state.form.types
})

const mapDispatchToProps = {
  clearAppState,
  signout
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ErrorBoundary))