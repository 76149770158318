import {
  UNAUTH_USER,
  AUTH_USER,
  AUTHENTICATING,
  AUTH_ERROR,
  SET_NEW_PASSWORD_USER,
  SET_RESET_PASSWORD_EMAIL,
} from '../types/action.types'
import { me, updateUserMessagesSeen, updateUserVisits, getUserStats } from './user.actions'
import { getProjects } from './project.actions'
import { getFormTypes } from './form.actions'
import { showFlashMessage } from './flash.actions'
import UtmConverter from 'utm-latlng'
import { setLocationState, writeLocationLog } from './layout.actions'
import { Geolocation } from '@capacitor/geolocation'
import { getLocalMessagesSeen } from '../helpers/localStorage'
import { Capacitor } from '@capacitor/core'

const utmConvert = new UtmConverter()

export function authenticating(value = true) {
  return {
    payload: value,
    type: AUTHENTICATING,
  }
}

export const signin = () => {
  return async function (dispatch) {
    dispatch({ type: AUTH_USER })
    dispatch(me())
    dispatch(getProjects())
    dispatch(getFormTypes())
    dispatch(getUserStats())
    dispatch(updateUserVisits())

    const localMessagesSeen = await getLocalMessagesSeen()

    if (localMessagesSeen.length) {
      for (const message of localMessagesSeen) {
        dispatch(updateUserMessagesSeen(message))
      }
    }
  }
}

export const updateUserLocation = () => {
  return async function (dispatch) {
    const success = (position) => {
      dispatch(
        writeLocationLog(
          `Attempting to get current postion using position ${JSON.stringify(
            position,
            null,
            2
          )}`
        )
      )
      const utm = utmConvert.convertLatLngToUtm(
        position.coords.latitude,
        position.coords.longitude,
        4
      )
      dispatch(
        writeLocationLog(
          `position converted to UTM ${JSON.stringify(utm, null, 2)}`
        )
      )

      const location = {
        lat: position.coords.latitude,
        lng: position.coords.longitude,
        utmEasting: utm.Easting,
        utmNorthing: utm.Northing,
        utmZone: utm.ZoneNumber,
      }

      dispatch(
        writeLocationLog(
          `location created ${JSON.stringify(location, null, 2)}`
        )
      )
      dispatch(writeLocationLog('Setting state location'))
      dispatch(setLocationState({ data: location }))

      dispatch(writeLocationLog('Setting form schema location'))
      dispatch(setLocationState({ gettingLocation: false }))
      dispatch(showFlashMessage('Location updated', 'success', 2000))
    }

    const error = (err) => {
      dispatch(
        setLocationState({
          gettingLocation: false,
          deviceLocationError: `ERROR(${err.code}): ${err.message}`,
        })
      )
      dispatch(writeLocationLog('Get device location failed'))
      dispatch(writeLocationLog(`ERROR(${err.code}): ${err.message}`))
    }

    dispatch(writeLocationLog('Attempting to use device location'))

    try {
      try {
        if (Capacitor.getPlatform() === 'web' && navigator.geolocation) {
          dispatch(writeLocationLog('Attempting to get current position using web'))
          const options = {
            enableHighAccuracy: true,
            timeout: 6000,
            maximumAge: 0,
          }
          navigator.geolocation.getCurrentPosition(success, error, options)
        } else {
          const coordinates = await Geolocation.getCurrentPosition()
          success(coordinates)
        }
      } catch (e) {
        error(e)
        dispatch(
          writeLocationLog(
            'Geolocation feature is not available on your current device'
          )
        )
        // alert('Geolocation feature is not available on your current device')
        dispatch(setLocationState({ gettingLocation: false }))
      }
    } catch (err) {
      dispatch(
        writeLocationLog(
          `Use location function ended in catch: ERROR(${err.code}): ${err.message}`
        )
      )
      console.error(err)
      dispatch(setLocationState({ gettingLocation: false }))
    }
  }
}

export const signout = (detected) => {
  return function (dispatch) {
    if (detected) {
      dispatch(
        showFlashMessage(
          'Your session has expired, please sign in again.',
          'secondary'
        )
      )
    }
    dispatch({ type: UNAUTH_USER })
  }
}

export function setNewPasswordUser(response) {
  return {
    payload: response,
    type: SET_NEW_PASSWORD_USER,
  }
}

export function setResetPasswordEmail(payload) {
  return {
    payload,
    type: SET_RESET_PASSWORD_EMAIL,
  }
}

export function authError(error) {
  return {
    payload: error,
    type: AUTH_ERROR,
  }
}
