import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import classnames from 'classnames'
import { DateTime } from 'luxon'
import { withStyles, Typography } from '@material-ui/core'
import Loading from '../micros/Loading'
import MountainBluebirdDetails from './MountainBluebirdDetails'
import StandardObservation from './StandardObservation'
import LabelValue from '../text/LabelValue'
import { IonLabel } from '@ionic/react'
import PhotoGallery from '../photos/PhotoGallery'

const styles = (theme) => ({
  root: {
    marginTop: '1rem',
  },
  label: {
    marginBottom: '0.4rem',
    color: theme.palette.primary.main,
    fontSize: '1.1rem',
  },
  value: {
    fontSize: '1.4rem',
  },
  topSection: {
    display: 'grid',
    gap: '1.8rem',
    marginBottom: '3rem',
  },
  bandResightSection: {
    margin: '3rem 0',
  },
  bandResightData: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridGap: '1.2rem',
    marginBottom: '2rem',
  },
  colorBandCombo: {
    marginBottom: '3rem',
  },
  textData: {
    display: 'grid',
    gridTemplateColumns: '100%',
    gridGap: '1.5rem',
    marginBottom: '2rem',

    '@media (min-width: 400px)': {
      gridTemplateColumns: '1fr 1fr',
      justifyContent: 'space-around',
      gridGap: '2.2rem',
    },

    [theme.breakpoints.up('sm')]: {
      gridTemplateColumns: '1fr 1fr 1fr',
    },

    [theme.breakpoints.up('lg')]: {
      gridTemplateColumns: '1fr 1fr 1fr 1fr',
    },
  },
  photoGrid: {
    display: 'grid',
    gridGap: '1rem',
    marginTop: '1rem',
    gridTemplateColumns: '1fr 1fr',

    '& img': {
      cursor: 'pointer',

      '&:hover': {
        opacity: 0.9,
      },
    },

    [theme.breakpoints.up('sm')]: {
      gridTemplateColumns: '1fr 1fr 1fr',
    },

    [theme.breakpoints.up('lg')]: {
      gridTemplateColumns: '1fr 1fr 1fr 1fr',
    },
  },
  colorBandGrid: {
    display: 'grid',
    gridTemplateColumns: '60px 100px 100px',
    alignItems: 'center',
    justifyItems: 'center',
    gap: '10px',
  },
  alignRight: {
    justifySelf: 'end',
  },
})

const ObservationDetails = ({ classes, history, observation, sexes, user }) => {
  if (!observation) {
    return <Loading />
  }

  if (observation.project.key === 'beaver') {
    return (
      <div className={classes.root}>
        <div className={classes.textData}>
          <LabelValue
            label="Date & Time"
            value={DateTime.fromISO(observation.obsDateTime).toLocaleString(
              DateTime.DATETIME_FULL
            )}
          />
          <LabelValue label="Site Id" value={observation.beaverSite?.siteId} />
          <LabelValue label="Tributary" value={observation.beaverSite?.name} />
          <LabelValue
            label="Current Activities"
            value={
              observation.currentActivities &&
              observation.currentActivities
                .map((activity) => activity.description)
                .join(', ')
            }
          />
          <LabelValue
            label="Recent Activities"
            value={
              observation.recentActivities &&
              observation.recentActivities
                .map((activity) => activity.description)
                .join(', ')
            }
          />
          <LabelValue
            label="Past Activities"
            value={
              observation.pastActivities &&
              observation.pastActivities
                .map((activity) => activity.description)
                .join(', ')
            }
          />
          <LabelValue
            label="No Activity"
            value={observation.noActivity ? 'Checked' : 'Not checked'}
          />
          <LabelValue label="Notes" value={observation.comment} />
          <LabelValue label="County" value={observation.county} />
          <LabelValue
            label="Status"
            value={observation.status ? observation.status.description : ''}
          />
          <LabelValue label="Project" value={observation.project.name} />
          {observation.creator && user.level > 4 ? (
            <div>
              <IonLabel className={classes.label}>Creator</IonLabel>
              <Typography
                className={classes.value}
              >{`${observation.creator.firstName} ${observation.creator.lastName}`}</Typography>
              <Typography className={classes.value}>
                {observation.creator.email}
              </Typography>
              {observation.creator && observation.creator.phone && (
                <Typography
                  className={classes.value}
                >{`Home: ${observation.creator.phone}`}</Typography>
              )}
              {observation.creator && observation.creator.cell && (
                <Typography
                  className={classes.value}
                >{`Cell: ${observation.creator.cell}`}</Typography>
              )}
            </div>
          ) : null}
          {observation.verified && observation.verifiedBy && (
            <Fragment>
              <LabelValue
                label="Verified on"
                value={DateTime.fromISO(observation.verified).toLocaleString(
                  DateTime.DATETIME_FULL
                )}
              />
              <LabelValue
                label="Verified by"
                value={`${observation.verifiedBy.firstName} ${observation.verifiedBy.lastName}`}
              />
            </Fragment>
          )}
          <LabelValue label="NM Id" value={observation.friendlyId} />
        </div>
        <IonLabel className={classes.label}>Photos</IonLabel>
        {observation.photos &&
        observation.photos.length > 0 &&
        observation.photos[0] !== '' ? (
          <div className={classes.photoGrid}>
            <PhotoGallery photos={observation.photos} />
          </div>
        ) : (
          <Typography className={classes.value}>
            No photos were added to this observation
          </Typography>
        )}
      </div>
    )
  }

  return (
    <div className={classes.root}>
      <div className={classes.topSection}>
        <LabelValue
          label="Date & Time"
          value={DateTime.fromISO(observation.obsDateTime).toLocaleString(
            DateTime.DATETIME_FULL
          )}
        />
        <LabelValue label="Project" value={observation.project.name} />
        {observation.project.key === 'gameCameras' && (
          <LabelValue
            label="Camera Name"
            value={observation.gameCamera ? observation.gameCamera.name : ''}
          />
        )}
        <LabelValue
          label="Wildlife Type"
          value={
            observation.speciesType
              ? observation.speciesType.name
              : observation.species.type
              ? observation.species.type.name
              : ''
          }
        />
        {observation.project.key === 'wildlifeTour' && (
          <LabelValue
            label="On wildlife tour?"
            value={observation.onTour ? 'Yes' : 'No'}
          />
        )}
        <LabelValue label="Notes" value={observation.comment} />
        {observation.bandResight && (
          <div className={classes.bandResightSection}>
            <div className={classnames(classes.bandResightData)}>
              <LabelValue
                label="Is bird banded?"
                value={observation.bandResight.isBanded}
              />
              <LabelValue
                label="Banded bird sex"
                value={
                  observation.bandResight.sex
                    ? sexes.find(
                        (type) => type._id === observation.bandResight.sex
                      ).description
                    : '-'
                }
              />
            </div>
            <div className={classes.colorBandCombo}>
              <IonLabel className={classes.label}>
                {'Color band combination'}
              </IonLabel>
              <div className={classes.colorBandGrid}>
                <div></div>
                <IonLabel className={classes.label}>Left</IonLabel>
                <IonLabel className={classes.label}>Right</IonLabel>
                <IonLabel
                  className={classnames(classes.alignRight, classes.label)}
                >
                  Top
                </IonLabel>
                <IonLabel
                  className={classes.value}
                  id="observation.bandResight.colorBandCombination.topLeft"
                >
                  {observation.bandResight.colorBandCombination.topLeft
                    ? observation.bandResight.colorBandCombination.topLeft
                    : '-'}
                </IonLabel>
                <IonLabel
                  className={classes.value}
                  id="observation.bandResight.colorBandCombination.topRight"
                >
                  {observation.bandResight.colorBandCombination.topRight
                    ? observation.bandResight.colorBandCombination.topRight
                    : '-'}
                </IonLabel>
                <IonLabel
                  className={classnames(classes.alignRight, classes.label)}
                >
                  Bottom
                </IonLabel>
                <IonLabel
                  className={classes.value}
                  id="observation.bandResight.colorBandCombination.bottomLeft"
                >
                  {observation.bandResight.colorBandCombination.bottomLeft
                    ? observation.bandResight.colorBandCombination.bottomLeft
                    : '-'}
                </IonLabel>
                <IonLabel
                  className={classes.value}
                  id="observation.bandResight.colorBandCombination.bottomRight"
                >
                  {observation.bandResight.colorBandCombination.bottomRight
                    ? observation.bandResight.colorBandCombination.bottomRight
                    : '-'}
                </IonLabel>
              </div>
            </div>
            <div className={classnames(classes.bandResightData)}>
              <LabelValue
                label="Is second bird banded?"
                value={
                  observation.bandResight.isBanded2
                    ? observation.bandResight.isBanded2
                    : '-'
                }
              />
              <LabelValue
                label="Second banded bird sex"
                value={
                  observation.bandResight.sex2
                    ? sexes.find(
                        (type) => type._id === observation.bandResight.sex2
                      ).description
                    : '-'
                }
              />
            </div>
            <div className={classes.colorBandCombo}>
              <IonLabel className={classes.label}>
                {'Color band combination'}
              </IonLabel>
              <div className={classes.colorBandGrid}>
                <div></div>
                <IonLabel className={classes.label}>Left</IonLabel>
                <IonLabel className={classes.label}>Right</IonLabel>
                <IonLabel
                  className={classnames(classes.alignRight, classes.label)}
                >
                  Top
                </IonLabel>
                <IonLabel
                  className={classes.value}
                  id="observation.bandResight.colorBandCombination2.topLeft"
                >
                  {observation.bandResight.colorBandCombination2.topLeft
                    ? observation.bandResight.colorBandCombination2.topLeft
                    : '-'}
                </IonLabel>
                <IonLabel
                  className={classes.value}
                  id="observation.bandResight.colorBandCombination2.topRight"
                >
                  {observation.bandResight.colorBandCombination2.topRight
                    ? observation.bandResight.colorBandCombination2.topRight
                    : '-'}
                </IonLabel>
                <IonLabel
                  className={classnames(classes.alignRight, classes.label)}
                >
                  Bottom
                </IonLabel>
                <IonLabel
                  className={classes.value}
                  id="observation.bandResight.colorBandCombination2.bottomLeft"
                >
                  {observation.bandResight.colorBandCombination2.bottomLeft
                    ? observation.bandResight.colorBandCombination2.bottomLeft
                    : '-'}
                </IonLabel>
                <IonLabel
                  className={classes.value}
                  id="observation.bandResight.colorBandCombination2.bottomRight"
                >
                  {observation.bandResight.colorBandCombination2.bottomRight
                    ? observation.bandResight.colorBandCombination2.bottomRight
                    : '-'}
                </IonLabel>
              </div>
            </div>
            <LabelValue
              label="Banded bird notes"
              value={
                observation.bandResight.comments
                  ? observation.bandResight.comments
                  : '-'
              }
            />
          </div>
        )}
      </div>
      <div className={classes.textData}>
        {observation.project.key === 'nestboxProject' ? (
          <MountainBluebirdDetails observation={observation} />
        ) : (
          <StandardObservation observation={observation} />
        )}
        <LabelValue label="County" value={observation.county} />
        <LabelValue
          label="Status"
          value={observation.status ? observation.status.description : ''}
        />
        {observation.creator && user.level > 4 ? (
          <div>
            <IonLabel className={classes.label}>Creator</IonLabel>
            <Typography
              className={classes.value}
            >{`${observation.creator.firstName} ${observation.creator.lastName}`}</Typography>
            <Typography className={classes.value}>
              {observation.creator.email}
            </Typography>
            {observation.creator && observation.creator.phone && (
              <Typography
                className={classes.value}
              >{`Home: ${observation.creator.phone}`}</Typography>
            )}
            {observation.creator && observation.creator.cell && (
              <Typography
                className={classes.value}
              >{`Cell: ${observation.creator.cell}`}</Typography>
            )}
          </div>
        ) : null}
        {observation.verified && observation.verifiedBy && (
          <Fragment>
            <LabelValue
              label="Verified on"
              value={DateTime.fromISO(observation.verified).toLocaleString(
                DateTime.DATETIME_FULL
              )}
            />
            <LabelValue
              label="Verified by"
              value={`${observation.verifiedBy.firstName} ${observation.verifiedBy.lastName}`}
            />
          </Fragment>
        )}
        <LabelValue label="NM Id" value={observation.friendlyId} />
      </div>
      <IonLabel className={classes.label}>Photos</IonLabel>
      {observation.photos &&
      observation.photos.length > 0 &&
      observation.photos[0] !== '' ? (
        <div className={classes.photoGrid}>
          <PhotoGallery photos={observation.photos} />
        </div>
      ) : (
        <Typography className={classes.value}>
          No photos were added to this observation
        </Typography>
      )}
    </div>
  )
}

const mapStateToProps = ({ form, observations, user }) => {
  return {
    observation: observations.current,
    statuses: form && form.types && form.types.observationStatuses,
    sexes: form && form.types && form.types.sexes,
    user,
  }
}

export default connect(mapStateToProps)(
  withRouter(withStyles(styles)(ObservationDetails))
)
