import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Auth } from 'aws-amplify'
import { Link } from 'react-router-dom'
import { withStyles, Typography } from '@material-ui/core'
import { Capacitor } from '@capacitor/core'
import { Storage } from '@ionic/storage'
import { SavePassword } from 'capacitor-ios-autofill-save-password'
import actions from '../../actions'
import LoaderButton from '../../components/buttons/LoaderButton'
import {
  IonInput,
  IonLabel,
  useIonAlert,
  useIonViewDidLeave,
  useIonViewWillEnter,
} from '@ionic/react'
import IonicPageLayout from '../../components/siteLayout/IonicPageLayout'
import { storageAvailable } from '../../helpers/localStorage'
import PasswordField from '../../components/forms/PasswordField'
import { useHistory } from 'react-router-dom'

const styles = (theme) => ({
  root: {
    marginTop: '30px',
  },
  logo: {
    backgroundColor: '#fff',
    display: 'block',
    maxWidth: 300,
    margin: '0 auto',
    width: '100%',
  },
  button: {
    marginTop: '1.5rem',
  },
  loginForm: {
    margin: '1rem auto',
    maxWidth: '350px',
    width: '100%',

    '& input': {
      marginTop: '14px',
      marginBottom: '14px',
    },

    '& .input-wrapper': {
      marginTop: '10px',
    },

    [theme.breakpoints.up('md')]: {},
  },
  textField: {
    marginTop: '8px',
    marginBottom: '20px',
  },
  forgotPasswordLink: {
    display: 'inline-block',
    margin: '1.5rem 0 2rem',
  },
  welcomeHeadline: {
    color: theme.palette.primary.main,
    margin: '1rem 0 1rem',
    fontSize: '1.5rem',
  },
  loginFormHeading: {
    margin: '1rem 1rem 2.5rem',
    textAlign: 'center',
  },
  welcomeText: {
    margin: '0 auto',
    maxWidth: 500,
    textAlign: 'center',

    '& p': {
      marginBottom: '1rem',
    },
  },
  offline: {},
})

const Login = (props) => {
  const {
    classes,
    signin,
    setNewPasswordUser,
    setResetPasswordEmail,
    passwordMigrationCheck2023,
  } = props
  const [loading, setLoading] = useState(false)
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [error, setError] = useState(null)
  const [presentAlert] = useIonAlert()
  const history = useHistory()

  useIonViewWillEnter(async () => {
    // put this check at top level and reload the site after clearing cache
    if (
      !storageAvailable('localStorage') ||
      !storageAvailable('sessionStorage')
    ) {
      caches.keys().then(function (names) {
        for (let name of names) caches.delete(name)
      })

      const store = new Storage()
      await store.create()
      await store.clear()

      // force reload
      window.location.reload()
    }

    setLoading(false)
  }, [])

  useIonViewDidLeave(() => {
    setError(null)
    setEmail('')
    setPassword('')
  })

  const handleLoginInput = (e) => {
    if (!e) e = window.event
    var keyCode = e.code || e.key
    if (keyCode === 'Enter') {
      return handleSubmit(e)
    }
  }

  const passwordMigrationCheck = async () => {
    const cleanEmail = email.toLowerCase().trim()
    const migrationNeeded = await passwordMigrationCheck2023(cleanEmail)

    return migrationNeeded
  }

  const handleSubmit = async (e) => {
    // doesn't exist if calling programatically based on enter key above
    if (e) {
      e.preventDefault()
    }
    setLoading(true)

    try {
      const cleanEmail = email.toLowerCase().trim()
      const user = await Auth.signIn(cleanEmail, password.trim())

      // if login error, check if they have an account in db but not cognito

      // if they don't have an account in the db, set error

      // if they do have an account in the db
      // send them a confirmation code to email
      // show modal with message
      // redirect to a page to enter the code and set a new password
      // if the code is correct, create a new user in cognito and connect it to the db user

      if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
        setLoading(false)
        setNewPasswordUser(user)
        return history.push(`/signin/new/password/`)
      }

      if (Capacitor.getPlatform() === 'ios') {
        await SavePassword.promptDialog({
          username: cleanEmail,
          password: password.trim(),
        })
      }

      signin()
      return history.push('/dashboard/projects')
    } catch (e) {
      if (
        e.code === 'NotAuthorizedException' ||
        e.name === 'NotAuthorizedException'
      ) {
        if (await passwordMigrationCheck()) {
          console.log('Password Migration flow detected')
          const cleanEmail = email.toLowerCase().trim()

          // set redux reset password email
          setResetPasswordEmail(cleanEmail)

          // show modal with message
          // redirect to a page to enter the code and set a new password
          presentAlert({
            backdropDismiss: false,
            header: 'Please reset your password for Nature Mapping',
            message: `Due to recent improvements to Nature Mapping, you need to reset your password. We sent you an email to ${cleanEmail}  with a confirmation code. Please follow the link in the email to enter the code and a new password.`,
            buttons: [
              {
                text: 'Ok',
                role: 'confirm',
                handler: () => {
                  history.push(`/signin/migrate/password?email=${cleanEmail}`)
                },
              },
            ],
          })
        } else {
          console.error(e.message)
          setError(e.message)
        }
      } else if (e.name === 'QuotaExceededError') {
        console.log('QuotaExceededError hit, clearing cache')
        caches.keys().then(function (names) {
          for (let name of names) caches.delete(name)
        })
        console.error(e.message)
        setError('We had a issue with your login, please try again.')
      } else {
        console.error(e.message)
        setError(e.message)
      }
      setLoading(false)
    }
  }

  const validateForm = () => {
    return email.length > 0 && password.length > 0
  }

  return (
    <IonicPageLayout withBackButton>
      <div className={classes.loginFormHeading}>
        <Typography align="center" component="h1" variant="srOnly">
          Nature Mapping Jackson Hole
        </Typography>
        <Typography className={classes.welcomeHeadline} variant="h2">
          <div>Welcome back</div>
          <div>to Nature Mapping</div>
        </Typography>
        <Typography>Sign back into your account below.</Typography>
      </div>
      <form className={classes.loginForm} onSubmit={handleSubmit}>
        <div>
          <div className={classes.textField}>
            <IonLabel position="stacked">Email</IonLabel>
            <IonInput
              autocomplete="email"
              autofocus
              id="email"
              onIonChange={(e) => setEmail(e.target.value)}
              onKeyPress={handleLoginInput}
              value={email}
            />
          </div>
          <div className={classes.textField}>
            <PasswordField
              autoComplete="current-password"
              id="password"
              label="Password"
              onIonChange={(e) => setPassword(e.target.value)}
              onKeyPress={handleLoginInput}
              type="password"
              value={password}
            />
          </div>
          {error && (
            <Typography align="center" color="error">
              {error}
            </Typography>
          )}
          <LoaderButton
            className={classes.button}
            disabled={!validateForm()}
            isLoading={loading}
            loadingText="Signing in..."
            text="Sign In"
            type="submit"
          />
        </div>
        <Link className={classes.forgotPasswordLink} to="/forgot-password">
          <Typography align="center">Forgot password?</Typography>
        </Link>
      </form>
    </IonicPageLayout>
  )
}

export default connect(null, actions)(withStyles(styles)(Login))
