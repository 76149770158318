import { IonLoading } from '@ionic/react'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router'
import { getLocalMessagesSeen } from '../helpers/localStorage'

interface StateProps {
  isAuthenticated: boolean
  user: any
}

const HomeOrLanding: React.FC<StateProps> = (props) => {
  const { isAuthenticated, user } = props
  const [loading, setLoading] = useState(true)
  const [showWelcome, setShowWelcome] = useState(true)

  useEffect(() => {
    const checkIfWelcomeMessageSeen = async () => {
      if (user.messagesSeen && user.messagesSeen.includes('welcomeTutorial')) {
        setShowWelcome(false)
      } else {
        const localMessages = await getLocalMessagesSeen()

        if (localMessages.includes('welcomeTutorial')) {
          setShowWelcome(false)
        }
      }
      setLoading(false)
    }

    checkIfWelcomeMessageSeen()
  })

  if (loading) {
    return <IonLoading isOpen />
  }

  if (showWelcome) {
    return <Redirect to="/welcome" />
  }

  return isAuthenticated ? (
    <Redirect to="/dashboard/projects" />
  ) : (
    <Redirect to="/dashboard/me" />
  )
}

function mapStateToProps({ auth, layout, user }: any) {
  return {
    isAuthenticated: auth.isAuthenticated,
    online: layout.online,
    user,
  }
}

export default connect(mapStateToProps)(HomeOrLanding)
