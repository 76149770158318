import React from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { connect } from 'react-redux'
import { Auth } from 'aws-amplify'
import {
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenu,
  IonMenuToggle,
} from '@ionic/react'
import {
  videocamOutline,
  addCircleOutline,
  eggOutline,
  bonfireOutline,
  informationCircleOutline,
  logInOutline,
  logOutOutline,
  albumsOutline,
  shieldCheckmarkOutline,
  personAdd,
  settingsOutline,
  helpBuoyOutline,
  downloadOutline,
  cameraOutline,
} from 'ionicons/icons'

import './Menu.css'
import actions from '../actions'

const routes = {
  all: [
    {
      key: 'addObservation',
      title: 'Add Observation',
      path: '/dashboard/projects',
      icon: addCircleOutline,
    },
  ],
  app: [
    {
      key: 'myObservations',
      title: 'My Observations',
      path: '/dashboard/me',
      icon: shieldCheckmarkOutline,
    },
    {
      key: 'allObservations',
      title: 'All Observations',
      path: '/allobservations',
      icon: albumsOutline,
    },
    {
      key: 'photoObservations',
      title: 'Photo Observations',
      path: '/photoobservations',
      icon: cameraOutline,
    },
  ],
  nestboxProject: [
    {
      key: 'nestboxProject',
      title: 'Mountain Bluebird',
      path: '/project/nestboxProject',
      icon: eggOutline,
    },
  ],
  gameCameras: [
    {
      key: 'gameCameras',
      title: 'Game Cameras',
      path: '/project/gameCameras',
      icon: videocamOutline,
    },
  ],
  dataVetter: [
    {
      key: 'dataVerification',
      title: 'Data Verification',
      path: '/verification',
      icon: shieldCheckmarkOutline,
    },
    {
      key: 'dataExport',
      title: 'Data Export',
      path: '/export',
      icon: downloadOutline,
    },
  ],
  admin: [
    {
      key: 'admin',
      title: 'Admin',
      path: '/admin',
      icon: bonfireOutline,
    },
  ],
  appBottom: [
    { title: 'About', path: '/about', icon: helpBuoyOutline },
    {
      title: 'Resources',
      path: 'https://jhwildlife.org/learn/quick-tips-for-nature-mappers/',
      target: '_blank',
      icon: informationCircleOutline,
    },
  ],
  loggedInPages: [
    { title: 'Settings', path: '/dashboard/settings', icon: settingsOutline },
  ],
  loggedOutPages: [
    { title: 'Sign in', path: '/signin', icon: logInOutline },
    { title: 'Signup', path: '/signup', icon: personAdd },
  ],
}

interface Pages {
  title: string
  path: string
  icon: string
  target?: string
  routerDirection?: string
}
interface StateProps {
  darkMode?: boolean
  isAuthenticated?: boolean
  userLevel: number
  projects?: any
  userProjects?: any
  auth?: any
  user?: any
  layout?: any
}

interface DispatchProps {
  signout: any
}

interface MenuProps extends StateProps, DispatchProps {
  menuEnabled: boolean
}

const Menu: React.FC<MenuProps> = ({
  userProjects,
  userLevel,
  projects,
  isAuthenticated,
  menuEnabled,
  signout,
}) => {
  const location = useLocation()
  const history = useHistory()

  const getMenuItemsForUser = () => {
    let items: any[] = []

    items = items.concat(routes.all)

    if (isAuthenticated) {
      items = items.concat(routes.app)
    }

    if (
      isAuthenticated &&
      userProjects &&
      projects &&
      userProjects.includes(
        projects.length &&
          projects
            .find((project: any) => project.key === 'nestboxProject')
            ._id.toString()
      )
    ) {
      items = items.concat(routes.nestboxProject)
    }

    if (
      isAuthenticated &&
      userProjects &&
      projects &&
      userProjects.includes(
        projects.length &&
          projects
            .find((project: any) => project.key === 'gameCameras')
            ._id.toString()
      )
    ) {
      items = items.concat(routes.gameCameras)
    }

    if (userLevel > 4) {
      items = items.concat(routes.dataVetter)
    }
    if (userLevel > 7) {
      items = items.concat(routes.admin)
    }

    items = items.concat(routes.appBottom)

    return items
  }

  const logout = async () => {
    await Auth.signOut()
    history.push('/signin')
    signout()
  }

  const availableMenuItems = getMenuItemsForUser()

  const renderlistItems = (list: Pages[]) => {
    return list
      .filter((route) => !!route.path)
      .map((p) => {
        if (p.target) {
          return (
            <IonItem
              className={
                location.pathname.startsWith(p.path) ? 'selected' : undefined
              }
              href={p.path}
              key={p.title}
              target={p.target}
            >
              <IonIcon icon={p.icon} slot="start" />
              <IonLabel>{p.title}</IonLabel>
            </IonItem>
          )
        } else {
          return (
            <IonItem
              className={
                location.pathname.startsWith(p.path) ? 'selected' : undefined
              }
              detail={false}
              key={p.title}
              routerLink={p.path}
            >
              <IonIcon icon={p.icon} slot="start" />
              <IonLabel>{p.title}</IonLabel>
            </IonItem>
          )
        }
      })
  }

  return (
    <IonMenu contentId="main" disabled={!menuEnabled} type="overlay">
      <IonContent forceOverscroll={false}>
        <IonMenuToggle autoHide={false}>
          <IonList lines="none">
            <IonListHeader>Nature Mapping</IonListHeader>
            {renderlistItems(availableMenuItems)}
          </IonList>
        </IonMenuToggle>
        <IonMenuToggle autoHide={false}>
          <IonList lines="none">
            <IonListHeader>Account</IonListHeader>
            {isAuthenticated
              ? renderlistItems(routes.loggedInPages)
              : renderlistItems(routes.loggedOutPages)}
            {isAuthenticated && (
              <IonItem onClick={logout} routerLink=' '>
                <IonIcon icon={logOutOutline} slot="start"></IonIcon>
                <IonLabel>Logout</IonLabel>
              </IonItem>
            )}
          </IonList>
        </IonMenuToggle>
      </IonContent>
    </IonMenu>
  )
}

function mapStateToProps({ projects, user, layout }: StateProps) {
  return {
    userLevel: user ? user.level : 0,
    projects,
    menuEnabled: layout.menuEnabled,
    userProjects:
      user.projects && user.projects.map((project: any) => project._id),
  }
}

export default connect(mapStateToProps, actions)(Menu)
