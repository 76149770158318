import React, { useState, useRef, useCallback } from 'react'
import { IonLabel, IonSegment, IonSegmentButton } from '@ionic/react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { debounce } from 'lodash'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import { Paper } from '@material-ui/core'
import NatureMappingTable from './NatureMappingTable'
import ObservationsMap from '../maps/ObservationsMap'
import actions from '../../actions'
import { getTableData, getTableView } from '../../selectors'

const propTypes = {
  tableKey: PropTypes.string.isRequired,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      Header: PropTypes.string,
      accessor: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    })
  ).isRequired,
  fetchData: PropTypes.func.isRequired,
  hideMap: PropTypes.bool,
  verifyActions: PropTypes.bool,
}

const styles = (theme) => ({
  root: {},
  dataTableView: {
    background: 'none',
    position: 'relative',
  },
  dataListWithMapView: {
    display: 'flex',
    flexDirection: 'column-reverse',
    position: 'relative',

    [theme.breakpoints.up('md')]: {
      display: 'grid',
      gridTemplateColumns: 'minmax(350px, 1fr) 2fr',
    },
  },
  tableInfo: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    marginBottom: '0.3rem',
  },
})

const ObservationTable = ({
  classes,
  columns,
  fetchData,
  forceTable,
  hideMap,
  onCellClick,
  project,
  setTableView,
  status,
  tableData,
  tableKey,
  tableView,
  verifyActions,
  showPhotos
}) => {
  const [loading, setLoading] = useState(false)
  const fetchIdRef = useRef(0)
  const tableViewValue = hideMap ? true : tableView
  const { pageSize, pageIndex, filters, sortBy, total, rows } = tableData
  const initialState = {
    pageSize,
    pageIndex,
    filters,
    sortBy,
  }

  const debouncedCall = debounce((state) => {
    // Whenever the table model changes, or the user sorts or changes pages, this method gets called and passed the current table model.
    const fetchID = ++fetchIdRef.current
    setLoading(true)

    if (fetchID === fetchIdRef.current) {
      fetchData({
        tableKey,
        status,
        tableState: state,
        project,
      }).then((res) => {
        setLoading(false)
      })
    }
  }, 1000)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const fetchDataCall = useCallback(debouncedCall, [
    fetchData,
    project,
    tableKey,
    status,
  ])

  return (
    <div className={classes.root}>
      <div className={classes.tableInfo}>
        <Typography gutterBottom>{`${total} Observations`}</Typography>
        {!hideMap && (
          <IonSegment
            onIonChange={(e) => {
              if (e.detail.value === 'table') {
                setTableView(true)
              } else {
                setTableView(false)
              }
            }}
            value={tableViewValue ? 'table' : 'map'}
          >
            <IonSegmentButton value="table">
              <IonLabel>Table</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton value="map">
              <IonLabel>Map</IonLabel>
            </IonSegmentButton>
          </IonSegment>
        )}
      </div>
      <Paper
        className={
          tableViewValue || forceTable
            ? classes.dataTableView
            : classes.dataListWithMapView
        }
      >
        <NatureMappingTable
          columns={columns}
          data={rows}
          fetchData={fetchDataCall}
          initialState={initialState}
          loading={loading}
          onCellClick={onCellClick}
          pageCount={Math.ceil(total / pageSize)}
          showPhotos={showPhotos}
          tableKey={tableKey}
          tableView={tableViewValue || forceTable}
          total={total}
          verifyActions={verifyActions}
        />
        {!hideMap && !tableView && (
          <ObservationsMap data={rows} tableKey={tableKey} />
        )}
      </Paper>
    </div>
  )
}

const mapStateToProps = (state, props) => {
  return {
    tableData: getTableData(state, props),
    tableView: getTableView(state),
  }
}

ObservationTable.propTypes = propTypes

export default connect(
  mapStateToProps,
  actions
)(withStyles(styles)(ObservationTable))
