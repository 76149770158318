import { DateTime } from 'luxon'
import React from 'react'
import projectForms from '../pages/projects/forms'

export const googleMapLibraries = ['places', 'geometry', 'drawing']

export function getInitials(name) {
  let initials
  const names = name.split(' ')

  if (names[0]) {
    initials = names[0].substring(0, 1)
  }

  if (names[1]) {
    initials += names[1].substring(0, 1)
  }

  return initials.toUpperCase()
}

export function capitalize(string) {
  if (process.env.NODE_ENV !== 'production' && typeof string !== 'string') {
    throw new Error('capitalize(string) expects a string argument.')
  }

  return string.charAt(0).toUpperCase() + string.slice(1)
}

export const taxonomicSortOrderBirds = [
  'UB',
  '169',
  '170',
  '171',
  '172',
  '181',
  '180',
  '144',
  '140',
  '141',
  '142',
  '135',
  '137',
  '132',
  '143',
  '139',
  '147',
  '146',
  '150',
  '148',
  '149',
  '155',
  '153',
  '151',
  '152',
  '131',
  '129',
  '130',
  '167',
  '300',
  '309',
  '297',
  '308',
  'RNP',
  '288.1',
  '288.2',
  '6',
  '3',
  '4',
  '1',
  '1.1',
  '313.1',
  '315.4',
  '316',
  '420',
  '418',
  '425',
  '426',
  '429',
  '436',
  '433',
  '432',
  '212',
  '214',
  '221',
  '206',
  '225',
  '226',
  '274',
  '273',
  '264',
  '249',
  '248',
  '241',
  '242',
  '239',
  '246',
  '247',
  '232',
  '230',
  '224',
  '223',
  '222',
  '263',
  '256',
  '254',
  '258',
  '255',
  '37',
  '60',
  '59',
  '54',
  '53',
  '64',
  '77',
  '70',
  '69',
  '7',
  '120',
  '125',
  '190',
  '194',
  '196',
  '197',
  '202',
  '186',
  '187',
  '325',
  '364',
  '349',
  '331',
  '332',
  '333',
  '334',
  '352',
  '343',
  '342',
  '337',
  '347',
  '348',
  '365',
  '374',
  '373.2',
  '375',
  '379',
  '378',
  '370',
  '366',
  '367',
  '371',
  '372',
  '390',
  '404',
  '402.1',
  '408',
  '401',
  '400',
  '394',
  '393',
  '412',
  '360',
  '357',
  '356',
  '355',
  '459',
  '462',
  '466',
  '468',
  '467',
  '469',
  '464',
  '457',
  '447',
  '444',
  '629.2',
  '629.1',
  '627',
  '624',
  '622',
  '621',
  '484',
  '478',
  '477',
  '475',
  '491',
  '488',
  '486',
  '735',
  '738',
  '474',
  '617',
  '614',
  '615',
  '616',
  '613',
  '612',
  '749',
  '748',
  '728',
  '727',
  '730',
  '726',
  '715',
  '721',
  '722',
  '722.1',
  '725',
  '701',
  '493',
  '704',
  '702',
  '703',
  '768',
  '754',
  '763',
  '756',
  '758',
  '759',
  '761',
  '618',
  '619',
  '688.2',
  '697',
  '514',
  '515',
  '0.36',
  '524',
  '525',
  '519',
  '517',
  '518',
  '528',
  '521',
  '522',
  '533',
  'LEGO',
  '529',
  '536',
  '534',
  '546',
  '560',
  '561',
  '562',
  '552',
  '605',
  '559',
  '585',
  '567',
  '567.1',
  '567.7',
  '568',
  '554',
  '553',
  '558',
  '574',
  '540',
  '542',
  '581',
  '583',
  '590',
  '587',
  '497',
  '494',
  '501.1',
  '506',
  '508',
  '507',
  '498',
  '495',
  '509',
  '510',
  '511',
  '675',
  '647',
  '646',
  '645',
  '680',
  '681',
  '687',
  '648',
  '652',
  '659',
  '655',
  '663',
  '668',
  '685',
  '607',
  '595',
  '596',
  '599',
  '598',
  '604'
]
export const taxonomicSortOrderMammals = [
  'UM',
  '801',
  '803',
  '804',
  '807',
  '807.5',
  '815',
  '816',
  '817',
  '818',
  '820',
  '822',
  '825',
  '0.5',
  '830',
  '831',
  '835',
  '836',
  '1002',
  '840',
  '841',
  '842',
  '843',
  '844',
  '845',
  '846',
  '849',
  '853',
  '854',
  '860',
  '861',
  '875',
  '880',
  '883',
  '884',
  '885',
  '886',
  '887',
  '888',
  '889',
  '891',
  '891.5',
  '892',
  '896',
  '900',
  '901',
  '902',
  '903',
  '905',
  '907',
  '908',
  '909',
  '910',
  '911',
  '919',
  '912',
  '914',
  '915',
  '917',
  '918',
  '920',
  '921',
  '922',
  '925',
  '926',
  '927',
  '930',
  '931',
  '932',
  '933',
  'DUNK',
  '935',
  '940',
  '941',
  'N/A',
  'U',
  'UA',
  'UR',
]
export const taxonomicSortOrderOther = [
  'U',
  'UA',
  'UR',
  '950',
  '951.2',
  '952',
  '952.1',
  '952.2',
  '952.3',
  '954.3',
  '955.2',
  '955.6',
  '955.8',
  '956',
]

export const taxonomicSortOrders = {
  Birds: taxonomicSortOrderBirds,
  Mammals: taxonomicSortOrderMammals,
  Reptiles: taxonomicSortOrderOther,
  Amphibians: taxonomicSortOrderOther,
  'N/A': ['N/A', 'U'],
}

const taxonomicSortOrderAllSpecies = taxonomicSortOrderBirds
  .concat(taxonomicSortOrderMammals)
  .concat(taxonomicSortOrderOther)

export const getSpeciesEnum = (
  projectKey,
  formTypes,
  speciesType,
  isTaxonomicSortOrderEnabled
) => {
  const speciesSortOrder = projectForms[projectKey].speciesSortOrder || []
  let speciesEnum = []

  if (!speciesType) {
    const taxonomicSortOrder = taxonomicSortOrderAllSpecies
    speciesEnum = formTypes
      ? isTaxonomicSortOrderEnabled
        ? formTypes.species
            .filter((species) => {
              if (!projectForms[projectKey].speciesList) return true

              return (
                projectForms[projectKey].speciesList &&
                projectForms[projectKey].speciesList.includes(species.code)
              )
            })
            .sort((a, b) =>
              taxonomicSortOrder.indexOf(a.code) >
              taxonomicSortOrder.indexOf(b.code)
                ? 1
                : -1
            )
        : formTypes.species
            .filter((species) => {
              if (!projectForms[projectKey].speciesList) return true

              return (
                projectForms[projectKey].speciesList &&
                projectForms[projectKey].speciesList.includes(species.code)
              )
            })
            .sort(sortByCommonName)
      : []
  } else {
    const speciesTypeName =
      formTypes.speciesTypes.find((i) => i._id === speciesType) &&
      formTypes.speciesTypes.find((i) => i._id === speciesType).name
    const taxonomicSortOrder = taxonomicSortOrders[speciesTypeName]
      ? taxonomicSortOrders[speciesTypeName]
      : []
    speciesEnum = formTypes
      ? isTaxonomicSortOrderEnabled
        ? formTypes.speciesByType[speciesType]
            .filter((species) => {
              if (!projectForms[projectKey].speciesList) return true

              return (
                projectForms[projectKey].speciesList &&
                projectForms[projectKey].speciesList.includes(species.code)
              )
            })
            .sort((a, b) =>
              taxonomicSortOrder.indexOf(a.code) >
              taxonomicSortOrder.indexOf(b.code)
                ? 1
                : -1
            )
        : formTypes.speciesByType[speciesType]
            .filter((species) => {
              if (!projectForms[projectKey].speciesList) return true

              return (
                projectForms[projectKey].speciesList &&
                projectForms[projectKey].speciesList.includes(species.code)
              )
            })
            .sort(sortByCommonName)
      : []
  }

  // bring species sorted for a project to the front
  if (speciesSortOrder.length) {
    speciesEnum = [
      ...speciesEnum
        .filter((species) => speciesSortOrder.includes(species.code))
        .sort((a, b) =>
          speciesSortOrder.indexOf(a.code) > speciesSortOrder.indexOf(b.code)
            ? 1
            : -1
        ),
      ...speciesEnum.filter(
        (species) => !speciesSortOrder.includes(species.code)
      ),
    ]
  }

  return speciesEnum.map((species) => species.code)
}

export const getSpeciesEnumNames = (
  projectKey,
  formTypes,
  speciesType,
  isTaxonomicSortOrderEnabled
) => {
  const speciesSortOrder = projectForms[projectKey].speciesSortOrder || []
  let speciesEnumNames = []

  if (!speciesType) {
    const taxonomicSortOrder = taxonomicSortOrderAllSpecies
    speciesEnumNames = formTypes
      ? isTaxonomicSortOrderEnabled
        ? formTypes.species
            .filter((species) => {
              if (!projectForms[projectKey].speciesList) return true

              return (
                projectForms[projectKey].speciesList &&
                projectForms[projectKey].speciesList.includes(species.code)
              )
            })
            .sort((a, b) =>
              taxonomicSortOrder.indexOf(a.code) >
              taxonomicSortOrder.indexOf(b.code)
                ? 1
                : -1
            )
        : formTypes.species
            .filter((species) => {
              if (!projectForms[projectKey].speciesList) return true

              return (
                projectForms[projectKey].speciesList &&
                projectForms[projectKey].speciesList.includes(species.code)
              )
            })
            .sort(sortByCommonName)
      : []
  } else {
    const speciesTypeName =
      formTypes.speciesTypes.find((i) => i._id === speciesType) &&
      formTypes.speciesTypes.find((i) => i._id === speciesType).name
    const taxonomicSortOrder = taxonomicSortOrders[speciesTypeName]
      ? taxonomicSortOrders[speciesTypeName]
      : []
    speciesEnumNames = formTypes
      ? isTaxonomicSortOrderEnabled
        ? formTypes.speciesByType[speciesType]
            .filter((species) => {
              if (!projectForms[projectKey].speciesList) return true

              return (
                projectForms[projectKey].speciesList &&
                projectForms[projectKey].speciesList.includes(species.code)
              )
            })
            .sort((a, b) =>
              taxonomicSortOrder.indexOf(a.code) >
              taxonomicSortOrder.indexOf(b.code)
                ? 1
                : -1
            )
        : formTypes.speciesByType[speciesType]
            .filter((species) => {
              if (!projectForms[projectKey].speciesList) return true

              return (
                projectForms[projectKey].speciesList &&
                projectForms[projectKey].speciesList.includes(species.code)
              )
            })
            .sort(sortByCommonName)
      : []
  }

  // bring species sorted for a project to the front
  if (speciesSortOrder.length) {
    speciesEnumNames = [
      ...speciesEnumNames
        .filter((species) => speciesSortOrder.includes(species.code))
        .sort((a, b) =>
          speciesSortOrder.indexOf(a.code) > speciesSortOrder.indexOf(b.code)
            ? 1
            : -1
        ),
      ...speciesEnumNames.filter(
        (species) => !speciesSortOrder.includes(species.code)
      ),
    ]
  }

  return speciesEnumNames.map((species) => species.commonName)
}

export const sortByName = (a, b) => {
  const nameA = a.name.toUpperCase()
  const nameB = b.name.toUpperCase()
  return nameA < nameB ? -1 : nameA > nameB ? 1 : 0
}

export const sortByCommonName = (a, b) => {
  const nameA = a.commonName && a.commonName.toUpperCase()
  const nameB = b.commonName && b.commonName.toUpperCase()
  return nameA < nameB ? -1 : nameA > nameB ? 1 : 0
}

export const safeWrap = (Comp) => (props) => <Comp {...props} />

export const getSelectedSeason = (date) => {
  const dateTime = DateTime.fromISO(date)
  switch (dateTime.month) {
    case 3:
    case 4:
    case 5:
      return 'spring'
    case 6:
    case 7:
    case 8:
      return 'summer'
    case 9:
    case 10:
    case 11:
      return 'fall'
    case 12:
    case 1:
    case 2:
      return 'winter'
    default:
      return 'summer'
  }
}

export const getContent = (contentId) => {
  const ionContent = document.querySelector(`ion-content#${contentId}`)
  return ionContent
}

export const scrollToTop = (contentId = 'appContent') => {
  const ionContent = getContent(contentId)
  if (ionContent) {
    ionContent.scrollToTop(100)
  }
}

export const getAgeEnum = (formTypes, speciesTypeId, projectKey) => {
  const speciesType = formTypes.speciesTypes.find(
    (s) => s._id === speciesTypeId
  )
  const name = speciesType.name.toLowerCase()

  if (projectKey === 'mooseDay') {
    return formTypes.ages
    .filter((item) => ['1', 1, '3', 3, '0', 0].includes(item.code))
    .map((item) => item._id)
  }

  switch (name) {
    case 'birds':
      return formTypes.ages
        .filter((item) => ['1', 1, '3', 3, '5', 5, '0', 0].includes(item.code))
        .map((item) => item._id)
    case 'mammals':
      return formTypes.ages
        .filter((item) => ['1', 1, '3', 3, '2', 2, '0', 0].includes(item.code))
        .map((item) => item._id)
    case 'reptiles':
      return formTypes.ages
        .filter((item) => ['1', 1, '3', 3, '5', 5, '0', 0].includes(item.code))
        .map((item) => item._id)
    case 'amphibians':
      return formTypes.ages
        .filter((item) => ['1', 1, '4', 4, '5', 5, '0', 0].includes(item.code))
        .map((item) => item._id)
    default:
      return formTypes.ages.map((item) => item._id)
  }
}

export const getAgeEnumNames = (formTypes, speciesTypeId) => {
  const speciesType = formTypes.speciesTypes.find(
    (s) => s._id === speciesTypeId
  )
  const name = speciesType.name.toLowerCase()

  switch (name) {
    case 'birds':
      return formTypes.ages
        .filter((item) => ['1', 1, '3', 3, '5', 5, '0', 0].includes(item.code))
        .map((item) => item.description)
    case 'mammals':
      return formTypes.ages
        .filter((item) => ['1', 1, '3', 3, '2', 2, '0', 0].includes(item.code))
        .map((item) => item.description)
    case 'reptiles':
      return formTypes.ages
        .filter((item) => ['1', 1, '3', 3, '5', 5, '0', 0].includes(item.code))
        .map((item) => item.description)
    case 'amphibians':
      return formTypes.ages
        .filter((item) => ['1', 1, '4', 4, '5', 5, '0', 0].includes(item.code))
        .map((item) => item.description)
    default:
      return formTypes.ages.map((item) => item.description)
  }
}

export const getActivityEnum = (formTypes, speciesCode, speciesTypeId) => {
  const speciesType = formTypes.speciesTypes.find(
    (s) => s._id === speciesTypeId
  )
  const speciesTypeName = speciesType.name.toLowerCase()
  const species = formTypes.species.find((s) => s.code === speciesCode)

  const activityWithoutSinging = formTypes.activities.filter(
    (item) => item.code !== '37'
  )

  switch (speciesTypeName) {
    case 'birds':
      return formTypes.activities.map((item) => item._id)
    case 'mammals':
      if (
        species &&
        (species.commonName.toLowerCase().includes('bat') ||
          species.commonName.toLowerCase().includes('squirrel'))
      ) {
        return activityWithoutSinging.map((item) => item._id)
      } else {
        return activityWithoutSinging
          .filter((item) => item.code !== '17')
          .map((item) => item._id)
      }
    case 'reptiles':
      return activityWithoutSinging.map((item) => item._id)
    case 'amphibians':
      return activityWithoutSinging.map((item) => item._id)
    default:
      return activityWithoutSinging.map((item) => item._id)
  }
}

export const getActivityEnumNames = (formTypes, speciesCode, speciesTypeId) => {
  const speciesType = formTypes.speciesTypes.find(
    (s) => s._id === speciesTypeId
  )
  const speciesTypeName = speciesType.name.toLowerCase()
  const species = formTypes.species.find((s) => s.code === speciesCode)

  const activityWithoutSinging = formTypes.activities.filter(
    (item) => item.code !== '37'
  )

  switch (speciesTypeName) {
    case 'birds':
      return formTypes.activities.map((item) => item.description)
    case 'mammals':
      if (
        species &&
        (species.commonName.toLowerCase().includes('bat') ||
          species.commonName.toLowerCase().includes('squirrel'))
      ) {
        return activityWithoutSinging.map((item) => item.description)
      } else {
        return activityWithoutSinging
          .filter((item) => item.code !== '17')
          .map((item) => item.description)
      }
    case 'reptiles':
      return activityWithoutSinging.map((item) => item.description)
    case 'amphibians':
      return activityWithoutSinging.map((item) => item.description)
    default:
      return activityWithoutSinging.map((item) => item.description)
  }
}

export function asNumber(value) {
  if (value === '') {
    return undefined
  }
  if (/\.$/.test(value)) {
    // "3." can't really be considered a number even if it parses in js. The
    // user is most likely entering a float.
    return value
  }
  if (/\.0$/.test(value)) {
    // we need to return this as a string here, to allow for input like 3.07
    return value
  }
  const n = Number(value)
  const valid = typeof n === 'number' && !Number.isNaN(n)

  if (/\.\d*0$/.test(value)) {
    // It's a number, that's cool - but we need it as a string so it doesn't screw
    // with the user when entering dollar amounts or other values (such as those with
    // specific precision or number of significant digits)
    return value
  }

  return valid ? n : value
}

/**
 * This is a silly limitation in the DOM where option change event values are
 * always retrieved as strings.
 */
export function processValue({ type, items }, value) {
  if (value === '') {
    return undefined
  }
  if (type === 'array' && items && ['number', 'integer'].includes(items.type)) {
    return value.map(asNumber)
  }
  if (type === 'boolean') {
    return value === 'true' || value === true
  }
  if (type === 'number') {
    return asNumber(value)
  }
  return value
}

export function getValue(event, index, value, multiple) {
  if (multiple) {
    return [].slice
      .call(event.target.options)
      .filter((o) => o.selected)
      .map((o) => o.value)
  }
  return value
}