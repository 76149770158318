import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import 'photoswipe/dist/photoswipe.css'
import { Gallery, Item } from 'react-photoswipe-gallery'

const styles = (theme) => ({
  observationImage: {
    cursor: 'pointer',
    maxWidth: '300px',
  },
  imagesRow: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    gap: 20,
    padding: 20,
    paddingBottom: 80,

    '&:hover': {
      backgroundColor: 'initial !important',
      cursor: 'initial !important',
    },
  },
})

const PhotoGallery = ({ classes, photos }) => {
  return (
    <Gallery>
      {photos &&
        photos.length > 0 &&
        photos.map((photo) => {
          const photoKey = `public/${photo.key || photo}`
          const thumbnailRequest = JSON.stringify({
            bucket: process.env.REACT_APP_S3_BUCKET,
            key: photoKey,
            edits: {
              resize: {
                width: 275,
                height: 275,
                fit: 'contain',
              },
            },
          })
          const originalRequest = JSON.stringify({
            bucket: process.env.REACT_APP_S3_BUCKET,
            key: photoKey,
            edits: {
              resize: {
                width: 2000,
                height: 2000,
                fit: 'contain',
              },
            },
          })

          const encodedThumbnailRequest = btoa(thumbnailRequest)
          const encodedOriginalRequest = btoa(originalRequest)

          const thumbnailUrl = `${process.env.REACT_APP_PHOTO_SERVER}/${encodedThumbnailRequest}`
          const originalUrl = `${process.env.REACT_APP_PHOTO_SERVER}/${encodedOriginalRequest}`
          // const ogUrl = `${process.env.REACT_APP_S3_BUCKET}/${photo}`
          return (
            <Item
              height="2000"
              original={originalUrl}
              thumbnail={thumbnailUrl}
              width="2000"
            >
              {({ ref, open }) => (
                <img
                  alt="nature mapping observation"
                  onClick={open}
                  ref={ref}
                  src={thumbnailUrl}
                />
              )}
            </Item>
          )
        })}
    </Gallery>
  )
}

export default withStyles(styles)(PhotoGallery)
