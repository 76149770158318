import React, { useState } from 'react'
import { Auth } from 'aws-amplify'
import { useHistory } from 'react-router-dom'
import { connect } from 'react-redux'
import { TextField, Typography, withStyles } from '@material-ui/core'
import LoaderButton from '../../components/buttons/LoaderButton'
import IonicPageLayout from '../../components/siteLayout/IonicPageLayout'
import actions from '../../actions'

const styles = (theme) => ({
  root: {},
  success: {
    textAlign: 'center',
  },
  textField: {
    marginTop: theme.spacing(),
    marginBottom: theme.spacing(),
  },
  button: {
    marginTop: theme.spacing(),
  },
})

const ForgotPassword = (props) => {
  const { classes, setResetPasswordEmail } = props
  const [email, setEmail] = useState('')
  const [isSendingCode, setIsSendingCode] = useState(false)
  const history = useHistory()

  const handleChange = (e) => {
    setEmail(e.target.value)
  }

  const handleSendCodeClick = async (e) => {
    e.preventDefault()

    setIsSendingCode(true)

    try {
      const cleanEmail = email.toLowerCase().trim()
      await Auth.forgotPassword(cleanEmail)
      
      setResetPasswordEmail(cleanEmail)
      history.push(`/signin/reset?email=${cleanEmail}`)
    } catch (e) {
      alert(e.message)
      setIsSendingCode(false)
    }
  }

  const validateCodeForm = () => {
    return email.length > 0
  }

  return (
    <IonicPageLayout withBackButton>
      <Typography gutterBottom variant="h2">
        Forgot your password?
      </Typography>
      <Typography gutterBottom>
        Enter your email here. If an account is found with the specified email,
        we'll send you an email to reset your password.
      </Typography>
      <form onSubmit={handleSendCodeClick}>
        <div>
          <TextField
            autoFocus
            className={classes.textField}
            id="email"
            label="Email"
            onChange={handleChange}
            type="email"
            value={email}
            variant="outlined"
          />
        </div>
        <div>
          <LoaderButton
            className={classes.button}
            color="primary"
            disabled={!validateCodeForm()}
            isLoading={isSendingCode}
            loadingText="Sending..."
            text="Submit"
            type="submit"
            variant="contained"
          />
        </div>
      </form>
    </IonicPageLayout>
  )
}
export default connect(null, actions)(withStyles(styles)(ForgotPassword))
