import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'
import OfflineWarning from '../../components/micros/OfflineWarning'
import ObservationTable from '../../components/tables/ObservationTable'
import tableColumns from '../../components/tables/tableColumns'
import { getAllObservationsWithPhotos } from '../../actions/observations.actions'
import IonicPageLayout from '../../components/siteLayout/IonicPageLayout'
import { useIonViewDidEnter } from '@ionic/react'
import { scrollToTop } from '../../helpers/utils'
import { user } from '../../selectors'

const styles = {
  root: {},
}

const PhotoObservations = (props) => {
  const { classes, getAllObservationsWithPhotos, online, history, user } = props

  useIonViewDidEnter(() => {
    scrollToTop()
  })

  const onCellClick = (event, cell) => {
    if (
      cell.row &&
      cell.row.original &&
      cell.column &&
      cell.column.id !== 'selection'
    ) {
      return history.push(`/photoobservations/${cell.row.original._id}`)
    }
  }

  return (
    <IonicPageLayout>
      <div className={classes.root}>
        <Typography component="h2" variant="h4">
          Photo observations
        </Typography>
        {online ? (
          <Fragment>
            {user.level > 4 ? (
              <Typography gutterBottom>
                This table shows all observations with photos
              </Typography>
            ) : (
              <Typography gutterBottom>
                This table only shows observations that have photos and have
                been verified
              </Typography>
            )}
            <ObservationTable
              columns={tableColumns.photoObservation}
              fetchData={getAllObservationsWithPhotos}
              forceTable
              hideMap
              onCellClick={onCellClick}
              showPhotos
              tableKey="photoObservations"
            />
          </Fragment>
        ) : (
          <OfflineWarning />
        )}
      </div>
    </IonicPageLayout>
  )
}

const mapStateToProps = (state) => ({
  user: user(state),
})

const mapDispatchToProps = {
  getAllObservationsWithPhotos,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(withRouter(PhotoObservations)))
