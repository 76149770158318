import React from 'react'
import ReactDOM from 'react-dom'
import ReactGA from 'react-ga'
import { Provider } from 'react-redux'
import { createStore, applyMiddleware } from 'redux'
import { CssBaseline, MuiThemeProvider } from '@material-ui/core'
import { Application } from 'react-rainbow-components'
import reduxThunk from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'
import { createLogger } from 'redux-logger'
import { Amplify } from 'aws-amplify'
import * as serviceWorker from './serviceWorker'
import reducers from './reducers'
import { loadState, saveState } from './helpers/localStorage'
import actions from './actions'
import config from './config'
import { defineCustomElements } from '@ionic/pwa-elements/loader'

import customTheme from './theme/theme'
import App from './App'

defineCustomElements(window)

const theme = {
  rainbow: {
      palette: {
          brand: '#537552',
      },
  },
}

// Object.entries polyfill
if (!Object.entries) {
  Object.entries = function (obj: any) {
    const ownProps = Object.keys(obj)
    let i = ownProps.length
    const resArray = new Array(i) // preallocate the Array
    while (i--) resArray[i] = [ownProps[i], obj[ownProps[i]]]

    return resArray
  }
}

Amplify.configure({
  API: {
    endpoints: [
      {
        endpoint: config.aws.apiGateway.URL,
        name: 'export',
        region: config.aws.apiGateway.REGION,
      },
      {
        endpoint: config.aws.apiGateway.URL,
        name: 'logFrontendError',
        region: config.aws.apiGateway.REGION,
      },
      {
        endpoint: config.aws.apiGateway.URL,
        name: 'healthCheck',
        region: config.aws.apiGateway.REGION,
      },
    ],
    graphql_endpoint: config.aws.graphql.URL,
    graphql_endpoint_iam_region: config.aws.apiGateway.REGION,
  },
  Auth: {
    identityPoolId: config.aws.cognito.IDENTITY_POOL_ID,
    region: config.aws.cognito.REGION,
    userPoolId: config.aws.cognito.USER_POOL_ID,
    userPoolWebClientId: config.aws.cognito.APP_CLIENT_ID,
  },
  Storage: {
    bucket: config.aws.s3.BUCKET,
    identityPoolId: config.aws.cognito.IDENTITY_POOL_ID,
    region: config.aws.s3.REGION,
  },
})

// Get state from local storage if it's there
loadState()
  .then((persistedState) => {
    const middlewares = []

    if (process.env.NODE_ENV === `production`) {
      // @ts-ignore
      ReactGA.initialize(config.gaTrackingId)
    }

    // Set up logger
    if (process.env.NODE_ENV === `development`) {
      const logger = createLogger({
        duration: true,
      })

      middlewares.push(logger)
    }

    // Set up store
    const createStoreWithMiddleware = composeWithDevTools(
      applyMiddleware(reduxThunk, ...middlewares)
    )(createStore)

    const store = createStoreWithMiddleware(reducers, persistedState)

    store.subscribe(() => {
      saveState(store.getState())
    })

    store.dispatch(actions.applicationStart())

    ReactDOM.render(
      <Provider store={store}>
        <MuiThemeProvider theme={customTheme}>
        <Application theme={theme}>
          <CssBaseline />
          <App />
        </Application>
        </MuiThemeProvider>
      </Provider>,
      document.getElementById('root') //eslint-disable-line
    )

    // If you want your app to work offline and load faster, you can change
    // unregister() to register() below. Note this comes with some pitfalls.
    // Learn more about service workers: http://bit.ly/CRA-PWA
    serviceWorker.unregister()
  })
  .catch((e) => console.error(e))
