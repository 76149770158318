import React from 'react'
import Spinner from 'react-spinkit'
import { withStyles } from '@material-ui/core'

const styles = () => ({
  loader: {
    left: '50%',
    position: 'absolute',
    top: '50%',
    zIndex: 99999,
    width: 36,
    height: 36,
    marginLeft: '-18px',
    marginTop: '-18px'
  },
  staticLoader: {
    margin: '1rem auto',
  },
})

const Loading = props => {
  const { theme, classes, position } = props

  return (
    <Spinner
      className={position === 'static' ? classes.staticLoader : classes.loader}
      color={theme.palette.primary.main}
      fadeIn="quarter"
      name="folding-cube"
    />
  )
}

const StyledLoading = withStyles(styles, { withTheme: true })(Loading)

export const ReactTableLoader = ({ loading }) => {
  return loading ? (
    <div className="-loading -active">
      <div className="-loading-inner">
        <StyledLoading />
      </div>
    </div>
  ) : null
}

export default StyledLoading
