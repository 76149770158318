import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import ObservationTable from '../tables/ObservationTable'
import tableColumns from '../tables/tableColumns'
import { updateDataVerificationSelectedTab } from '../../actions/layout.actions'
import {
  getDataVerificationStats,
  getDataVetterObservationsByStatus,
  setCurrentVerificationIndex,
} from '../../actions/observations.actions'
import {
  dataVetterStats,
  currentDataVerificationSelectedTab,
} from '../../selectors'

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <Typography
      aria-labelledby={`data-verification-tab-${index}`}
      component="div"
      hidden={value !== index}
      id={`data-verification-tabpanel-${index}`}
      role="tabpanel"
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
}

function a11yProps(index) {
  return {
    id: `data-verification-tab-${index}`,
    'aria-controls': `data-verification-tabpanel-${index}`,
  }
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
}))

function DataVerificationTabs(props) {
  const {
    currentDataVerificationSelectedTab,
    getDataVerificationStats,
    getDataVetterObservationsByStatus,
    stats,
    updateDataVerificationSelectedTab,
    setCurrentVerificationIndex,
  } = props
  const classes = useStyles()
  const history = useHistory()

  React.useEffect(() => {
    getDataVerificationStats()
  }, [getDataVerificationStats])

  const handleChange = (event, newValue) => {
    updateDataVerificationSelectedTab(newValue)
  }

    const onCellClick = (event, cell, tableKey) => {
      if (
        cell.row &&
        cell.row.original &&
        cell.column &&
        cell.column.id !== 'selection'
      ) {
        setCurrentVerificationIndex(tableKey, cell.row.index)
        return history.push(
          `/verification/${tableKey}/${cell.row.original._id}`
        )
      }
    }

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Tabs
          aria-label="Data verification observations tab menu"
          onChange={handleChange}
          value={currentDataVerificationSelectedTab}
        >
          <Tab
            label={`Outstanding (${stats.numberToVerify || '~'})`}
            {...a11yProps(0)}
          />
          <Tab
            label={`Verified (${stats.numberVerified || '~'})`}
            {...a11yProps(1)}
          />
          <Tab
            label={`Rejected (${stats.numberRejected || '~'})`}
            {...a11yProps(2)}
          />
          <Tab
            label={`Questionable (${stats.numberQuestionable || '~'})`}
            {...a11yProps(3)}
          />
        </Tabs>
      </AppBar>
      <TabPanel index={0} value={currentDataVerificationSelectedTab}>
        <ObservationTable
          columns={tableColumns.dataVerificationObservation}
          fetchData={getDataVetterObservationsByStatus}
          onCellClick={onCellClick}
          status={'N'}
          tableKey="verification-new"
          verifyActions
        />
      </TabPanel>
      <TabPanel index={1} value={currentDataVerificationSelectedTab}>
        <ObservationTable
          columns={tableColumns.dataVerificationObservation}
          fetchData={getDataVetterObservationsByStatus}
          onCellClick={onCellClick}
          status={'V'}
          tableKey="verification-verified"
        />
      </TabPanel>
      <TabPanel index={2} value={currentDataVerificationSelectedTab}>
        <ObservationTable
          columns={tableColumns.dataVerificationObservation}
          fetchData={getDataVetterObservationsByStatus}
          onCellClick={onCellClick}
          status={'R'}
          tableKey="verification-rejected"
        />
      </TabPanel>
      <TabPanel index={3} value={currentDataVerificationSelectedTab}>
        <ObservationTable
          columns={tableColumns.dataVerificationObservation}
          fetchData={getDataVetterObservationsByStatus}
          onCellClick={onCellClick}
          status={'Q'}
          tableKey="verification-questionable"
          verifyActions
        />
      </TabPanel>
    </div>
  )
}

function mapStateToProps(state) {
  return {
    currentDataVerificationSelectedTab:
      currentDataVerificationSelectedTab(state) || 0,
    stats: dataVetterStats(state),
  }
}

const mapDispatchToProps = {
  getDataVetterObservationsByStatus,
  getDataVerificationStats,
  updateDataVerificationSelectedTab,
  setCurrentVerificationIndex,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DataVerificationTabs)
