import { IonButton, IonIcon, IonRouterOutlet, IonSplitPane } from '@ionic/react'
import { Route } from 'react-router'
import { useHistory, useLocation } from 'react-router-dom'
import { connect } from 'react-redux'
import Menu from '../components/Menu'
import HomeOrLanding from './HomeOrLanding'
import MainTabs from './MainTabs'
import Signup from './auth/Signup'
import ObservationProjectRouterOutlet from '../components/siteLayout/ObservationProjectRouterOutlet'
import ResetPassword from './auth/ResetPassword'
import ForgotPassword from './auth/ForgotPassword'
import SetNewPassword from './auth/SetNewPassword'
import Login from './auth/Login'
import Admin from './admin'
import About from './About'
import ViewUser from './users/ViewUser'
import CreateUser from './admin/createUser'
import VerificationForm from './auth/VerificationForm'
import Tutorial from './Tutorial'
import AllObservationsPage from './observations/AllObservations.page'
import ViewObservationPage from './observations/ViewObservation.page'
import ProjectObservations from './observations/ProjectObservations'
import DataVerification from './observations/DataVerification.page'
import VerifyObservationPage from './observations/ObservationVerification.page'
import Export from './dataExport'
import NotFound from './NotFound'
import { useEffect } from 'react'
import { Auth } from 'aws-amplify'
import { signout } from '../actions/auth.actions'
import IonicPageLayout from '../components/siteLayout/IonicPageLayout'
import { makeStyles, Typography } from '@material-ui/core'
import { cloudDownloadOutline } from 'ionicons/icons'
import { getProjects } from '../actions/project.actions'
import { getFormTypes } from '../actions/form.actions'
import StyledLoading from '../components/micros/Loading'
import PhotoObservationsPage from './observations/PhotoObservations.page'
import PasswordMigration from './auth/PasswordMigration'

const useStyles = makeStyles((theme) => ({
  warning: {
    backgroundColor: '#FFF',
    color: theme.palette.primary.textColor,
    padding: '2rem 3rem',
    textAlign: 'center',
    boxShadow:
      '0px 1px 30px 5px rgba(0, 0, 0, 0.03), 0px 2px 2px 0px rgba(0, 0, 0, 0.01), 0px 3px 1px -2px rgba(0, 0, 0, 0.02);',

    '& h2': {
      maxWidth: '320px',
      margin: '0 auto 1rem',
    },

    '& p': {
      maxWidth: '320px',
      margin: '0 auto 2rem',
      color: theme.palette.primary.textColor,
    },
  },
}))

const NavRoutes = (props) => {
  const {
    dispatch,
    isAuthenticated,
    online,
    formTypes,
    projects,
    getFormTypes,
    getProjects,
  } = props
  const history = useHistory()
  const location = useLocation()
  const classes = useStyles()

  useEffect(async () => {
    // this is needed for a workaround for
    // bug https://forum.ionicframework.com/t/ion-page-invisible-class-not-being-removed-navigating-in-between-pages-video/162114/9
    setTimeout(() => {
      if (document.getElementById('main')) {
        const main = document
          .getElementById('main')
          .querySelector('.ion-page.ion-page-invisible')
        if (main) {
          main.classList.remove('ion-page-invisible')
        }
      }
    }, 100)

    // Auth check
    try {
      await Auth.currentAuthenticatedUser()
    } catch (error) {
      if (
        error === 'The user is not authenticated' &&
        isAuthenticated &&
        online &&
        location.pathname !== '/signin'
      ) {
        dispatch(signout(true))
        history.push('/signin')
      }
    }
  }, [location])

  const tryToGetData = () => {
    getFormTypes()
    getProjects()
  }

  if (!formTypes || !projects) {
    return (
      <IonicPageLayout>
        <Typography component="h2" gutterBottom variant="h4">
          Downloading Data
        </Typography>
        <div className={classes.warning}>
          <IonIcon icon={cloudDownloadOutline} size="large" />
          <Typography component="h2" gutterBottom variant="body1">
            Nature Mapping is downloading the application data it needs to
            function. This should only take a moment.
          </Typography>
          <Typography component="p" gutterBottom variant="body1">
            If this continues, please check your internet connection and open
            the application again when you have better connectivity.
          </Typography>
          <StyledLoading position="static" />
          <IonButton onClick={tryToGetData}>Force Request Data Again</IonButton>
        </div>
      </IonicPageLayout>
    )
  }

  return (
    <IonSplitPane contentId="main">
      <Menu {...props} />

      <IonRouterOutlet id="main">
        <Route
          exact={true}
          path="/welcome"
          render={() => <Tutorial {...props} />}
        />
        <Route path="/observation/:action">
          <ObservationProjectRouterOutlet />
        </Route>
        <Route path="/dashboard" render={() => <MainTabs {...props} />} />
        <Route
          exact
          path="/admin/user/create"
          render={() => <CreateUser {...props} />}
        />
        <Route
          exact
          path="/admin/user/view/:id"
          render={() => <ViewUser {...props} />}
        />
        <Route exact={true} path="/admin" render={() => <Admin {...props} />} />
        <Route
          exact
          path="/verify-account"
          render={() => <VerificationForm {...props} />}
        />
        <Route exact path="/signup" render={() => <Signup {...props} />} />
        <Route
          exact
          path="/signin/migrate/password"
          render={() => <PasswordMigration {...props} />}
        />
        <Route
          exact
          path="/signin/reset"
          render={() => <ResetPassword {...props} />}
        />
        <Route
          exact
          path="/forgot-password"
          render={() => <ForgotPassword {...props} />}
        />
        <Route
          exact
          path="/allobservations"
          render={() => <AllObservationsPage {...props} />}
        />
        <Route
          exact
          path="/photoobservations"
          render={() => <PhotoObservationsPage {...props} />}
        />
        <Route
          exact
          path="/allobservations/:id"
          render={() => <ViewObservationPage {...props} />}
        />
        <Route
          exact
          path="/photoobservations/:id"
          render={() => <ViewObservationPage {...props} />}
        />
        <Route
          exact
          path="/project/:key"
          render={() => <ProjectObservations {...props} />}
        />
        <Route
          exact
          path="/project/:key/observation/:id"
          render={() => <ViewObservationPage {...props} />}
        />
        <Route
          exact
          path="/signin/new/password"
          render={() => <SetNewPassword {...props} />}
        />
        <Route
          exact
          path="/verification/:tableKey/:id"
          render={() => <VerifyObservationPage {...props} />}
        />
        <Route
          exact
          path="/verification"
          render={() => <DataVerification {...props} />}
        />
        <Route exact path="/export" render={() => <Export {...props} />} />
        <Route exact path="/about" render={() => <About />} />
        <Route exact path="/signin" render={() => <Login {...props} />} />
        <Route exact path="/404" render={() => <NotFound {...props} />} />
        <Route
          exact
          path="/"
          render={(routeProps) => <HomeOrLanding {...routeProps} {...props} />}
        />
      </IonRouterOutlet>
    </IonSplitPane>
  )
}

function mapStateToProps({ auth, form, projects, layout, user }) {
  return {
    isAuthenticated: auth.isAuthenticated,
    online: layout.online,
    user,
    formTypes: form.types,
    projects,
  }
}

const mapDispatchToProps = {
  getFormTypes,
  getProjects,
}

export default connect(mapStateToProps, mapDispatchToProps)(NavRoutes)
