import { Storage } from '@ionic/storage'

const store = new Storage();
store.create();

export function storageAvailable(type) {
  var storage
  try {
    storage = window[type]
    var x = '__storage_test__'
    storage.setItem(x, x)
    storage.removeItem(x)
    return true
  } catch (e) {
    return (
      e instanceof DOMException &&
      // everything except Firefox
      (e.code === 22 ||
        // Firefox
        e.code === 1014 ||
        // test name field too, because code might not be present
        // everything except Firefox
        e.name === 'QuotaExceededError' ||
        // Firefox
        e.name === 'NS_ERROR_DOM_QUOTA_REACHED') &&
      // acknowledge QuotaExceededError only if there's something already stored
      storage &&
      storage.length !== 0
    )
  }
}

export const getLocalMessagesSeen = async () => {
  try {
    const messagesSeen = await store.get('messagesSeen')
    if (messagesSeen === null) {
      return []
    }
    return messagesSeen
  } catch (err) {
    return []
  }}

export const clearLocalMessagesSeen = async () => {
  try {
    await store.remove('messagesSeen')
    return true
  } catch (err) {
    return false
  }
}

export const saveLocalMessagesSeen = async (name) => {
  try {
    const value = await getLocalMessagesSeen()

    if (value.includes(name)) {
      return true
    }

    const update = [...value, name]

    await store.set('messagesSeen', update)
    return true
  } catch (err) {
    // TODO - log errors
    return false
  }
}

export const loadState = async () => {
  try {
    const serializedState = await store.get('state')
    if (serializedState === null) {
      return undefined
    }
    return JSON.parse(serializedState)
  } catch (err) {
    return undefined
  }
}

export const saveState = (state) => {
  try {
    const serializedState = JSON.stringify(state)
    store.set('state', serializedState).then(() => {
      return true
    })
  } catch (err) {
    // TODO - log errors
    return false
  }
}
