import React from 'react'
import { MenuItem } from '@material-ui/core'

const defaultOption = {
  label: 'All',
  value: -1,
}

export const addDefaultMenuItemOption = label => {
  return (
    <MenuItem key="default" value={-1}>
      {label}
    </MenuItem>
  )
}

export const getAccountStatusOptions = () => {
  return [
    defaultOption,
    {
      label: 'Active',
      value: true,
    },
    {
      label: 'Not Active',
      value: false,
    },
  ]
}

export const getCodeItemOptions = (items, defaultValue = false) => {
  const options = []

  if (defaultValue) options.push(defaultOption)

  items.forEach(item => {
    options.push({
      label: item.description,
      value: item._id,
    })
  })

  return options
}

export const getNameOptions = items => {
  const options = []

  options.push(defaultOption)

  items.forEach(item => {
    options.push({
      label: item.name,
      value: item._id,
    })
  })

  return options
}
