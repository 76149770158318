// ***************************
//  Types of actions
//
//
//  Auth Types
// ***************************
export const AUTH_USER = 'AUTH_USER'
export const UNAUTH_USER = 'UNAUTH_USER'
export const SIGNIN_USER = 'SIGNIN_USER'
export const AUTH_ERROR = 'AUTH_ERROR'
export const AUTHENTICATING = 'AUTHENTICATING'
export const SET_NEW_PASSWORD_USER = 'SET_NEW_PASSWORD_USER'
export const SET_RESET_PASSWORD_EMAIL = 'SET_RESET_PASSWORD_EMAIL'

// ***************************
//  User Types
// ***************************
export const UPDATE_USER = 'UPDATE_USER'
export const CREATE_USER = 'CREATE_USER'
export const DELETE_USER = 'DELETE_USER'
export const UPDATE_USERS_LIST = 'UPDATE_USERS_LIST'
export const SET_CURRENT_USER = 'SET_CURRENT_USER'
export const CLEAR_CURRENT_USER = 'CLEAR_CURRENT_USER'
export const UPDATE_DATA_VETTER_STATS = 'UPDATE_DATA_VETTER_STATS'
export const GET_EXPORT_HISTORY = 'GET_EXPORT_HISTORY'
export const GET_EXPORT_STATUS = 'GET_EXPORT_STATUS'
export const UPDATE_USER_STATS = 'UPDATE_USER_STATS'

// ***************************
//  Project Types
// ***************************
export const UPDATE_PROJECTS = 'UPDATE_PROJECTS'

// ***************************
//  Observation Types
// ***************************
export const CREATE_OBSERVATION = 'CREATE_OBSERVATION'
export const DELETE_OBSERVATION = 'DELETE_OBSERVATION'
export const EDIT_OBSERVATION = 'EDIT_OBSERVATION'
export const GET_EXCEL_DATA = 'GET_EXCEL_DATA'
export const GET_ALL_NOT_VERIFIED_OBSERVATIONS =
  'GET_ALL_NOT_VERIFIED_OBSERVATIONS'
export const GET_ALL_VERIFIED_OBSERVATIONS = 'GET_ALL_VERIFIED_OBSERVATIONS'
export const SET_CURRENT_OBSERVATION = 'SET_CURRENT_OBSERVATION'
export const CLEAR_CURRENT_OBSERVATION = 'CLEAR_CURRENT_OBSERVATION'
export const VERIFIED_OBSERVATIONS = 'VERIFIED_OBSERVATIONS'
export const SAVE_DRAFT_OBSERVATION = 'SAVE_DRAFT_OBSERVATION'
export const DELETE_DRAFT_OBSERVATION = 'DELETE_DRAFT_OBSERVATION'

// ***************************
//  Layout Types
// ***************************
export const SET_INFORMATION_DRAWER = 'SET_INFORMATION_DRAWER'
export const CLOSE_INFORMATION_DRAWER = 'CLOSE_INFORMATION_DRAWER'
export const OPEN_FLASH_MESSAGE = 'OPEN_FLASH_MESSAGE'
export const CLOSE_FLASH_MESSAGE = 'CLOSE_FLASH_MESSAGE'
export const OPEN_MODAL = 'OPEN_MODAL'
export const CLOSE_MODAL = 'CLOSE_MODAL'
export const NETWORK_CHECK = 'NETWORK_CHECK'
export const SET_DATA_VERIFICATION_SELECTED_TAB = 'SET_DATA_VERIFICATION_SELECTED_TAB'
export const SET_MENU_ENABLED = 'SET_MENU_ENABLED'

// ***************************
//  Form Types
// ***************************
export const UPDATE_FORM_TYPES = 'UPDATE_FORM_TYPES'
export const DISABLE_FORM_SUBMIT = 'DISABLE_FORM_SUBMIT'
export const ENABLE_FORM_SUBMIT = 'ENABLE_FORM_SUBMIT'
export const SET_REVIEW_DATA = 'SET_REVIEW_DATA'
export const CLEAR_REVIEW_DATA = 'CLEAR_REVIEW_DATA'
export const SET_FORM_METADATA = 'SET_FORM_METADATA'
export const CLEAR_FORM_METADATA = 'CLEAR_FORM_METADATA'

export const UPDATE_APP_STATS = 'UPDATE_APP_STATS'

// ***************************
//  Table Types
// ***************************
export const UPDATE_TABLE_DATA = 'UPDATE_TABLE_DATA'
export const UPDATE_TABLE_VIEW = 'UPDATE_TABLE_VIEW'
export const UPDATE_CURRENT_VERIFICATION_INDEX = 'UPDATE_CURRENT_VERIFICATION_INDEX'
export const UPDATE_PERSIST_TABLEDATA_ON_START = 'UPDATE_PERSIST_TABLEDATA_ON_START'

// ***************************
//  Lifecycle Types
// ***************************
export const APP_START = 'APP_START'
export const CLEAR_APPLICATION_STATE = 'CLEAR_APPLICATION_STATE'

// ***************************
//  Error Types
// ***************************
export const GENERIC_ERROR = 'GENERIC_ERROR'

export const WRITE_LOCATION_LOGS = 'WRITE_LOCATION_LOGS'
export const CLEAR_LOCATION_LOGS = 'CLEAR_LOCATION_LOGS'
export const SET_LOCATION_STATE = 'SET_LOCATION_STATE'
