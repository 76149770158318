import {
  CLEAR_CURRENT_OBSERVATION,
  SET_CURRENT_OBSERVATION,
} from '../types/action.types'

const initialState = {
  current: null
}

export default function(state = initialState, action) {
  switch (action.type) {
    case SET_CURRENT_OBSERVATION:
      return { ...state, current: action.payload }
    case CLEAR_CURRENT_OBSERVATION:
      return { ...state, current: null }
    default:
      return state
  }
}
