import React from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import { useTable, useSortBy, usePagination, useBlockLayout } from 'react-table'
import { Paper } from '@material-ui/core'
import TablePagination from './TablePagination'
import { setReviewData } from '../../actions/form.actions'

const propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      Header: PropTypes.string,
      accessor: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    })
  ).isRequired,
}

const styles = (theme) => ({
  table: {
    display: 'block',
    width: '100%',
    overflowX: 'auto',
    marginBottom: '0.5rem',

    '& tbody tr': {
      borderBottom: `1px solid ${theme.palette.primary.borderColor}`,
      minHeight: '2rem',

      '&:hover': {
        backgroundColor: theme.palette.primary.light,
        color: theme.palette.primary.textColor,
        cursor: 'pointer',
      },

      '& td': {
        alignSelf: 'center',
        padding: '0.1rem 0.6rem',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
      },
    },

    '& thead': {
      boxShadow: 'none',
      borderBottom: `1px solid ${theme.palette.primary.borderColor}`,
      fontWeight: 600,

      '& th': {
        padding: '0.6rem 0.9rem',

        '& input': {
          borderRadius: 4,
          border: `1px solid ${theme.palette.primary.borderColor}`,
          padding: '0 0.3rem',
          width: '100%',
        },
      },
    },
  },
  tableButton: {
    borderRadius: 0,
    boxShadow: 'none',
    width: '100%',
  },
  infoPanel: {
    textAlign: 'center',
  },
  pagination: {
    textAlign: 'center',
  },
  sortIcon: {
    display: 'inline-block',
    marginLeft: '1rem',
  },
})

const OfflineObservationTable = ({
  classes,
  columns,
  data,
  history,
  loading,
  pageCount: controlledPageCount,
  deleteDraftObservation,
}) => {
  const dispatch = useDispatch()

  function onCellClick(event, cell) {
    if (
      cell.row &&
      cell.row.original &&
      cell.column &&
      cell.column.id !== 'selection' &&
      cell.column.id !== 'actions'
    ) {
      dispatch(setReviewData(cell.row.original.formData))
      return history.push(
        `/observation/create/${cell.row.original.projectKey}/0?offlineKey=${cell.row.original.offlineKey}`
      )
    }
  }

  const TableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 10 },
      pageCount: controlledPageCount,
    },
    useSortBy,
    usePagination,
    useBlockLayout
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page
  } = TableInstance

  return (
    <div>
      <Paper>
        <table className={classes.table} {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps()}>
                    <div>
                      <span {...column.getSortByToggleProps()}>
                        {column.render('Header')}
                        {column.isSorted ? (
                          column.isSortedDesc ? (
                            <span
                              aria-label="descending sort icon"
                              className={classes.sortIcon}
                              role="img"
                            >
                              🔽
                            </span>
                          ) : (
                            <span
                              aria-label="ascending sort icon"
                              className={classes.sortIcon}
                              role="img"
                            >
                              🔼
                            </span>
                          )
                        ) : (
                          ''
                        )}
                      </span>
                    </div>
                    <div>
                      {column.canFilter ? column.render('Filter') : null}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row)
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td
                        onClick={(e) => onCellClick(e, cell)}
                        {...cell.getCellProps()}
                      >
                        {cell.render('Cell', { deleteDraftObservation })}
                      </td>
                    )
                  })}
                </tr>
              )
            })}
          </tbody>
        </table>
      </Paper>
      <TablePagination
        loading={loading}
        total={data.length}
        {...TableInstance}
      />
    </div>
  )
}

OfflineObservationTable.propTypes = propTypes

export default withStyles(styles)(withRouter(OfflineObservationTable))
