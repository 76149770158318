import React from 'react'
import PropTypes from 'prop-types'
import { DateTimePicker } from 'react-rainbow-components'
import { DateTime } from 'luxon'

function isIsoDate(str) {
  if (!/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/.test(str)) return false
  var d = new Date(str)
  return d.toISOString() === str
}

const DateTimeWidget = (props) => {
  const { value, required, onChange, placeholder } = props

  const handleChange = (e) => {
    const dateTime = DateTime.fromJSDate(e)
    onChange(dateTime.toISO())
  }

  let valueInput = value

  if (isIsoDate(value)) {
    valueInput = DateTime.fromISO(value).toLocal().toJSDate()
  }

  return (
    <div>
      <DateTimePicker
        borderRadius='semi-square'
        className="form-control"
        formatStyle="large"
        maxDate={new Date()}
        minDate={new Date(2010, 0, 1)}
        onChange={handleChange}
        placeholder={placeholder}
        required={required}
        value={valueInput}
      />
    </div>
  )
}

DateTimeWidget.defaultProps = {
  autoFocus: false,
}

DateTimeWidget.propTypes = {
  autoFocus: PropTypes.bool,
}

export default DateTimeWidget
