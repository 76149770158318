import { DateTime } from 'luxon'

const speciesList = [
  '225',
  '360',
  '352',
  '491',
  '420',
  '394',
  '393',
  '468',
  '357',
  '356',
  '206',
  '187',
  '466',
  '230',
  '940',
  '941',
  '931',
  '933',
  '952',
  '875',
]

const createSchema = formTypes => ({
  steps: [
    {
      type: 'object',
      required: ['dateTime', 'speciesType', 'species'],
      properties: {
        speciesType: {
          type: 'string',
          title: 'What type of wildlife was it?',
          enum: formTypes
            ? formTypes.speciesTypes.map(species => species._id)
            : [],
          enumNames: formTypes
            ? formTypes.speciesTypes.map(species => species.name)
            : [],
        },
        species: {
          type: 'string',
          title: 'What species was it?',
          enum: [],
          enumNames: [],
        },
        dateTime: {
          title: 'When did you make this observation?',
          type: 'string',
          format: 'date-time',
          default: DateTime.local().toISO(),
        },
        comment: {
          type: 'string',
          title: 'Notes',
        },
      },
    },
    {
      definitions: {
        observation: {
          type: 'object',
          required: ['sex', 'age', 'quantity', 'activity', 'mortality'],
          properties: {
            sex: {
              type: 'string',
              title: 'Sex',
              enum: formTypes ? formTypes.sexes.map(item => item._id) : [],
              enumNames: formTypes
                ? formTypes.sexes.map(item => item.description)
                : [],
              default:
                formTypes &&
                formTypes.sexes.find(item => item.code === 'U')._id,
            },
            age: {
              type: 'string',
              title: 'Age',
              enum: formTypes ? formTypes.ages.map(item => item._id) : [],
              enumNames: formTypes
                ? formTypes.ages.map(item => item.description)
                : [],
              default:
                formTypes && formTypes.ages.find(item => item.code === '0')._id,
            },
            quantity: {
              type: 'number',
              title: 'Quantity',
              default: 1,
            },
            activity: {
              type: 'string',
              title: 'Activity',
              enum: formTypes ? formTypes.activities.map(item => item._id) : [],
              enumNames: formTypes
                ? formTypes.activities.map(item => item.description)
                : [],
              default:
                formTypes &&
                formTypes.activities.find(item => item.code === '0')._id,
            },
            mortality: {
              type: 'string',
              title: 'Mortality',
              enum: formTypes ? formTypes.mortality.map(item => item._id) : [],
              enumNames: formTypes
                ? formTypes.mortality.map(item => item.description || ' ')
                : [],
              default:
                formTypes &&
                formTypes.mortality.find(item => item.code === '0')._id,
            },
          },
        },
      },
      type: 'object',
      properties: {
        observations: {
          title: 'What did you see?',
          type: 'array',
          minItems: 1,
          items: {
            $ref: '#/definitions/observation',
          },
        },
        comment: {
          type: 'string',
          title: 'Notes',
        },
      },
    },
    {
      type: 'object',
      properties: {
        location: {
          title: 'Where did you observe this species?',
          type: 'string',
        },
      },
    },
    {
      type: 'object',
      properties: {
        photos: {
          type: 'string',
          title: 'Photos',
        },
      },
    },
  ],
})

const uiSchema = {
  dateTime: {
    'ui:widget': 'DateTimeWidget',
    'ui:options': {
      placeholder: 'Select date and time',
    },
  },
  comment: {
    'ui:options': {
      rows: 4,
      resize: false,
      placeholder: 'Any notes about the observation?',
    },
    'ui:widget': 'textarea',
  },
  observations: {
    'ui:options': {
      orderable: false,
    },
    items: {
      activity: {
        'ui:widget': 'SelectWidget',
      },
      age: {
        'ui:widget': 'SelectWidget',
      },
      quantity: {
        'ui:widget': 'NumberWidget',
      },
      sex: {
        'ui:widget': 'RadioWidget',
      },
      mortality: {
        'ui:widget': 'SelectWidget',
      },
    },
  },
  species: {
    'ui:widget': 'SpeciesWidget',
    'ui:options': {
      placeholder: 'Select or type species',
    },
  },
  location: {
    'ui:widget': 'UtmWidget',
  },
  speciesType: {
    'ui:widget': 'RadioWidget',
  },
  photos: {
    'ui:widget': 'PhotoWidget',
  },
}

export default {
  createSchema,
  speciesList,
  uiSchema,
}
