import { API, graphqlOperation } from 'aws-amplify'
import {
  UPDATE_FORM_TYPES,
  DISABLE_FORM_SUBMIT,
  ENABLE_FORM_SUBMIT,
  CLEAR_REVIEW_DATA,
  SET_REVIEW_DATA,
  UPDATE_APP_STATS,
  SET_FORM_METADATA,
  CLEAR_FORM_METADATA,
} from '../types/action.types'
import Query from '../graphql'

export const getFormTypes = () => dispatch => {
  return new Promise(async function getFormTypesPromise(resolve, reject) {
    try {
      const {
        data: { getAllFormTypes },
      } = await API.graphql(graphqlOperation(Query.getAllFormTypesQuery))

      dispatch({
        payload: getAllFormTypes,
        type: UPDATE_FORM_TYPES,
      })

      resolve()
    } catch (err) {
      console.log(err)
      reject()
    }
  })
}

export const getAppStats = () => dispatch => {
  return new Promise(async function getAppStatsPromise(resolve, reject) {
    try {
      const {
        data: { getAppStats },
      } = await API.graphql(graphqlOperation(Query.getAppStats))

      dispatch({
        payload: getAppStats,
        type: UPDATE_APP_STATS,
      })

      resolve()
    } catch (err) {
      console.log(err)
      reject()
    }
  })
}

export const disableFormSubmit = () => ({
  type: DISABLE_FORM_SUBMIT,
})

export const enableFormSubmit = () => ({
  type: ENABLE_FORM_SUBMIT,
})

export const setReviewData = (data) => ({
  type: SET_REVIEW_DATA,
  payload: data
})

export const clearReviewData = () => ({
  type: CLEAR_REVIEW_DATA,
})

export const setFormMetadata = (payload) => ({
  type: SET_FORM_METADATA,
  payload
})

export const clearFormMetadata = () => ({
  type: CLEAR_FORM_METADATA,
})
