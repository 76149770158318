import { API, graphqlOperation } from 'aws-amplify'
import {
  CLEAR_CURRENT_OBSERVATION,
  CREATE_OBSERVATION,
  DELETE_OBSERVATION,
  EDIT_OBSERVATION,
  SET_CURRENT_OBSERVATION,
  SAVE_DRAFT_OBSERVATION,
  DELETE_DRAFT_OBSERVATION,
  VERIFIED_OBSERVATIONS,
  UPDATE_DATA_VETTER_STATS,
  UPDATE_TABLE_DATA,
  UPDATE_TABLE_VIEW,
  UPDATE_CURRENT_VERIFICATION_INDEX,
} from '../types/action.types'
import Query from '../graphql'
import { showFlashMessage } from './flash.actions'
import { me } from './user.actions'
import tableColumns from '../components/tables/tableColumns'

const cleanProjectTableFilters = (project, filters) => {
  const supportedColumns =
    project && project.key && tableColumns[project.key]
      ? tableColumns[project.key]
      : tableColumns.observation
  const supportedColumnKeys = supportedColumns.map((column) => column.accessor)

  return filters.filter((filter) => supportedColumnKeys.includes(filter.id))
}

export const setCurrentObservation = (observation) => ({
  type: SET_CURRENT_OBSERVATION,
  payload: observation,
})

export const saveDraftObservation = (projectKey, observation) => ({
  type: SAVE_DRAFT_OBSERVATION,
  payload: {
    projectKey,
    data: observation,
  },
})

export const deleteDraftObservation = (offlineKey) => ({
  type: DELETE_DRAFT_OBSERVATION,
  payload: offlineKey,
})

export const setCurrentVerificationIndex = (tableKey, currentIndex) => ({
  type: UPDATE_CURRENT_VERIFICATION_INDEX,
  payload: {
    tableKey,
    currentIndex,
  },
})

export const clearCurrentObservation = () => ({
  type: CLEAR_CURRENT_OBSERVATION,
})

export const createObservation =
  (projectKey, values) => (dispatch, getState) => {
    return new Promise(async function createObservationPromise(
      resolve,
      reject
    ) {
      // get the project associated with this observation from state
      const project = getState().projects.find(
        (project) => project.key === projectKey
      )
      const nestbox = getState().form.types.nestboxes.find(
        (nestbox) => nestbox.number === values.nestbox
      )
      const species = getState().form.types.species.find(
        (s) => s.code === values.species
      )

      // if not already an array, make an array
      const observations =
        values.observations && values.observations.length > 0
          ? values.observations
          : [values.observations]

      const input = {
        comment: values.comment,
        dateTime: values.dateTime,
        location: values.location,
        gameCamera: values.gameCamera,
        nestbox: nestbox ? nestbox._id : null,
        nestMaterial: values.nestMaterial,
        onTour: values.onTour,
        observations,
        obsPeriod: values.obsPeriod,
        photos: values.photos,
        project: project._id,
        species: species?._id,
        speciesType: values.speciesType,
        trailSegment: values.trailSegment,
        doesObservationNeedToBeVerified: values.doesObservationNeedToBeVerified,
        beaverSite: values.beaverSite,
        currentActivities: values.currentActivities,
        recentActivities: values.recentActivities,
        pastActivities: values.pastActivities,
        noActivity: values.noActivity,
        bandResight: values.bandResight
      }

      try {
        const {
          data: { createObservation },
        } = await API.graphql(
          graphqlOperation(Query.createObservationMutation, {
            input,
          })
        )

        dispatch({
          payload: createObservation,
          type: CREATE_OBSERVATION,
        })

        dispatch(
          showFlashMessage(
            'Thank you! Your observation has been created.',
            'success'
          )
        )

        if (values.offlineKey) {
          dispatch(deleteDraftObservation(values.offlineKey))
        }

        resolve()
      } catch (err) {
        console.error(err)
        if (
          err.errors &&
          err.errors[0] &&
          err.errors[0].message === 'Network Error'
        ) {
          dispatch(saveDraftObservation(project.key, values))
          dispatch(
            showFlashMessage(
              `We can't save your observation due to network connectivity right now  but it has been stored on your device. Observations entered offline will be uploaded when you have a internet connection again and the Nature Mapping application is open.`,
              'success',
              8000
            )
          )
          resolve()
        } else {
          dispatch(
            showFlashMessage(
              `Sorry, we're not able to create your observation right now`,
              'danger'
            )
          )
          reject(err)
        }
      }
    })
  }

export const editObservation = (projectKey, values) => (dispatch, getState) => {
  return new Promise(async function editObservationPromise(resolve, reject) {
    try {
      const project = getState().projects.find(
        (project) => project.key === projectKey
      )
      const nestbox = getState().form.types.nestboxes.find(
        (nestbox) => nestbox.number === values.nestbox
      )
      const species = getState().form.types.species.find(
        (s) => s.code === values.species
      )

      const observations = values.observations.length
        ? values.observations
        : [values.observations]

      const {
        data: { editObservation },
      } = await API.graphql(
        graphqlOperation(Query.editObservationMutation, {
          input: {
            _id: values._id,
            comment: values.comment,
            dateTime: values.dateTime,
            gameCamera: values.gameCamera,
            location: values.location,
            nestbox: nestbox ? nestbox._id : null,
            nestMaterial: values.nestMaterial,
            onTour: values.onTour,
            observations,
            obsPeriod: values.obsPeriod,
            photos: values.photos,
            project: project._id,
            species: species ? species._id : null,
            speciesType: values.speciesType,
            trailSegment: values.trailSegment,
            doesObservationNeedToBeVerified:
              values.doesObservationNeedToBeVerified,
            beaverSite: values.beaverSite,
            currentActivities: values.currentActivities,
            recentActivities: values.recentActivities,
            pastActivities: values.pastActivities,
            noActivity: values.noActivity,
            bandResight: values.bandResight
          },
        })
      )

      dispatch({
        payload: editObservation,
        type: EDIT_OBSERVATION,
      })

      dispatch(showFlashMessage('Observation updated successfully.', 'success'))

      resolve(editObservation)
    } catch (err) {
      console.error(err)
      dispatch(
        showFlashMessage(
          'Error. Observation could not be updated right now.',
          'danger'
        )
      )
      reject(err)
    }
  })
}

export const deleteObservation = (id) => (dispatch) => {
  return new Promise(async function deleteObservationPromise(resolve, reject) {
    try {
      const {
        data: { deleteObservation },
      } = await API.graphql(
        graphqlOperation(Query.deleteObservationMutation, {
          input: {
            id,
          },
        })
      )

      dispatch({
        payload: deleteObservation,
        type: DELETE_OBSERVATION,
      })

      dispatch(me())

      dispatch(showFlashMessage('Observation deleted successfully.', 'success'))

      resolve(deleteObservation)
    } catch (err) {
      console.error(err)
      dispatch(
        showFlashMessage(
          'Error. Observation could not be deleted right now.',
          'danger'
        )
      )
      reject(err)
    }
  })
}

export const getObservation = (id) => (dispatch) => {
  return new Promise(async function (resolve, reject) {
    try {
      const {
        data: { getObservation },
      } = await API.graphql(
        graphqlOperation(Query.getObservationQuery, {
          input: {
            id,
          },
        })
      )

      dispatch(setCurrentObservation(getObservation))

      resolve(getObservation)
    } catch (err) {
      console.error(err)
      reject(err)
    }
  })
}

export const getObservationEntry = (id) => (dispatch) => {
  return new Promise(async function (resolve, reject) {
    try {
      const {
        data: { getObservationEntry },
      } = await API.graphql(
        graphqlOperation(Query.getObservationEntryQuery, {
          input: {
            id,
          },
        })
      )

      // dispatch(setCurrentObservationEntry(getObservationEntry))

      resolve(getObservationEntry)
    } catch (err) {
      console.error(err)
      reject(err)
    }
  })
}

export const getObservationsByProject =
  ({
    tableKey,
    tableState: { pageIndex = 0, pageSize = 5, sortBy, filters },
    project,
  }) =>
  (dispatch, getState) => {
    return new Promise(async function (resolve, reject) {
      try {
        const projectObj = await getState().projects.find(
          (p) => p._id === project
        )

        filters = cleanProjectTableFilters(projectObj, filters)

        const {
          data: { getObservationsByProject },
        } = await API.graphql(
          graphqlOperation(Query.getObservationsByProjectQuery, {
            input: {
              limit: pageSize,
              offset: pageIndex,
              sortBy,
              filters,
              project,
            },
          })
        )

        dispatch({
          payload: {
            tableKey,
            pageIndex,
            pageSize,
            sortBy,
            filters,
            rows: getObservationsByProject.data,
            total: getObservationsByProject.count,
          },
          type: UPDATE_TABLE_DATA,
        })

        resolve(getObservationsByProject)
      } catch (err) {
        console.error(err)
        reject(err)
      }
    })
  }

export const getMyObservations =
  ({
    tableKey,
    tableState: { pageIndex = 0, pageSize = 5, sortBy, filters },
    project,
  }) =>
  (dispatch) => {
    return new Promise(async function (resolve, reject) {
      try {
        const {
          data: { getMyObservations },
        } = await API.graphql(
          graphqlOperation(Query.getMyObservations, {
            input: {
              limit: pageSize,
              offset: pageIndex,
              sortBy,
              filters,
            },
          })
        )

        dispatch({
          payload: {
            tableKey,
            pageIndex,
            pageSize,
            sortBy,
            filters,
            rows: getMyObservations.data,
            total: getMyObservations.count,
          },
          type: UPDATE_TABLE_DATA,
        })

        resolve(getMyObservations)
      } catch (err) {
        console.error(err)
        reject(err)
      }
    })
  }

export const searchObservations = (value) => (dispatch) => {
  return new Promise(async function (resolve, reject) {
    try {
      const {
        data: { searchObservations },
      } = await API.graphql(
        graphqlOperation(Query.searchObservations, {
          input: {
            value,
          },
        })
      )

      resolve(searchObservations)
    } catch (err) {
      console.error(err)
      reject(err)
    }
  })
}

export const getAllObservationsWithPhotos = ({
  tableKey,
  tableState: { pageIndex = 0, pageSize = 100, sortBy, filters }
}) => dispatch => {
  return new Promise(async function(resolve, reject) {
    try {
      const {
        data: { getAllObservationsWithPhotos },
      } = await API.graphql(
        graphqlOperation(Query.getAllObservationsWithPhotosQuery, {
          input: {
            limit: pageSize,
            offset: pageIndex,
            sortBy,
            filters,
          },
        }),
      )

      dispatch({
        payload: {
          tableKey,
          pageIndex,
          pageSize,
          sortBy,
          filters,
          rows: getAllObservationsWithPhotos.data,
          total: getAllObservationsWithPhotos.count,
        },
        type: UPDATE_TABLE_DATA,
      })

      resolve(getAllObservationsWithPhotos)
    } catch (err) {
      console.error(err)
      reject(err)
    }
  })
}

export const getAllVerifiedObservations =
  ({
    tableKey,
    tableState: { pageIndex = 0, pageSize = 5, sortBy, filters },
    project,
  }) =>
  (dispatch) => {
    return new Promise(async function (resolve, reject) {
      try {
        const {
          data: { getAllVerifiedObservations },
        } = await API.graphql(
          graphqlOperation(Query.getAllVerifiedObservationsQuery, {
            input: {
              limit: pageSize,
              offset: pageIndex,
              sortBy,
              filters,
            },
          })
        )

        dispatch({
          payload: {
            tableKey,
            pageIndex,
            pageSize,
            sortBy,
            filters,
            rows: getAllVerifiedObservations.data,
            total: getAllVerifiedObservations.count,
          },
          type: UPDATE_TABLE_DATA,
        })

        resolve(getAllVerifiedObservations)
      } catch (err) {
        console.error(err)
        reject(err)
      }
    })
  }

export const getAllNotVerifiedObservations =
  ({
    tableKey,
    tableState: { pageIndex = 0, pageSize = 5, sortBy, filters },
    project,
  }) =>
  (dispatch) => {
    return new Promise(async function (resolve, reject) {
      try {
        const {
          data: { getAllNotVerifiedObservations },
        } = await API.graphql(
          graphqlOperation(Query.getAllNotVerifiedObservationsQuery, {
            input: {
              limit: pageSize,
              offset: pageIndex,
              sortBy,
              filters,
            },
          })
        )

        dispatch({
          payload: {
            tableKey,
            pageIndex,
            pageSize,
            sortBy,
            filters,
            rows: getAllNotVerifiedObservations.data,
            total: getAllNotVerifiedObservations.count,
          },
          type: UPDATE_TABLE_DATA,
        })

        resolve(getAllNotVerifiedObservations)
      } catch (err) {
        console.error(err)
        reject(err)
      }
    })
  }

const getTableKeyStatus = (tableKey) => {
  let status = null

  switch (tableKey) {
    case 'verification-new':
      status = 'N'
      break
    case 'verification-rejected':
      status = 'R'
      break
    case 'verification-questionable':
      status = 'Q'
      break
    case 'verification-verified':
      status = 'V'
      break
    default:
      status = 'N'
  }

  return status
}

export const getDataVetterObservationsByStatus =
  ({
    tableKey,
    tableState: { pageIndex = 0, pageSize = 5, sortBy, filters },
    status,
  }) =>
  (dispatch) => {
    return new Promise(async function (resolve, reject) {
      try {
        const {
          data: { getDataVetterObservationsByStatus },
        } = await API.graphql(
          graphqlOperation(Query.getDataVetterObservationsByStatusQuery, {
            input: {
              limit: pageSize,
              offset: pageIndex,
              sortBy,
              filters,
              status: status ? status : getTableKeyStatus(tableKey),
            },
          })
        )

        dispatch({
          payload: {
            tableKey,
            pageIndex,
            pageSize,
            sortBy,
            filters,
            rows: getDataVetterObservationsByStatus.data,
            total: getDataVetterObservationsByStatus.count,
          },
          type: UPDATE_TABLE_DATA,
        })

        resolve(getDataVetterObservationsByStatus)
      } catch (err) {
        console.error(err)
        reject(err)
      }
    })
  }

export const getDataVerificationStats = () => (dispatch) => {
  return new Promise(async function (resolve, reject) {
    try {
      const {
        data: { getDataVerificationStats },
      } = await API.graphql(graphqlOperation(Query.getDataVerificationStats))

      dispatch({
        payload: getDataVerificationStats,
        type: UPDATE_DATA_VETTER_STATS,
      })

      resolve(getDataVerificationStats)
    } catch (err) {
      console.error(err)
      reject(err)
    }
  })
}

export const updateObservationStatus =
  (id, { status }) =>
  (dispatch) => {
    return new Promise(async function (resolve, reject) {
      try {
        const {
          data: { updateObservationStatus },
        } = await API.graphql(
          graphqlOperation(Query.updateObservationStatusMutation, {
            input: {
              id,
              status,
            },
          })
        )

        dispatch(setCurrentObservation(updateObservationStatus))

        dispatch(
          showFlashMessage('Observation status updated successfully', 'success')
        )

        resolve(updateObservationStatus)
      } catch (err) {
        console.error(err)
        dispatch(
          showFlashMessage(
            'Observation status could not be updated at this time',
            'danger'
          )
        )
        reject(err)
      }
    })
  }

export const updateDataVerificationNote = (id, value) => (dispatch) => {
  return new Promise(async function (resolve, reject) {
    try {
      const {
        data: { updateDataVerificationNote },
      } = await API.graphql(
        graphqlOperation(Query.updateDataVerificationNoteMutation, {
          input: {
            id,
            note: value,
          },
        })
      )

      dispatch(setCurrentObservation(updateDataVerificationNote))

      dispatch(
        showFlashMessage(
          'Observation data verification note updated successfully',
          'success'
        )
      )

      resolve(updateDataVerificationNote)
    } catch (err) {
      console.error(err)
      dispatch(
        showFlashMessage(
          'Observation data verification note could not be updated at this time',
          'danger'
        )
      )
      reject(err)
    }
  })
}

export const batchReject = (ids) => (dispatch) => {
  return new Promise(async function (resolve, reject) {
    try {
      const {
        data: { batchReject },
      } = await API.graphql(
        graphqlOperation(Query.batchRejectMutation, {
          input: {
            ids,
          },
        })
      )

      if (batchReject) {
        dispatch({
          type: VERIFIED_OBSERVATIONS,
          ids,
        })

        dispatch(
          showFlashMessage(`${ids.length} observations rejected successfully.`),
          'success'
        )
      }

      dispatch(getDataVerificationStats())

      resolve(batchReject)
    } catch (err) {
      console.error(err)
      dispatch(
        showFlashMessage(`Could not update observations right now.`),
        'danger'
      )
      reject(err)
    }
  })
}

export const batchVerify = (ids) => (dispatch) => {
  return new Promise(async function (resolve, reject) {
    try {
      const {
        data: { batchVerify },
      } = await API.graphql(
        graphqlOperation(Query.batchVerifyMutation, {
          input: {
            ids,
          },
        })
      )

      if (batchVerify) {
        dispatch({
          type: VERIFIED_OBSERVATIONS,
          ids,
        })

        dispatch(
          showFlashMessage(`${ids.length} observations verified successfully.`),
          'success'
        )
      }

      dispatch(getDataVerificationStats())

      resolve(batchVerify)
    } catch (err) {
      console.error(err)
      dispatch(
        showFlashMessage(`Could not update observations right now.`),
        'danger'
      )
      reject(err)
    }
  })
}

export const setTableView = (value) => ({
  type: UPDATE_TABLE_VIEW,
  payload: {
    value,
  },
})
