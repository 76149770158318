import {
  CLEAR_CURRENT_USER,
  GET_ALL_VERIFIED_OBSERVATIONS,
  SET_CURRENT_USER,
  UPDATE_DATA_VETTER_STATS,
  UPDATE_USERS_LIST,
  VERIFIED_OBSERVATIONS,
} from '../types/action.types'

const initialState = {
  allUsers: [],
  currentUser: null,
  dataVetterStats: {},
  notVerified: {
    count: 0,
    data: [],
  },
}

export default function(state = initialState, action) {
  switch (action.type) {
    case UPDATE_USERS_LIST:
      return { ...state, allUsers: action.payload }
    case GET_ALL_VERIFIED_OBSERVATIONS:
      return { ...state, verified: action.payload }
    case SET_CURRENT_USER:
      return { ...state, currentUser: action.payload }
    case CLEAR_CURRENT_USER:
      return { ...state, currentUser: null }
    case UPDATE_DATA_VETTER_STATS:
      return { ...state, dataVetterStats: action.payload }
    case VERIFIED_OBSERVATIONS:
      return {
        ...state,
        notVerified: {
          ...state.notVerified,
          data: state.notVerified.data
            ? state.notVerified.data.filter(observation => {
                return !action.ids.includes(observation._id)
              })
            : [],
        },
      }
    default:
      return state
  }
}
