import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { Divider } from '@material-ui/core'

const styles = theme => ({
  standard: {
    margin: '3rem 0',
  },
})

const SectionDivider = props => {
  const { classes, variant } = props

  return <Divider className={classes.standard} variant={variant} />
}

SectionDivider.propTypes = {
  classes: PropTypes.object.isRequired,
  variant: PropTypes.oneOf(['middle', 'page']),
}

SectionDivider.defaultProps = {
  variant: 'middle',
}

export default withStyles(styles)(SectionDivider)
