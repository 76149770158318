import gql from 'graphql-tag'

export const CodeItemFragment = gql`
  fragment CodeItem on CodeItem {
    _id
    code
    description
  }
`

export const SpeciesTypeFragment = gql`
  fragment SpeciesType on SpeciesType {
    _id
    name
  }
`

export const NestboxFragment = gql`
  fragment Nestbox on Nestbox {
    _id
    number
    utmEasting
    utmNorthing
    utmZone
    latitude
    longitude
    notes
  }
`

export const StateFragment = gql`
  fragment State on State {
    _id
    abbr
    country
    name
  }
`

export const CommonalityFragment = gql`
  fragment Commonality on Commonality {
    occurrence
    spring
    summer
    fall
    winter
  }
`

export const SpeciesFragment = gql`
  fragment Species on Species {
    _id
    code
    commonality {
      ...Commonality
    }
    commonName
    wosName
    name
    type {
      ...SpeciesType
    }
    scientificName
  }
  ${CommonalityFragment}
  ${SpeciesTypeFragment}
`

export const ProjectFragment = gql`
  fragment Project on Project {
    _id
    key
    name
    type
    active
  }
`

export const ObservationFormDataFragment = gql`
  fragment ObservationFormData on ObservationFormData {
    _id
    activity
    age
    mortality
    quantity
    sex
    eggs
    fledglings
    deadNestlings
    nestlings
    unhatchedEggs
  }
`

export const ObservationFragment = gql`
  fragment Observation on Observation {
    _id
    created
    creator {
      _id
      firstName
      lastName
      phone
      cell
      email
      address1
      city
      state
      zip
      country
      utmEasting
      utmNorthing
      utmZone
    }
    dataVerificationNote
    project {
      ...Project
    }
    obsDateTime
    sex {
      ...CodeItem
    }
    age {
      ...CodeItem
    }
    quantity
    activity {
      ...CodeItem
    }
    mortality {
      ...CodeItem
    }
    county
    utmEasting
    utmNorthing
    utmZone
    obsPeriod {
      ...CodeItem
    }
    friendlyId
    gameCamera {
      _id
      name
      notes
      utmEasting
      utmNorthing
      utmZone
      longitude
      latitude
    }
    entry
    photos
    comment
    status {
      ...CodeItem
    }
    species {
      ...Species
    }
    speciesType {
      ...SpeciesType
    }
    lastUpdated
    updatedBy
    verified
    verifiedBy {
      firstName
      lastName
      email
    }
    wosExportDate
    latitude
    longitude
    trailSegment {
      ...CodeItem
    }
    nestbox {
      ...Nestbox
    }
    nestMaterial
    onTour
    eggs
    fledglings
    deadNestlings
    nestlings
    unhatchedEggs
    beaverSite {
      _id
      siteId
      name
      utmEasting
      utmNorthing
      utmZone
      longitude
      latitude
    }
    currentActivities {
      _id
      description
      timing
    }
    recentActivities {
      _id
      description
      timing
    }
    pastActivities {
      _id
      description
      timing
    }
    noActivity
    bandResight {
      isBanded
      sex
      colorBandCombination {
        topLeft
        topRight
        bottomLeft
        bottomRight
      }
      isBanded2
      sex2
      colorBandCombination2 {
        topLeft
        topRight
        bottomLeft
        bottomRight
      }
      comments
    }
  }
  ${CodeItemFragment}
  ${NestboxFragment}
  ${ProjectFragment}
  ${SpeciesFragment}
  ${SpeciesTypeFragment}
`

export const ObservationEntryFragment = gql`
  fragment ObservationEntry on ObservationEntry {
    _id
    comment
    creator {
      _id
    }
    dateTime
    gameCamera
    location
    nestbox
    nestMaterial
    onTour
    observations {
      ...ObservationFormData
    }
    obsPeriod
    offlineKey
    photos
    project
    species
    speciesType
    trailSegment
    beaverSite {
      _id
      siteId
      name
      utmEasting
      utmNorthing
      utmZone
      longitude
      latitude
    }
    currentActivities {
      _id
      description
      timing
    }
    recentActivities {
      _id
      description
      timing
    }
    pastActivities {
      _id
      description
      timing
    }
    noActivity
    bandResight
  }
  ${ObservationFormDataFragment}
`

export const UserFragment = gql`
  fragment User on User {
    _id
    level
    active
    firstName
    lastName
    phone
    cell
    email
    address1
    city
    state
    zip
    country
    utmEasting
    utmNorthing
    utmZone
    signupDate
    trainingDate
    messagesSeen
    lastUpdated
    updateBy
    userVisits
    lastUserVisit
    observations {
      _id
    }
    projects {
      _id
      key
    }
    settings {
      dataVetterAccess {
        projects
        speciesTypes
      }
      taxonomicSortOrder
      passwordMigrated
    }
  }
`

export const UserDisplayFragment = gql`
  fragment UserDisplay on User {
    _id
    level
    active
    firstName
    lastName
    phone
    cell
    email
    address1
    city
    state
    zip
    country
    utmEasting
    utmNorthing
    utmZone
    signupDate
    trainingDate
    lastUpdated
    userVisits
    lastUserVisit
    projects {
      ...Project
    }
    settings {
      dataVetterAccess {
        projects
        speciesTypes
      }
    }
  }
  ${ProjectFragment}
`

export const ObservationDisplayFragment = gql`
  fragment ObservationDisplay on ObservationDisplay {
    _id
    activity
    age
    comment
    county
    created
    creator
    dataVerificationNote
    firstName
    lastName
    entry
    friendlyId
    gameCamera {
      _id
      name
      notes
      utmEasting
      utmNorthing
      utmZone
      longitude
      latitude
    }
    lastUpdated
    latitude
    longitude
    mortality
    obsDateTime
    obsPeriod
    photos
    project {
      ...Project
    }
    quantity
    sex
    status {
      ...CodeItem
    }
    species
    speciesType
    updatedBy
    utmEasting
    utmNorthing
    utmZone
    verified
    verifiedBy
    wosExportDate
    nestbox {
      ...Nestbox
    }
    nestMaterial
    onTour
    eggs
    fledglings
    deadNestlings
    nestlings
    unhatchedEggs
    trailSegment
    beaverSite {
      _id
      siteId
      name
      utmEasting
      utmNorthing
      utmZone
      longitude
      latitude
    }
    currentActivities {
      _id
      description
      timing
    }
    recentActivities {
      _id
      description
      timing
    }
    pastActivities {
      _id
      description
      timing
    }
    noActivity
  }
  ${CodeItemFragment}
  ${NestboxFragment}
  ${ProjectFragment}
`

export const ObservationTableRowFragment = gql`
  fragment ObservationTableRow on ObservationTableRow {
    _id
    activity
    age
    comment
    county
    created
    creator
    firstName
    lastName
    entry
    friendlyId
    gameCamera {
      _id
      name
      notes
      utmEasting
      utmNorthing
      utmZone
      longitude
      latitude
    }
    lastUpdated
    latitude
    longitude
    mortality
    obsDateTime
    obsPeriod
    photos
    project
    quantity
    sex
    status
    species
    speciesType
    updatedBy
    utmEasting
    utmNorthing
    utmZone
    verified
    verifiedBy
    wosExportDate
    nestbox {
      ...Nestbox
    }
    nestMaterial
    onTour
    eggs
    fledglings
    deadNestlings
    nestlings
    unhatchedEggs
    trailSegment
    beaverSite {
      _id
      siteId
      name
      utmEasting
      utmNorthing
      utmZone
      longitude
      latitude
    }
    currentActivities {
      _id
      description
      timing
    }
    recentActivities {
      _id
      description
      timing
    }
    pastActivities {
      _id
      description
      timing
    }
    noActivity
  }
  ${NestboxFragment}
`

export const FormTypesFragment = gql`
  fragment FormTypes on FormTypes {
    activities {
      ...CodeItem
    }
    ages {
      ...CodeItem
    }
    mortality {
      ...CodeItem
    }
    observationPeriods {
      ...CodeItem
    }
    observationStatuses {
      ...CodeItem
    }
    sexes {
      ...CodeItem
    }
    speciesTypes {
      ...SpeciesType
    }
    species {
      ...Species
    }
    speciesByType
    states {
      ...State
    }
    trailSegments {
      ...CodeItem
    }
    nestboxes {
      ...Nestbox
    }
    gameCameras {
      _id
      name
      notes
      utmEasting
      utmNorthing
      utmZone
      longitude
      latitude
    }
    beaverSites {
      _id
      siteId
      name
      utmEasting
      utmNorthing
      utmZone
      longitude
      latitude
    }
    beaverActivities {
      _id
      description
      timing
    }
  }
  ${CodeItemFragment}
  ${NestboxFragment}
  ${StateFragment}
  ${SpeciesFragment}
  ${SpeciesTypeFragment}
`

export const TicketFragment = gql`
  fragment Ticket on Ticket {
    _id
    created
    user {
      ...User
    }
    url
    input {
      project
      mortality
      status
      species
      speciesType
      startDate
      endDate
    }
    error
    type
  }
  ${UserFragment}
`

export default {
  ObservationFragment,
  ObservationEntryFragment,
  ObservationDisplayFragment,
  UserFragment,
  ProjectFragment,
  CodeItemFragment,
  SpeciesFragment,
  SpeciesTypeFragment,
  FormTypesFragment,
  StateFragment,
  TicketFragment,
}
