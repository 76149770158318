import React, { useEffect, useState } from 'react'
import { Auth } from 'aws-amplify'
import { Link, useLocation } from 'react-router-dom'
import { connect } from 'react-redux'
import queryString from 'qs'
import {
  TextField,
  Typography,
  withStyles,
  InputLabel,
  Button,
  Divider,
} from '@material-ui/core'
import CheckCircleOutline from '@material-ui/icons/CheckCircleOutline'
import LoaderButton from '../../components/buttons/LoaderButton'
import PasswordField from '../../components/forms/PasswordField'
import IonicPageLayout from '../../components/siteLayout/IonicPageLayout'

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    margin: '40px auto',
    maxWidth: 600,
  },
  success: {
    textAlign: 'center',
  },
  checkIcon: {
    color: theme.palette.primary.main,
  },
  textField: {
    marginTop: theme.spacing(),
    marginBottom: theme.spacing(),
  },
  button: {
    marginTop: theme.spacing(),
  },
  spacing: {
    margin: '2rem 0',
  },
})

const ResetPassword = (props) => {
  const { classes, resetPasswordEmail } = props
  const location = useLocation()

  const [code, setCode] = useState('')
  const [email, setEmail] = useState(
    resetPasswordEmail ? resetPasswordEmail : ''
  )
  const [password, setPassword] = useState('')
  const [confirmed, setConfirmed] = useState(false)
  const [confirmPassword, setConfirmPassword] = useState('')
  const [isConfirming, setIsConfirming] = useState(false)

  useEffect(() => {
    // Get the query strings
    const parsedQueryString = queryString.parse(location.search, {
      ignoreQueryPrefix: true,
    })

    if (parsedQueryString.email) {
      setEmail(parsedQueryString.email.toLowerCase().trim())
    }
  }, [])

  const handleChange = (e) => {
    switch (e.target.id) {
      case 'email':
        setEmail(e.target.value)
        break
      case 'code':
        setCode(e.target.value)
        break
      case 'password':
        setPassword(e.target.value)
        break
      case 'confirmPassword':
        setConfirmPassword(e.target.value)
        break
      default:
        break
    }
  }

  const handleConfirmClick = async (e) => {
    e.preventDefault()

    setIsConfirming(true)

    // check code is right on email in NM db, new endpoint
    // if code is right, create new cognito user with email and password and attach to NM db user
    // if code is wrong, alert user with helpful error

    try {
      await Auth.forgotPasswordSubmit(email.toLowerCase(), code, password)
      setConfirmed(true)
    } catch (e) {
      alert(e.message)
      setIsConfirming(false)
    }
  }

  const validateResetForm = () => {
    return (
      email.length > 0 &&
      code.length > 0 &&
      password.length > 0 &&
      password === confirmPassword
    )
  }

  const renderConfirmationForm = () => {
    return (
      <form onSubmit={handleConfirmClick}>
        <InputLabel>Email</InputLabel>
        <TextField
          autoComplete="email"
          autoFocus
          className={classes.textField}
          id="email"
          onChange={handleChange}
          placeholder="Email"
          type="email"
          value={email}
          variant="outlined"
        />
        <InputLabel>Enter the verification code we sent you</InputLabel>
        <TextField
          autoComplete="one-time-code"
          autoFocus
          className={classes.textField}
          helperText={`Please check your email for the confirmation code. Remember to check your spam folder too.`}
          id="code"
          onChange={handleChange}
          placeholder="Code"
          type="tel"
          value={code}
          variant="outlined"
        />
        <Divider className={classes.spacing} />
        <InputLabel>Set new password</InputLabel>
        <PasswordField
          className={classes.textField}
          helperText={`Passwords must be at least 8 characters.`}
          id="password"
          label="New Password"
          onIonChange={handleChange}
          value={password}
          variant="outlined"
        />
        <PasswordField
          className={classes.textField}
          id="confirmPassword"
          label="Confirm New Password"
          onIonChange={handleChange}
          value={confirmPassword}
          variant="outlined"
        />
        <LoaderButton
          className={classes.button}
          color="primary"
          disabled={!validateResetForm()}
          isLoading={isConfirming}
          loadingText="Submitting..."
          text="Submit"
          type="submit"
          variant="contained"
        />
      </form>
    )
  }

  const renderSuccessMessage = () => {
    return (
      <div className={classes.success}>
        <CheckCircleOutline className={classes.checkIcon} />
        <Typography gutterBottom variant="body1">
          Your password has been reset.
        </Typography>
        <Button component={Link} to="/" variant="contained">
          Login with your new credentials
        </Button>
      </div>
    )
  }

  return (
    <IonicPageLayout>
      <div className={classes.root}>
        {!confirmed ? renderConfirmationForm() : renderSuccessMessage()}
      </div>
    </IonicPageLayout>
  )
}

const mapStateToProps = ({ auth }) => ({
  resetPasswordEmail: auth.resetPasswordEmail,
})

export default connect(mapStateToProps, null)(withStyles(styles)(ResetPassword))
