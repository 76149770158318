import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import {
  dataVetterAccess,
  online,
  projects,
  speciesTypes,
} from '../../selectors'
import OfflineWarning from '../../components/micros/OfflineWarning'
import DataVerificationTabs from '../../components/menus/DataVerificationTabs'
import IonicPageLayout from '../../components/siteLayout/IonicPageLayout'

const styles = (theme) => ({
  table: {
    margin: '1rem 0',
  },
  accessList: {
    marginBottom: '1rem',
  },
  accessGrid: {
    display: 'grid',
    gridGap: '1rem',
    gridTemplateColumns: '1fr 1fr',
  },
  stats: {
    margin: '1rem 0',
  },
  statsGrid: {
    display: 'grid',
    gridGap: '1rem',
    gridTemplateColumns: '1fr 1fr 1fr',
  },
  caption: {
    maxWidth: 550,
  },
})

const DataVerification = (props) => {
  const { access, classes, online, projects, speciesTypes } = props

  const renderProjectAccess = () => {
    return (
      <div className={classes.accessList}>
        <Typography variant="h6">Projects</Typography>
        {access.projects && access.projects.length ? (
          access.projects.map((id) => (
            <Typography key={id} variant="body2">
              {projects.find((project) => project._id === id).name}
            </Typography>
          ))
        ) : (
          <Typography variant="body2">None</Typography>
        )}
      </div>
    )
  }

  const renderSpeciesTypeAccess = () => {
    return (
      <div className={classes.accessList}>
        <Typography variant="h6">Wildlife Types</Typography>
        {access.speciesTypes && access.speciesTypes.length ? (
          access.speciesTypes.map((id) => (
            <Typography key={id} variant="body2">
              {speciesTypes.find((type) => type._id === id).name}
            </Typography>
          ))
        ) : (
          <Typography variant="body2">None</Typography>
        )}
      </div>
    )
  }

  const AccessPanel = () => {
    return (
      <Accordion>
        <AccordionSummary
          aria-controls="access-panel-content"
          expandIcon={<ExpandMoreIcon />}
          id="access-panel-header"
        >
          <Typography>
            {`You have access to ${
              access.projects ? access.projects.length : 0
            } projects and ${
              access.speciesTypes ? access.speciesTypes.length : 0
            } wildlife types`}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography gutterBottom>
            You have access to verify observations for the following:
          </Typography>
          <div className={classes.accessGrid}>
            {renderProjectAccess()}
            {renderSpeciesTypeAccess()}
          </div>
        </AccordionDetails>
      </Accordion>
    )
  }

  return (
    <IonicPageLayout>
      <Typography gutterBottom variant="h4">Data Verification</Typography>
      {online ? (
        <Fragment>
          <Typography className={classes.caption} gutterBottom>
            Explore the observations outstanding to be verified and the
            observations you've previously taken action on. These counts only
            reflect on data you have access to.
          </Typography>
          <AccessPanel />
          <div className={classes.table}>
            {access ? (
              <DataVerificationTabs />
            ) : (
              <Typography>
                It appears you have not been given specific data vetting access
                yet. This means an administrator has not given you access to
                verify observations from a specific project or for a specific
                wildlife type yet.
              </Typography>
            )}
          </div>
        </Fragment>
      ) : (
        <OfflineWarning />
      )}
    </IonicPageLayout>
  )
}

function mapStateToProps(state) {
  return {
    access: dataVetterAccess(state),
    online: online(state),
    projects: projects(state),
    speciesTypes: speciesTypes(state),
  }
}

export default withStyles(styles)(connect(mapStateToProps)(DataVerification))
