/* eslint-disable prefer-destructuring */
/* eslint-disable no-undef */
import React, { useState } from 'react'
import { connect } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { withStyles } from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress'
import Typography from '@material-ui/core/Typography'
import { Geolocation } from '@capacitor/geolocation'
import { MyLocationOutlined, ErrorOutlined } from '@material-ui/icons'
import UtmConverter from 'utm-latlng'
import proj4 from 'proj4'
import config from '../../../config'
import LabelValue from '../../../components/text/LabelValue'
import MapWithAMarker from '../../../components/maps/MapWithAMarker'
import actions from '../../../actions'
import { IonButton, useIonViewDidEnter } from '@ionic/react'
import { safeWrap } from '../../../helpers/utils'
import { Capacitor } from '@capacitor/core'

const styles = (theme) => ({
  root: {
    marginTop: '0.4rem',
  },
  myLocationButton: {
    marginBottom: '1rem',
  },
  myLocationIcon: {
    marginRight: '0.8rem',
  },
  locationInfo: {
    margin: '1rem 0',
  },
  utmFields: {
    display: 'flex',
    alignItems: 'end',
    justifyContent: 'center',

    '& > *': {
      margin: '1rem 0.5rem',
    },
  },
  latlonFields: {
    display: 'flex',
    alignItems: 'end',
    justifyContent: 'center',

    '& > *': {
      margin: '1rem 0.5rem',
    },
  },
  locationError: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '1.5rem',

    '& p': {
      margin: '0 1rem',
    },
  },
  logs: {
    flexDirection: 'column',
    padding: '8px 30px 30px',
  },
})

const utmConvert = new UtmConverter()

const UtmWidget = ({
  classes,
  id,
  onChange,
  reviewData,
  clearLogs,
  locationLogs,
  writeLocationLog,
  setReviewData,
  showFlashMessage,
}) => {
  const [gettingLocation, setGettingLocation] = useState(false)
  const [deviceLocationError, setDeviceLocationError] = useState(null)
  const { lat, lng, utmEasting, utmNorthing, utmZone } = JSON.parse(
    reviewData.location
  )
  const location = useLocation()

  // eslint-disable-next-line prefer-destructuring
  const projectKey = location.pathname.split('/')[3]
  const isFixedLocation = config.fixedLocationProjects.includes(projectKey)

  useIonViewDidEnter(() => {
    clearLogs()
  })

  const latChange = (e) => {
    let utm = null

    if (lat && lng) utm = utmConvert.convertLatLngToUtm(e.target.value, lng, 4)

    const location = {
      lat: parseFloat(e.target.value),
      lng,
    }

    if (utm) {
      location.utmEasting = utm.Easting
      location.utmNorthing = utm.Northing
      location.utmZone = utm.ZoneNumber
    }

    // form schema expects a string
    onChange(JSON.stringify(location))
  }

  const lngChange = (e) => {
    let utm = null

    if (lat && lng) utm = utmConvert.convertLatLngToUtm(lat, e.target.value, 4)

    const location = {
      lat,
      lng: parseFloat(e.target.value),
    }

    if (utm) {
      location.utmEasting = utm.Easting
      location.utmNorthing = utm.Northing
      location.utmZone = utm.ZoneNumber
    }

    // form schema expects a string
    onChange(JSON.stringify(location))
  }

  const utmEastingChange = async (e) => {
    const utmEasting = parseFloat(e.target.value)

    if (utmNorthing && utmZone) {
      const utm = `+proj=utm +zone=${utmZone}`
      const wgs84 = '+proj=longlat +ellps=GRS80 +datum=NAD83 +no_defs'

      const conversion = await proj4(utm, wgs84, [utmEasting, utmNorthing])

      const location = {
        lat:
          parseFloat(conversion[1]) &&
          parseFloat(parseFloat(conversion[1]).toFixed(8)),
        lng:
          parseFloat(conversion[0]) &&
          parseFloat(parseFloat(conversion[0]).toFixed(8)),
        utmEasting,
        utmNorthing,
        utmZone,
      }

      // form schema expects a string
      onChange(JSON.stringify(location))
    }
  }

  const utmNorthingChange = async (e) => {
    const utmNorthing = parseFloat(e.target.value)

    if (utmEasting && utmZone) {
      const utm = `+proj=utm +zone=${utmZone}`
      const wgs84 = '+proj=longlat +ellps=GRS80 +datum=NAD83 +no_defs'

      const conversion = await proj4(utm, wgs84, [utmEasting, utmNorthing])

      const location = {
        lat: parseFloat(conversion[1]),
        lng: parseFloat(conversion[0]),
        utmEasting,
        utmNorthing,
        utmZone,
      }

      // form schema expects a string
      onChange(JSON.stringify(location))
    }
  }

  const utmZoneChange = async (e) => {
    const utmZone = parseFloat(e.target.value)

    if (utmNorthing && utmEasting) {
      const utm = `+proj=utm +zone=${utmZone}`
      const wgs84 = '+proj=longlat +ellps=GRS80 +datum=NAD83 +no_defs'

      const conversion = await proj4(utm, wgs84, [utmEasting, utmNorthing])

      const location = {
        lat: parseFloat(conversion[1]),
        lng: parseFloat(conversion[0]),
        utmEasting,
        utmNorthing,
        utmZone,
      }

      // form schema expects a string
      onChange(JSON.stringify(location))
    }
  }

  const handleClick = (e) => {
    const utm = utmConvert.convertLatLngToUtm(e.latLng.lat(), e.latLng.lng(), 4)

    const location = {
      lat: e.latLng.lat(),
      lng: e.latLng.lng(),
      utmEasting: utm.Easting,
      utmNorthing: utm.Northing,
      utmZone: utm.ZoneNumber,
    }

    // form schema expects a string
    onChange(JSON.stringify(location))
  }

  const updateLocation = async () => {
    setGettingLocation(true)
    setDeviceLocationError(null)
    clearLogs()

    const success = (position) => {
      writeLocationLog(
        `Attempting to get current postion using position ${JSON.stringify(
          position,
          null,
          2
        )}`
      )
      const utm = utmConvert.convertLatLngToUtm(
        position.coords.latitude,
        position.coords.longitude,
        4
      )
      writeLocationLog(
        `position converted to UTM ${JSON.stringify(utm, null, 2)}`
      )

      const location = {
        lat: position.coords.latitude,
        lng: position.coords.longitude,
        utmEasting: utm.Easting,
        utmNorthing: utm.Northing,
        utmZone: utm.ZoneNumber,
      }

      writeLocationLog(`location created ${JSON.stringify(location, null, 2)}`)
      writeLocationLog('Setting state location')

      writeLocationLog('Setting form schema location')
      // form schema expects a string
      console.log('location', location)
      onChange(JSON.stringify(location))
      setReviewData({
        ...reviewData,
        location: JSON.stringify(location),
      })
      setGettingLocation(false)
      showFlashMessage('Location updated', 'success', 2000)
    }

    const error = (err) => {
      setGettingLocation(false)
      setDeviceLocationError(
        `ERROR${err.statusCode ? ` (${err.statusCode})` : ''}: ${err.message}`
      )
      writeLocationLog('Get device location failed')
      writeLocationLog(
        `ERROR${err.statusCode ? ` (${err.statusCode})` : ''}: ${err.message}`
      )
    }

    writeLocationLog('Attempting to use device location')

    try {
      try {
        if (Capacitor.getPlatform() === 'web' && navigator.geolocation) {
          writeLocationLog('Attempting to get current position using web')
          const options = {
            enableHighAccuracy: true,
            timeout: 20000,
            maximumAge: 3000,
          }
          navigator.geolocation.getCurrentPosition(success, error, options)
        } else {
          const coordinates = await Geolocation.getCurrentPosition()
          success(coordinates)
        }
      } catch (e) {
        error(e)
        writeLocationLog(
          'Location services are not available at the moment. This could be temporary due to latency issues or because location permissions have not been granted.'
        )
        alert(
          'Location services are not available at the moment. This could be temporary due to latency issues or because location permissions have not been granted.'
        )
        setGettingLocation(false)
      }
    } catch (err) {
      writeLocationLog(
        `Use location function ended in catch: ERROR${err.statusCode ? ` (${err.statusCode})` : ''}: ${err.message}`
      )
      console.error(err)
      setGettingLocation(false)
    }
  }

  return (
    <div className={classes.root}>
      <Typography className="control-label" component="label" variant="inherit">
        Location
      </Typography>
      {!isFixedLocation && (
        <>
          <Typography>To update the observation location below:</Typography>
          <ul>
            <li>
              <Typography>click directly on the map</Typography>
            </li>
            <li>
              <Typography>
                click the update your location button to get your current GPS
                coordinates
              </Typography>
            </li>
            <li>
              <Typography>
                or enter the values manually in the input boxes below the map
                for coordinates or UTMs
              </Typography>
            </li>
          </ul>
        </>
      )}
      {deviceLocationError && (
        <div className={classes.locationError}>
          <ErrorOutlined color="error" />
          <div>
            <Typography>
              Not able to successfully get location data from your device.
            </Typography>
            <Typography>
              Please check your location sharing settings on your device.
            </Typography>
            <Typography>{deviceLocationError}</Typography>
          </div>
        </div>
      )}

      {!isFixedLocation && (
        <div className={classes.myLocationButton}>
          <IonButton
            disabled={gettingLocation}
            onClick={updateLocation}
            type="button"
          >
            {gettingLocation ? (
              <CircularProgress className={classes.myLocationIcon} size={20} />
            ) : (
              <MyLocationOutlined className={classes.myLocationIcon} />
            )}
            Update your location
          </IonButton>
        </div>
      )}

      <MapWithAMarker
        center={{ lat, lng }}
        id={id}
        lat={lat}
        lng={lng}
        onClick={!isFixedLocation ? handleClick : null}
      />

      <section className={classes.locationInfo}>
        {isFixedLocation ? (
          <Typography>
            This project uses your account address by default and can only be
            changed in your account settings.
          </Typography>
        ) : (
          <Typography>
            If entering the location manually, you can enter either the latitude
            and longitude or the UTMs.
          </Typography>
        )}
        <div className={classes.locationFields}>
          <div className={classes.latlonFields}>
            <LabelValue
              disabled={isFixedLocation}
              editable
              inputmode="numeric"
              label="Latitude"
              onBlur={latChange}
              step="any"
              type="number"
              value={lat}
            />
            <LabelValue
              disabled={isFixedLocation}
              editable
              inputmode="numeric"
              label="Longitude"
              onBlur={lngChange}
              step="any"
              type="number"
              value={lng}
            />
          </div>
          <div className={classes.utmFields}>
            <LabelValue
              disabled={isFixedLocation}
              editable
              inputmode="numeric"
              label="UTM Easting"
              onBlur={utmEastingChange}
              step="any"
              type="number"
              value={utmEasting}
            />
            <LabelValue
              disabled={isFixedLocation}
              editable
              inputmode="numeric"
              label="UTM Northing"
              onBlur={utmNorthingChange}
              step="any"
              type="number"
              value={utmNorthing}
            />
          </div>
          <div className={classes.utmFields}>
            <LabelValue
              disabled={isFixedLocation}
              editable
              inputmode="numeric"
              label="UTM Zone"
              onBlur={utmZoneChange}
              type="number"
              value={utmZone}
            />
          </div>
        </div>
      </section>

      {/* {locationLogs.length > 0 && (
        <Accordion>
          <AccordionSummary
            aria-controls="panel1a-content"
            expandIcon={<ExpandMore />}
            id="panel1a-header"
          >
            <Typography>Developer Logs</Typography>
          </AccordionSummary>
          <AccordionDetails className={classes.logs}>
            {locationLogs.map((log, i) => (
              <Typography gutterBottom key={`log${i}`}>
                {log}
              </Typography>
            ))}
          </AccordionDetails>
        </Accordion>
      )} */}
    </div>
  )
}

const mapStateToProps = ({ form, user, layout }) => {
  return {
    user,
    reviewData: form.data,
    locationLogs: layout.location.logs || [],
  }
}

const mapDispatchToProps = {
  clearLogs: actions.clearLogs,
  writeLocationLog: actions.writeLocationLog,
  setReviewData: actions.setReviewData,
  showFlashMessage: actions.showFlashMessage,
}

export default safeWrap(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(UtmWidget))
)
