import React, { useEffect, useRef } from 'react'
import {
  IonTabs,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonIcon,
  IonLabel
} from '@ionic/react'
import { Route } from 'react-router'
import { useLocation } from 'react-router-dom'
import {
  settingsOutline,
  addCircleOutline,
  personOutline,
} from 'ionicons/icons'
import Home from './Home'
import Settings from './Settings'
import SelectProject from './SelectProject'
import ObservationVerificationView from './observations/ObservationVerification.page'
import ViewObservationPage from '../pages/observations/ViewObservation.page'

const MainTabs: React.FC<any> = (props) => {
  const location = useLocation()
  const [selectedTab, setSelectedTab] = React.useState('me')
  const tabBarRef: any = useRef()

  useEffect(() => {
    if (location.pathname.includes('/dashboard/projects')) {
      setSelectedTab('projects')
      tabBarRef.current?.select('projects')
    } else if (location.pathname.includes('/dashboard/me')) {
      setSelectedTab('me')
      tabBarRef.current?.select('me')
    } else if (location.pathname.includes('/dashboard/settings')) {
      setSelectedTab('settings')
      tabBarRef.current?.select('settings')
    }
  }, [location.pathname])

  if (!location.pathname.includes('dashboard')) {
    return null
  }

  return (
    <IonTabs>
      <IonRouterOutlet>
        <Route
          exact
          path={`/dashboard/me`}
          render={() => <Home {...props}/>}
        />
        <Route
          exact
          path="/dashboard/me/verify/:tableKey/:id"
          render={() => <ObservationVerificationView {...props}/>}
        />
        <Route
          exact
          path="/dashboard/me/view/:id"
          render={() => <ViewObservationPage />}
        />
        <Route
          exact
          path="/dashboard/projects"
          render={() => <SelectProject {...props}/>}
        />
        <Route
          exact
          path={`/dashboard/settings`}
          render={() => <Settings {...props}/>}
        />
      </IonRouterOutlet>
      <IonTabBar slot="bottom">
        <IonTabButton
          href="/dashboard/projects"
          selected={selectedTab === 'projects'}
          tab="projects"
        >
          <IonIcon icon={addCircleOutline} />
          <IonLabel>Add Observation</IonLabel>
        </IonTabButton>
        <IonTabButton
          href="/dashboard/me"
          selected={selectedTab === 'me'}
          tab="me"
        >
          <IonIcon icon={personOutline} />
          <IonLabel>My NM</IonLabel>
        </IonTabButton>
        {props.isAuthenticated && (
          <IonTabButton
            href="/dashboard/settings"
            selected={selectedTab === 'settings'}
            tab="settings"
          >
            <IonIcon icon={settingsOutline} />
            <IonLabel>Settings</IonLabel>
          </IonTabButton>
        )}
      </IonTabBar>
    </IonTabs>
  )
}

export default MainTabs
