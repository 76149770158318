import { DateTime } from 'luxon'

const createSchema = (formTypes) => ({
  steps: [
    {
      type: 'object',
      required: ['dateTime', 'beaverSite'],
      properties: {
        beaverSite: {
          title: 'What tributary site is this for?',
          type: 'string',
          enum: formTypes ? formTypes.beaverSites.map((site) => site._id): [],
          enumNames: formTypes
            ? formTypes.beaverSites.map(
                (site) => `Site ${site.siteId}: ${site.name}`,
              )
            : [],
          placeholder: 'Select a site',
        },
        location: {},
        dateTime: {
          title: 'When was this observation made?',
          type: 'string',
          format: 'date-time',
          default: DateTime.local().toISO(),
        },
        currentActivities: {
          type: 'array',
          title: 'Current Activities (0-6 months)',
          items: {
            type: 'string',
            enum: formTypes
              ? formTypes.beaverActivities
                  .filter((activity) => activity.timing.includes('CURRENT'))
                  .map((activity) => activity._id)
              : [],
            enumNames: formTypes
              ? formTypes.beaverActivities
                  .filter((activity) => activity.timing.includes('CURRENT'))
                  .map((activity) => activity.description)
              : [],
          },
          uniqueItems: true,
        },
        recentActivities: {
          type: 'array',
          title: 'Recent Activities (6 months - 2 years)',
          items: {
            type: 'string',
            enum: formTypes
              ? formTypes.beaverActivities
                  .filter((activity) => activity.timing.includes('RECENT'))
                  .map((activity) => activity._id)
              : [],
            enumNames: formTypes
              ? formTypes.beaverActivities
                  .filter((activity) => activity.timing.includes('RECENT'))
                  .map((activity) => activity.description)
              : [],
          },
          uniqueItems: true,
        },
        pastActivities: {
          type: 'array',
          title: 'Past Activities (>2 years)',
          items: {
            type: 'string',
            enum: formTypes
              ? formTypes.beaverActivities
                  .filter((activity) => activity.timing.includes('PAST'))
                  .map((activity) => activity._id)
              : [],
            enumNames: formTypes
              ? formTypes.beaverActivities
                  .filter((activity) => activity.timing.includes('PAST'))
                  .map((activity) => activity.description)
              : [],
          },
          uniqueItems: true,
        },
        noActivity: {
          type: 'boolean',
          title: 'No Activity',
        },
        comment: {
          type: 'string',
          title: 'Comments',
        },
      },
      dependencies: {
        beaverSite: {
          oneOf: [
            {
              properties: {
                beaverSite: {
                  enum: formTypes.beaverSites.filter((site) => site.siteId !== 0).map((site) => site._id)
                }
              }
            },
            {
              properties: {
                beaverSite: {
                  enum: [
                    formTypes.beaverSites.find((site) => site.siteId === 0)._id,
                  ]
                },
                location: {
                  title: 'Where was this?',
                  type: 'string',
                },
              },
              required: [
                'location'
              ]
            },
  ]}
        }
    },
    {
      type: 'object',
      properties: {
        photos: {
          type: 'string',
          title: 'Photos',
        },
      },
    },
  ],
})

const uiSchema = {
  dateTime: {
    'ui:widget': 'DateTimeWidget',
    'ui:options': {
      placeholder: 'Select date and time',
    },
  },
  currentActivities: {
    'ui:widget': 'checkboxes',
  },
  recentActivities: {
    'ui:widget': 'checkboxes',
  },
  pastActivities: {
    'ui:widget': 'checkboxes',
  },
  noActivity: {
    'ui:widget': 'checkbox',
  },
  comment: {
    'ui:options': {
      rows: 4,
      resize: false,
      placeholder: 'Any notes about the observation?',
    },
    'ui:widget': 'textarea',
  },
  location: {
    'ui:widget': 'UtmWidget',
  },
  photos: {
    'ui:widget': 'PhotoWidget',
  },
}

export default {
  createSchema,
  uiSchema,
}
