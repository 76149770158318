import { combineReducers } from 'redux'
import adminReducer from './admin.reducer'
import authReducer from './auth.reducer'
import formReducer from './form.reducer'
import exportsReducer from './exports.reducer'
import layoutReducer from './layout.reducer'
import observationsReducer from './observations.reducer'
import draftObservationsReducer from './drafts.reducer'
import projectsReducer from './projects.reducer'
import tableDataReducer from './tableData.reducer'
import userReducer from './user.reducer'
import { UNAUTH_USER, CLEAR_APPLICATION_STATE } from '../types/action.types'

const appReducer = combineReducers({
  admin: adminReducer,
  auth: authReducer,
  dataExports: exportsReducer,
  form: formReducer,
  layout: layoutReducer,
  observations: observationsReducer,
  drafts: draftObservationsReducer,
  projects: projectsReducer,
  tableData: tableDataReducer,
  user: userReducer,
})

const rootReducer = (state, action) => {
  if (action.type === UNAUTH_USER || action.type === CLEAR_APPLICATION_STATE) {
    // eslint-disable-next-line no-undef
    localStorage.removeItem('state')

    return appReducer({
      layout: {
        ...state.layout,
        showModal: false,
      },
      projects: state.projects,
      user: {
        projects: state.user.projects,
      },
      form: {
        types: state.form.types
      },
      drafts: state.drafts,
    }, action)
  }

  return appReducer(state, action)
}

export default rootReducer
