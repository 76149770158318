import React, { useState } from 'react'
import { connect } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { Formik, Field } from 'formik'
import { DateTime } from 'luxon'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import InputLabel from '@material-ui/core/InputLabel'
import IconButton from '@material-ui/core/IconButton'
import EditIcon from '@material-ui/icons/EditOutlined'
import CloseIcon from '@material-ui/icons/CloseOutlined'
import DeleteIcon from '@material-ui/icons/DeleteOutlined'
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeftOutlined'
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRightOutlined'
import { motion, AnimatePresence } from 'framer-motion'
import DisplayObservationLocation from '../../components/maps/DisplayObservationLocation'
import Loading from '../../components/micros/Loading'
import actions from '../../actions'
import LoaderButton from '../../components/buttons/LoaderButton'
import NatureMappingSelect from '../../components/forms/renderSelect'
import ObservationDetails from '../../components/observations/ObservationDetails'
import { getCodeItemOptions } from '../../helpers/dropdownOptions'
import IonicPageLayout from '../../components/siteLayout/IonicPageLayout'
import {
  IonButton,
  useIonAlert,
  useIonViewDidEnter,
  useIonViewWillLeave,
} from '@ionic/react'

const styles = (theme) => ({
  actions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    paddingBottom: '1rem',

    '& > *': {
      paddingTop: '0.5rem',
    },
  },
  fieldWrapper: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    flexWrap: 'wrap',
    paddingBottom: '0.5rem',

    '& > button': {
      marginTop: '0.5rem',
      marginLeft: '0.6rem',
    },
  },
  headingArea: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    marginBottom: '0.5rem',
  },
  rightButtons: {
    width: '100%',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',

    '& svg': {
      marginRight: '0.6rem',
    },

    '& > button:last-of-type': {
      marginLeft: '1rem',
    },
  },
  addNoteHeader: {
    alignItems: 'center',
    display: 'flex',
  },
  textArea: {
    backgroundColor: 'initial',
    marginTop: '0.5rem',
    minHeight: 80,
    minWidth: 300,
  },
  addNoteWrapper: {
    padding: '0 1rem',
    maxWidth: 300,
    margin: '0.5rem auto 0',
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: 4,
  },
  statusWrapper: {
    marginTop: '1rem',
    width: '100%',
  },
})

export const ObservationVerificationView = (props) => {
  const {
    classes,
    clearReviewData,
    observation,
    statuses,
    user,
    getObservation,
    getDataVetterObservationsByStatus,
    setCurrentVerificationIndex,
    clearCurrentObservation,
    deleteObservation,
    updateDataVerificationNote,
    updateObservationStatus,
    updatePersistTableDataOnStart,
    fullTableData,
  } = props
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(true)
  const [editNote, setEditNote] = useState(false)
  const [animationDirection] = useState(null)

  const { tableKey, id } = useParams()
  const history = useHistory()
  const [presentAlert] = useIonAlert()

  const tableData = fullTableData[tableKey] || {}

  useIonViewDidEnter(() => {
    updatePersistTableDataOnStart(false) // we set to true on navigation away to edit page, remove on return to this page
    getObservation(id)
      .then(() => {
        setLoading(false)
      })
      .catch((e) => {
        setError(e.errors[0])
        setLoading(false)
      })
  }, [tableKey, id])

  useIonViewWillLeave(() => {
    clearReviewData()
    clearCurrentObservation()
  })

  const handleUpdateStatus = (value, actions) => {
    updateObservationStatus(observation._id, value)
      .then(actions.setSubmitting(false))
      .catch(actions.setSubmitting(false))
  }

  const checkChangeObservationStatus = () => {
    if (!user.settings.dataVetterAccess) return false

    const projectAccess = user.settings.dataVetterAccess.projects
    const speciesTypesAccess = user.settings.dataVetterAccess.speciesTypes
    if (
      !projectAccess.includes(observation.project && observation.project._id) ||
      (!speciesTypesAccess.includes(
        observation.speciesType && observation.speciesType._id
      ) &&
        observation.speciesType !== null)
    ) {
      return false
    }

    if (
      observation.verified &&
      DateTime.fromISO(observation.verified).diffNow('day').days > -7
    )
      return true
    if (observation.status && observation.status.code === 'V') return false

    return true
  }

  const handleDeleteObservation = () => {
    return deleteObservation(observation._id).then(() =>
      history.push('/verification')
    )
  }

  const previousObservation = () => {
    setLoading(true)
    // if at the beginning of the array and more pages left, fetch that data and display that previous observation, disabled button if on first page last obs
    if (tableData.pageIndex > 0 && tableData.currentIndex === 0) {
      return getDataVetterObservationsByStatus({
        tableKey,
        tableState: {
          ...tableData,
          pageIndex: tableData.pageIndex - 1,
        },
      }).then(() => {
        setCurrentVerificationIndex(tableKey, tableData.pageSize - 1)
        getObservation(tableData.rows[tableData.pageSize - 1]._id)
        setLoading(false)
        return history.push(
          `/verification/${tableKey}/${
            tableData.rows[tableData.pageSize - 1]._id
          }`
        )
      })
    } else {
      setCurrentVerificationIndex(tableKey, tableData.currentIndex - 1)
      getObservation(tableData.rows[tableData.currentIndex - 1]._id)
      setLoading(false)
      return history.push(
        `/verification/${tableKey}/${
          tableData.rows[tableData.currentIndex - 1]._id
        }`
      )
    }
  }

  const nextObservation = () => {
    setLoading(true)
    // if at the end and there is more pages left, call to get the next page and then display the next observation, disable button if last obs
    if (
      tableData.pageIndex !== Math.ceil(tableData.total / tableData.pageSize) &&
      tableData.currentIndex === tableData.pageSize - 1
    ) {
      return getDataVetterObservationsByStatus({
        tableKey,
        tableState: {
          ...tableData,
          pageIndex: tableData.pageIndex + 1,
        },
      }).then(() => {
        setCurrentVerificationIndex(tableKey, 0)
        getObservation(tableData.rows[0]._id)
        setLoading(false)
        return history.push(
          `/verification/${tableKey}/${tableData.rows[0]._id}`
        )
      })
    } else {
      setCurrentVerificationIndex(tableKey, tableData.currentIndex + 1)
      getObservation(tableData.rows[tableData.currentIndex + 1]._id)
      setLoading(false)
      return history.push(
        `/verification/${tableKey}/${
          tableData.rows[tableData.currentIndex + 1]._id
        }`
      )
    }
  }

  const handleUpdateDataVerificationNote = (values) => {
    updateDataVerificationNote(observation._id, values.dataVerificationNote)
      .then(() => setEditNote(false))
      .catch(() => setEditNote(false))
  }

  const renderDataVetterNotePanel = () => {
    if (!editNote) {
      return (
        <div className={classes.addNoteWrapper}>
          <div className={classes.addNoteHeader}>
            <InputLabel>
              {observation.dataVerificationNote
                ? 'Data Verification Note'
                : 'Add a Data Verification Note'}
            </InputLabel>
            <IconButton
              aria-label="edit"
              color="primary"
              onClick={() => setEditNote(!editNote)}
            >
              <EditIcon />
            </IconButton>
          </div>
          <Typography>{observation.dataVerificationNote}</Typography>
        </div>
      )
    }

    return (
      <div className={classes.addNoteWrapper}>
        <div className={classes.addNoteHeader}>
          <InputLabel>
            {observation.dataVerificationNote
              ? 'Data Verification Note'
              : 'Add a Data Verification Note'}
          </InputLabel>
          <IconButton
            aria-label="close"
            color="primary"
            onClick={() => setEditNote(!editNote)}
          >
            <CloseIcon />
          </IconButton>
        </div>
        <Formik
          enableReinitialize
          initialValues={{
            dataVerificationNote: observation.dataVerificationNote,
          }}
          onSubmit={handleUpdateDataVerificationNote}
          render={(props) => (
            <form onSubmit={props.handleSubmit}>
              <div className={classes.fieldWrapper}>
                <Field
                  className={classes.textArea}
                  component={'textarea'}
                  id="dataVerificationNote"
                  label="Data Verification Note"
                  name="dataVerificationNote"
                  value={props.values.dataVerificationNote}
                />
                <LoaderButton
                  disabled={props.isSubmitting}
                  text="Update Note"
                  type="submit"
                  variant="contained"
                />
              </div>
            </form>
          )}
        />
      </div>
    )
  }

  if (error) {
    return (
      <IonicPageLayout withBackButton>
        <Typography>{error.message}</Typography>
      </IonicPageLayout>
    )
  }

  if (!observation || loading) {
    return (
      <IonicPageLayout>
        <Loading />
      </IonicPageLayout>
    )
  }

  const statusOptions = getCodeItemOptions(statuses)

  const location = observation.nestbox || {
    utmEasting: observation.utmEasting,
    utmNorthing: observation.utmNorthing,
    utmZone: observation.utmZone,
    latitude: observation.latitude,
    longitude: observation.longitude,
  }

  const animation = {
    opacity: 1,
    x: 0,
  }

  const initial = {
    opacity: 0,
    x: animationDirection === 'left' ? -50 : 50,
  }

  return (
    <IonicPageLayout customBackButton="/verification">
      <AnimatePresence>
        <motion.div
          animate={animation}
          className={classes.data}
          exit={{ opacity: 0 }}
          initial={initial}
          transition={{ duration: 0.4, ease: ['easeOut'] }}
        >
          <div className={classes.headingArea}>
            <div>
              <Typography component="h2" variant="h3">
                {`${observation.species && observation.species.commonName}`}
              </Typography>
              <Typography component="p" variant="h6">
                {`${
                  (observation.species && observation.species.scientificName) ||
                  ''
                }`}
              </Typography>
            </div>
            <div className={classes.rightButtons}>
              <IonButton
                disabled={
                  tableData.pageIndex === 0 && tableData.currentIndex === 0
                }
                onClick={previousObservation}
              >
                <KeyboardArrowLeftIcon />
                Previous
              </IonButton>
              <IonButton
                disabled={
                  (tableData.pageIndex ===
                    Math.ceil(tableData.total / tableData.pageSize) &&
                    tableData.currentIndex === tableData.pageSize - 1) ||
                  !(tableData.currentIndex < tableData.total - 1)
                }
                onClick={nextObservation}
              >
                Next
                <KeyboardArrowRightIcon />
              </IonButton>
            </div>
          </div>
          <div className={classes.actions}>
            {['N', 'Q'].includes(observation.status.code) && (
              <div className={classes.rightButtons}>
                <IonButton
                  href={`/observation/edit/${observation.project.key}/${observation.entry}/0?fromVerification=${observation._id}`}
                  onClick={() => {
                    updatePersistTableDataOnStart(true)
                  }}
                  title="Edit"
                >
                  <EditIcon />
                  Edit
                </IonButton>
                <IonButton
                  onClick={() =>
                    presentAlert({
                      header: 'Are you sure?',
                      message:
                        'Data entered will be lost, do you want to proceed?',
                      buttons: [
                        {
                          text: 'Cancel',
                          role: 'cancel',
                          handler: () => {},
                        },
                        {
                          text: 'Yes',
                          role: 'confirm',
                          handler: () => {
                            handleDeleteObservation()
                          },
                        },
                      ],
                    })
                  }
                  title="Delete"
                  variant="contained"
                >
                  <DeleteIcon />
                  Delete
                </IonButton>
              </div>
            )}
            {user.level > 4 && checkChangeObservationStatus() && (
              <div className={classes.statusWrapper}>
                <Formik
                  enableReinitialize
                  initialValues={{ status: observation.status._id }}
                  onSubmit={handleUpdateStatus}
                  render={(props) => (
                    <form onSubmit={props.handleSubmit}>
                      <div className={classes.fieldWrapper}>
                        <Field
                          component={NatureMappingSelect}
                          id="status"
                          label="Observation Status"
                          name="status"
                          onBlur={props.setFieldTouched}
                          onChange={props.setFieldValue}
                          options={statusOptions}
                          value={props.values.status}
                        />
                        <LoaderButton
                          disabled={props.isSubmitting}
                          text="Update Status"
                          type="submit"
                          variant="contained"
                        />
                      </div>
                    </form>
                  )}
                />
              </div>
            )}
            {renderDataVetterNotePanel()}
          </div>
          <div className={classes.data}>
            <ObservationDetails observation={observation} />
            {user.level > 4 || user._id === observation.creator._id ? (
              <DisplayObservationLocation location={location} />
            ) : null}
          </div>
        </motion.div>
      </AnimatePresence>
    </IonicPageLayout>
  )
}

const mapStateToProps = ({ form, observations, tableData, user }) => {
  return {
    fullTableData: tableData,
    observation: observations.current,
    statuses: form && form.types && form.types.observationStatuses,
    user,
  }
}

const mapDispatchToProps = {
  clearCurrentObservation: actions.clearCurrentObservation,
  clearReviewData: actions.clearReviewData,
  deleteObservation: actions.deleteObservation,
  getObservation: actions.getObservation,
  updateObservationStatus: actions.updateObservationStatus,
  updateDataVerificationNote: actions.updateDataVerificationNote,
  setCurrentVerificationIndex: actions.setCurrentVerificationIndex,
  getDataVetterObservationsByStatus: actions.getDataVetterObservationsByStatus,
  updatePersistTableDataOnStart: actions.updatePersistTableDataOnStart,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(ObservationVerificationView))
