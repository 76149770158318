import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import {
  withStyles,
  Typography,
  InputLabel,
  Button,
  Checkbox,
} from '@material-ui/core'
import { Formik, FieldArray, Field } from 'formik'
import LoaderButton from '../../components/buttons/LoaderButton'
import actions from '../../actions'

const propTypes = {
  user: PropTypes.object.isRequired,
}

const styles = theme => ({
  root: {
    flexGrow: 1,
    marginBottom: '4rem',
  },
  button: {
    margin: theme.spacing(0.5),
  },
  label: {
    fontWeight: 500,
  },
  labelButton: {
    display: 'flex',
    alignItems: 'center',
  },
  grid: {
    display: 'grid',
    gridTemplateColumns: '1fr',

    [theme.breakpoints.up('sm')]: {
      gridTemplateColumns: '1fr 1fr',
    },
  },
  fieldWrapper: {
    marginBottom: '1rem',
  },
})

class AssignDataVetterSettingsForm extends Component {
  handleSubmit = values => {
    const { updateUserDataVettingAccess } = this.props

    updateUserDataVettingAccess(values)
  }

  render() {
    const { classes, projects, speciesTypes, user } = this.props

    return (
      <section className={classes.root}>
        <Typography gutterBottom variant="h2">
          Data Verification Access
        </Typography>
        <Typography gutterBottom>
          Select the projects and wildlife types that this data vetter can verify observations for.
        </Typography>
        <Formik
          enableReinitialize
          initialValues={{
            id: user._id,
            projects:
              user.settings && user.settings.dataVetterAccess
                ? user.settings.dataVetterAccess.projects
                : [],
            speciesTypes:
              user.settings && user.settings.dataVetterAccess
                ? user.settings.dataVetterAccess.speciesTypes
                : [],
          }}
          onSubmit={this.handleSubmit}
          render={props => (
            <form onSubmit={props.handleSubmit}>
              <Field component="input" name="id" type="hidden" />
              <div className={classes.grid}>
                <div className={classes.fieldWrapper}>
                  <div className={classes.labelButton}>
                    <InputLabel>Projects</InputLabel>
                    <Button
                      onClick={() => {
                        props.setFieldValue(
                          'projects',
                          props.values.projects.length > 0
                            ? []
                            : projects.map(project => project._id),
                        )
                      }}
                    >
                      {props.values.projects.length > 0
                        ? 'Deselect all'
                        : 'Select all'}
                    </Button>
                  </div>
                  <FieldArray
                    name="projects"
                    render={arrayHelpers => (
                      <div>
                        {projects.map(project => {
                          return (
                            <div key={project._id}>
                              <label
                                className={classes.label}
                                htmlFor={`vetter-access-${project._id}`}
                              >
                                <Checkbox
                                  checked={props.values.projects.includes(
                                    project._id,
                                  )}
                                  id={`vetter-access-${project._id}`}
                                  inputProps={{ name: 'projectIds' }}
                                  onChange={e => {
                                    if (e.target.checked)
                                      arrayHelpers.push(project._id)
                                    else {
                                      const idx = props.values.projects.indexOf(
                                        project._id,
                                      )
                                      arrayHelpers.remove(idx)
                                    }
                                  }}
                                  type="checkbox"
                                  value={project._id}
                                />
                                {project.name}
                              </label>
                            </div>
                          )
                        })}
                      </div>
                    )}
                  />
                </div>
                <div className={classes.fieldWrapper}>
                  <div className={classes.labelButton}>
                    <InputLabel>Wildlife types</InputLabel>
                    <Button
                      onClick={() => {
                        props.setFieldValue(
                          'speciesTypes',
                          props.values.speciesTypes.length > 0
                            ? []
                            : speciesTypes.map(type => type._id),
                        )
                      }}
                    >
                      {props.values.speciesTypes.length > 0
                        ? 'Deselect all'
                        : 'Select all'}
                    </Button>
                  </div>
                  <FieldArray
                    name="speciesTypes"
                    render={arrayHelpers => (
                      <div>
                        {speciesTypes.map(type => {
                          return (
                            <div key={type._id}>
                              <label
                                className={classes.label}
                                htmlFor={type._id}
                              >
                                <Checkbox
                                  checked={props.values.speciesTypes.includes(
                                    type._id,
                                  )}
                                  id={type._id}
                                  inputProps={{ name: 'speciesTypesIds' }}
                                  onChange={e => {
                                    if (e.target.checked)
                                      arrayHelpers.push(type._id)
                                    else {
                                      const idx = props.values.speciesTypes.indexOf(
                                        type._id,
                                      )
                                      arrayHelpers.remove(idx)
                                    }
                                  }}
                                  type="checkbox"
                                  value={type._id}
                                />
                                {type.name}
                              </label>
                            </div>
                          )
                        })}
                      </div>
                    )}
                  />
                </div>
              </div>
              <div>
                <LoaderButton
                  className={classes.button}
                  disabled={props.isSubmitting}
                  text="Update data vetter access"
                  type="submit"
                  variant="contained"
                />
              </div>
            </form>
          )}
        />
      </section>
    )
  }
}

AssignDataVetterSettingsForm.propTypes = propTypes

function mapStateToProps({ projects, form }) {
  return {
    projects,
    speciesTypes: form && form.types && form.types.speciesTypes,
  }
}

const mapDispatchToProps = {
  updateUserDataVettingAccess: actions.updateUserDataVettingAccess,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(AssignDataVetterSettingsForm))
